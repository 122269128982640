import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { BasicSettings } from "./BasicSettings";
import { LocationSettings } from "./LocationSettings";
import { EquipmentSettings } from "./EquipmentSettings";
import { CallSignSettings } from "./CallSignSettings";
import { ContestSettings } from "./ContestSettings";
import { Form, Formik } from "formik";
import { LogBookSettingsSaveAction } from "./LogBookSettingsAction";
import { LogbookSettingsForm } from "types/Models";
import { PotaSettings } from "./PotaSettings";
import { ExportSettings } from "./ExportSettings";
import { PotaModal } from "../LogBookQuickSettings/Modals";
import useTheme from "hooks/useTheme.hook";

const initialValues: LogbookSettingsForm = {
  name: "",
  description: "",
  logbookStyle: "",
  defaultLocation: "",
  radio: "",
  antenna: "",
  defaultCallSign: "",
  callSignType: "",
  defaultPower: "",
  contestPower: "",
  numberOfTransmitters: "",
  contestBand: "",
  assisted: "",
  operator: "",

  // WFD Specific
  contestTransmitters: "",
  contestClass: "",
  contestSection: "",
};

const LogbookSettings = () => {
  const { theme } = useTheme();
  const {
    saveLogbookSettings,
    locationForm,
    setLocationForm,
    locationLoading,
    findLocation,
    setLocationOption,
    locationOption,
  } = useLogbookSetting();

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full">
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          saveLogbookSettings(values);
          actions.setSubmitting(false);
        }}
      >
        <Form>
          <div className={`flex flex-col w-full gap-6 ${theme === 'dark' ? 'glassmorphism': 'bg-white' } px-6 py-4 pb-16`}>
            {/* Basic Settings */}
            <BasicSettings />

            {/* POTA Settings */}
            {/* <PotaSettings /> */}
            <PotaModal onClose={() => {}} settingDisplay />

            {/* Export Settings */}
            <ExportSettings />

            {/* Location Settings */}
            <LocationSettings
              {...{
                locationForm,
                setLocationForm,
                locationLoading,
                findLocation,
                setLocationOption,
                locationOption,
              }}
            />

            {/* Equipment Settings */}
            <EquipmentSettings />

            {/* CallSign Settings */}
            <CallSignSettings />

            {/* Contest Settings */}
            <ContestSettings />

            {/* Save Button */}
            <LogBookSettingsSaveAction />
          </div>
        </Form>
      </Formik>
    </main>
  );
};

export default LogbookSettings;
