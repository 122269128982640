import useGlobe from "hooks/globe.hook";
import Globe from "react-globe.gl";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import ReactResizeDetector from "react-resize-detector";

import { GlobeMapProps } from "types/Component";

import { HiOutlineGlobeAsiaAustralia } from "react-icons/hi2";

import RecentContactTable from "components/Table/RecentContactTable";
import Icons from "assets/Icons";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import useLogBook from "hooks/logBook.hook";
import FeaturedMembers from "components/FeaturedMembers";
import { globalNavigate } from "routes/GlobalRoutes";
import TodayLeaders from "components/TodayLeaders";
import { QuickActionBar } from "components/QuickActionBar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";

function GlobeMap({ setSelectedGlobe, setHoverArc, hoverArc }: GlobeMapProps) {
  const { logBookArc, OPACITY } = useGlobe();
  const [isWebGLEnabled, setIsWebGLEnabled] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const userProfile = useSelector(getUserProfileSelector);
  const { isLoading, allContacts, featuredMembers, isFeaturedMembersLoading } = useLogBook();

  useEffect(() => {
    const canvas = document.createElement("canvas");
    const context =
      canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    if (context) {
      setIsWebGLEnabled(true);
    }
  }, []);


  // we may reuse this to show a different banner
  const renderBanner = () => {
    const isFreeUser = !userProfile?.membershipStatus || userProfile?.membershipStatus === "free"; 
    if (!showBanner || !isFreeUser) return null;

    return (
      <div className="flex flex-col gap-3 lg:flex-row text-sm justify-between items-center glassmorphism w-full  px-4 py-2">
      <div className="text-center dark:text-white text-[#2b2e48] font-[Barlow] ">
        <span className="font-bold">Christmas Sale!</span> 10% off your WRL Membership with coupon code <span className="font-bold">CHRISTMAS</span> at checkout!
      </div>

      <div className="flex flex-row gap-6">
        <button onClick={()=>{
          if (userProfile){
            globalNavigate("/membership");
          }else{
            globalNavigate("/login");
          }
        }} className="bg-[#F82937] text-white shadow-custom-glow px-3 py-1 rounded-full font-[Barlow] font-semibold">
          Claim Your Discount
        </button>
        <img className="cursor-pointer" onClick={()=>{setShowBanner(false)}} src={Icons.CrossIcon} alt="" />
      </div>
    </div>
    );
  }

  return (
    <>
      <div className="h-fit relative flex flex-col items-center overflow-x-hidden w-full dashboardWrapper pb-4">
        {/* {renderBanner()} */}
        <div className="w-full">
          <QuickActionBar title="Quick Links" />
        </div>
        <div className="w-full">
          <h1 className="dark:text-white text-[#2b2e48] text-center font-[Play] text-4xl md:text-4xl xl:text-5xl mt-3">
            World Radio League
          </h1>
          <div className="flex justify-center items-center w-full">
            <p className="text-lg font-[Barlow] text-center dark:text-[#D0D6F9] text-[#71717a] mt-2 w-full md:w-[690px]">
              Log your ham contacts, win awards, and chat with other operators
              with the most advanced logbook on the planet.
            </p>
          </div>
          <div className="flex flex-col-reverse lg:flex-row justify-center relative w-full px-3 xl:px-8 mb-5">
            <div className="w-full lg:w-1/2">
              {isWebGLEnabled ? (
                <ReactResizeDetector handleWidth>
                  {({ width }) => {
                    return (
                      <Globe
                        globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
                        arcsData={logBookArc.slice(0, 500)}
                        arcDashLength={() => Math.random()}
                        arcDashGap={() => Math.random()}
                        arcDashAnimateTime={() => 6000}
                        width={width ? width - 20 : 200}
                        height={width ? width - 20 : 200}
                        backgroundColor="rgba(0,0,0,0)"
                        arcColor={(d: any) => {
                          const op = !hoverArc
                            ? OPACITY
                            : d === hoverArc
                            ? 0.9
                            : OPACITY / 4;
                          return [
                            `rgba(207, 9, 113, ${op})`,
                            `rgba(73, 103, 128, ${op})`,
                            `rgba(55, 164, 186, ${op})`,
                          ];
                        }}
                        onArcHover={(d: any) => {
                          setHoverArc(d);
                        }}
                        // pointsData={logBookArc}
                        pointColor={() => "orange"}
                        pointAltitude={0}
                        pointRadius={0.8}
                        pointsMerge={true}
                        arcStroke={0.5}
                      />
                    );
                  }}
                </ReactResizeDetector>
              ) : null}
            </div>
            <div className="w-full h-fit lg:w-1/2 dark:bg-[#0B0B38] bg-white mt-14">
              <div className="w-full border-b border-solid dark:border-b-white border-slate-400 flex items-center justify-between py-[15px] px-[24px]">
                <div className="flex items-center">
                  <img className="h-5 w-5 invert dark:filter-none dark:invert-0" src={Icons.TopChart} alt="icon" />
                  <p className="dark:text-white text-[#2b2e48] font-[play] font-bold text-base ml-[15px]">
                    MOST RECENT <span className="dark:text-[#17F9DA] text-[#1e40af]">CONTACTS</span>
                  </p>
                </div>
                <div
                  className="dark:text-[#17F9DA] text-[#1e40af] font-[play] text-sm cursor-pointer"
                  onClick={() => {
                    globalNavigate("/most-recent-contacts?tab=list");
                  }}
                >
                  View all
                </div>
              </div>
              <div className="px-4 mb-7 flex flex-col justify-center items-center">
                {isLoading ? (
                  <ActivityIndicator size={36} />
                ) : allContacts && allContacts.length ? (
                  <RecentContactTable lists={allContacts.slice(0, 5)} />
                ) : allContacts && allContacts.length === 0 ? (
                  <div className="h-[200px] w-full text-white font-[play] flex justify-center items-center">
                    No Contacts Found
                  </div>
                ) : null}
                {allContacts && allContacts.length ? (
                  <div className="w-[120px] mr-3 mt-4">
                    <BorderButtonSecondary
                      text={`View Recent`}
                      onClick={() =>
                        globalNavigate("/most-recent-contacts?tab=list")
                      }
                      className="sm:mb-0"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {/* <div className="flex justify-center items-center mb-14 mt-6">
            <div className="w-72">
              <BorderButtonSecondary
                text="Join for Free"
                onClick={() => {}}
                className=""
                fontStyle="font-bold"
              />
            </div>
          </div> */}
        </div>
        <TodayLeaders />
        <FeaturedMembers
          featuredMembers={featuredMembers ? featuredMembers : []}
          isLoading={isFeaturedMembersLoading}
        />
      </div>
      {/* <GlobeToggle onClick={setSelectedGlobe} /> */}
      <div
        className="absolute bottom-4 right-5 w-fit bg-white p-[11px] lg:pointer-events-none md:hidden rounded flex items-center cursor-pointer"
        onClick={() => setSelectedGlobe(false)}
      >
        <HiOutlineGlobeAsiaAustralia size={18} className="text-[#1F1F3F]" />
        <p className="text-[#1F1F3F] text-base font-[play] font-bold ml-2">
          Map
        </p>
      </div>
    </>
  );
}

export default GlobeMap;
