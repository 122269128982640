import React, { useEffect, useMemo, useState } from "react";
import useAllContestants from "hooks/useAllContestants";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import { mapStylesDark, mapStylesLight } from "constants/MapStyles";
import Icons from "assets/Icons";
import { UserAvatar } from "components/UserAvatar";
import CountryDxccFlag from "components/CountryDxccFlag";
import CustomBorderButton from "components/Button/CustomBorderButton";
import useConfig from "hooks/config.hook";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import useContest from "hooks/contest.hook";
import { getRelativeTime } from "helpers/Utils";
import { setUserContestDetail } from "store/Actions/contest.actions";
import { globalNavigate } from "routes/GlobalRoutes";
import { CONTEST } from "constants/Links";
import { useDispatch } from "react-redux";
import { FaRegCircleXmark } from "react-icons/fa6";
import useTheme from "hooks/useTheme.hook";
import ActivityIndicator from "components/Loading/ActivityIndicator";

const defaultCenter = {
  lat: 37.7749, // Default latitude (San Francisco)
  lng: -122.4194, // Default longitude (San Francisco)
};

const googleLibraries: any[] = ["geometry", "marker", "maps", "drawing"];

// Array of pin SVGs
const pinIcons = [Icons.Pin1, Icons.Pin2, Icons.Pin3, Icons.Pin4, Icons.Pin5];

// Function to get a random pin
const getRandomPin = () =>
  pinIcons[Math.floor(Math.random() * pinIcons.length)];

function ContestMap({ contestId }: { contestId: string }) {
  const dispatch = useDispatch();
  const { contestants } = useAllContestants(contestId);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API!,
    libraries: googleLibraries,
  });
  const {
    getSubscriptionLevelButtonColor,
    getSubscriptionLevelButtonBgColor,
    getMemberStatusText,
  } = useConfig();

  const {
    getContestantDetail,
    userContestantDetail,
    isUserContestantLoading,
    contestDetail,
  } = useContest();

  const [selectedContestant, setSelectedContestant] = useState<any>(null);
  const [lastActive, setLastActive] = useState<string | null>(null);
  const [totalQsoFreq, setTotalQsoFreq] = useState<string | null>(null);
  const { theme } = useTheme();

  useEffect(() => {
    if (userContestantDetail) {
      const userLogs = userContestantDetail?.userLogs;

      if (userLogs && Array.isArray(userLogs) && userLogs.length > 0) {
        const firstLog = userLogs[0];
        if (firstLog?.contactTimeStamp?.seconds) {
          setLastActive(
            getRelativeTime(firstLog?.contactTimeStamp?.seconds) || null
          );
        }

        if (firstLog?.frequency) {
          setTotalQsoFreq(firstLog?.frequency || null);
        }
      }
    }
  }, [userContestantDetail]);

  // Memoize the map center based on contestants
  const center = useMemo(() => {
    if (contestants.length > 0 && contestants[0].coordinates) {
      return {
        lat: contestants[0].coordinates.latitude,
        lng: contestants[0].coordinates.longitude,
      };
    }
    return defaultCenter;
  }, [contestants]);

  const options = useMemo(() => {
    return {
      styles: theme === "dark" ? mapStylesDark : mapStylesLight,
      streetViewControl: false,
      rotateControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      zoomControl: false,
      scrollwheel: true, // Default is true
    };
  }, [theme]);

  // Memoize the markers to avoid recalculation on every render
  const markers = useMemo(() => {
    if (!isLoaded) return [];
    const contestId = contestDetail?.id;

    return contestants.map((contestant: any) => (
      <Marker
        key={contestant.id}
        position={{
          lat: contestant?.coordinates?.latitude || 0,
          lng: contestant?.coordinates?.longitude || 0,
        }}
        title={contestant.name}
        icon={{
          url: getRandomPin(), // Use a random pin SVG
          scaledSize: new google.maps.Size(20, 30), // Scale the icon size
        }}
        onClick={() => {
          setSelectedContestant(contestant);
          const userId = contestant?.id;

          if (contestId && userId) {
            getContestantDetail(contestId, userId);
          }
        }}
      />
    ));
  }, [contestants, isLoaded, contestDetail]);

  if (!isLoaded) return <p>Loading Google Maps...</p>;

  return (
    <div className="w-full h-[465px] relative">
      <GoogleMap
        mapContainerClassName="map-container no-close-button-on-info-windows"
        zoom={2}
        options={options}
        center={center}
      >
        {markers}
        {selectedContestant ? (
          <InfoWindow
            position={{
              lat: selectedContestant?.coordinates?.latitude || 0,
              lng: selectedContestant?.coordinates?.longitude || 0,
            }}
            options={{
              pixelOffset: new google.maps.Size(0, -30), // Move the window up by 30 pixels
              disableAutoPan: false, // Disable auto pan
            }}
            onCloseClick={() => {
              setSelectedContestant(null);
            }}
          >
            {!isUserContestantLoading ? (
              <div className="flex flex-col p-4 w-[300px] relative">
                <div className="absolute top-2 right-2">
                  <FaRegCircleXmark
                    className="cursor-pointer"
                    onClick={() => setSelectedContestant(null)}
                    size={20}
                  />
                </div>
                <div className="flex w-full mb-4 gap-3 items-center">
                  <div className="relative flex items-center mr-3">
                    {selectedContestant?.image ? (
                      <img
                        className="w-[48px] h-[48px] rounded-full bg-[#f2f2f2]"
                        src={selectedContestant?.image}
                        alt=""
                      />
                    ) : (
                      <UserAvatar className="w-[48px] h-[48px] !text-[#010a41]" />
                    )}
                    <div className="absolute bottom-[0px] right-[-8px]">
                      <CountryDxccFlag
                        countryCode={selectedContestant?.country || ""}
                        flagCode={selectedContestant?.flagCode || ""}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-1">
                    <div className="font-[Play] font-semibold text-[#010A41]">
                      {selectedContestant?.firstName} -{" "}
                      {selectedContestant?.callSign}
                    </div>
                    <div className="w-[90px]">
                      <CustomBorderButton
                        text={getMemberStatusText(
                          selectedContestant.membershipStatus || "FREE"
                        )}
                        onClick={() => {}}
                        height="h-[18px]"
                        className=""
                        childHeight="h-[57px]"
                        childClassName="text-[12px] font-bold px-2"
                        fromBg={getSubscriptionLevelButtonColor(
                          "from",
                          getMemberStatusText(
                            selectedContestant.membershipStatus || "FREE"
                          )
                        )}
                        viaBg={getSubscriptionLevelButtonColor(
                          "via",
                          getMemberStatusText(
                            selectedContestant.membershipStatus || "FREE"
                          )
                        )}
                        toBg={getSubscriptionLevelButtonColor(
                          "to",
                          getMemberStatusText(
                            selectedContestant.membershipStatus || "FREE"
                          )
                        )}
                        childBgColor={getSubscriptionLevelButtonBgColor(
                          getMemberStatusText(
                            selectedContestant.membershipStatus || "FREE"
                          )
                        )}
                      />
                    </div>
                  </div>
                </div>
                {userContestantDetail?.contestRanking ? (
                  <div className="flex justify-between">
                    <div className="font-bold text-sm text-[#010a41]">
                      Current Ranking
                    </div>
                    <div className="font-normal text-sm text-[#010a41]">
                      {userContestantDetail?.contestRanking}
                    </div>
                  </div>
                ) : null}
                {userContestantDetail?.user?.foundingMemberCount ? (
                  <div className="flex justify-between">
                    <div className="font-bold text-sm text-[#010a41]">
                      Founding Member #
                    </div>
                    <div className="font-normal text-sm text-[#010a41]">
                      {userContestantDetail?.user?.foundingMemberCount}
                    </div>
                  </div>
                ) : null}

                <div className="h-[2px] w-full my-2 bg-gradient-to-br from-[#9c27b0] to-[#651fff] opacity-20"></div>
                {lastActive ? (
                  <div className="flex justify-between">
                    <div className="font-Play font-bold text-sm text-[#2650fd]">
                      Last Active:
                    </div>
                    <div className="font-normal text-sm text-[#2650fd]">
                      {lastActive}
                    </div>
                  </div>
                ) : null}
                {userContestantDetail?.totalQso ? (
                  <div className="flex justify-between">
                    <div className="font-Play font-bold text-sm text-[#2650fd]">
                      Total QSOs:
                    </div>
                    <div className="font-normal text-sm text-[#2650fd]">
                      {userContestantDetail?.totalQso}
                    </div>
                  </div>
                ) : null}
                {totalQsoFreq ? (
                  <div className="flex justify-between">
                    <div className="font-Play font-bold text-sm text-[#2650fd]">
                      Latest QSO Frequency:
                    </div>
                    <div className="font-normal text-sm text-[#2650fd]">
                      {totalQsoFreq}
                    </div>
                  </div>
                ) : null}

                <div className="h-[2px] w-full my-2 bg-gradient-to-br from-[#9c27b0] to-[#651fff] opacity-20"></div>
                <div className="flex justify-between w-full gap-4">
                  {/* <div className="w-[50%]">
                    <BorderButtonSecondary
                      text={`copy to log`}
                      onClick={() => {
                        // open in google maps in new tab with lat long
                      }}
                      className="sm:mb-0 cursor-pointer uppercase"
                      height="h-6"
                      childClassName="text-xs bg-white text-[#010A41] font-bold"
                    />
                  </div> */}
                  <div className="w-[50%]">
                    <BorderButtonSecondary
                      text={`view statistics`}
                      onClick={() => {
                        dispatch(setUserContestDetail(null));
                        globalNavigate(
                          `${CONTEST}/user-logbook-detail?contest_name=${contestDetail?.name}&contest_id=${contestId}&user_id=${selectedContestant?.id}&tab=entries`,
                          {
                            state: {
                              contestPassed: true,
                            },
                          }
                        );
                      }}
                      className="sm:mb-0 cursor-pointer uppercase"
                      height="h-6"
                      childClassName="text-xs bg-white text-[#010A41] font-bold"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <ActivityIndicator size={12} />
              </>
            )}
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </div>
  );
}

export default ContestMap;
