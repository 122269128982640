import { UserNotification } from "types/Models";
import {} from "types/State";

export const FETCH_USER_NOTIFICATIONS = "FETCH_USER_NOTIFICATIONS";
export const SET_USER_NOTIFICATION = "SET_USER_NOTIFICATION";
export const UPDATE_USER_NOTIFICATIONS = "FETCH_USER_NOTIFICATIONS";

export const SET_USER_NORMAL_NOTIFICATIONS = "SET_USER_NORMAL_NOTIFICATIONS";
export const MARK_USER_NORMAL_NOTIFICATIONS = "MARK_USER_NORMAL_NOTIFICATIONS";
export const FETCH_USER_NORMAL_NOTIFICATIONS =
  "FETCH_USER_NORMAL_NOTIFICATIONS";

export type NotificationActionPayload = {
  type: string;
  payload?: any;
};

export function fetchUserNotifications(): NotificationActionPayload {
  return {
    type: FETCH_USER_NOTIFICATIONS,
  };
}

export function setUserNotification(
  data: UserNotification
): NotificationActionPayload {
  return {
    type: SET_USER_NOTIFICATION,
    payload: data,
  };
}

export function updateUserNotification(
  data: UserNotification
): NotificationActionPayload {
  return {
    type: UPDATE_USER_NOTIFICATIONS,
    payload: data,
  };
}
export function setUserNormalNotifications(
  data: UserNotification[]
): NotificationActionPayload {
  return {
    type: SET_USER_NORMAL_NOTIFICATIONS,
    payload: data,
  };
}

export function markReadUserNormalNotifications(): NotificationActionPayload {
  return {
    type: MARK_USER_NORMAL_NOTIFICATIONS,
  };
}

export function fetchUserNormalNotifications(): NotificationActionPayload {
  return {
    type: FETCH_USER_NORMAL_NOTIFICATIONS,
  };
}
