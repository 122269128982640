import React, { useMemo, useEffect, useState, useRef } from "react";
import { GoogleMap, useLoadScript, PolylineF } from "@react-google-maps/api";
import { mapStylesDark, mapStylesLight } from "constants/MapStyles";
import RouteLoading from "components/Loading/RouteLoading";
import { FlatGoogleMapProps } from "types/Component";
import { MarkerLines, MarkerPoint } from "types/State";
import useMap from "hooks/map.hook";

import ContactMarker from "components/GoogleMap/ContactMarker";
import { generateCurvePath } from "helpers/Utils";
import useTheme from "hooks/useTheme.hook";

const MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API;
const googleLibraries: any[] = ["geometry", "marker", "maps", "drawing"];

const CurvedLine = ({ start, end, lineData, setHoverArc }: any) => {
  const onLoad = (polyline: any) => {
    // console.log("polyline: ", polyline);
  };

  const options = {
    strokeColor: "#17F9DA",
    strokeOpacity: 0.8,
    strokeWeight: 1,
    fillColor: "#17F9DA",
    fillOpacity: 0.35,
    clickable: true,
    draggable: false,
    editable: false,
    visible: true,
    radius: 30000,
    zIndex: 1,
    geodesic: true,
  };

  const curvePath = [start, end];

  return (
    <PolylineF
      onLoad={onLoad}
      path={curvePath}
      options={options}
      onMouseOver={() => {
        if (setHoverArc) {
          setHoverArc(lineData);
        }
      }}
      onMouseOut={() => {
        if (setHoverArc) {
          setHoverArc(null);
        }
      }}
    />
  );
};

const UserContactMap = ({
  className,
  setHoverArc,
  captureSnapshot,
  guestMode,
  showShare,
  restrictBounds,
  shouldFitBounds,
}: FlatGoogleMapProps) => {
  const [mapMarkers, setMapMarkers] = useState<MarkerPoint[]>([]);
  const [polylines, setPolylines] = useState<MarkerLines[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const mapRef = useRef<any>(null);

  const { theme } = useTheme();

  const { allUserContactMarkers, allUserContactMarkersLines } = useMap();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: MAP_API_KEY!,
    libraries: googleLibraries,
  });
  const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);

  const onMapLoad = (map: any) => {
    mapRef.current = map;
  };
  // Helper function to ensure uniqueness based on lat and lng
  function getUniqueMarks(marks: { lat: number; lng: number }[]) {
    const uniqueSet = new Map();
    marks.forEach((mark) => {
      if (mark) {
        const key = `${mark?.lat}-${mark?.lng}`;
        if (!uniqueSet.has(key)) {
          uniqueSet.set(key, mark);
        }
      }
    });
    return Array.from(uniqueSet.values());
  }

  useEffect(() => {
    const newMarkers = [...allUserContactMarkers];

    if (guestMode) {
      const myMarks = newMarkers
        .slice(0, 60)
        .filter((mark: any) => mark?.isMyLocation);
      const theirMarks = newMarkers
        .slice(0, 60)
        .filter((line: any) => !line?.isMyLocation);
      const uniqueMyMarks = getUniqueMarks(myMarks);

      setMapMarkers([...theirMarks, ...uniqueMyMarks]);
    } else {
      console.log("newMarkers: ", newMarkers.length);

      setMapMarkers(newMarkers);
    }
  }, [allUserContactMarkers]);

  useEffect(() => {
    const newLines = [...allUserContactMarkersLines];

    if (guestMode) {
      const lines = newLines.slice(0, 30);
      setPolylines(lines);
    } else {
      setPolylines(newLines);
    }
  }, [allUserContactMarkersLines]);

  useEffect(() => {
    if (isLoaded) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  useEffect(() => {
    if (mapRef.current && mapMarkers.length > 0 && shouldFitBounds) {
      const bounds = new window.google.maps.LatLngBounds();

      mapMarkers.forEach((marker) => {
        bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
      });

      // Fit the map to the bounds
      mapRef.current.fitBounds(bounds);
    }
  }, [mapMarkers, mapRef.current]);

  return (
    <div className={className}>
      {!isLoaded || isLoading ? (
        <RouteLoading />
      ) : (
        <GoogleMap
          mapContainerClassName="map-container no-close-button-on-info-windows"
          center={center}
          zoom={2}
          onLoad={onMapLoad}
          options={{
            styles: theme === "dark" ? mapStylesDark : mapStylesLight,
            ...(restrictBounds
              ? {
                  restriction: {
                    latLngBounds: {
                      north: 85,
                      south: -85,
                      west: -179.9999,
                      east: 179.9999,
                    },
                    strictBounds: true,
                  },
                }
              : {}),
          }}
        >
          {mapMarkers
            ? mapMarkers.map((position, index) => {
                return (
                  <ContactMarker
                    position={position}
                    key={`${position.lat}-${position.lng}-${index}`}
                    index={index}
                  />
                );
              })
            : null}
          {polylines.map((line: MarkerLines, index) => {
            return (
              <CurvedLine
                key={`${line.start}-${line.end}-${index}`}
                start={line.start}
                end={line.end}
                lineData={line}
                setHoverArc={setHoverArc}
              />
            );
          })}
        </GoogleMap>
      )}
    </div>
  );
};

export default UserContactMap;
