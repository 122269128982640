import { RegionDropdown } from "react-country-region-selector";
import { TitledRegionPickerProps } from "types/Component";

function RegionDropDownPicker({
  placeHolder,
  name,
  type,
  text,
  setText,
  className,
  width,
  title,
  error,
  props,
  value,
  tabindex,
  country,
  info,
}: TitledRegionPickerProps) {
  return (
    <div className={`${width ? width : "w-full"}`}>
      <RegionDropdown
        name={name}
        country={country ? country : ""}
        value={value ? value : ""}
        onChange={(txt) => setText(txt)}
        classes={`h-10 dark:bg-[#23234C] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none px-3 text-sm font-[Barlow] w-full ${
          className ? className : ""
        } ${error ? "!border !border-orange-700 !border-solid" : ""}`}
      />
    </div>
  );
}

export default RegionDropDownPicker;
