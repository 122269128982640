import { formatBandValue } from "helpers/Utils";

interface ModeIconProps {
  mode: string;
  className?: string;
}

export const ModeIcon: React.FC<ModeIconProps> = ({ mode, className = "" }) => {
  const getShapeForMode = (mode: string) => {
    switch (mode.toUpperCase()) {
      case 'CW':
        return 'rounded-full'; // Circle
      case 'SSB':
        return 'clip-path-triangle'; // Triangle
      case 'FM':
        return 'clip-path-pentagon'; // Pentagon
      case 'FT8':
        return 'clip-path-star'; // Star
      case 'LSB':
        return 'clip-path-arrow'; // Arrow
      default:
        return 'rounded-sm'; // Square/Rectangle default
    }
  };

  return (
    <div
      className={`w-4 h-4 bg-[#17f9da] ${getShapeForMode(mode)} ${className}`}
    />
  );
};

const LogBookFooter: React.FC<{
    bands: string[];
    modes: string[];
  }> = ({ bands, modes }) => {
    const getBandColor = (band: string) => {
      const bandMap = {
        '2200': '#AAAAAA',
        '630': '#FFE000',
        '160': '#093F00',
        '80': '#777777',
        '60': '#FFA500',
        '40': '#FF0000',
        '30': '#800080',
        '20': '#0000FF',
        '17': '#444444',
        '15': '#00FFFF',
        '12': '#FF00FF',
        '10': '#FFC0CB',
        '6': '#A276FF',
        '2': '#92FF7F'
      };
      return bandMap[band as keyof typeof bandMap] || '#FFFFFF';
    };

    return (
      <div className="flex-1 h-[45px] min-h-[45px] max-h-[45px] bg-black/10 dark:bg-white/10 backdrop-blur-[81.55px] flex items-center justify-center px-4 overflow-hidden">
        {/* Bands Section */}
        <div className="flex items-center gap-2">
          {bands.map((band, index) => (
            <span 
              key={band}
              className="text-sm font-normal font-['Barlow']"
              style={{ color: getBandColor(band) }}
            >
              {formatBandValue(band)}{index !== bands.length - 1 && ","}
            </span>
          ))}
        </div>

        {/* Divider */}
        <div className="h-5 w-[1px] bg-white/20 mx-4" />

        {/* Modes Section */}
        <div className="flex items-center gap-5">
          {modes.map((mode) => (
            <div key={mode} className="flex items-center gap-[5px]">
              <ModeIcon mode={mode} />
              <div className="text-[#1F1F3F] dark:text-white text-sm font-normal font-['Barlow'] leading-3">
                {mode.toUpperCase()}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

export default LogBookFooter;