import ActivityIndicator from "components/Loading/ActivityIndicator";
import TableEntries from "components/Table/TableEntries";
import TableEntriesCompact from "components/Table/TableEntries/TableEntriesCompact";
import TableEntriesContest from "components/Table/TableEntries/TableEntriesContest";
import React from "react";
import { EntriesTableCompProps } from "types/Component";

function EntriesTableComp({
  lists,
  isSavingOnLoading,
  isLoading,
  editable,
  missingFields,
  guestUser,
  setDateOrder,
  showSort,
  showExchange,
  exchangeOne,
  exchangeTwo,
  exchangeThree,
  exchangeFour,
  isCompact = false,
  isWFDLogBook = false,
}: EntriesTableCompProps) {
  if (lists && lists.length) {
    return (
      <div className="">
        {isCompact ? (
          <TableEntriesCompact
            lists={lists}
            editable={editable}
            missingFields={missingFields}
            guestUser={guestUser}
            setDateOrder={setDateOrder}
            showSort={showSort}
            showExchange={showExchange}
            exchangeOne={exchangeOne}
            exchangeTwo={exchangeTwo}
            exchangeThree={exchangeThree}
            exchangeFour={exchangeFour}
            isWFDLogBook={isWFDLogBook}
          />
        ) : isWFDLogBook ? (
          <TableEntriesContest
            lists={lists}
            editable={editable}
            missingFields={missingFields}
            guestUser={guestUser}
            setDateOrder={setDateOrder}
            showSort={showSort}
            showExchange={showExchange}
            exchangeOne={exchangeOne}
            exchangeTwo={exchangeTwo}
            exchangeThree={exchangeThree}
            exchangeFour={exchangeFour}
          />
        ) : (
          <TableEntries
            lists={lists}
            editable={editable}
            missingFields={missingFields}
            guestUser={guestUser}
            setDateOrder={setDateOrder}
            showSort={showSort}
            showExchange={showExchange}
            exchangeOne={exchangeOne}
            exchangeTwo={exchangeTwo}
            exchangeThree={exchangeThree}
            exchangeFour={exchangeFour}
          />
        )}
        {isLoading || isSavingOnLoading ? (
          <div className="w-full h-full absolute top-0 left-0 dark:bg-[#151541] bg-white bg-opacity-100 items-center flex justify-center">
            <div className="h-[200px] w-full">
              <ActivityIndicator size={36} />
            </div>
          </div>
        ) : null}
      </div>
    );
  } else if (lists && lists.length === 0) {
    return (
      <div className="h-[200px] w-full dark:text-white text-[#2b2e48] font-[play] flex justify-center items-center">
        No Contacts Found
      </div>
    );
  } else {
    return null;
  }
}

export default EntriesTableComp;
