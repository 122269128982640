import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { convertDistance, getFlatActivityIcon, getShortDistanceUnit } from "helpers/Utils";
import useActivity from "hooks/activity.hook";
import { useUserSettings } from "hooks/useUserSettings.hook";
import React, { useState, useEffect } from "react";
import { FaSquareCheck } from "react-icons/fa6";
import { TbSquareXFilled } from "react-icons/tb";
import { useSelector } from "react-redux";
import { getActivityListViewSelector } from "store/Reducers/pota.reducer";
import { ActivitiesMapData } from "types/Models";

type ActivityListProps = {
  onCreateLogbook?: (park: ActivitiesMapData) => void;
};

const ActivityList = ({
  onCreateLogbook,
}: ActivityListProps): React.ReactElement => {
  const activitiesListData = useSelector(getActivityListViewSelector);
  const { fetchActivityListView } = useActivity();
  const { settings } = useUserSettings();
  useEffect(() => {
    fetchActivityListView();
  }, []);

  return (
    <div className="w-full overflow-scroll px-4">
      <table className="w-full min-w-max table-auto">
        <thead className="">
          <tr className="bg-[#94a3b883] dark:bg-[#3C3C58] py-2.5 border-0 border-b border-slate-300 border-solid dark:text-white text-[#2b2e48]">
            <th className="py-3 font-[Barlow] text-sm md:text-left">
              <div className="flex items-center md:ml-4">
                <span>Site Number</span>
              </div>
            </th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">
              <div className="flex items-center md:ml-0">
                <span>Site Name</span>
              </div>
            </th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">
              Distance from your location
            </th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">Type</th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">Actions</th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">
              Activated
            </th>
            {/* <th className="py-3 font-[Barlow] text-sm"></th> */}
          </tr>
        </thead>
        <tbody>
          {activitiesListData?.map((park) => (
            <tr
              key={park.id}
              className={`dark:bg-[#1F1F3F] bg-white border-0 border-b border-slate-300 border-solid`}
            >
              <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3 md:pl-4">
                {park.reference}
              </td>
              <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3 md:pl-4">
                {park.name}
              </td>
              <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3 md:pl-4">
                {convertDistance(
                  park.distanceInMiles ?? 0,
                  settings.distanceUnit
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                })} {getShortDistanceUnit(settings.distanceUnit)}
              </td>
              <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3 md:pl-4">
                <div className="flex items-center">
                  <img
                    src={getFlatActivityIcon(park.type)}
                    alt="activity icon"
                    className="w-5 h-5 mr-1"
                  />
                  {park.type}
                </div>
              </td>
              <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3 md:pl-4">
                <div className="flex justify-evenly w-full flex-col md:flex-row">
                  <div className="w-[100%] md:w-[45%] xl:w-[40%] mb-2 md:mb-0">
                    <a
                      target="_blank"
                      href={`https://www.google.com/maps/search/?api=1&query=${park?.latitude},${park?.longitude}`}
                      rel="noreferrer"
                    >
                      <BorderButtonSecondary
                        text={`OPEN IN MAPS`}
                        onClick={() => {
                          // open in google maps in new tab with lat long
                        }}
                        className="sm:mb-0 cursor-pointer"
                        height="h-6"
                        childClassName="text-xs bg-white text-[#010A41] font-bold"
                      />
                    </a>
                  </div>
                  <div className="w-[100%] md:w-[50%] xl:W-[40%]">
                    <BorderButtonSecondary
                      text={`+ CREATE LOGBOOK`}
                      onClick={() => {
                        onCreateLogbook && onCreateLogbook(park);
                      }}
                      className="sm:mb-0 cursor-pointer"
                      height="h-6"
                      childClassName="text-xs bg-white text-[#010A41] font-bold"
                    />
                  </div>
                </div>
              </td>
              <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3 md:pl-4">
                <div className="flex items-center">
                  {park?.activated ? (
                    <FaSquareCheck color="#15B86B" />
                  ) : (
                    <TbSquareXFilled color="#BF1D27" size={14} />
                  )}

                  <span className="ml-2">{park?.activated ? "Yes" : "No"}</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ActivityList;
