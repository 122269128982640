import { FC, useState } from "react";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import Header from "components/Header";
import { SelectableButtonsGroup } from "components/SelectableButtonsGroup";
import { DistanceUnit } from "types/Models";
import useTheme from "hooks/useTheme.hook";
import { useUserSettings } from "hooks/useUserSettings.hook";
import Icons from "assets/Icons";

export const UserSettings: FC = () => {
  const { theme } = useTheme();
  const { settings, updateSettings, isLoading } = useUserSettings();
  const [pendingSettings, setPendingSettings] = useState(settings);

  return (
    <main className="relative w-full h-full">
      <Header title="My Settings" />
      <div className="w-full flex justify-center p-3.5 md:p-7 dashboardWrapper overflow-x-hidden">
        <div className={`${theme === 'dark' ? 'glassmorphism' : 'bg-white'} w-full h-fit pb-6`}>
          {/* Header Section */}
          <div className="px-3 md:px-7 py-5 flex items-center justify-center border-b border-slate-400 border-solid">
            <div className="flex items-center gap-2">
              <img
                src={Icons.SettingsIcon}
                alt=""
                className={`w-[30px] h-[30px] mr-2 ${theme === 'dark' ? '' : 'invert'}`}
              />
              <p className="font-[play] font-bold text-[25px] dark:text-white text-[#2b2e48]">
                SETTINGS
              </p>
            </div>
          </div>

          {/* Settings Content */}
          <div className="px-3 md:px-7 mt-6">
            <div className="flex flex-col dark:text-white text-[#2b2e48] gap-4">
              <div className="font-bold font-Play text-xl">Basic Settings</div>
              
              <div className="flex flex-col gap-4">
                <div>
                  <div className="mb-3 font-['Barlow'] font-bold">Distance Display:</div>
                  <SelectableButtonsGroup
                    initialSelected={settings?.distanceUnit || DistanceUnit.MILES}
                    actions={[
                      {
                        id: DistanceUnit.MILES,
                        label: "Miles",
                      },
                      {
                        id: DistanceUnit.KILOMETERS,
                        label: "Kilometers",
                      },
                    ]}
                    onButtonChange={(action) => {
                      if (action?.id) {
                        setPendingSettings({
                          ...pendingSettings,
                          distanceUnit: action.id as DistanceUnit
                        });
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Save Button */}
            <div className="flex justify-start mt-10">
              <div className="w-[200px]">
                <BorderButtonSecondary
                  text="Save Settings"
                  onClick={() => {
                    updateSettings(pendingSettings);
                  }}
                  className="sm:mb-0 cursor-pointer"
                  height="h-9"
                  childClassName="text-md"
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}; 