import { MouseEvent, ReactNode } from "react";
import {
  MarkerPoint,
  NewLogBook,
  UserContactPaginationProp,
  UserProfileContactsWithPagination,
} from "types/State";
import {
  Antenna,
  ArcType,
  Award,
  ContestLeadrBoard,
  ContestModel,
  DXCCList,
  FeaturedMember,
  LeaderData,
  LogBookContactModel,
  LogBookModel,
  Radios,
  SavedLocation,
  SortConfig,
  SpotFilters,
  SpotModel,
  Stations,
  UserAwards,
  UserNotification,
  UserProfile,
} from "types/Models";
import { NewContacts } from "store/Actions/guest.action";

export type BorderButtonPrimaryProps = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  isLoading?: boolean;
  height?: string;
  type?: "button" | "submit" | "reset";
  childClassName?: string;
};

export type MapNavButtonProps = {
  onClick: () => void;
};

export type TableLeaderBoardProps = {
  list: UserProfile[];
  selectedTab?: string;
  contest?: boolean;
  contestId?: string;
  contestName?: string;
  contestPassed?: boolean;
  distance?: boolean;
  weekly?: boolean;
  isWFDLogBook?: boolean;
};

export type TableRecentContactProps = {
  list: LogBookContactModel[];
};

export type AwardListProps = {
  userAwards: UserAwards[];
  selectedAward: UserAwards | null;
  setSelectedAward: (item: UserAwards) => void;
  setFeaturedStatusForAward: (award: UserAwards) => void;
  guestUser: boolean;
  userCallSign?: string;
  user?: UserProfile;
};

export type TableLeaderBoardColProps = {
  userData: UserProfile | ContestLeadrBoard;
  index: number;
  selectedTab?: string;
  contest?: boolean;
  className?: string;
  contestId?: string;
  contestName?: string;
  contestPassed?: boolean;
  distance?: boolean;
  weekly?: boolean;
  isWFDLogBook?: boolean;
};

export type TableRecentContactColProps = {
  contact: LogBookContactModel;
  index: number;
};

export type TableWrapperLeaderBoardProps = {
  isLoading: boolean;
  list: UserProfile[];
  selectedTab?: string;
  distance?: boolean;
  leaderboard?: boolean;
  weekly?: boolean;
};

export type TableWrapperContactsProps = {
  isLoading: boolean;
  list: LogBookContactModel[];
};

export type BorderButtonSecondaryProps = {
  text: string;
  onClick: () => void;
  className?: string;
  isLoading?: boolean;
  fontStyle?: string;
  height?: string;
  childHeight?: string;
  childClassName?: string;
  icon?: any;
  selected?: boolean;
  tabIndex?: number;
};

export interface ButtonWithLoadingAnimationProps
  extends BorderButtonSecondaryProps {
  successState: boolean;
  endAnimation: () => void;
}

export type CustomBorderButtonProps = {
  text: string;
  onClick: () => void;
  className?: string;
  isLoading?: boolean;
  fontStyle?: string;
  height?: string;
  childHeight?: string;
  childClassName?: string;
  childBgColor?: string;
  fromBg?: string | void;
  viaBg?: string | void;
  toBg?: string | void;
};

export type CongratulationModalProps = {
  notificationData: UserNotification;
  setNotificationData: (value: null) => void;
};

export type BorderDisplayCompProps = {
  contactDetail: ArcType | null;
  selectedGlobe: boolean;
  positionClassName?: string;
  recentGlobeStyle?: string;
};

export type GlobeMapProps = {
  setSelectedGlobe: (prev: boolean) => void;
  setHoverArc: (prev: ArcType) => void;
  hoverArc: ArcType | null;
};

export type PricingListProps = {
  icon: string;
  subscriptionType: string;
  benefits: string[];
  YearlyPrice: number;
  month: number;
  btnTxt: string;
  priceId: string;
  onPress: (lookupKey: string) => void;
};

export type ButtonGradientProps = {
  text: string;
  onClick: () => void;
  className?: string;
  isLoading?: boolean;
  fontStyle?: string;
};

export type InputWithLabelProps = {
  multiline?: boolean;
  label: string;
  placeholder?: string;
  name?: string;
  setText: (e: any) => void;
  error?: string | null;
  defaultValue?: string | null;
  capitalize?: boolean;
  onBlur?: (e: any) => void;
};

export type InputWithLabelPropsDefault = {
  multiline?: boolean;
  label: string;
  placeholder?: string;
  name?: string;
  text: string;
  setText: (e: any) => void;
  error?: string | null;
  defaultValue?: string | null;
  capitalize?: boolean;
  onBlur?: (e: any) => void;
  type?: string;
};

export type CountyPickerWithLabelProps = {
  multiline?: boolean;
  label: string;
  placeholder?: string;
  name?: string;
  setText: (e: any) => void;
  error?: string | null;
  defaultValue?: string | null;
  value?: any;
  className?: string;
};

export type RegionPickerWithLabelProps = {
  multiline?: boolean;
  label: string;
  placeholder?: string;
  name?: string;
  setText: (e: any) => void;
  error?: string | null;
  defaultValue?: string | null;
  value?: string;
  className?: string;
  country?: string;
};

export type RegionPickerProps = {
  multiline?: boolean;
  label: string;
  placeholder?: string;
  name?: string;
  setText: (e: any) => void;
  error?: string | null;
  defaultValue?: string | null;
  value?: string;
  className?: string;
  country?: string;
};

export type ButtonGlowProps = {
  onClick: () => void;
  text: string;
  selected: boolean;
  icon?: any;
  disabled?: boolean;
  info?: string;
  showLoading?: boolean;
};

export type TableCustomProps = {
  list: LogBookModel[];
  setSortConfig?: React.Dispatch<React.SetStateAction<SortConfig>>;
};

export type ActivityLogTableProps = {
  list: any[];
  itemClick: (item: any) => void;
};

export type TextDescProps = {
  text?: string;
};

export type TableColProps = {
  className?: string;
  item: LogBookModel;
};

export type ImportsTableColProps = {
  className?: string;
  item: any;
  onClick: () => void;
};

export type SaveLocationTableColProps = {
  item: SavedLocation;
  index: number;
};

export type LeaderBoardTabProps = {
  tabs: string[];
  setSelectedTab: (prev: string) => void;
  selectedTab: string;
  fetchUsersDataByMode: (prev: string) => void;
};

export type TableEntColProps = {
  className?: string;
  item: any;
  editable?: boolean;
  missingFields?: boolean;
  live?: boolean;
  guestUser?: boolean;
  showExchange?: boolean;
  onImpersonateClick?: (item: any) => void;
};
export type TableContestProps = {
  className?: string;
  item: ContestModel;
  editable?: boolean;
  live?: boolean;
  passed?: boolean;
  userEnrolledContests?: string[];
  index: number;
  onEdit?: (e: MouseEvent<HTMLElement>, item: ContestModel) => void;
  onDelete?: (e: MouseEvent<HTMLElement>, item: ContestModel) => void;
};

export type RecentContactTableColProps = {
  className?: string;
  item: LogBookContactModel;
};

export type TableColAnimationWrapperProps = {
  itemId?: string;
  animateContacts: NewContacts | null;
};

export type ActivityIndicatorProps = {
  size: number;
  className?: string;
};

export interface BorderButtonSquareProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  children?: any;
}

export type HeaderProps = {
  title?: string;
  subTitle?: string;
  blueText?: string;
};

export type DropDownInputProps = {
  value: string;
  onChange: (text: string) => void;
  lists?: string[];
};

export interface SocialLoginButtonProps {
  text: string;
  icon: any;
  onClick?: () => void;
  isLoading?: boolean;
  imageClass?: string;
}

export type UserProfileLogBookProps = {
  userData: UserProfile | null;
};

export type UserProfileChartProps = {
  data: any;
  totalAmount: number;
  title: string;
  listSurfix?: string;
};

export type TabNavGlowProps = {
  selectedIndex: string | null;
  setSelectedIndex: (prev: string) => void;
  isUserEnrolled?: boolean;
  contestStatus?: string;
  contestantDetailLoading?: boolean;
  tabs?: string[];
};

export type TopTabNavProps = {
  selectedTabIndex: string;
  setSelectedTabIndex: (prev: string) => void;
  firstIndexText: string;
  secondIndexText: string;
  thirdIndexText?: string;
  fourthIndexText?: string;
  openSpottingPopup?: () => void;
  openMapPopup?: () => void;
  showExternal?: boolean;
  className?: string;
};

export interface PasswordInputProps {
  password: string | undefined;
  setPassword: (e: any) => void;
  error?: string;
  className?: string;
}

export type GlobeToggleProps = {
  onClick: (prev: boolean) => void;
};

export interface TextInputProps {
  placeHolder: string;
  name: string;
  type?: string;
  text: string | undefined;
  setText: (e: any) => void;
  error?: string | null;
  className?: string | null;
  multiple?: boolean;
}

export interface TextInputWithCheckMarkProps {
  placeHolder: string;
  name: string;
  type?: string;
  text: string | undefined;
  setText: (e: any) => void;
  error?: string | boolean;
  className?: string | null;
  isLoading: boolean;
  onBlur?: (e: any) => void;
}

export type TitledInputProps = {
  placeHolder: string;
  name: string;
  type?: string;
  value?: string | number;
  setText: (e: any, activityType?: string) => void;
  className?: string | null;
  width?: string;
  title?: string;
  error?: string | any;
  touched?: boolean;
  max?: string;
  props?: any;
  tabindex?: number;
  info?: string;
  onBlur?: (e: any) => void;
  onFocus?: (e: any) => void;
  isLoading?: boolean;
  status?: string | null;
  statusBg?: string;
  multiple?: boolean;
  containerClass?: string;
  suggestions?: Array<any> | null;
  suggestionsFormatter?: (suggestion: any) => string;
  onSuggestionClick?: (suggestion: any) => void;
  disabled?: boolean;
  activityType?: string;
  onActivityClose?: () => void;
  allCaps?: boolean;
  flashField?: boolean;
};

export type DropDownTitledProps = {
  placeHolder: string;
  name: string;
  type?: string;
  value?: string;
  onChange: (e: any) => void;
  className?: string | null;
  width?: string;
  title?: string;
  error?: string | any;
  touched?: boolean;
  max?: string;
  props?: any;
  lists: Array<string | { [key: string]: any }>;
  getTitle?: (item: any) => string;
  getValue?: (item: any) => string | number;
  tabindex?: number;
  info?: string;
  contest?: boolean;
  containerClass?: string;
};

export type TitledRegionPickerProps = {
  placeHolder: string;
  name: string;
  type?: string;
  text?: string;
  setText: (e: any) => void;
  className?: string | null;
  width?: string;
  title?: string;
  error?: string | any;
  touched?: boolean;
  max?: string;
  props?: any;
  value?: string;
  tabindex?: number;
  country: string;
  info?: string;
  containerClass?: string;
  disabled?: boolean;
  valueType?: "full" | "short";
};

export type TitledTimeInputProps = {
  placeHolder: string;
  name: string;
  type?: string;
  text?: string;
  setText: (e: any) => void;
  className?: string | null;
  width?: string;
  title?: string;
  error?: string | any;
  touched?: boolean;
  max?: string;
  props?: any;
  value: string;
  tabindex?: number;
  containerClass?: string;
};

export type SocialInputProps = {
  icon: ReactNode;
  placeHolder: string;
  name: string;
  setText: (e: any) => void;
  error?: string | null;
};

export type ButtonProps = {
  text: string;
  onClick: () => void;
  width?: string;
  bgColor?: string;
  className?: string;
  fontWeight?: string;
  isLoading?: boolean;
  type?: "button" | "submit" | "reset";
  tabindex?: number;
};

export type EntriesProps = {
  lists: LogBookContactModel[];
  editable?: boolean;
  pagination?: boolean;
  paginateContact?: (props: UserContactPaginationProp, uid?: string) => void;
  missingFields?: boolean;
  paginatedData?: UserProfileContactsWithPagination | null;
  logbookId?: string;
  isLoading?: boolean;
  guestUser?: boolean;
  userProfile?: UserProfile | null;
  searchable?: boolean;
  showSort?: boolean;
  showExchange?: boolean;
  exchangeOne?: string;
  exchangeTwo?: string;
  exchangeThree?: string;
  exchangeFour?: string;
  isCompact?: boolean;
  isWFDLogBook?: boolean;
};

export type ContestEntriesProps = {
  lists: LogBookContactModel[];
  editable?: boolean;
  pagination?: boolean;
  paginateContact?: (props: UserContactPaginationProp) => void;
};

export type MemberShipFilter = {
  country?: string;
  state?: string;
  membershipStatus?: string;
  callSign?: string;
};

export type MembershipProps = {
  lists: UserProfile[];
  count: number;
  filter?: MemberShipFilter;
  setFilters?: (e: any) => void;
};

export type ContestTableCompCompProps = {
  lists: ContestModel[];
  isLoading: boolean;
};

export type TableEntriesProps = {
  lists: LogBookContactModel[];
  editable?: boolean;
  missingFields?: boolean;
  guestUser?: boolean;
  setDateOrder?: () => void;
  showSort?: boolean;
  showExchange?: boolean;
  exchangeOne?: string;
  exchangeTwo?: string;
  exchangeThree?: string;
  exchangeFour?: string;
  isWFDLogBook?: boolean;
};

export type TableMembershipEntriesProps = {
  lists: UserProfile[];
  count: number | null;
  filter?: MemberShipFilter;
  setFilters?: (e: any) => void;
  live?: boolean;
};

export type TableContestEntriesProps = {
  lists: ContestModel[];
  live?: boolean;
  userEnrolledContests?: string[];
  onEdit?: (e: MouseEvent<HTMLElement>, item: ContestModel) => void;
  onDelete?: (e: MouseEvent<HTMLElement>, item: ContestModel) => void;
  passed?: boolean;
};

export type RecentContactTableProps = {
  lists: LogBookContactModel[];
  className?: string;
};

export interface EntriesTableCompProps extends EntriesProps {
  isSavingOnLoading: boolean;
  isLoading: boolean;
  editable?: boolean;
  missingFields?: boolean;
  setDateOrder?: () => void;
  showSort?: boolean;
  isCompact?: boolean;
  isWFDLogBook?: boolean;
}

export interface ContestEntriesTableCompProps extends ContestEntriesProps {
  isSavingOnLoading: boolean;
  isLoading: boolean;
  editable?: boolean;
}

export interface MembershipTableCompProps extends MembershipProps {
  isLoading: boolean;
  count: number;
  live?: boolean;
}

export interface ContestTableCompProps {
  lists: ContestModel[];
  isLoading: boolean;
  live?: boolean;
  passed?: boolean;
  userEnrolledContests?: string[];
  onEdit?: (e: MouseEvent<HTMLElement>, item: ContestModel) => void;
  onDelete?: (e: MouseEvent<HTMLElement>, item: ContestModel) => void;
}

export type ButtonRoundedProps = {
  text: string;
  subText?: string;
  onClick: () => void;
  rounded?: boolean;
};

export type InputWithButtonProps = {
  placeHolder: string;
  name?: string;
  type?: string;
  text?: string;
  setText: (e: any) => void;
  className?: string | null;
  width?: string;
  btnTxt?: string;
  fitBtn?: boolean;
  onBtnClick?: () => void;
  onFieldBlur?: () => void;
  title?: string;
  error?: string | any;
  tabindex?: number;
  value?: string;
  info?: string;
  btnWidth?: string;
  containerClass?: string;
  btnHeight?: string;
  flashField?: boolean;
};

export type DropDownProps = {
  placeHolder?: string;
  list: SubscriptionTypeArray[] | any[];
  width?: string;
  className?: string;
  onChange: (e: any) => void;
  value?: number;
};

export type FilterDropDownProps = {
  placeHolder?: string;
  list: any[];
  width?: string;
  className?: string;
  onChange: (e: any) => void;
  value?: string;
  multiple?: boolean;
  arrow?: boolean;
};

export type LogbookSettingDropdownProps = {
  list?: any[];
  title: string;
  input?: boolean;
  placeholder?: string;
  className?: string;
  value?: string;
  setValue?: (value: string) => void;
  displayKey?: string;
  type?: string;
};

export type EditLogBookContactProps = {
  contactData: LogBookContactModel;
};

export type ContactMapProps = {
  className: string;
  containerClassName: string;
  allContactMode?: boolean;
  setHoverArc?: (prev: ArcType) => void;
  captureSnapshot?: () => void;
  isLoadingImg?: boolean;
  screenShotImage?: string;
  limit?: number;
  guestMode?: boolean;
  contest?: boolean;
  userContactMap?: boolean;
  showControls?: boolean;
  userId?: string;
  mapControls?: google.maps.MapOptions;
  contactDetail?: boolean;
  captureInProgress?: boolean;
  showShare?: boolean;
  restrictBounds?: boolean;
  shouldFitBounds?: boolean;
  useCustomMarkers?: boolean;
  curved?: boolean;
  onMapLoaded?: () => void;
};

export type FlatGoogleMapProps = {
  className: string;
  allContactMode?: boolean;
  setHoverArc?: (prev: ArcType) => void;
  captureSnapshot?: () => void;
  isLoadingImg?: boolean;
  screenShotImage?: string;
  limit?: number;
  guestMode?: boolean;
  contest?: boolean;
  userContactMap?: boolean;
  mapControls?: google.maps.MapOptions;
  contactDetail?: boolean;
  captureInProgress?: boolean;
  showShare?: boolean;
  restrictBounds?: boolean;
  shouldFitBounds?: boolean;
  useCustomMarkers?: boolean;
  curved?: boolean;
  onMapLoaded?: () => void;
};

export type HeaderNavBarProps = {
  className?: string;
};

export type ShareMapModalProps = {
  className: string;
};

export type RouteLoadingProps = {
  noBg?: boolean;
  text?: string;
  subtext?: string;
};

export interface NavItemProps {
  color?: string | null;
  text: string;
  icon: any;
  onPress: () => void;
  link?: string;
  redirect?: boolean;
  newFeature?: boolean;
  collapsed?: boolean;
}
export type ContactMapControlsProps = {
  setShowImported: (prev: boolean) => void;
  showLimit: number;
  setShowLimit: (prev: number) => void;
  captureSnapshot?: () => void;
};

export enum ToastTypes {
  WARN,
  ERROR,
  SUCCESS,
}

export interface ShowToastProps {
  message: string;
  type: ToastTypes;
}

export type TableDropDownItemsProps = {
  text: string;
  link?: string;
  color?: string;
  meta?: any;
  type?: string;
};

export type TableDropDownProps = {
  listItems: TableDropDownItemsProps[];
  itemData: any;
  onClick: () => void;
};

export type FlatMapProps = {
  markers: MarkerPoint[];
  logBookMarkersLines?: any;
};

export type SubscriptionTypeArray = {
  icon: string;
  subscriptionType: string;
  benefits: string[];
  YearlyPrice: number;
  btnTxt: string;
};

export type AwardsTypeArray = {
  name: string;
  icon: string;
};

export type FoundingMemberCountDownProps = {
  foundingMemberSpotsLeft: string;
};

export type FeaturedMembersProps = {
  isLoading: boolean;
  featuredMembers: FeaturedMember[];
};

export type FeaturedMembersProfileProps = {
  user: FeaturedMember;
};

export type ContestTableHeaderProps = {
  title: string;
  filterOneTitle: string;
  filterTwoTitle: string;
  filterOnePlaceholder: string;
  filterTwoPlaceholder: string;
};

export type AccessControlProps = {
  permission: string;
  children: ReactNode;
};

export type AddContestModalProps = {};

export type EnrollSuccessModalProps = {};

export type ContestRulesProps = {
  contestDetail: ContestModel;
  contestStatus: string | null;
};

export type ContestLeaderboardProps = {
  contestDetail: ContestModel;
  contestStatus: string | null;
};

export type ContestCountDownProps = {
  startDate: Date;
  endDate: Date;
};
export type MembershipMarkerProps = {
  user: UserProfile;
  index?: number;
  clusterer?: any;
  weekly?: boolean;
};

export type ContactMarkerProps = {
  position: any;
  index: number;
  onCopyPressed?: (item: SpotModel) => void;
  useCustomMarker?: boolean;
};

export type AddUserAwardModalModalProps = {
  user?: UserProfile;
};

export type NotificationBannerProps = {
  onNotificationToggle: () => void;
};

export type LeaderBoardFilterControlsProps = {
  selectedMode: string;
  setSelectedMode: (prev: string) => void;
  increaseWeek: () => void;
  decreaseWeek: () => void;
  currentWeek: string;
};

export type LeaderBoardTimerProps = {};

export type StateItemProps = {
  geo: any;
  setShowInfo: (prev: any) => void;
};

export type ContactInfoProps = {
  icon: any;
  value: string;
};

export type ConfirmationInfoProps = {
  title: any;
  value?: string;
};

export type AdminControlUserProps = {
  userData: UserProfile;
};
export type MemberShipPillProps = {
  status: string;
};

export type TodayLeadersProps = {};

export type TodayLeaderItemProps = {
  data: LeaderData;
  onPress: (item: LeaderData) => void;
};

export type SpottingMapProps = {
  className?: string;
  spotsData: SpotModel[];
  spotLoading?: boolean;
  onCopyPressed?: (item: SpotModel) => void;
};

export type SpottingViewProps = {
  spotsData: SpotModel[];
  onCopyPressed: (item: SpotModel) => void;
  setSortConfig?: React.Dispatch<React.SetStateAction<SortConfig>>;
};
export type SpotItemProps = {
  spot: SpotModel;
  onCopyPressed: (item: SpotModel) => void;
  onReSpotClicked: (item: SpotModel) => void;
};
export type ContactSpottingProps = {
  setValues: any;
  setMode: any;
  setBand: any;
  setTheirPark: any;

  setSortConfig: any;
  filters: SpotFilters[];
  setFilters: any;
  applyChanges: any;

  filterCountry: any;
  setFilterCountry: any;
  filterRegion: any;
  setFilterRegion: any;
  filterBand: any;
  setFilterBand: any;
  filterMode: any;
  setFilterMode: any;
  spottedCountry: any;
  setSpottedCountry: any;
  spottedRegion: any;
  setSpottedRegion: any;
  operatingNearMe: any;
  setOperatingNearMe: any;
  dxOnly: any;
  setDxOnly: any;
  spottedNearMe: any;
  setSpottedNearMe: any;
  potaOnly: any;
  setPotaOnly: any;
  wrlOnly: any;
  setWrlOnly: any;
  logged: any;
  setLogged: any;
  callSignBlurPopupHandler: any;
  getUserFromHamApiData: any;
  showAddSpotCTA?: boolean;
};

export type SpottingFiltersProps = {
  applyChanges: (filters?: SpotFilters[]) => void;
  filters?: SpotFilters[];
  setFilters: (
    filters: SpotFilters[] | ((prev: SpotFilters[]) => SpotFilters[])
  ) => void;

  updateUrl?: boolean;
  className?: string;
};

export type MultiSelectDropDownProps = {
  options: any[];
  value: any;
  onSelectValue: (selectedList: any) => void;
  onRemoveValue: (selectedList: any) => void;
  placeholder: string;
  className?: string;
  width?: string;
  optionContainer?: any;
};

export type MapLeaderInfoProps = {
  showInfo: any;
  countryLeader: any;
  loading: any;
  setShowInfo: (prev: any) => void;
  state?: boolean;
};

export type HintProps = {
  text: string;
  warning?: boolean;
};

export type StationItemProps = {
  add?: boolean;
  buttonText?: string;
  onClick?: () => void;
  data?: Radios | Antenna | Stations;
  type?: "Radio" | "Antenna" | "Station";
  loading?: boolean;
};
export type ContactLogPopupSummary = {
  firstName?: string;
  lastName?: string;
  isCallSignMatch?: boolean;
  theirCallsign?: string;
  theirCountry?: string;
  theirState?: string;
  distance?: string;
  direction?: number;
  noOfContacts?: number;
  lastContact?: string;
};
export type WarningProps = {
  message: string;
  textSize?: string;
};

export interface AddContactLogPopupProps {
  data: {
    addContactLogPopupSummary: ContactLogPopupSummary;
    userProfile: any;
    theirCallSign: string;
    flagCode: string;
    duplicateInfo?: {
      band: string;
      timestamp: any;
      date: string;
      time: string;
    } | null;
  };
}

export type TitledCountryPickerProps = {
  placeHolder: string;
  name: string;
  type?: string;
  text?: string;
  setText: (e: any) => void;
  className?: string | null;
  width?: string;
  title?: string;
  error?: string | any;
  touched?: boolean;
  max?: string;
  props?: any;
  value?: string;
  tabindex?: number;
  info?: string;
  containerClass?: string;
  disabled?: boolean;
};
export type SearchCountryPickerProps = {
  title?: string;
  name: string;
  info?: string;
  placeHolder: string;
  tabindex?: number;
  width?: string;
  containerClass?: string;
  setText: (e: any) => void;
  value?: string;
  dxccNumber?: number;
  countryCode?: string;
  error?: string | any;
};

export type FilterGroup = {
  groupName: string;
  filters: string[];
};
