import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import Hint from "components/Hint";
import { CallSignTypes, LogbookTemplatesOptions } from "constants/Config";
import { useLogBookSettingsForm } from "hooks/useLogBookSettingsForm.hook";
import { FC } from "react";

export const CallSignSettings: FC = () => {
  const { values, setFormValue } = useLogBookSettingsForm();

  return (
    <div className="flex flex-col dark:text-white text-[#2b2e48] gap-4">
      <div className="font-bold font-Play text-xl">Call Sign Settings</div>
      <div className="flex flex-wrap">
        <LogbookSettingDropdown
          title="Callsign for this Logbook:"
          input
          placeholder="Callsign"
          value={values?.defaultCallSign || ""}
          setValue={(value) =>
            setFormValue("defaultCallSign", value ? value.toUpperCase() : value)
          }
          className="sm:w-[36%] md:w-[26%]"
        />
        <LogbookSettingDropdown
          list={CallSignTypes}
          title="Callsign Type:"
          value={values?.callSignType || ""}
          setValue={(value) => setFormValue("callSignType", value)}
          className="sm:w-[36%] md:w-[26%]"
        />
        <Hint
          text={
            "You may use a different Callsign for Special Event or Club stations. NEVER use a callsign that you do not have permission to use. Doing so may result in account deactivation."
          }
        />
      </div>
    </div>
  );
};
