import { Tooltip } from "components/Tooltip";
import { MY_PROFILE_ROUTE } from "constants/Links";
import useProfile from "hooks/profile.hook";
import { Link, useLocation } from "react-router-dom";
import { globalNavigate } from "routes/GlobalRoutes";
import { NavItemProps } from "types/Component";

function NavItem({
  color,
  text,
  icon,
  onPress,
  link,
  redirect,
  newFeature,
  collapsed, // New prop for collapsed view
}: NavItemProps) {
  const location = useLocation();
  const { userProfile } = useProfile();

  const handleClick = () => {
    if (
      location.pathname.includes(MY_PROFILE_ROUTE) &&
      link?.includes(MY_PROFILE_ROUTE)
    ) {
      window.location.href = link;
    } else if (link?.includes(MY_PROFILE_ROUTE)) {
      globalNavigate(link, { state: userProfile?.uid });
    } else {
      globalNavigate(link!);
    }
  };

  const renderIcon = (text?: string) => (
    <img
      src={icon}
      className={`w-4 h-4 ml-10 lg:ml-5 xl:ml-10 mr-3.5 filter  ${
        text === "Log out" ? "" : "invert dark:filter-none dark:invert-0"
      }`}
      alt=""
    />
  );

  const renderIconCompact = (text?: string) => (
    <img
      src={icon}
      className={`w-5 h-5 filter ${
        text === "Log out" ? "" : "invert dark:filter-none dark:invert-0"
      }`}
      alt=""
    />
  );

  const renderText = () => (
    <h3
      className={`text-base xl:text-base font-Play ${
        color ? color : "dark:text-white text-[#2b2e48]"
      }`}
    >
      {text}
    </h3>
  );

  // Return collapsed view
  if (collapsed) {
    if (text !== "Log out" && link && !redirect) {
      return (
        <div
          className="flex items-center justify-center px-5 relative my-5 cursor-pointer transition-all duration-500 ease-in-out"
          onClick={handleClick}
        >
          <Tooltip message={text} className="w-[100px] !-top-1 left-10">
            {renderIconCompact()}
          </Tooltip>
          {location?.pathname === link ||
          (location.pathname.includes(MY_PROFILE_ROUTE) &&
            link?.includes(MY_PROFILE_ROUTE)) ? (
            <div className="w-1 dark:bg-white bg-[#2b2e48] h-8 absolute right-0 transition-all duration-500 ease-in-out" />
          ) : null}
        </div>
      );
    } else if (redirect && link) {
      return (
        <Link to={link} target="_blank" rel="noreferrer">
          <div className="flex items-center justify-center px-5 relative my-5 cursor-pointer transition-all duration-500 ease-in-out">
            <Tooltip message={text} className="w-[100px] !-top-1 left-10">
              {renderIconCompact()}
            </Tooltip>
          </div>
        </Link>
      );
    } else {
      return (
        <div
          className="flex items-center justify-center px-5 relative my-5 cursor-pointer transition-all duration-500 ease-in-out"
          onClick={onPress}
        >
          <Tooltip message={text} className="w-[100px] !-top-1 left-10">
            {renderIconCompact(text)}
          </Tooltip>
        </div>
      );
    }
  }

  if (text !== "Log out" && link && !redirect) {
    return (
      <div
        className="flex items-center relative my-5 cursor-pointer"
        onClick={handleClick}
      >
        {renderIcon()}
        <div className="flex justify-between items-center w-full mr-2">
          {renderText()}
          {newFeature ? (
            <div className="bg-[#9f0e5c] rounded-full items-center justify-center flex h-[17px] w-[45px]">
              <h2 className="font-[Play] text-[11px] text-white">New</h2>
            </div>
          ) : null}
        </div>
        {location?.pathname === link ||
        (location.pathname.includes(MY_PROFILE_ROUTE) &&
          link?.includes(MY_PROFILE_ROUTE)) ? (
          <div className="w-1 dark:bg-white bg-[#2b2e48] h-8 absolute right-0" />
        ) : null}
      </div>
    );
  } else if (redirect && link) {
    return (
      <Link to={link} target="_blank" rel="noreferrer">
        <div className="flex items-center relative my-5">
          {renderIcon()}
          {renderText()}
          {location?.pathname === link ? (
            <div className="w-1 dark:bg-white bg-[#2b2e48] h-8 absolute right-0" />
          ) : null}
        </div>
      </Link>
    );
  } else {
    return (
      <div
        className="flex items-center relative my-5 cursor-pointer"
        onClick={onPress}
      >
        {renderIcon(text)}
        {renderText()}
        {location?.pathname === link ? (
          <div className="w-1 dark:bg-white bg-[#2b2e48] h-8 absolute right-0" />
        ) : null}
      </div>
    );
  }
}

export default NavItem;
