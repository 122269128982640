import { useField } from "formik";
import { useState } from "react";
import { RiInformationLine } from "react-icons/ri";
import { DropDownTitledProps } from "types/Component";

function DropDownTitled({
  placeHolder,
  name,
  value,
  onChange,
  className,
  width,
  title,
  error,
  lists,
  getTitle,
  getValue,
  tabindex,
  info,
  containerClass,
}: DropDownTitledProps) {
  const [infoOpen, setInfoOpen] = useState(false);
  const [field] = useField({
    name: name ? name : "",
  });
  return (
    <div
      className={`${width ? width : "w-full"} relative ${
        containerClass ? containerClass : ""
      }`}
    >
      <div className="flex items-center">
        <label className="font-[Barlow] font-bold text-sm dark:text-white text-[#2b2e48]">
          {title}
        </label>

        {info ? (
          <div
            onMouseEnter={info ? () => setInfoOpen(true) : () => {}}
            onMouseLeave={info ? () => setInfoOpen(false) : () => {}}
          >
            <RiInformationLine color="#999" className="ml-1" />
          </div>
        ) : null}
      </div>
      <select
        {...field}
        value={value}
        className={`h-8 dark:bg-[#23234C] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none px-3 font-[Barlow] text-sm mt-1.5 w-full ${
          className ? className : ""
        } ${error ? "!border !border-orange-700 !border-solid" : ""}`}
        name={name}
        onChange={(e: any) => {
          onChange(e);
        }}
        tabIndex={tabindex}
        id=""
        placeholder={placeHolder}
      >
        <option value=""></option>
        {lists.map((item, index) => {
          const displayValue = typeof item === 'string' ? item : getTitle ? getTitle(item) : '';
          const itemValue = typeof item === 'string' ? item : getValue ? getValue(item) : '';
          return (
            <option key={index} value={itemValue}>
              {displayValue}
            </option>
          );
        })}
      </select>
      {info && infoOpen ? (
        <div className="absolute top-[50%] left-0 right-0 z-[1000] px-[4px] py-[5px] lg:px-[6px] xl:px-[10px] text-[12px] font-[Play] bg-[#524A6F] text-white">
          {info}
        </div>
      ) : null}
    </div>
  );
}

export default DropDownTitled;
