import React, { useState } from "react";
import Icons from "assets/Icons";
import { ToastTypes } from "types/Component";
import { getAdminUserControlSelector } from "store/Reducers/profile.reducer";
import { useDispatch, useSelector } from "react-redux";
import {
  adminDeleteUser,
  adminDisableUser,
  adminEnableUser,
  adminUpdateSubscription,
  setUserAdminControl,
} from "store/Actions/profile.actions";
import TextInput from "components/Inputs/TextInput";
import { showToast } from "helpers/Toast";
import useConfig from "hooks/config.hook";
import MemberShipPill from "components/MemberShipPill";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import { getIsAdminControlLoadingSelector } from "store/Reducers/loading.reducer";
import AdminOptions from "./AdminOptions";

const AdminControlUser = () => {
  const adminControlUser = useSelector(getAdminUserControlSelector);
  const isAdminControlLoading = useSelector(getIsAdminControlLoadingSelector);
  const dispatch = useDispatch();
  const [disableCallSign, setDisableCallSign] = useState("");
  const [deleteCallSign, setDeleteCallSign] = useState("");
  const [userSubscription, setUserSubscription] = useState("");

  const { getMemberStatusText } = useConfig();
  const disableUser = (enable?: boolean) => {
    if (disableCallSign) {
      if (disableCallSign === adminControlUser?.callSign) {
        const isConfirmed = window.confirm(
          "Are you sure you want to change status of this user?"
        );
        if (isConfirmed) {
          setDisableCallSign("");
          if (enable) {
            dispatch(adminEnableUser(adminControlUser.uid!));
          } else {
            dispatch(adminDisableUser(adminControlUser.uid!));
          }
        }
      } else {
        showToast({
          message: "Selected user callsign and input callsign doesnt match",
          type: ToastTypes.WARN,
        });
      }
    } else {
      showToast({
        message: "Please enter user callsign first",
        type: ToastTypes.WARN,
      });
    }
  };

  const deleteUser = () => {
    if (deleteCallSign) {
      if (deleteCallSign === adminControlUser?.callSign) {
        const isConfirmed = window.confirm(
          "Are you sure you want to delete this user? This action cannot be undone!"
        );
        if (isConfirmed) {
          setDeleteCallSign("");
          dispatch(adminDeleteUser(adminControlUser.uid!));
        }
      } else {
        showToast({
          message: "Selected user callsign and input callsign doesnt match",
          type: ToastTypes.WARN,
        });
      }
    } else {
      showToast({
        message: "Please enter user callsign first",
        type: ToastTypes.WARN,
      });
    }
  };

  const updateSubscription = () => {
    if (userSubscription) {
      const isConfirmed = window.confirm(
        "Are you sure you want to update this users subscription?"
      );
      if (isConfirmed) {
        setUserSubscription("");
        dispatch(
          adminUpdateSubscription({
            userId: adminControlUser?.uid!,
            subscriptionLevel: userSubscription,
          })
        );
      }
    } else {
      showToast({
        message: "Please select subscription level first",
        type: ToastTypes.WARN,
      });
    }
  };

  return (
    <section className="absolute w-screen h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[5%] lg:px-[10%]">
      <div className="overflow-x-hidden w-full">
        <div className="w-full">
          <div className="flex flex-col w-full bg-[#151541] px-3 py-5 text-white font-[Play] relative">
            <h5 className="font-[Play] text-xl font-bold text-white py-2 ml-3 w-full">
              Admin Controls (
              <span className="text-[#FF6F46]">{`${adminControlUser?.callSign} - ${adminControlUser?.firstName} ${adminControlUser?.lastName}`}</span>
              )
            </h5>
            <div className="flex px-4 mt-10 flex-wrap justify-center">
              <div className=" w-[45%] py-4 bg-[#4D4E80] rounded-md flex items-center justify-center flex-col text-center px-3">
                <h1 className="font-bold text-[20px]">Update Subscription</h1>
                <div className="flex items-center">
                  <h1 className="font-bold text-[15px] w-[200px]">
                    Current Level:{" "}
                  </h1>
                  <MemberShipPill
                    status={getMemberStatusText(
                      adminControlUser?.membershipStatus || "FREE"
                    )}
                  />
                </div>

                <div className="mt-4 flex items-center">
                  <h1 className="font-bold text-[15px] w-[150px]">
                    Change To:
                  </h1>
                  <select
                    className="bg-[#151541] w-[200px] h-[40px]"
                    onChange={(e) => {
                      setUserSubscription(e.target.value);
                    }}
                  >
                    <option value="">Select</option>
                    <option value="free">FREE</option>
                    <option value="basic">BASIC</option>
                    <option value="premium">PREMIUM</option>
                    <option value="vip">VIP</option>
                  </select>
                </div>

                <button
                  className="bg-[#151541] w-full py-2 rounded-full mt-3"
                  onClick={() => updateSubscription()}
                >
                  <h1 className="text-[#00FF87]">Update</h1>
                </button>
              </div>
              <div className=" ml-10 w-[45%] py-4 bg-[#4D4E80] rounded-md flex items-center justify-center flex-col text-center px-3">
                <h1 className="font-bold text-[20px]">
                  Update Roles / Permissions
                </h1>

                {/* <button className="bg-[#151541] w-full py-2 rounded-full mt-1">
                  <h1 className="text-[#00FF87]">Update</h1>
                </button> */}
              </div>
              <div className="mt-10 w-[45%] py-4 bg-[#4D4E80] rounded-md flex items-center justify-center flex-col text-center px-3">
                <h1 className="font-bold text-[20px]">Disable User?</h1>
                <p className="mb-2">
                  Please type the users callsign below to confirm
                </p>
                <TextInput
                  text={disableCallSign}
                  setText={setDisableCallSign}
                  placeHolder="CallSign"
                  name="callsign"
                />
                <button
                  className="bg-[#38976b] w-full py-2 rounded-full mt-1"
                  onClick={() => {
                    disableUser(true);
                  }}
                >
                  <h1 className="text-[#fff]">Enable User</h1>
                </button>
                <button
                  className="bg-[#844D0F] w-full py-2 rounded-full mt-3"
                  onClick={() => {
                    disableUser();
                  }}
                >
                  <h1 className="">Disable User</h1>
                </button>
              </div>
              <div className="mt-10 ml-10 w-[45%] py-4 bg-[#4D4E80] rounded-md flex items-center justify-center flex-col text-center px-3">
                <h1 className="font-bold text-[20px]">Delete User?</h1>
                <p className="mb-2">
                  Please type the users callsign below to confirm
                </p>
                <TextInput
                  text={deleteCallSign}
                  setText={setDeleteCallSign}
                  placeHolder="CallSign"
                  name="callsign"
                />
                <button
                  className="bg-[#9C2624] w-full py-2 rounded-full mt-1"
                  onClick={() => {
                    deleteUser();
                  }}
                >
                  <h1 className="">Delete User</h1>
                </button>
              </div>
            </div>
            {isAdminControlLoading ? (
              <div className="w-full h-full bg-[#000] opacity-60 absolute top-0 left-0">
                <ActivityIndicator size={40} />
              </div>
            ) : null}
            <div>
              <AdminOptions />
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute top-22 right-16 cursor-pointer"
        onClick={() => {
          dispatch(setUserAdminControl(null));
        }}
      >
        <img
          src={Icons.GlowingClose}
          className="h-8 w-8 bg-gray-300 rounded-full dark:bg-transparent"
          alt="close"
        />
      </div>
    </section>
  );
};

export default AdminControlUser;
