import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import { LogbookTemplatesOptions } from "constants/Config";
import useConfig from "hooks/config.hook";
import useContest from "hooks/contest.hook";
import { useLogBookSettingsForm } from "hooks/useLogBookSettingsForm.hook";
import { FC } from "react";
import { globalNavigate } from "routes/GlobalRoutes";

export const BasicSettings: FC = () => {
  const { values, setFormValue } = useLogBookSettingsForm();
  const { contestDetail } = useContest();
  const { handlePrimaryModal } = useConfig();
  return (
    <div className="flex flex-col dark:text-white text-[#2b2e48] gap-4">
      <div className="font-bold font-Play text-xl">Basic Settings</div>
      <div className="flex flex-wrap">
        <LogbookSettingDropdown
          title="Logbook Name:"
          input
          placeholder="Enter name"
          value={values?.name || ""}
          setValue={(value) => setFormValue("name", value)}
          className="sm:w-[36%] md:w-[26%]"
        />
        <LogbookSettingDropdown
          title="Logbook Description:"
          input
          placeholder="Enter description"
          value={values?.description || ""}
          setValue={(value) => setFormValue("description", value)}
          className="sm:w-[36%] md:w-[26%]"
        />
        {!contestDetail?.isWFDContest ? (
          <LogbookSettingDropdown
            list={LogbookTemplatesOptions}
            title="Logbook Style:"
            value={values?.logbookStyle || ""}
            setValue={(value) => {
              if (value === "view") {
                globalNavigate("/log-contacts/logbook-styles");
              } else {
                if (value === "WFD") {
                  handlePrimaryModal({
                    title:
                      'To participate in the Winter Field Day contest, please go the the contest section and enroll in the "Winter Field Day 2025" contest. This action will generate a new logbook for use in the contest which can be accessed from the "My Logbook" tab on the Winter Field Day contest page.',
                    type: "Enroll to Winter Field Day 2025?",
                    onCancelPressed: () => handlePrimaryModal(null),
                    onSubmitPressed: () => {
                      globalNavigate(
                        "/contest/contest-detail?tab=contest_rules&contest_id=cCkt5FsidQkXim4Grhx6"
                      );
                      handlePrimaryModal(null);
                    },
                    submitText: "Take Me There",
                    cancelText: "Cancel",
                  });
                } else {
                  setFormValue("logbookStyle", value);
                }
              }
            }}
            className="sm:w-[36%] md:w-[24%]"
          />
        ) : null}
      </div>
    </div>
  );
};
