import { Product } from "@stripe/firestore-stripe-payments";
import { Store } from "redux";
import { Persistor } from "redux-persist";
import { AllEffect, ForkEffect } from "redux-saga/effects";
import {
  ActivitiesMapData,
  Antenna,
  Award,
  CallSignLookUpData,
  CallsignModel,
  ContestLeader,
  ContestLeadrBoard,
  ContestModel,
  ContestantDetail,
  ContestantUserDetail,
  EnrollSuccessData,
  FeaturedMember,
  LeaderData,
  LogBookContactModel,
  LogBookModel,
  ParkReferenceModel,
  PendingEmailUpdate,
  PotaAutoSpotPayload,
  Radios,
  SavedLocation,
  SortConfig,
  SpotFilters,
  SpotModel,
  Stations,
  SubscriptionStatus,
  UserAwards,
  UserNotification,
  UserProfile,
} from "types/Models";

interface RootSagaFunc {
  (): Generator<AllEffect<ForkEffect<void>>, void, unknown>;
}

export interface SagaStore extends Store {
  runSaga(rootSaga: RootSagaFunc): void;
  close(): void;
}

export type StoreConfig = {
  store: SagaStore;
  persistor: Persistor;
};

export type RegisterActionParams = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export type Coordinates = {
  latitude: number;
  longitude: number;
};

export type CreateActionParams = {
  email: string;
  uid: string;
  providerData: string;
};

export type EditLogBook = {
  newLogBookData: LogBookModel;
  logBookId: string;
  noToast?: boolean;
};

export type UpdateUserProfile = {
  user: UserProfile;
  profilePic?: File | null;
};

export type CreateUserProfileActionParams = {
  email: string;
  uid: string;
  firstName: string;
  lastName: string;
  provideId: string;
  bio: string;
  phoneNumber: string;
  callSign: string;
};

export type LoginActionParams = {
  email: string;
  password: string;
};

export type DiscourseSSOParams = {
  sso: string;
  sig: string;
  idToken: string;
};

export type CreateUserActionParams = {
  bio: string;
  phoneNumber: string;
  callSign: string;
};

export type RegisterActionPayload = {
  type: string;
  payload: RegisterActionParams;
};

export type LoginActionPayload = {
  type: string;
  payload: LoginActionParams;
};

export type AuthorizationState = {
  authFirstName: null;
  authLastName: null;
  discourseParams: any | null;
  discourseLoading: boolean;
  callSignAssistantModal: any;
  desktopLoginParams: any;
};

export type LogBookState = {
  logBooks: LogBookModel[];
  selectedLogBook: null | LogBookModel;
  logBookContacts: LogBookContactModel[];
  allContacts: LogBookContactModel[];
  allContactsListPaginated: LogBookContactModel[];
  callSignLookup: CallSignLookUpData | string | null;
  editContactModalData: LogBookContactModel | null;
  allUserContactsData: LogBookContactModel[];
  workedUserCountries: string[];
  workedUserGridSquare: string[];
  allProfilePaginatedContacts: UserProfileContactsWithPagination | null;
  allLogBookPaginatedContacts: UserProfileContactsWithPagination | null;
  contactDetail: LogBookContactModel | null;
  searchResults: LogBookContactModel[] | null;
  dailyLeaders: LeaderData[];
  addSpotModal: null | SpotModel;
  addRadioModal: Radios | null;
  addAntennaModal: Antenna | null;
  savedLocations: SavedLocation[];
  antennas: Antenna[];
  radios: Radios[];
  addContactPopupSummary: AddContactLogPopupSummary | null;
  addStationModal: Stations | null;
  stations: Stations[];
  mergeLogbook: null | any;
  confirmChangesModalData: null | any;
  currentSearchString: string;
  quickSettingsCurrentTab: null | any;
};

export type UserContactPaginationProp = {
  page: number;
  move: number;
  firstDoc: LogBookContactModel;
  lastDoc: LogBookContactModel;
  logbookId?: string;
  dateOrder?: string;
};

export type LogbookContactPaginationProp = {
  page?: number;
  move?: number;
  firstDoc?: LogBookContactModel;
  lastDoc?: LogBookContactModel;
  logbookId?: string;
  loading?: boolean;
  dateOrder?: string;
};

export type UserProfileContactsWithPagination = {
  contacts: LogBookContactModel[];
  count: number;
  lastDoc: LogBookContactModel;
  firstDoc: LogBookContactModel;
  page: number;
  dateOrder?: string;
};

export type RecentContactData = {
  contacts?: LogBookContactModel[];
  count?: number;
  lastDoc?: LogBookContactModel;
  firstDoc?: LogBookContactModel;
  page?: number;
};

export type LoadingState = {
  isActivitiesMapLoading: any;
  authIsLoading: boolean;
  isFacebookLoading: boolean;
  isGoogleLoading: boolean;
  discourseLoading: boolean;
  logIsLoading: boolean;
  contactIsSavingLoading: boolean;
  isProfileLoading: boolean;
  isLeaderBoardLoading: boolean;
  isLeaderBoardByModeLoading: boolean;
  isFetchingGuestUserProfile: boolean;
  isSubscriptionLoading: boolean;
  isUserAwardLoading: boolean;
  isFetchingCallSignAvailability: boolean;
  isGettingLocation: boolean;
  isCallSignDataLoading: boolean;
  isUploadImageLoading: boolean;
  isMembersDataLoading: boolean;
  isFeaturedMembersLoading: boolean;
  isLogContactForAdifLoading: boolean;
  isContestLoading: boolean;
  isActiveContestLoading: boolean;
  isUpcomingContestLoading: boolean;
  isPassedContestLoading: boolean;
  isEnrollContestLoading: boolean;
  isContestantDetailLoading: boolean;
  isContestLeaderLoading: boolean;
  isContestLeaderBoardLoading: boolean;
  isContestContactsLoading: boolean;
  isContestDetailLoading: boolean;
  isContestUserDetailLoading: boolean;
  isMapMembersLoading: boolean;
  isGlobeMembersLoading: boolean;
  isLeaderBoardByWeekLoading: boolean;
  isLeaderBoardWeekByModeLoading: boolean;
  isDistanceLeaderBoardLoading: boolean;
  isDXLoading: boolean;
  isStateLeaderBoardLoading: boolean;
  isAdiUploadLoading: boolean;
  isAdminControlLoading: boolean;
  isDailyLeadersLoading: boolean;
  isSpottingLoading: boolean;
  isLogbookSettingLoading: boolean;
  isAppleAuthLoading: boolean;
};

export type SubscriptionState = {
  subscriptionProducts: Product[];
  subscriptionStatus: SubscriptionStatus | null;
  foundingMemberSpotsLeft: string;
};

export type SpottingState = {
  spots: SpotModel[];
  filters: SpotFilters[];
  sort: SortConfig;
};

export type PrimaryModalDataProps = {
  title: string;
  type: string;
  subType?: string;
  onCancelPressed: () => void;
  onSubmitPressed: () => void;
  submitText: string;
  cancelText: string;
  criticality?: string;
};

export type ConfigState = {
  isMenuOpen: boolean;
  primaryModalData: null | PrimaryModalDataProps;
  screenShotImage: string;
  showSignInHelp: boolean;
  addContactSuccess: boolean;
  spottingCopied: boolean;
  contactMapModalStatus: boolean;
};

export type ContestState = {
  showAddContestModal: boolean;
  showEditContestModal: null | ContestModel;
  activeContest: ContestModel[];
  upcomingContest: ContestModel[];
  passedContest: ContestModel[];
  contestDetails: ContestModel | null;
  enrollSuccessData: EnrollSuccessData | null;
  contestantDetails: ContestantDetail | null;
  userContestRanking: number;
  contestLeader: ContestLeader;
  contestLeaderBoard: ContestLeadrBoard[];
  contestContacts: LogBookContactModel[];
  userContestantDetails: ContestantUserDetail | null;
  awardContest: UserAwards[];
  topBand: string;
  shouldLoadUserDetails: boolean;
};

export type NotificationState = {
  userNotification: UserNotification | null;
  userNormalNotifications: UserNotification[];
};

export type FetchProfileProps = {
  callSign: string;
  userId: string;
};

export type MemberState = {
  members: UserProfile | null;
  mapMembers: UserProfile[];
  globeMembers: UserProfile[];
};

export type GuestState = {
  guestProfile: UserProfile | null;
};

export type LeaderBoardState = {
  leaderBoardUsersData: UserProfile[];
  leaderBoardUsersDataWithMode: UserProfile[];
  weekLeaders: UserProfile[];
  weekModeLeaders: UserProfile[];
  distanceLeaders: UserProfile[];
  dxLeaders: UserProfile[];
  stateLeader: UserProfile | null;
};

export type ProfileState = {
  userProfile: null | UserProfile;
  userAvatar: null | string;
  userAwards: UserAwards[];
  isCallSignAvailable: any;
  featuredMembers: FeaturedMember[];
  showAwardModal: null | UserProfile;
  awards: UserAwards[];
  userAwardAdmin: UserAwards[];
  adminUserControlData: null | UserProfile;
  showAccountDeleteModal: boolean;
  isCollapsed: boolean;
  impersonationData: null | any;
  pendingEmailUpdate: PendingEmailUpdate | null;
  showReauthModal: boolean;
};

export type LogBookListProps = {
  logBooksList: LogBookModel[];
  isLoading: boolean;
  setSortConfig?: React.Dispatch<React.SetStateAction<SortConfig>>;
};

export type UserState = {
  email: string;
  uid: string;
  providerData: string;
};

export interface NewLogBook extends LogBookModel {
  // template: string;
}

export type MarkerPoint = {
  lat: number;
  lng: number;
};

export type MarkerLines = {
  myCallSign?: string;
  theirCallsign?: string;
  theirCountry?: string;
  country?: string;
  myCountry?: string;
  flagCode?: string;
  start: {
    lat: number;
    lng: number;
  };
  end: {
    lat: number;
    lng: number;
  };
};

export type AddContactLogPopupSummary = {
  noOfContacts?: number;
  lastContact?: string;
};

export type PotaState = {
  parkReferenceData: { [key: string]: ParkReferenceModel | null } | null;
  isParkReferenceDataLoading: { [key: string]: boolean } | null;
  parkReferenceSuggestionsData: {
    [key: string]: ParkReferenceModel[] | null;
  } | null;
  nearbyParkReferencesData: ParkReferenceModel[] | null;
  autoSpotData: Partial<PotaAutoSpotPayload> | null;
  activityMap: ActivitiesMapData[];
  isFindMyParkLoading: boolean;
  activityListView: ActivitiesMapData[];
};

export type CallsignState = {
  callsignData: CallsignModel | null;
  callsignSuggestionsData: CallsignModel[] | null;
  isCallsignDataLoading: boolean;
  callsignSearchKey: string;
};

export type AwardState = {
  awards: Award[];
  isLoading: boolean;
  showAwardModal: boolean;
};

export interface QSOManagerState {
  contacts: LogBookContactModel[];
  totalCount: number;
  limitExceeded: boolean;
  loading: boolean;
  error: string | null;
}

export const defaultQSOManagerState: QSOManagerState = {
  contacts: [],
  totalCount: 0,
  limitExceeded: false,
  loading: false,
  error: null,
};

export interface UserActivityState {
  userActivities: any[];
  totalCount: number;
  status: string;
  activityType: string;
  activityId: string;
  loading: boolean;
  error: string | null;
  showExportAllContacts: boolean;
}

export const defaultUserActivityState: UserActivityState = {
  userActivities: [],
  totalCount: 0,
  status: "",
  activityType: "",
  activityId: "",
  loading: false,
  error: null,
  showExportAllContacts: true,
};

export interface UserActivityPayload {
  type: string;
  payload: any;
}
