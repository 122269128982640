import React from "react";
import { FoundingMemberCountDownProps } from "types/Component";

const FoundingMemberCountDown = ({
  foundingMemberSpotsLeft,
}: FoundingMemberCountDownProps) => {
  return (
    <div className="my-10">
      <p className="text-center dark:text-[#17F9DA] text-[#1e40af] font-[Barlow] font-bold text-base sm:text-xl">
        The first 5,000 Members will be given a Founding Member Award on your
        profile for being the first members to join the league.
      </p>
      <div className="flex items-center justify-center">
        <p className="dark:text-white text-[#2b2e48] text-base font-[Play] font-bold">
          Founding Member Spots left :
        </p>
        <div className="border-solid border-[#fff] border-[3px] flex w-[185px] ml-2 mt-2 rounded-full overflow-hidden">
          <p className="text-[#FF0000] w-[25%] border-[1px] border-solid border-white h-full rounded-l-full text-center text-lg font-[Barlow] font-bold">
            {`${foundingMemberSpotsLeft[0] ? foundingMemberSpotsLeft[0] : "-"}`}
          </p>
          <p className="text-[#FF0000] w-[25%] border-[1px] border-solid border-white h-full text-center text-lg font-[Barlow] font-bold">
            {`${foundingMemberSpotsLeft[1] ? foundingMemberSpotsLeft[1] : "-"}`}
          </p>
          <p className="text-[#FF0000] w-[25%] border-[1px] border-solid border-white h-full text-center text-lg font-[Barlow] font-bold">
            {`${foundingMemberSpotsLeft[2] ? foundingMemberSpotsLeft[2] : "-"}`}
          </p>
          <p className="text-[#FF0000] w-[25%] border-[1px] border-solid border-white h-full rounded-r-full text-center text-lg font-[Barlow] font-bold">
            {`${foundingMemberSpotsLeft[3] ? foundingMemberSpotsLeft[3] : "-"}`}
          </p>
        </div>
      </div>
    </div>
  );
};

export default FoundingMemberCountDown;
