import React, { useRef, useState } from "react";
import { FaCaretDown } from "react-icons/fa6";
import { LogbookSettingDropdownProps } from "types/Component";
import FilterDropDown from "./FilterDropDown";
import { FaEye, FaPlusCircle } from "react-icons/fa";
import useTheme from "hooks/useTheme.hook";

function LogbookSettingDropdown({
  list,
  title,
  input,
  placeholder,
  className,
  value,
  setValue,
  type,
}: LogbookSettingDropdownProps) {
  const [showDropDown, setShowDropDown] = useState(false);
  const { theme } = useTheme();
  const getIcon = (type: string) => {
    if (type === "add") return <FaPlusCircle color={ theme === 'dark' ? "#fff": "#2b2e48]"} className="mr-3" />;
    if (type === "view") return <FaEye color={ theme === 'dark' ? "#fff": "#2b2e48]"} className="mr-3" />;
    return null;
  };
  return (
    <div
      className={`w-[80%] sm:w-[23.5%] md:w-[20%] sm:mr-10 relative mb-4 cursor-pointer  ${className}`}
      onMouseEnter={() => setShowDropDown(true)}
      onMouseLeave={() => setShowDropDown(false)}
    >
      <p className="font-[Barlow] font-bold text-sm dark:text-white text-[#2b2e48] truncate mb-2">
        {title}
      </p>
      {input ? (
        <input
          className="dark:bg-[#23234C] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none text-sm font-[Barlow] px-2 h-[36px] w-full"
          placeholder={placeholder}
          value={value || ""}
          type={type || "text"}
          onChange={(e) => setValue && setValue(e.target.value)}
        />
      ) : (
        <div className="pointer-events-none">
          <FilterDropDown
            placeHolder={"--"}
            list={list || []}
            onChange={(e: any) => {
              setValue && setValue(e.target.value);
            }}
            width={"w-full"}
            className="h-[36px] dropdown-select "
            arrow
            value={value}
          />
        </div>
      )}
      {input ? null : (
        <div className="absolute h-[36px] w-[36px] bg-[#0243DD] bottom-0 right-0 pointer-events-none items-center justify-center flex">
          <FaCaretDown color="#fff" />
        </div>
      )}
      {!input && showDropDown ? (
        <div className="w-full dark:bg-[#10103C] bg-white absolute z-50 dark:text-white text-[#2b2e48] rounded-md border border-gray-600 overflow-scroll  max-h-[330px]">
          {list?.map((item, index) => (
            <div
              key={item.value + index}
              className={`py-1 dark:hover:bg-[#23234C] hover:bg-[#e4e6e7]  px-2 cursor-pointer ${
                item.value === "add" || item.value === "view"
                  ? "py-2 border-t border-gray-500"
                  : "py-1 border-t-0"
              } ${item.value === value ? "dark:bg-[#23234C] bg-[#f1f3f4]" : "dark:bg-[#10103C] bg-white"}`}
              onClick={() => {
                setShowDropDown(false);
                setValue && setValue(item.value);
              }}
            >
              <div className="flex items-center">
                {getIcon(item.value)}
                <span className="text-base">{item.label}</span>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}

export default LogbookSettingDropdown;
