import {
  RegisterActionParams,
  LoginActionParams,
  CreateUserActionParams,
  DiscourseSSOParams,
} from "types/State";

export const REGISTER_USER = "REGISTER_USER";
export const LOG_IN_USER = "LOG_IN_USER";
export const LOG_USER_OUT = "LOG_USER_OUT";
export const CREATE_USER = "CREATE_USER";
export const GOOGLE_LOGIN = "GOOGLE_LOGIN";
export const APPLE_LOGIN = "APPLE_LOGIN";
export const FACEBOOK_LOGIN = "FACEBOOK_LOGIN";
export const DISCOURSE_AUTH = "DISCOURSE_AUTH";
export const SEND_EMAIL_TO_VERIFY = "SEND_EMAIL_TO_VERIFY";
export const SAVE_DISCOURSE_PARAMS = "SAVE_DISCOURSE_PARAMS";
export const CALLSIGN_ASSISTANT_MODAL = "CALLSIGN_ASSISTANT_MODAL";
export const SAVE_DESKTOP_LOGIN_PARAMS = "SAVE_DESKTOP_LOGIN_PARAMS";

export type AuthorizationActionPayload = {
  type: string;
  payload: any;
};

export type LogoutAction = {
  type: string;
};

export function registerUser(
  data: RegisterActionParams
): AuthorizationActionPayload {
  return {
    type: REGISTER_USER,
    payload: data,
  };
}

export function loginUser(data: LoginActionParams): AuthorizationActionPayload {
  return {
    type: LOG_IN_USER,
    payload: {
      loggedInUser: data,
      isUserRegistered: null,
    },
  };
}

export function discourseSSO(
  data: DiscourseSSOParams
): AuthorizationActionPayload {
  return {
    type: DISCOURSE_AUTH,
    payload: data,
  };
}

export function googleLogin(): LogoutAction {
  return {
    type: GOOGLE_LOGIN,
  };
}
export function appleLogin(): LogoutAction {
  return {
    type: APPLE_LOGIN,
  };
}

export function sendEmailToVerify(email: string): AuthorizationActionPayload {
  return {
    type: SEND_EMAIL_TO_VERIFY,
    payload: email,
  };
}

export function facebookLogin(): LogoutAction {
  return {
    type: FACEBOOK_LOGIN,
  };
}

export function createUserForAllAuth(
  data: CreateUserActionParams
): AuthorizationActionPayload {
  return {
    type: CREATE_USER,
    payload: {
      userData: data,
      isUserRegistered: null,
    },
  };
}

export function logoutUser(): AuthorizationActionPayload {
  return {
    type: LOG_USER_OUT,
    payload: {
      authFirstName: null,
      authLastName: null,
    },
  };
}

export function saveDiscourseParams(data: any): AuthorizationActionPayload {
  return {
    type: SAVE_DISCOURSE_PARAMS,
    payload: data,
  };
}
export function saveDesktopLoginParams(data: any): AuthorizationActionPayload {
  return {
    type: SAVE_DESKTOP_LOGIN_PARAMS,
    payload: data,
  };
}

export function callSignAssistantModal(data: any): AuthorizationActionPayload {
  return {
    type: CALLSIGN_ASSISTANT_MODAL,
    payload: data,
  };
}
