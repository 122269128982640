import React from "react";
import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";

import RouteLoading from "components/Loading/RouteLoading";
import Switch from "react-switch";

import { formatFirebaseDate } from "helpers/Utils";
import useSubscription from "hooks/subscription.hook";
import useConfig from "hooks/config.hook";

const SubscriptionDetail = () => {
  const {
    subscriptionStatus,
    isSubscriptionLoading,
    createStripePortalSession,
    autoRenew,
    stopAutoRenew,
  } = useSubscription();

  return (
    <main className="">
      <div className="px-4 py-8 sm:p-8 lg:p-14 dashboardWrapper overflow-x-hidden">
        <div className="dark:bg-[#0B0B39] bg-white mt-6 w-full rounded-[20px] sm:rounded-[25px] md:rounded-[50px] flex flex-col-reverse md:flex-row justify-between">
          <div className="w-full mt-20 md:mt-0 md:w-[67%] px-4 md:px-8 py-9">
            <div className="border-b border-b-[#191A45] border-solid">
              <h3 className="font-[Play] font-bold text-lg dark:text-white text-[#2b2e48] pl-4">
                Billing and Payment
              </h3>
              <div className="flex items-center justify-between pt-2.5 pb-6 px-8">
                <div className="flex items-center">
                  <img className="h-5 w-5 mr-3 invert dark:filter-none dark:invert-0" src={Icons.Wallet} alt="" />
                  <p className="dark:text-white text-[#2b2e48] font-[Play] text-sm">
                    ********{subscriptionStatus?.cardLast4Digits}{" "}
                    {subscriptionStatus?.cardProvider
                      ? subscriptionStatus?.cardProvider.toLocaleUpperCase()
                      : ""}
                  </p>
                </div>
                {/* <div className=" w-[130px]">
                  <BorderButtonSecondary
                    text="Update Card"
                    onClick={() => {}}
                    height="h-[34px]"
                    childHeight="h-[57px]"
                    childClassName="text-xs font-[Play]"
                  />
                </div> */}
              </div>
            </div>
            <div className="border-b border-b-[#191A45] border-solid mt-6">
              <h3 className="font-[Play] font-bold text-lg dark:text-white text-[#2b2e48] pl-4">
                Subscription Start Date:
              </h3>
              <div className="flex items-center justify-between pt-2.5 pb-6 px-8">
                <p className="dark:text-white text-[#2b2e48] font-[Play] text-sm">
                  {formatFirebaseDate(
                    "DD MMMM YYYY",
                    subscriptionStatus?.startDate?.seconds
                  )}
                </p>
              </div>
            </div>
            <div className="mt-6">
              <h3 className="font-[Play] font-bold text-lg dark:text-white text-[#2b2e48] pl-4">
                {autoRenew ? "Renewal Date :" : "Subscription End Date :"}
              </h3>
              <div className="flex items-center justify-between pt-2.5 pb-6 px-8">
                <p className="dark:text-white text-[#2b2e48] font-[Play] text-sm">
                  {formatFirebaseDate(
                    "DD MMMM YYYY",
                    subscriptionStatus?.renewalDate?.seconds
                  )}{" "}
                  {autoRenew ? (
                    <>
                      <span className="mx-3">|</span>
                      <span className="dark:text-[#17F9DA] text-[#1e40af]">Amount: </span>$
                      {subscriptionStatus?.amount}
                    </>
                  ) : null}
                </p>
                <div className="flex flex-row items-center">
                  <p className="dark:text-white text-[#2b2e48] font-[Play] text-sm mr-4">
                    Auto Renew
                  </p>
                  <Switch
                    onChange={() => {
                      stopAutoRenew();
                    }}
                    checked={autoRenew}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={12}
                    onColor="#7B61FF"
                    width={30}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center">
              {/* <div className="w-[132px] lg:w-[135px]">
                <BorderButtonSecondary
                  text="Compare Options"
                  onClick={createStripePortalSession}
                  height="h-[34px]"
                  childHeight="h-[57px]"
                  childClassName="text-xs font-[Play]"
                />
              </div> */}
              {/* <div className="w-[132px] lg:w-[135px]">
                <BorderButtonSecondary
                  text="Upgrade Subscription"
                  onClick={createStripePortalSession}
                  height="h-[34px]"
                  childHeight="h-[57px]"
                  childClassName="text-xs font-[Play]"
                />
              </div> */}

              <div className="w-[250px] mt-10">
                <BorderButtonSecondary
                  text="Manage Subscription"
                  onClick={createStripePortalSession}
                  height="h-[44px]"
                  childHeight="h-[57px]"
                  childClassName="text-base font-[Play]"
                />
              </div>
              <p className="text-xs font-[Play] dark:text-white text-[#2b2e48] mt-5 text-center">
                Click to manage subscriptions, upgrade, downgrade, update card
                and cancel.
              </p>
            </div>
          </div>
          <div className="w-full md:w-[33%] mt-[10%] md:mt-0 flex justify-center">
            <div className="relative flex flex-col w-[280px] sm:w-[400px] md:w-full items-center h-full">
              <div className="absolute w-full h-[110%] absoluteGradientImg top-[-5%] flex justify-center">
                <img
                  className="w-full h-full object-fill"
                  src={Icons.GradientRectangle}
                  alt="bg"
                />
              </div>
              <div className="dark:bg-[#161638] bg-white shadow-lg h-full w-full rounded-[24px] flex flex-col justify-center items-center pt-8 lg:pt-12 pb-6 relative">
                <img
                  className="w-[60%]"
                  src={subscriptionStatus?.image}
                  alt="subscription"
                />
                <p className="dark:text-white text-[#2b2e48] text-center font-[Play] text-base md:text-lg mt-4 t">
                  Current Subscription Level:
                </p>
                <div className="mt-2 xl:mt-4 dark:text-[#17F9DA] text-[#1e40af] dark:bg-[#27274F] bg-[#f1f3f4] font-[Play] text-base sm:text-xl text-center py-1 xl:py-3 px-7 xl:px-12">
                  {subscriptionStatus?.name}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSubscriptionLoading ? (
        <div className="absolute z-10 top-0 w-full h-full">
          <RouteLoading text={"Loading Subscription..."} />
        </div>
      ) : null}
    </main>
  );
};

export default SubscriptionDetail;
