import React from "react";
import Icons from "assets/Icons";
import useTheme from "hooks/useTheme.hook";

type Tab = {
  id: string;
  label: string;
  showExternal?: boolean;
  onExternalClick?: () => void;
  hidden?: boolean;
};

type TabsProps = {
  tabs: Tab[];
  selectedTabId: string;
  setSelectedTabId: (id: string) => void;
  className?: string;
};

function Tabs({ tabs, selectedTabId, setSelectedTabId, className }: TabsProps) {
  const { theme } = useTheme();

  return (
    <div
      className={`${
        className ? className : ""
      } flex justify-between items-center w-full`}
    >
      {tabs
        .filter((tab) => !tab.hidden)
        .map((tab) => (
          <button
            key={tab.id}
            className={`text-[#17F9DA] text-base cursor-pointer font-[Play] font-bold w-[50%] pl-4 py-2 ${
              selectedTabId === tab.id
                ? "dark:bg-[#151762] bg-[#1e40af] text-[#17F9DA]"
                : "dark:bg-[#151541] bg-[#94a3b883] dark:text-[#17F9DA] text-[#2b2e48]"
            }`}
            onClick={() => setSelectedTabId(tab.id)}
          >
            <div className="w-full flex items-center justify-start gap-2">
              {tab.label}
              {tab.showExternal && tab.onExternalClick && (
                <img
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent triggering the tab click
                    tab.onExternalClick && tab.onExternalClick();
                  }}
                  className="h-5 w-5"
                  src={
                    theme === "dark" || selectedTabId === tab.id
                      ? Icons.External
                      : Icons.ExternalDark
                  }
                  alt="External Icon"
                />
              )}
            </div>
          </button>
        ))}
    </div>
  );
}

export default Tabs;
