import Icons from "assets/Icons";
import useTheme from "hooks/useTheme.hook";
import React from "react";
import { globalNavigate } from "routes/GlobalRoutes";
import { HeaderNavBarProps } from "types/Component";

function HeaderNavBar({ className }: HeaderNavBarProps) {
  const { theme } = useTheme();
  return (
    <div
      className={`${
        className ? className : ""
      } my-10 mt-6 px-5 relative flex justify-center dark:h-[120px] dark:my-0 h-[76px]`}
    >
      <img
        src={theme === 'dark' ? Icons.MainLogo: Icons.MainLogoDark}
        onClick={() => globalNavigate("/")}
        className="cursor-pointer object-contain w-[180px]"
        alt="logo"
        crossOrigin="anonymous"
      />
    </div>
  );
}

export default HeaderNavBar;
