import WorldBg from "./worldBg.png";
import SpaceBg from "./spaceBg.svg";
import ButtonPrimary from "./buttonPrimary.png";
import ButtonSecondary from "./buttonSecondary.png";
import ButtonSquare from "./buttonSquare.png";
import ProfilePicSample from "./profilePicSample.png";
import ContactMap from "./contactMap.png";
import CongratulationBg from "./congratulationBg.svg";
import TimerBG from "./timerBg.png";
import Globe from "./globe.png";
import Mode from "./mode.png";
import DX from "./dx.webp";
import State from "./state.webp";
import Add from "./add.png";
import Apple from "./apple.png";
import FirstImg  from "./first.svg";
import SecondImg from "./second.svg";
import ThirdImg from "./third.svg";
import ShareLogbookMap from "./share-logbook.png"
import ShareLogbookLight from "./share-logbook-light.jpg"


const Images = {
  WorldBg: WorldBg,
  SpaceBg: SpaceBg,
  ButtonPrimary: ButtonPrimary,
  ButtonSecondary: ButtonSecondary,
  ButtonSquare: ButtonSquare,
  ProfilePicSample: ProfilePicSample,
  ContactMap: ContactMap,
  CongratulationBg: CongratulationBg,
  TimerBG: TimerBG,
  Globe: Globe,
  Mode: Mode,
  DX: DX,
  State: State,
  Add,
  Apple,
  FirstImg,
  SecondImg,
  ThirdImg,
  ShareLogbookMap,
  ShareLogbookLight
};

export default Images;
