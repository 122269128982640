import { useEffect, useMemo, useState } from "react";
import MultiSelectList from "./MultiSelectList";
import { useSelector } from "react-redux";
import { getContestDetailsSelector } from "store/Reducers/contest.reducer";
import React from "react";
import useLogbookSetting from "hooks/logbookSetting.hook";

function ObjectivesStat({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) {
  return (
    <div className="dark:bg-[#FFFFFF0F] bg-[#f1f3f4] flex flex-col flex-1 gap-1 justify-center cursor-pointer px-4 py-6 dark:text-white text-[#2b2e48]">
      <div className=" font-Barlow text-[28px] font-bold">{value}</div>
      <div className="font-medium text-[14px]">{title}</div>
    </div>
  );
}

type Option = {
  id: string;
  objective: string;
  point: number;
};

function ContestObjectives() {
  const contestDetail = useSelector(getContestDetailsSelector);
  const { updateLogbookSettings, selectedLogbook, logbookLoading } = useLogbookSetting();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
    setSelectedOptions(selectedLogbook?.contestObjectives || []);
  }, [selectedLogbook]);

  const options: Option[] = useMemo(
    () => contestDetail?.objectives || [],
    [contestDetail?.objectives]
  );

  const getSelectedTotalPoints = useMemo(() => {
    return selectedOptions.reduce((acc, optionId) => {
      const option = options.find((o) => o.id === optionId);
      return acc + (option ? option.point : 0);
    }, 0);
  }, [selectedOptions, options]);

  const getCompletionPercentage = useMemo(() => {
    return (selectedOptions.length / options.length) * 100;
  }, [selectedOptions, options]);

  const getTotalPoints = useMemo(() => {
    return options.reduce((acc, option) => acc + option.point, 0);
  }, [options]);

  if (!contestDetail || !contestDetail?.objectives?.length) return null;

  return (
    <section className="dark:bg-[#151541] bg-white mt-2.5 w-full pt-5 px-2.5 py-10 relative dark:text-white text-[#2b2e48]">
      <div className="flex flex-row w-full gap-4">
        <ObjectivesStat
          title="Objectives Completed"
          value={selectedOptions.length}
        />
        <ObjectivesStat title="Total Multipliers" value={getSelectedTotalPoints} />
        <ObjectivesStat
          title="Objectives Completion"
          value={getCompletionPercentage.toFixed(0) + "%"}
        />
      </div>
      <div className="w-full mt-4">
        <div className="font-Barlow text-base font-bold mb-2">
          Mark the Box for All Objectives You Complete
        </div>
        <MultiSelectList
          options={options}
          selectedOptions={selectedOptions}
          loading={logbookLoading}
          onChange={(values) => {
            // setSelectedOptions(values);
            updateLogbookSettings({
              contestObjectives: values,
              isWFD: true
            });
          }}
        />
      </div>
    </section>
  );
}

export default React.memo(ContestObjectives);
