import React from 'react';

const VerticalDivider = () => {
  return <div className="h-full border-l dark:border-white border-[#292948]  !border-opacity-20"></div>;
};

const HorizontalDivider = () => {
  return <div className="w-full border-t dark:border-white border-[#292948] !border-opacity-20"></div>;
};

export { VerticalDivider, HorizontalDivider };
