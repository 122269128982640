import ActivityIndicator from "components/Loading/ActivityIndicator";
import TableMembershipEntries from "components/Table/TableMembershipEntries";
import React from "react";
import { MembershipTableCompProps } from "types/Component";

function MembershipTableComp({
  lists,
  isLoading,
  count,
  filter,
  setFilters,
}: MembershipTableCompProps) {
  if (!isLoading && lists && lists.length) {
    return (
      <TableMembershipEntries
        lists={lists}
        count={count}
        filter={filter}
        setFilters={setFilters}
      />
    );
  } else if (isLoading) {
    return (
      <div className="h-[200px] w-full">
        <ActivityIndicator size={36} />
      </div>
    );
  } else if (lists && lists.length === 0) {
    return (
      <div className="h-[200px] w-full dark:text-white text-[#2b2e48] font-[play] flex justify-center items-center">
        No Contacts Found
      </div>
    );
  } else {
    return (
      <div className="h-[200px] w-full dark:text-white text-[#2b2e48] font-[play] flex justify-center items-center">
        Something Went Wrong
      </div>
    );
  }
}

export default MembershipTableComp;
