import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { useLogBookSettingsForm } from "hooks/useLogBookSettingsForm.hook";
import { FC } from "react";
import { globalNavigate } from "routes/GlobalRoutes";

export const EquipmentSettings: FC = () => {
  const { savedRadioOptions, savedAntennaOptions } = useLogbookSetting();
  const { values, setFormValue } = useLogBookSettingsForm();

  return (
    <div className="flex flex-col dark:text-white text-[#2b2e48] gap-4">
      <div className="font-bold font-Play text-xl">Equipment Settings</div>
      <div className="flex flex-wrap">
        <LogbookSettingDropdown
          list={savedRadioOptions}
          title="Default Radio:"
          value={values?.radio || ""}
          setValue={(value) => {
            if (value === "add") {
              //encode url to avoid special characters and navigate to the url
              globalNavigate("/log-contacts/my-station?tab=My+Radios");
            } else {
              setFormValue("radio", value);
            }
          }}
          className="sm:w-[36%] md:w-[26%]"
        />
        <LogbookSettingDropdown
          list={savedAntennaOptions}
          title="Default Antenna:"
          value={values?.antenna || ""}
          setValue={(value) => {
            if (value === "add") {
              // globalNavigate("/log-contacts/my-station");
              globalNavigate("/log-contacts/my-station?tab=My+Antennas");
            } else {
              setFormValue("antenna", value);
            }
          }}
          className="sm:w-[36%] md:w-[26%]"
        />
        <LogbookSettingDropdown
          title="Default Power:"
          input
          placeholder="Enter power"
          value={values?.defaultPower || ""}
          setValue={(value) => setFormValue("defaultPower", value)}
        />
      </div>
    </div>
  );
};
