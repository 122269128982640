import { LocationSource } from "components/DefaultLocationSettings";
import { ActivityFilterList } from "constants/Config";
import { fetchCoordinates } from "helpers/Location/GeoLocation";
import { getCountryByDXNumber, getStateShortCode } from "helpers/Utils";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNewLogBookAction } from "store/Actions/logBook.actions";
import {
  fetchActivitiesListView,
  fetchActivitiesMap,
  searchActivityListView,
} from "store/Actions/pota.actions";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { Activities, ActivitiesMapData } from "types/Models";
import { useDebouncedCallback } from "use-debounce";
var uniq = require("lodash.uniq");

const useActivity = () => {
  const userProfile = useSelector(getUserProfileSelector);
  const [center, setCenter] = React.useState<any>(null);
  const [userCoordinate, setUserCoordinate] = React.useState<any>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [currentBound, setCurrentBound] = React.useState<any>(null);
  const [infoWindowOpen, setInfoWindowOpen] = React.useState<any>(false);
  const [zoomLevel, setZoomLevel] = React.useState<number>(10);
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  const dispatch = useDispatch();
  const [activityFilter, setActivityFilter] =
    useState<any[]>(ActivityFilterList);
  const onLoad = useCallback((map: google.maps.Map) => setMap(map), []);
  const getUserLocation = async () => {
    const coor = await fetchCoordinates();
    if (coor?.latitude && coor?.longitude) {
      setUserCoordinate(coor);
      setCenter({
        lat: coor.latitude,
        lng: coor.longitude,
      });
      //just for testing uncomment above and comment below  line
      // setCenter({
      //   lat: 37.3229978,
      //   lng: -122.0321823,
      // });
    } else {
      setCenter({
        lat: userProfile?.coordinates?.latitude || 18.52043,
        lng: userProfile?.coordinates?.longitude || 73.856743,
      });
    }
  };
  const debouncedBoundChangeListener = useDebouncedCallback(
    () => {
      if (map && !infoWindowOpen) {
        const selectedFilters = activityFilter
          .filter((item) => item.selected)
          .map((item) => item.name);
        const bounds = map.getBounds();
        const mapZoomLevel = map.getZoom();
        setZoomLevel(mapZoomLevel || 10);
        setCurrentBound(bounds);
        dispatch(fetchActivitiesMap(bounds, selectedFilters, mapZoomLevel));
      }
    },
    // delay in ms
    1000
  );

  const fetchActivityListView = async () => {
    const selectedFilters = activityFilter
      .filter((item) => item.selected)
      .map((item) => item.name);
    const coor = await fetchCoordinates();
    let userCoordinate: any = null;
    if (coor?.latitude && coor?.longitude) {
      userCoordinate = [coor?.latitude, coor?.longitude];
    } else if (
      userProfile?.coordinates?.latitude &&
      userProfile?.coordinates?.longitude
    ) {
      userCoordinate = [
        userProfile?.coordinates?.latitude,
        userProfile?.coordinates?.longitude,
      ];
    } else {
      userCoordinate = [39.8283, -98.5795]; // if we dont have any coordinates then default to US
    }
    dispatch(
      fetchActivitiesListView(
        selectedFilters,
        userCoordinate,
        // [39.8283, -98.5795],

        null
      )
    );
  };

  const debouncedDragListener = useDebouncedCallback(
    () => {
      setInfoWindowOpen(false);
      debouncedBoundChangeListener();
    },
    // delay in ms
    1000
  );

  useEffect(() => {
    //get names of selected filters
    const selectedFilters = activityFilter
      .filter((item) => item.selected)
      .map((item) => item.name);

    filtersChanged(selectedFilters);
  }, [activityFilter]);

  const filtersChanged = useDebouncedCallback((filters) => {
    // filters.length === 4 ? undefined : filters; an temporary workaround because we dont have type="POTA" on the existing pota data
    // and just didnt want to get into writing a script to update all the data just yet until PROD deployment:)
    if (map) {
      const bounds = map.getBounds();
      const mapZoomLevel = map.getZoom();
      dispatch(
        fetchActivitiesMap(
          bounds,
          filters.length === 4 ? undefined : filters,
          mapZoomLevel
        )
      );

      fetchActivityListView();
    }
  }, 1000);

  const onCreateLogbook = (activity: ActivitiesMapData) => {
    const newLogbook: any = {
      name: `${userProfile?.callSign} @ ${activity?.reference}`,
    };
    if (activity?.latitude && activity?.longitude) {
      newLogbook.defaultLocation = {
        isCustom: true,
        country:
          activity.type === Activities.SOTA
            ? ""
            : getCountryByDXNumber(activity?.dxccNumber) ||
              activity.country ||
              "",
        referencePrefix: "",
        state: getStateShortCode(activity?.state || ""),
        qth: "",
        lat: parseFloat(activity.latitude.toString()),
        lng: parseFloat(activity.longitude.toString()),
        gridsquare: activity?.grid6 || activity?.grid4 || "",
        id: "",
        locationType: "",
        default: false,
        name: "",
        uid: "",
        locationSource: LocationSource.PARK,
        parkReference: activity?.reference,
        type: activity?.type || Activities.POTA,
      };
    }
    newLogbook.logbookStyle = Activities.POTA;
    newLogbook.primaryActivity = activity?.type || Activities.POTA;
    newLogbook.activities = [activity?.type || Activities.POTA]; //for easier searching in case its needed in the future
    newLogbook.activitiesData = [
      {
        ...activity,
      },
    ];
    newLogbook.activitiesReferences = [activity?.reference];
    dispatch(createNewLogBookAction(newLogbook));
  };

  const debouncedSearchActivity = useDebouncedCallback(
    async (searchText: string) => {
      const selectedFilters = activityFilter
        .filter((item) => item.selected)
        .map((item) => item.name);
      if (searchText === "") {
        fetchActivityListView();
      } else {
        const coor = await fetchCoordinates();
        let userCoordinate = null;
        if (coor?.latitude && coor?.longitude) {
          userCoordinate = [coor?.latitude, coor?.longitude];
        } else if (
          userProfile?.coordinates?.latitude &&
          userProfile?.coordinates?.longitude
        ) {
          userCoordinate = [
            userProfile?.coordinates?.latitude,
            userProfile?.coordinates?.longitude,
          ];
        } else {
          userCoordinate = [39.8283, -98.5795];
        }
        dispatch(
          searchActivityListView(searchText, selectedFilters, userCoordinate)
        );
      }
    },
    // delay in ms
    1000
  );

  return {
    center,
    getUserLocation,
    debouncedBoundChangeListener,
    map,
    setMap,
    onLoad,
    activityFilter,
    setActivityFilter,
    onCreateLogbook,
    userCoordinate,
    setInfoWindowOpen,
    debouncedDragListener,
    zoomLevel,
    fetchActivityListView,
    debouncedSearchActivity,
    setSearchQuery,
    searchQuery,
  };
};

export default useActivity;
