import { useField } from "formik";
import { SocialInputProps } from "types/Component";

function SocialInput({
  icon,
  placeHolder,
  name,
  setText,
  error,
}: SocialInputProps) {
  const [field] = useField({
    name: name ? name : "",
  });
  return (
    <div className="flex items-center dark:bg-[#FFFFFF0F] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none w-full sm:w-[48%] h-[38px] font-[Play] text-sm">
      <div>{icon}</div>
      <input
        className="bg-transparent w-fit dark:text-white text-[#2b2e48] font-[Barlow] font-medium"
        placeholder={placeHolder}
        type="text"
        id=""
        {...field}
        name={name}
        onChange={setText}
      />
    </div>
  );
}

export default SocialInput;
