/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Icons from "assets/Icons";
import LottieFiles from "assets/LottieFiles";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";

import TableLeaderBoard from "components/Table/TableLeaderBoard";

import { Unsubscribe } from "firebase/firestore";
import { showToast } from "helpers/Toast";
import useContest from "hooks/contest.hook";

import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ContestLeaderboardProps, ToastTypes } from "types/Component";
import { CONTEST_STATUS } from "types/Functions";
import TopPlacement from "./ContestHome/TopPlacement";
import { SliderButtonGroup } from "components/SelectableButtonsGroup";
import { useDispatch } from "react-redux";
import { globalNavigate } from "routes/GlobalRoutes";
import { CONTEST } from "constants/Links";
import { setUserContestDetail } from "store/Actions/contest.actions";

function ContestLeaderboard({
  contestDetail,
  contestStatus,
}: ContestLeaderboardProps) {
  let [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState<string | number>("all");

  const {
    fetchContestLeaderBoard,
    isContestLeaderBoardLoading,
    contestLeaderBoard,
  } = useContest();

    useEffect(() => {
      const contestId = searchParams.get("contest_id");
      if(contestId) {
        fetchContestLeaderBoard(contestId);

        // Fetch every 1.5 minutes after initial load
        const intervalId = setInterval(() => {
          fetchContestLeaderBoard(contestId);
        }, 60 * 1000); // 1.5 minutes in milliseconds
        
        // Cleanup: Unsubscribe the interval when the component unmounts or when `contestId` changes
        return () => {
          clearInterval(intervalId);
        };
      }
    },[searchParams]);

  // get top three leaders
  const topThreeLeaders = contestLeaderBoard.slice(0, 3);

  return (
    <main className=" px-8 py-4">
      <div className="flex flex-col w-full gap-6 mt-6">
        <div className="flex flex-row items-center justify-center dark:text-white text-[#2b2e48] gap-4 text-xl font-bold font-Play uppercase">
          Current leaders | Overall
        </div>

        <div className="flex flex-col md:flex-row w-full gap-4 px-4">
          {
            // display top three leaders
            topThreeLeaders.map((leader, index) => (
              <TopPlacement
                key={index}
                place={index === 0 ? "first" : index === 1 ? "second" : "third"}
                data={leader}
                isWFDContest={contestDetail?.isWFDContest}
                onClick={() => {
                  dispatch(setUserContestDetail(null));
                  globalNavigate(
                    `${CONTEST}/user-logbook-detail?contest_name=${contestDetail?.name}&contest_id=${contestDetail?.id}&user_id=${leader.uid}&tab=entries`
                  );
                }}
              />
            ))
          }
        </div>
      </div>

      <div className="dark:bg-[#010950] bg-white px-8 py-5 w-full mt-[60px]">
        <div className="flex justify-between items-center">
          <div className="flex">
            <div className="flex items-center">
              <div className="flex items-center">
                <img
                  className="h-5 w-5 mr-3 invert dark:filter-none dark:invert-0"
                  src={Icons.TopChart}
                  alt="icon"
                />
                {contestStatus === CONTEST_STATUS.ACTIVE ? (
                  <div className="w-[9px] h-[9px] bg-[#CC000A] rounded animate-shrink-grow"></div>
                ) : null}
              </div>
              <p className="dark:text-white text-[#2b2e48] font-[Play] font-bold text-base ml-[15px]">
                {contestStatus === CONTEST_STATUS.ACTIVE ? "LIVE" : ""} CONTEST
                LEADERS
              </p>
              {isContestLeaderBoardLoading ? (
                <div className={`w-${16} h-${16}`}>
                  <Lottie
                    animationData={LottieFiles.ActivityIndicator}
                    loop={true}
                  />
                </div>
              ) : null}
            </div>
          </div>
          {contestDetail?.isWFDContest ? (
            <div className="w-[50%]">
              <SliderButtonGroup
                initialSelected={selectedFilter}
                disabled={false}
                sliderClass="w-full"
                buttonClass="w-1/5"
                actions={[
                  {
                    id: "all",
                    label: "All",
                  },
                  {
                    id: "H",
                    label: "H - Home",
                  },
                  {
                    id: "I",
                    label: "I - Indoor",
                  },
                  {
                    id: "O",
                    label: "O - Outdoor",
                  },
                  {
                    id: "M",
                    label: "M - Mobile",
                  },
                ]}
                onButtonChange={(action) => {
                  if (action && action.id && contestDetail?.id) {
                    setSelectedFilter(action.id);

                    if (action.id === "all") {
                      fetchContestLeaderBoard(contestDetail?.id);
                    } else {
                      const filters = [
                        {
                          field: "contestClass",
                          operator: "==",
                          value: action.id,
                        },
                      ];
                      fetchContestLeaderBoard(contestDetail?.id, filters);
                    }
                  }
                }}
              />
            </div>
          ) : null}
        </div>

        <div className="mt-4 overflow-y-hidden">
          <div className="w-full">
            <TableLeaderBoard
              list={contestLeaderBoard}
              selectedTab={""}
              contest
              contestId={contestDetail?.id}
              contestName={contestDetail.name}
              contestPassed={contestStatus === CONTEST_STATUS.PASSED}
              isWFDLogBook={contestDetail?.isWFDContest}
            />
          </div>
        </div>
      </div>
      <p className="dark:text-white text-[#2b2e48] text-center my-20 font-[Play]">
        <span className="font-bold">Disclaimer</span> - Live contest scores are
        a live representation of current logging. All logs will undergo an
        extensive audit before winners are declared.
      </p>
    </main>
  );
}

export default ContestLeaderboard;
