import { useField } from "formik";

interface CheckboxProps {
  name: string;
  label: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({ label, ...props }) => {
  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        {...props}
        className="form-checkbox h-5 w-5 bg-[#0243DD]"
        checked={props.value as any}
      />
      <label
        htmlFor={props.name}
        className="ml-2 dark:text-white text-[#2b2e48] text-sm font-Play "
      >
        {label}
      </label>
    </div>
  );
};
