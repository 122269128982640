import { FC } from "react";

type QuickSettingItem = {id:number, icon: string; label: string};

type LogBookQuickSettingItemProps = {
    data: QuickSettingItem;
    onItemClick: (item: QuickSettingItem) => void;
};

export const QuickSettingItem: FC<LogBookQuickSettingItemProps> = ({data, onItemClick}) => {
  return (
    <div
      className="flex flex-row items-center gap-2 cursor-pointer"
      onClick={()=> {
        onItemClick(data)
      }}
    >
      <img src={data.icon} alt="heading icon" />
      <div className="dark:text-white text-[#2b2e48] text-sm">{data.label}</div>
    </div>
  );
};
