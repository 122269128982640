import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import Header from "components/Header";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import { useSupport } from "hooks/useBugReport.hook";
import useTheme from "hooks/useTheme.hook";
import { useState } from "react";
import { globalNavigate } from "routes/GlobalRoutes";

export const ReportBug = () => {
  const { isLoading, reportBug } = useSupport();
  const { theme } = useTheme();

  const [formValues, setFormValues] = useState({
    details: "",
  });

  const handleSubmit = async () => {
    reportBug(formValues, () => {
      setFormValues({
        details: "",
      });
      globalNavigate("/");
    });
  };

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full">
      <Header title="Report a Bug" />

      <div className="w-full dashboardWrapper flex items-center justify-center">
        <div className={`${theme === 'dark' ? 'glassmorphism': 'bg-white' } w-[80%] sm:w-2/3 md:w-4/6 lg:w-4/6 xl:w-4/6 2xl:w-3/6 py-20 px-6 sm:px-6 md:px-12 lg:px-14 xl:px-20 flex flex-col items-center gap-4`}>
          <TitledInputDefault
            setText={(e) => {
              setFormValues((prev: any) => {
                return {
                  ...prev,
                  details: e.target.value,
                };
              });
            }}
            title="Please provide as much detail as possible: "
            name="details"
            placeHolder="details about the bug..."
            value={formValues?.details}
            width="w-full"
            className="bg-white bg-opacity-10 h-[200px]"
            tabindex={1}
            multiple
          />

          <div className="flex w-[60%]">
            <BorderButtonSecondary
              isLoading={isLoading}
              text="Submit Bug Report"
              onClick={handleSubmit}
              childClassName="text-sm"
              tabIndex={2}
            />
          </div>
        </div>
      </div>
    </main>
  );
};
