import React, { useEffect, useState } from "react";
import InputWithLabelDefault from "components/Inputs/InputWithLabel/InputWithLabelDefault";
import BorderButtonPrimary from "components/Button/BorderButtonPrimary";
import useResetPassword from "hooks/useResetPassword.hook";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { useSelector } from "react-redux";
import { getAuth, UserProfile } from "firebase/auth";
import Hint from "components/Hint";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordProvider, setPasswordProvider] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      const isPasswordProvider = user.providerData.some(
        (provider) => provider.providerId === "password"
      );

      setPasswordProvider(isPasswordProvider);
    }
  });

  const { handleResetPassword } = useResetPassword();

  const resetFields = () => {
    setPassword("");
    setConfirmPassword("");
    setPasswordError("");
    setConfirmPasswordError("");
    setCurrentPassword("");
  };

  const updatePassword = () => {
    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters long.");
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      return;
    }

    handleResetPassword(currentPassword, password);
    resetFields();
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value.length < 6) {
      setPasswordError("Password must be at least 6 characters long.");
    } else {
      setPasswordError("");
    }

    if (confirmPassword && e.target.value !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(e.target.value);
    if (password && e.target.value !== password) {
      setConfirmPasswordError("Passwords do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  return (
    <div className="mt-8 dark:text-white text-[#2b2e48] flex flex-col gap-y-1">
      <span className="font-Play font-bold">Password</span>
      <div className="flex justify-between mb-4">
        <span className="text-xs">Change the password for your account</span>
      </div>

      {passwordProvider ? (
        <div className="flex flex-col sm:gap-6 sm:flex-row justify-start sm:justify-between">
          <div className="mb-6 w-full">
            <InputWithLabelDefault
              text={currentPassword}
              type="password"
              label="Current password"
              placeholder="Enter your current password."
              setText={(e) => setCurrentPassword(e.target.value)}
            />
          </div>
          <div className="w-full"></div>
        </div>
      ) : null}

      <div className="flex flex-col sm:gap-6 sm:flex-row justify-start sm:justify-between">
        <div className="w-full mb-6">
          <InputWithLabelDefault
            text={password}
            type="password"
            label="New password"
            placeholder="Create a password with at least 6 characters."
            setText={handlePasswordChange}
            error={passwordError ? passwordError : ""}
          />
        </div>
        <div className="w-full mb-6">
          <InputWithLabelDefault
            text={confirmPassword}
            type="password"
            label="Confirm new password"
            placeholder="Re-enter your new password."
            setText={handleConfirmPasswordChange}
            error={confirmPasswordError ? confirmPasswordError : ""}
          />
        </div>
      </div>

      {!passwordError &&
      !confirmPasswordError &&
      password &&
      confirmPassword ? (
        <div className="w-full flex flex-row gap-4 justify-center">
          <div className="w-[100%] sm:w-[50%]  flex flex-row gap-4">
            <BorderButtonPrimary
              type="button"
              text="Update password"
              onClick={updatePassword}
            />
            <BorderButtonPrimary
              type="button"
              text="Cancel"
              onClick={resetFields}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ResetPassword;
