import ActivityIndicator from "components/Loading/ActivityIndicator";
import TableLeaderBoard from "components/Table/TableLeaderBoard";
import React from "react";
import { TableWrapperLeaderBoardProps } from "types/Component";

function TableWrapperLeaderBoard({
  isLoading,
  list,
  selectedTab,
  distance,
  leaderboard,
  weekly
}: TableWrapperLeaderBoardProps) {
  if (isLoading) {
    return (
      <div className="px-5">
        <ActivityIndicator size={36} />
      </div>
    );
  } else if (!list.length) {
    return (
      <div className="h-[200px] w-full dark:text-white text-[#2b2e48] font-[play] flex justify-center items-center">
        No Users Found
      </div>
    );
  } else {
    return (
      <div className="p-[20px] overflow-y-hidden">
        <div className="w-[570px]">
          <TableLeaderBoard
            list={list}
            selectedTab={selectedTab}
            distance={distance}
            weekly={weekly}
          />
        </div>
      </div>
    );
  }
}

export default TableWrapperLeaderBoard;
