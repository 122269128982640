import React, { useCallback, useEffect, useRef, useState } from 'react';

interface Option {
  key: string;
  label: string;
  visible: boolean;
}

interface MultiSelectDropdownProps {
  options: Option[];
  label: string;
  context?: string;
  onChange: (selectedKeys: string[], context?: string) => void;
  selectedKeys?: string[];
  className?: string;
}

const CustomMultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  options,
  label,
  context,
  onChange,
  selectedKeys = [],
  className = '',
}) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(selectedKeys || []);
  const debounceTimerRef = useRef<any>();

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectedOptions(selectedKeys);
  }, [selectedKeys]);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownVisible(false);
      }
    };

    // Add the event listener
    document.addEventListener('mousedown', handleOutsideClick);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

   // Debounced onChange handler
   const debouncedOnChange = useCallback((updatedOptions: string[]) => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
      onChange(updatedOptions, context);
    }, 1000); // 1 second delay
  }, [onChange, context]);

  // Cleanup timer on unmount
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);


  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleOptionClick = (key: string) => {
    let updatedSelectedOptions = [...selectedOptions];
    if (updatedSelectedOptions.includes(key)) {
      updatedSelectedOptions = updatedSelectedOptions.filter((option) => option !== key);
    } else {
      updatedSelectedOptions.push(key);
    }
    setSelectedOptions(updatedSelectedOptions);
    debouncedOnChange(updatedSelectedOptions);
  };

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      {/* Dropdown Button */}
      <div
        className="w-[150px] h-[30px] flex items-center justify-between rounded-sm px-3 cursor-pointer border border-[#5a5a7a] bg-gradient-to-r from-[#3a3a5f] to-[#444467] hover:from-[#4c4c6d] hover:to-[#5a5a7a] transition-all duration-150 ease-in-out shadow-inner dropdown-button no-select"
        onClick={(e) => {
          e.stopPropagation();
          toggleDropdown();
        }}
      >
        <span className="text-white font-Play text-sm">{label}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 text-white"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </div>

      {/* Dropdown Menu */}
      {dropdownVisible && (
        <div
          className="absolute top-10 left-0 w-[150px] bg-[#3a3a5f] rounded-sm shadow-lg p-2 max-h-60 overflow-y-auto z-10 border border-[#5a5a7a] no-select"
        >
          {options.map((option) => (
            <div
              key={option.key}
              className="flex items-center p-2 hover:bg-[#5a5a7a] rounded-sm transition-colors duration-150 ease-in-out cursor-pointer"
              onClick={() => handleOptionClick(option.key)}
            >
              <input
                type="checkbox"
                checked={selectedOptions.includes(option.key)}
                onChange={() => handleOptionClick(option.key)}
                className="form-checkbox text-[#FFD700] bg-[#2f2f4f] border-gray-500 rounded mr-2"
              />
              <label className="text-white font-Play text-xs">{option.label}</label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomMultiSelectDropdown;
