import BorderButtonPrimary from "components/Button/BorderButtonPrimary";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import useAuth from "hooks/auth.hook";
import useConfig from "hooks/config.hook";
import useTheme from "hooks/useTheme.hook";
import React from "react";

function PrimaryModal() {
  const { primaryModalData, handlePrimaryModal } = useConfig();
  const { logOut } = useAuth();
  const isDanger = primaryModalData?.criticality === "danger";
  const { theme } = useTheme();
  return (
    <section
      className={`h-full w-full flex-grow overflow-x-auto absolute top-0 right-0 bg-[#eeeeee38] flex items-center justify-center ${
        primaryModalData ? "" : "hidden"
      } shadow-xl`}
    >
      <div
        className="dark:bg-[#0B0B39] bg-white w-[95vw] sm:w-auto sm:max-w-[600px] px-8 sm:px-14 pt-7 pb-4 rounded-[20px]"
        style={{
          backgroundColor: isDanger
            ? "red"
            : theme === "dark"
            ? "#0B0B39"
            : "#fff",
        }}
      >
        <p className="font-[Play] font-bold dark:text-white text-[#2b2e48] text-center text-lg">
          {primaryModalData?.type}
        </p>
        {primaryModalData?.subType ? (
          <p className="font-[Play] font-bold dark:text-[#17F9DA] text-[#1e40af] text-center text-lg">
            {primaryModalData?.subType}
          </p>
        ) : null}
        <p className="dark:text-white text-[#2b2e48] text-center font-[Play] font-bold text-2xl mb-7">
          {primaryModalData?.title}
        </p>
        <div className="flex justify-between items-center">
          <div className="w-[48%] mb-0 sm:mb-5">
            <BorderButtonSecondary
              text={primaryModalData?.cancelText || ""}
              onClick={
                primaryModalData?.onCancelPressed
                  ? primaryModalData?.onCancelPressed
                  : () => handlePrimaryModal(null)
              }
            />
          </div>
          <div className="w-[48%]">
            <BorderButtonPrimary
              text={primaryModalData?.submitText || ""}
              onClick={
                primaryModalData?.onSubmitPressed
                  ? primaryModalData?.onSubmitPressed
                  : () => {
                      handlePrimaryModal(null);
                      logOut();
                    }
              }
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrimaryModal;
