import TableCol from "components/Table/TableEntries/TableCol";
import React from "react";
import { TableEntriesProps } from "types/Component";
import { FaSort } from "react-icons/fa";

function TableEntries({
  lists,
  editable,
  missingFields,
  guestUser,
  setDateOrder,
  showSort,
  showExchange,
  exchangeOne,
  exchangeTwo,
  exchangeThree,
  exchangeFour,
}: TableEntriesProps) {
  return (
    <div className="w-full overflow-scroll mt-4">
      <table className="w-full min-w-max table-auto">
        <thead className="">
          <tr className="bg-[#94a3b883] dark:bg-[#3C3C58] py-2.5 border-0 border-b border-slate-300 border-solid dark:text-white text-[#2b2e48]">
            <th className="py-3 font-[Barlow] text-sm md:text-left flex items-center">
              <div className="md:ml-4">Date</div>
              {showSort ? (
                <div
                  className="ml-2 mt-1 cursor-pointer"
                  onClick={() => setDateOrder && setDateOrder()}
                >
                  <FaSort className="" />
                </div>
              ) : null}
            </th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">User</th>
            <th className="py-3 font-[Barlow] text-sm md:text-left"></th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">Sent</th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">Rcvd</th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">
              Frequency
            </th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">Mode</th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">
              Distance
            </th>
            {showExchange ? (
              <>
                <th className="py-3 font-[Barlow] text-sm md:text-left">
                  {exchangeOne}
                </th>

                <th className="py-3 font-[Barlow] text-sm md:text-left">
                  {exchangeTwo}
                </th>

                <th className="py-3 font-[Barlow] text-sm md:text-left">
                  {exchangeThree}
                </th>

                <th className="py-3 font-[Barlow] text-sm md:text-left">
                  {exchangeFour}
                </th>
              </>
            ) : null}

            {editable ? (
              <th className="py-3 font-[Barlow] text-sm md:text-left">Notes</th>
            ) : null}
            <th className="py-3 font-[Barlow] text-sm md:text-left">Tags</th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">Conf.</th>
            <th className="py-3 font-[Barlow] text-sm md:text-left"></th>
            {editable ? (
              <>
                <th className="py-3 font-[Barlow] text-sm md:text-left"></th>
                <th className="py-3 font-[Barlow] text-sm md:text-left"></th>
              </>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {lists.map((item: any, index: number) => {
            return (
              <TableCol
                key={`${item.id}_${index}`}
                item={item}
                className={`${
                  index % 2 ? "dark:bg-[#151536] bg-white" : "dark:bg-[#1F1F3F] bg-[#fafafa]"
                } border-0 border-b border-slate-300 border-solid`}
                editable={editable}
                missingFields={missingFields}
                guestUser={guestUser}
                showExchange={showExchange}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableEntries;
