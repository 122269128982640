import Icons from "assets/Icons";
import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import { UserProfileChartProps } from "types/Component";

const UserProfileChart = ({
  data,
  totalAmount,
  title,
  listSurfix = "",
}: UserProfileChartProps) => {
  return (
    <div className="w-[48%] h-full relative">
      <div className="absolute w-full h-[106%] flex justify-center top-[-3%] z-0">
        <img
          className={`w-[90%] h-full object-fill`}
          src={Icons.GradientRectanglePic}
          alt="bg"
        />
      </div>
      <div className="h-full dark:bg-[#181643] bg-white shadow-lg rounded-3xl relative">
        <div className="border-b border-white border-solid px-5 py-3">
          <p className="dark:text-white text-[#2b2e48] font-[Play] text-lg font-bold">{title}</p>
          {/* <div className="flex items-end">
            <p className="text-white text-2xl font-[Play] font-bold mr-1">
              {totalAmount}
            </p>
            <p className="text-[#9D9D9D] font-[Play] text-lg">in total</p>
          </div> */}
        </div>
        <div className="flex justify-between items-center px-4 lg:px-2 xl:px-5 pt-5 pb-8">
          <div className="w-[60%]">
            <PieChart
              data={
                data && data.length
                  ? data
                  : [
                      {
                        title: "",
                        value: 100,
                        color: "#CCFF00",
                      },
                    ]
              }
              animate
            />
          </div>
          <div className="">
            {data && data.length
              ? data.map((item: any, i: number) => {
                  return (
                    <div className="flex items-center" key={`${item.key}-${i}`}>
                      <div
                        className="h-2 w-4 mr-1"
                        style={{
                          backgroundColor: item?.color,
                        }}
                      />
                      <p className="text-xs dark:text-white text-[#2b2e48] font-[Play]">
                        {item.key} {listSurfix}
                      </p>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        {!data?.length ? (
          <p className="dark:text-white text-[#2b2e48] text-center font-[Play] text-sm md:text-base w-full my-2">
            No Data Found
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default UserProfileChart;
