import React, { useState, useEffect } from "react";
import { ContestCountDownProps } from "types/Component";

const CountDown = ({ startDate, endDate }: ContestCountDownProps) => {
  const calculateTimeRemaining = () => {
    const now = new Date().getTime();
    let difference;

    if (startDate.getTime() > now) {
      difference = startDate.getTime() - now;
    } else {
      difference = endDate.getTime() - now;
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    if (endDate.getTime() < now) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    } else {
      return { days, hours, minutes, seconds };
    }
  };

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  return (
    <div className="flex items-center">
      <div className="flex text-white text-xl md:text-3xl font-bold">
        <div className="flex flex-col items-center">
          <div>{timeRemaining.days}</div>
          <div className="text-[10px] md:text-xs font-semibold">DAYS</div>
        </div>
        <span> : </span>
        <div className="flex flex-col items-center">
          <div>{timeRemaining.hours}</div>
          <div className="text-[10px] md:text-xs font-semibold">HRS</div>
        </div>
        <span> : </span>
        <div className="flex flex-col items-center">
          <div>{timeRemaining.minutes}</div>
          <div className="text-[10px] md:text-xs font-semibold">MIN</div>
        </div>
        <span> : </span>
        <div className="flex flex-col items-center">
          <div>{timeRemaining.seconds}</div>
          <div className="text-[10px] md:text-xs font-semibold">SEC</div>
        </div>
      </div>
    </div>
  );
};

export default CountDown;
