import React, { useState, useEffect } from "react";
import ContactMapControls from "components/ContactMapControls";
import UserContactMap from "components/UserContactMap";
import { ContactMapProps } from "types/Component";
import { useDispatch } from "react-redux";
import { getAllUserContacts } from "store/Actions/logBook.actions";
function UserContactMapComp({
  className,
  containerClassName,
  allContactMode,
  setHoverArc,
  captureSnapshot,
  isLoadingImg,
  screenShotImage,
  guestMode,
  userId,
  showShare,
}: ContactMapProps) {
  const [showImported, setShowImported] = useState(true);
  const [showLimit, setShowLimit] = useState(200);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userId) {
      dispatch(
        getAllUserContacts(userId, {
          limit: showLimit,
          showImported: showImported,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showImported, showLimit]);
  return (
    <div className={containerClassName}>
      {!guestMode ? (
        <ContactMapControls
          setShowImported={setShowImported}
          setShowLimit={setShowLimit}
          showLimit={showLimit}
        />
      ) : null}
      <UserContactMap
        className={className}
        allContactMode={false}
        setHoverArc={setHoverArc}
        captureSnapshot={captureSnapshot}
        isLoadingImg={isLoadingImg}
        screenShotImage={screenShotImage}
        guestMode={guestMode}
        showShare={showShare}
        restrictBounds={false} // Only show a single map not repeated
        shouldFitBounds={false} // Fit bounds to the map to show region with markers
      />
    </div>
  );
}

export default UserContactMapComp;
