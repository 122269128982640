import {
  getCountryAndStateFromGrid,
  getLocationFromAddress,
} from "firebase-config";
import { fetchCoordinates } from "helpers/Location/GeoLocation";
import {
  calculateMaidenhead,
  getCQAndITUZonesFromCoordinates,
  getStateLongName,
  getStateShortCode,
  latLonFromGrid,
} from "helpers/Utils";
import { useState } from "react";
import {
  CoordinatesParams,
  updateUserProfile,
} from "store/Actions/profile.actions";
import { SavedLocation } from "types/Models";
import useConfig from "./config.hook";
import { useDispatch, useSelector } from "react-redux";
import { addSavedLocation } from "store/Actions/logBook.actions";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { useLocation } from "react-router-dom";
import {
  getIsLogbookSettingLoadingSelector,
  getIsProfileLoadingSelector,
} from "store/Reducers/loading.reducer";

const initialLocationForm = {
  locationType: "",
  name: "",
  qth: "",
  country: "",
  state: "",
  city: "",
  gridsquare: "",
  lat: "",
  lng: "",
  ituZone: "",
  cqZone: "",
  setAsHome: false,
  locationMethod: "address" as any,
};

export const useSavedLocations = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [locationForm, setLocationForm] =
    useState<SavedLocation>(initialLocationForm);

  const [addressLoading, setAddressLoading] = useState(false);
  const { handlePrimaryModal } = useConfig();

  const savedLocationLoading = useSelector(getIsLogbookSettingLoadingSelector);
  const skipLocationLoading = useSelector(getIsProfileLoadingSelector);

  const getLocationDetailsFromAddress = async (address: string) => {
    try {
      setAddressLoading(true);
      const { data }: any = await getLocationFromAddress(address);

      if (data) {
        const {
          lat,
          lng,
          formattedAddress,
          city,
          state,
          country,
          countryCode,
        } = data;
        const { ituZone, cqZone } = await getCQAndITUZonesFromCoordinates(
          lat,
          lng
        );
        const gridsquare = calculateMaidenhead(lat, lng);
        setAddressLoading(false);

        return {
          lat,
          lng,
          formattedAddress,
          ituZone,
          cqZone,
          gridsquare,
          city,
          state,
          country,
          countryCode,
        };
      }
      setAddressLoading(false);

      return data;
    } catch (error) {
      setAddressLoading(false);
      return null;
    }
  };

  const getLocationDetailsFromGrid = async (grid: string) => {
    try {
      setAddressLoading(true);
      const { data }: any = await getCountryAndStateFromGrid({ grid });

      if (data) {
        const { formattedAddress, city, state, country, countryCode } = data;

        const latLng = latLonFromGrid(grid);
        const { ituZone, cqZone } = await getCQAndITUZonesFromCoordinates(
          latLng?.latitude || 0,
          latLng?.longitude || 0
        );
        setAddressLoading(false);

        return {
          lat: latLng?.latitude,
          lng: latLng?.longitude,
          formattedAddress,
          ituZone,
          cqZone,
          gridsquare: grid,
          city,
          state,
          country,
          countryCode,
        };
      }
      setAddressLoading(false);

      return data;
    } catch (error) {
      setAddressLoading(false);
      return null;
    }
  };

  const populateAddressForm = async (
    payload: any,
    type: "address" | "grid" | "map" | "currentLocation"
  ) => {
    if (payload && type === "address") {
      const response = await getLocationDetailsFromAddress(payload);

      if (response) {
        const { formattedAddress, ...rest } = response;

        if (rest) {
          setLocationForm((prev) => ({
            ...prev,
            ...rest,
          }));
        }
      }
    }

    if (payload && type === "grid") {
      const response = await getLocationDetailsFromGrid(payload);

      if (response) {
        const { formattedAddress, countryCode, ...rest } = response;

        if (rest) {
          setLocationForm((prev) => ({
            ...prev,
            ...rest,
          }));
        }
      }
    }

    if (type === "currentLocation") {
      const response: any = await getUserCurrentLocation();

      if (response) {
        const { formattedAddress, countryCode, ...rest } = response;

        if (rest) {
          setLocationForm((prev) => ({
            ...prev,
            ...rest,
          }));
        }
      }
    }

    if (payload?.lat && payload?.lng && type === "map") {
      const response: any = await getMapLocationDetails({
        latitude: payload?.lat,
        longitude: payload?.lng,
      });

      if (response) {
        const { formattedAddress, countryCode, ...rest } = response;

        if (rest) {
          setLocationForm((prev) => ({
            ...prev,
            ...rest,
          }));
        }
      }
    }
  };

  const getUserCurrentLocation = async () => {
    try {
      setAddressLoading(true);
      const coordinates: CoordinatesParams = await fetchCoordinates();

      if (coordinates) {
        const gridsquare = calculateMaidenhead(
          coordinates.latitude,
          coordinates.longitude
        );
        if (gridsquare) {
          const { data }: any = await getCountryAndStateFromGrid({
            grid: gridsquare,
          });

          if (data) {
            const { formattedAddress, city, state, country, countryCode } =
              data;

            const { ituZone, cqZone } = await getCQAndITUZonesFromCoordinates(
              coordinates?.latitude || 0,
              coordinates?.longitude || 0
            );

            setAddressLoading(false);
            return {
              lat: coordinates?.latitude,
              lng: coordinates?.longitude,
              formattedAddress,
              ituZone,
              cqZone,
              gridsquare,
              city,
              state,
              country,
              countryCode,
            };
          }
        }
        setAddressLoading(false);
      } else {
        handlePrimaryModal({
          title:
            "We dont have access to your location data, you can give us permission by going to the top URL section of your app and granting us permission.",
          type: "Location Permission",
          onCancelPressed: () => handlePrimaryModal(null),
          onSubmitPressed: () => {
            handlePrimaryModal(null);
          },
          submitText: "Ok",
          cancelText: "Cancel",
        });
        setAddressLoading(false);
      }
      setAddressLoading(false);

      return null;
    } catch (e) {
      setAddressLoading(false);
      return null;
    }
  };

  const getMapLocationDetails = async (coordinates: CoordinatesParams) => {
    try {
      setAddressLoading(true);

      if (coordinates) {
        const gridsquare = calculateMaidenhead(
          coordinates.latitude,
          coordinates.longitude
        );
        if (gridsquare) {
          const { data }: any = await getCountryAndStateFromGrid({
            grid: gridsquare,
          });

          if (data) {
            const { formattedAddress, city, state, country, countryCode } =
              data;

            const { ituZone, cqZone } = await getCQAndITUZonesFromCoordinates(
              coordinates?.latitude || 0,
              coordinates?.longitude || 0
            );
            setAddressLoading(false);

            return {
              lat: coordinates?.latitude,
              lng: coordinates?.longitude,
              formattedAddress,
              ituZone,
              cqZone,
              gridsquare,
              city,
              state,
              country,
              countryCode,
            };
          }
          setAddressLoading(false);
        }
        setAddressLoading(false);
      } else {
        handlePrimaryModal({
          title:
            "We dont have access to your location data, you can give us permission by going to the top URL section of your app and granting us permission.",
          type: "Location Permission",
          onCancelPressed: () => handlePrimaryModal(null),
          onSubmitPressed: () => {
            handlePrimaryModal(null);
          },
          submitText: "Ok",
          cancelText: "Cancel",
        });
        setAddressLoading(false);
      }

      return null;
    } catch (e) {
      setAddressLoading(false);
      return null;
    }
  };

  const validateLocationForm = (formData: any) => {
    let requiredFields: any = {
      name: "Please enter location name.",
      locationType: "Please select location type.",
      country: "Please enter country.",
      // state: "Please enter state.",
      // city: "Please enter city.",
      gridsquare: "Please enter grid.",
      lat: "Please enter latitude.",
      lng: "Please enter longitude.",
      ituZone: "Please enter ITU zone.",
      cqZone: "Please enter CQ zone.",
      locationMethod: "Please enter location method.",
    };

    if (formData.locationMethod === "address") {
      requiredFields.qth = "Please enter address.";
    }

    for (const field in requiredFields) {
      if (!formData[field]) {
        return requiredFields[field];
      }
    }

    return null;
  };

  const toast = (message: string) => {
    showToast({
      message: message,
      type: ToastTypes.ERROR,
    });
  };

  const saveLocation = () => {
    const errorMessage = validateLocationForm(locationForm);

    if (errorMessage) {
      toast(errorMessage);
    } else {
      let { setAsHome, ...rest } = locationForm;

      if (location?.state?.data?.id) {
        rest.id = location?.state?.data?.id;
      }

      if (rest.locationMethod !== "address") {
        rest.qth = "";
      }
      const data = { ...rest };
      const stateShortCode = getStateShortCode(data?.state || "");
      if (stateShortCode) {
        data.stateLongName = getStateLongName(data?.state || "");
        data.state = stateShortCode;
      }
      // console.log("LOCATION DATA : ", data);
      dispatch(addSavedLocation({ ...data, setAsHome }));
    }
  };

  const resetLocationForm = () => {
    const { locationMethod, ...rest } = initialLocationForm;
    setLocationForm((prev) => ({
      ...prev,
      ...rest,
    }));
  };

  const resetPartialLocationForm = () => {
    const { locationMethod, name, locationType, ...rest } = initialLocationForm;
    setLocationForm((prev) => ({
      ...prev,
      ...rest,
    }));
  };

  const skipLocationOnboarding = () => {
    dispatch(
      updateUserProfile({
        user: {
          needsLocationOnboarding: false,
        },
      })
    );
  };

  return {
    locationForm,
    setLocationForm,
    addressLoading,
    populateAddressForm,
    getUserCurrentLocation,
    saveLocation,
    resetLocationForm,
    savedLocationLoading,
    skipLocationOnboarding,
    skipLocationLoading,
    resetPartialLocationForm,
  };
};
