import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import Header from "components/Header";
import { auth } from "firebase-config";
import { useUserActivity } from "hooks/useUserActivity.hook";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { globalNavigate } from "routes/GlobalRoutes";
import { NavigateBackButton } from "../NavigateBackButton";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import { appendCopyToADI, filterDescription, objectToString } from "helpers/Utils";
import { UserActivityTypeDisplayName } from "components/Table/ActivityLogTable/TableCol";

function ActivityDetails() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState<any>(null);
  const dataTime = moment
    .unix(data?.timestamp.seconds)
    .format("hh:mm:ss DD/MM/YYYY");
  const { setIsLoading, isLoading, getActivityDetailsById } = useUserActivity();

  const fetchActivityDetails = () => {
    const activityId = searchParams.get("activity_id");
    if (activityId) {
      setIsLoading(true);
      return getActivityDetailsById(activityId, (response) => {
        setData(response);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    let unsubscribe: any;

    if (userId) {
      if (searchParams.get("activity_id") && !data) {
        unsubscribe = fetchActivityDetails();
      }
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [auth.currentUser?.uid, data, searchParams.get("activity_id")]);

  const renderImportDetails = () => {
    return (
      <div className="mt-6 mb-10 px-5 md:px-7 h-fit relative">
        <div className="flex flex-col items-start py-3 gap-2">
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">Logbook Name: </span>
            {data?.logbookName}
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">File Name: </span>{" "}
            {data?.fileName}
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">Date Time: </span>{" "}
            {dataTime}
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">Import Status: </span>{" "}
            {data?.status}
          </p>
        </div>

        <div className="flex py-3 gap-x-4 mt-6">
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            Total QSOs:{" "}
            <span className="dark:text-white text-[#2b2e48]">{data?.totalQsos || 0}</span>
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            Total Successful:{" "}
            <span className="text-[#3AFE09]">{data?.successQsos || 0}</span>
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            Total Failed:{" "}
            <span className="text-[#FF0202]">{data?.failedQsos || 0}</span>
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            Duplicate:{" "}
            <span className="dark:text-white text-[#2b2e48]">{data?.duplicateQsos || 0}</span>
          </p>
        </div>

        {data?.status !== "In Progress" ? (
          <div className="flex flex-col sm:flex-row justify-center items-center gap-10 mt-14">
            <div className="w-[80%] md:w-[30%]">
              <BorderButtonSecondary
                text={`View QSOs from Import`}
                onClick={() => globalNavigate(`/log-contacts/qso-manager?adiFileName=${data?.fileName}`)}
                className="sm:mb-0"
              />
            </div>
            <div className="w-[80%] md:w-[30%]">
              <BorderButtonSecondary
                text={`Download Import .ADIF`}
                onClick={() => {

                  try {
                    const fileUrl = data?.adiFileCopy || appendCopyToADI(data?.adiFile); // Replace with your file URL

                    if (!fileUrl) {
                      return;
                    }                    
  
                    const link = document.createElement("a");
                    link.href = fileUrl;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link); // Clean up
                  } catch (error) {
                    
                  }
                }}
                className="sm:mb-0"
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const renderMergeDetails = () => {
    return (
      <div className="mt-6 mb-10 px-5 md:px-7 h-fit relative">
        <div className="flex flex-col items-start py-3">
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">Merge Date Time:</span>{" "}
            {dataTime}
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">Merge From: </span>
            {data?.sourceLogbookName}
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">Merge Into: </span>{" "}
            {data?.destinationLogbookName}
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">
              Total QSOs Merged:{" "}
            </span>{" "}
            {data?.totalQsos}
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">Merge Status: </span>{" "}
            {data?.status}
          </p>
        </div>
      </div>
    );
  };

  const renderExportDetails = () => {
    return (
      <div className="mt-6 mb-10 px-5 md:px-7 h-fit relative">
        <div className="flex flex-col items-start py-3">
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">Export Date Time:</span>{" "}
            {dataTime}
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">Export From: </span>
            {data?.logbookName}
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">File Name: </span>{" "}
            {data?.fileName}
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">
              Total QSOs Exported:{" "}
            </span>{" "}
            {data?.totalQsos}
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">Export Status: </span>{" "}
            {data?.status}
          </p>
        </div>

        <div className="flex flex-col sm:flex-row justify-center items-center gap-10 mt-14">
          <div className="w-[80%] md:w-[30%]">
            <BorderButtonSecondary
              text={`Download Export .ADIF`}
              onClick={() => {
                const fileUrl = data?.fileUrl; // Replace with your file URL
                const link = document.createElement("a");
                link.href = fileUrl;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link); // Clean up
              }}
              className="sm:mb-0"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderBulEditDetails = () => {
    return (
      <div className="mt-6 mb-10 px-5 md:px-7 h-fit relative">
        <div className="flex flex-col items-start py-3">
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48]">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">
              Bulk Edit Date Time:
            </span>{" "}
            {dataTime}
          </p>
          {/* <p className="font-[play] text-lg dark:text-white text-[#2b2e48]">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">
              Selection Criteria:{" "}
            </span>{" "}
            {filterDescription(data?.criteria)}
          </p> */}
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48]">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">
              Total QSOs Updated:{" "}
            </span>{" "}
            {data?.totalQsos}
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48]">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">Merge Status: </span>{" "}
            {data?.status}
          </p>
        </div>

        <p className="font-[play] font-bold text-lg dark:text-white text-[#2b2e48] my-4">
          Selection Criteria
        </p>
        <div className="w-[90%] lg:w-[50%] overflow-y-hidden mb-4">
          <table className="bg-white w-full">
            <tbody>
              <tr className="bg-[#94a3b883] dark:bg-[#3C3C58] pb-2.5 border-0 border-b border-slate-300 border-solid">
                <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48]">
                  Field name
                </th>
                <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48] flex items-center">
                  Criteria
                </th>
              </tr>
              {Object.keys(data?.criteria).length > 0
                ? Object.keys(data?.criteria).map((item, index) => {
                    return (
                      <tr
                        key={item}
                        className={`${
                          index % 2 ? "dark:bg-[#151536] bg-white" : "dark:bg-[#1F1F3F] bg-white"
                        } border-0 border-b border-slate-300 border-solid cursor-pointer`}
                      >
                        <td className="pl-4 dark:text-white text-[#2b2e48]  text-xs font-[Barlow] font-normal py-3">
                          <div className="flex">
                            <span>{item}</span>
                          </div>
                        </td>
                        <td className="pl-4 py-3 dark:text-[#17F9DA] text-[#1e40af] text-xs font-[Barlow] font-normal border-l">
                          {Array.isArray(data?.criteria[item])
                            ? data?.criteria[item].join(", ")
                            : typeof data?.criteria[item] === "object"
                            ? objectToString(data?.criteria[item]) || "--"
                            : data?.criteria[item]}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>

        <p className="font-[play] font-bold text-lg dark:text-white text-[#2b2e48] my-4 mt-10">
          Fields Updated
        </p>
        <div className="w-[90%] lg:w-[50%] overflow-y-hidden mb-4">
          <table className="bg-white w-full">
            <tbody>
              <tr className="bg-[#94a3b883] dark:bg-[#3C3C58] pb-2.5 border-0 border-b border-slate-300 border-solid">
                <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48]">
                  Field Name
                </th>
                <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48] flex items-center">
                  New Value
                </th>
              </tr>
              {Object.keys(data?.fieldsUpdated).length > 0
                ? Object.keys(data?.fieldsUpdated).map((item, index) => {
                    return (
                      <tr
                        key={item}
                        className={`${
                          index % 2 ? "dark:bg-[#151536] bg-white" : "dark:bg-[#1F1F3F] bg-white"
                        } border-0 border-b border-slate-300 border-solid cursor-pointer`}
                      >
                        <td className="pl-4 dark:text-white text-[#2b2e48]  text-xs font-[Barlow] font-normal py-3">
                          <div className="flex">
                            <span>{item}</span>
                          </div>
                        </td>
                        <td className="pl-4 py-3 dark:text-[#17F9DA] text-[#1e40af] text-xs font-[Barlow] font-normal border-l">
                          {Array.isArray(data?.fieldsUpdated[item])
                            ? data?.fieldsUpdated[item].join(", ")
                            : typeof data?.fieldsUpdated[item] === "object"
                            ? objectToString(data?.fieldsUpdated[item]) || "--"
                            : data?.fieldsUpdated[item]}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderBulDeleteDetails = () => {
    return (
      <div className="mt-6 mb-10 px-5 md:px-7 h-fit relative">
        <div className="flex flex-col items-start py-3">
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48]">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">
              Bulk Delete Date Time:
            </span>{" "}
            {dataTime}
          </p>
          {/* <p className="font-[play] text-lg dark:text-white text-[#2b2e48]">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">
              Selection Criteria:{" "}
            </span>{" "}
            {filterDescription(data?.criteria)}
          </p> */}
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48]">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">
              Total QSOs Deleted:{" "}
            </span>{" "}
            {data?.totalQsos}
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48]">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">Delete Status: </span>{" "}
            {data?.status}
          </p>
        </div>

        <p className="font-[play] font-bold text-lg dark:text-white text-[#2b2e48] my-4">
          Selection Criteria
        </p>
        <div className="w-[90%] lg:w-[50%] overflow-y-hidden mb-4">
          <table className="bg-white w-full">
            <tbody>
              <tr className="bg-[#94a3b883] dark:bg-[#3C3C58] pb-2.5 border-0 border-b border-slate-300 border-solid">
                <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48]">
                  Field name
                </th>
                <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48] flex items-center">
                  Criteria
                </th>
              </tr>
              {Object.keys(data?.criteria).length > 0
                ? Object.keys(data?.criteria).map((item, index) => {
                    return (
                      <tr
                        key={item}
                        className={`${
                          index % 2 ? "dark:bg-[#151536] bg-white" : "dark:bg-[#1F1F3F] bg-white"
                        } border-0 border-b border-slate-300 border-solid cursor-pointer`}
                      >
                        <td className="pl-4 dark:text-white text-[#2b2e48]  text-xs font-[Barlow] font-normal py-3">
                          <div className="flex">
                            <span>{item}</span>
                          </div>
                        </td>
                        <td className="pl-4 py-3 text-[#17F9DA] text-xs font-[Barlow] font-normal border-l">
                          {Array.isArray(data?.criteria[item])
                            ? data?.criteria[item].join(", ")
                            : typeof data?.criteria[item] === "object"
                            ? objectToString(data?.criteria[item]) || "--"
                            : data?.criteria[item]}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const renderBulkExportDetails = () => {
    return (
      <div className="mt-6 mb-10 px-5 md:px-7 h-fit relative">
        <div className="flex flex-col items-start py-3">
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">Export Date Time:</span>{" "}
            {dataTime}
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">File Name: </span>{" "}
            {data?.fileName}
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">
              Total QSOs Exported:{" "}
            </span>{" "}
            {data?.totalQsos}
          </p>
          <p className="font-[play] text-lg dark:text-white text-[#2b2e48] pl-4">
            <span className="dark:text-[#17F9DA] text-[#1e40af] font-bold">Export Status: </span>{" "}
            {data?.status}
          </p>
        </div>
        <p className="font-[play] font-bold text-lg dark:text-white text-[#2b2e48] my-4">
          Selection Criteria
        </p>
        <div className="w-[90%] lg:w-[50%] overflow-y-hidden mb-4">
          <table className="bg-white w-full">
            <tbody>
              <tr className="bg-[#94a3b883] dark:bg-[#3C3C58] pb-2.5 border-0 border-b border-slate-300 border-solid">
                <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48]">
                  Field name
                </th>
                <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48] flex items-center">
                  Criteria
                </th>
              </tr>
              {Object.keys(data?.criteria).length > 0
                ? Object.keys(data?.criteria).map((item, index) => {
                    return (
                      <tr
                        key={item}
                        className={`${
                          index % 2 ? "dark:bg-[#151536] bg-white" : "dark:bg-[#1F1F3F] bg-white"
                        } border-0 border-b border-slate-300 border-solid cursor-pointer`}
                      >
                        <td className="pl-4 dark:text-white text-[#2b2e48]  text-xs font-[Barlow] font-normal py-3">
                          <div className="flex">
                            <span>{item}</span>
                          </div>
                        </td>
                        <td className="pl-4 py-3 dark:text-[#17F9DA] text-[#1e40af] text-xs font-[Barlow] font-normal border-l">
                          {Array.isArray(data?.criteria[item])
                            ? data?.criteria[item].join(", ")
                            : typeof data?.criteria[item] === "object"
                            ? objectToString(data?.criteria[item]) || "--"
                            : data?.criteria[item]}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
        <div className="flex flex-col sm:flex-row justify-center items-center gap-10 mt-14">
          <div className="w-[80%] md:w-[30%]">
            <BorderButtonSecondary
              text={`Download Export .ADIF`}
              onClick={() => {
                const fileUrl = data?.fileUrl; // Replace with your file URL
                const link = document.createElement("a");
                link.href = fileUrl;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link); // Clean up
              }}
              className="sm:mb-0"
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <main className="w-full dashboardWrapper flex flex-col items-center relative">
        <Header title="Import Summary" />
        <div className="w-full md:px-20 px-2 mt-10">
          <NavigateBackButton />
          <div className="w-full flex flex-col dark:bg-[#151541] bg-white py-[25px] px-[25px]">
            {!isLoading ? (
              <div className="px-3 md:px-7 flex items-center justify-between border-b border-slate-400 border-solid pb-4">
                <div className="flex flex-col sm:flex-row gap-6 items-center">
                  {/* <div
                  onClick={() => {
                    globalNavigate("/log-contacts?tab=Activity+Log");
                  }}
                >
                  <img src={Icons.BackIcon} alt="back button" />
                </div> */}
                  <p className="font-[play] font-bold text-lg dark:text-white text-[#2b2e48] uppercase">
                    {UserActivityTypeDisplayName?.[data?.operationType]} Details
                  </p>
                </div>
              </div>
            ) : null}

            {isLoading ? (
              <ActivityIndicator size={36} />
            ) : (
              <>
                {data?.operationType === "importAdiFile" &&
                  renderImportDetails()}
                {data?.operationType === "mergeLogbook" && renderMergeDetails()}
                {data?.operationType === "exportLogbook" &&
                  renderExportDetails()}
                {data?.operationType === "bulkEdit" && renderBulEditDetails()}
                {data?.operationType === "bulkDelete" &&
                  renderBulDeleteDetails()}
                {data?.operationType === "exportContacts" ||
                data?.operationType === "exportAllContacts"
                  ? renderBulkExportDetails()
                  : null}
              </>
            )}
          </div>

          {data?.errorMessage?.length > 0 ? (
            <div className="w-full flex flex-col dark:bg-[#151541] bg-white py-[25px] px-[25px] mt-10">
              <div className="px-3 md:px-7 flex items-center justify-between border-solid pb-4">
                <div className="flex flex-col sm:flex-row gap-6 items-center">
                  <p className="font-[play] font-bold text-lg dark:text-white text-[#2b2e48] uppercase">
                    Error Logs
                  </p>
                </div>
              </div>
              <div className="dark:text-white text-[#2b2e48] px-5 md:px-7">
                {data?.errorMessage}
              </div>
            </div>
          ) : null}
        </div>
      </main>
    </>
  );
}

export default ActivityDetails;
