import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider as ReduxProvider } from "react-redux";
import { persistor, store } from "store/index";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import rootSaga from "store/Sagas";
import { GlobalHistory } from "routes/GlobalRoutes";
import FullStory from "react-fullstory";
import TagManager from "react-gtm-module";
import { ErrorBoundary } from "react-error-boundary";
import PageError from "components/PageError";
import ThemeProvider from "contexts/theme.context";
import { logError, auth } from "firebase-config";
import * as Sentry from "@sentry/react";

const ORG_ID = "R3QT9";

const tagManagerArgs = {
  gtmId: "GTM-P4V4NKQQ",
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
store.runSaga(rootSaga);
if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://012fb29e1df403470eb52ec3a97cf35a@o4508675740925952.ingest.us.sentry.io/4508699914272768",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
  });
}

root.render(
  <ErrorBoundary fallback={<PageError />}>
    <React.StrictMode>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider>
            <BrowserRouter>
              <FullStory org={ORG_ID} />
              <GlobalHistory />
              <App />
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </ReduxProvider>
    </React.StrictMode>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
