import React, { useState, useEffect } from "react";
import Header from "components/Header";
import TabNavGlow from "components/TabNavGlow";
import { useSearchParams } from "react-router-dom";
import { stationTabs } from "constants/NavMenus";
import MyRadios from "components/MyRadios";
import MyAntennas from "components/MyAntennas";
import StationPhotos from "components/StationPhotos";
import { useDispatch } from "react-redux";
import {
  fetchAntennas,
  fetchRadios,
  fetchStations,
} from "store/Actions/logBook.actions";
import { NavigateBackButton } from "./NavigateBackButton";
import { useHardwareAnalytics } from "hooks/useHardwareAnalytics";
import { auth } from "firebase-config";
import useTheme from "hooks/useTheme.hook";

const MyStation = () => {
  const dispatch = useDispatch();
  const { theme } = useTheme();
  const [selectedIndex, setSelectedIndex] = useState<string | null>("");
  let [searchParams, setSearchParams] = useSearchParams();
  const { getHardwareAnalytics, hardwareAnalytics, loading } = useHardwareAnalytics();
  const userId = auth.currentUser?.uid;

  useEffect(() => {
    if (!searchParams.get("tab")) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("tab", "My Radios");
      setSearchParams(newSearchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchRadios());
    dispatch(fetchAntennas());
    dispatch(fetchStations());
  }, []);

  useEffect(() => {
    if (userId) {
      getHardwareAnalytics(userId);
    }
  }, [userId]);

  useEffect(() => {
    setSelectedIndex(
      searchParams.get("tab") ? searchParams.get("tab") : "My Radios"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const getLayout = () => {
    switch (selectedIndex) {
      case "My Radios":
        return <MyRadios analytics={ hardwareAnalytics?.radios } loading={loading} />;
      case "My Antennas":
        return <MyAntennas analytics={ hardwareAnalytics?.antennas } loading={loading} />;
      case "Station Photos":
        return <StationPhotos />;
      default:
        return <MyRadios analytics={ hardwareAnalytics?.radios } loading={loading} />;
    }
  };

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full">
      <Header title="Logbook" />
      <div className="w-full flex justify-center p-3.5 md:p-7 dashboardWrapper overflow-x-hidden">
        <div className="h-full w-full overflow-x-hidden">
          <NavigateBackButton />
          <div className={`${theme === 'dark' ? 'glassmorphism': 'bg-white' } w-full h-fit pb-6`}>
            <div className="px-3 md:px-7 py-5 flex items-center justify-between border-b border-slate-400 border-solid">
              <p className="font-[play] font-bold text-lg dark:text-white text-[#2b2e48]">
                MY STATION
              </p>
            </div>
            <div className="mt-6 mb-5 h-fit overflow-x-scroll">
              <div className="w-full flex justify-start -mt-6">
                <TabNavGlow
                  selectedIndex={selectedIndex ? selectedIndex : ""}
                  setSelectedIndex={(index) => {
                    const newSearchParams = new URLSearchParams(searchParams);
                    newSearchParams.set("tab", index);
                    setSearchParams(newSearchParams);
                  }}
                  tabs={stationTabs}
                />
              </div>
            </div>
            {getLayout()}
          </div>
        </div>
      </div>
    </main>
  );
};

export default MyStation;
