import Icons from "assets/Icons";
import Images from "assets/Images";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import { useSupport } from "hooks/useBugReport.hook";
import useTheme from "hooks/useTheme.hook";
import { FC, useState } from "react";

export const ReportBug: FC<any> = ({ onClose }) => {
  const { isLoading, reportBugAnonymously } = useSupport();
  const { theme } = useTheme();

  const [formValues, setFormValues] = useState({
    details: "",
    email: "",
  });

  const handleSubmit = async () => {
    reportBugAnonymously(formValues, () => {
      setFormValues({
        details: "",
        email: "",
      });
      onClose();
    });
  };

  return (
    <section className="relative w-full h-full flex justify-center items-center dark:bg-[#000030] bg-[#f1f3f4]">
      <img
        src={theme === "dark" ? Images.WorldBg : Images.SpaceBg}
        className="absolute top-0 left-0 z-0 h-full w-full object-cover"
        alt="background"
      />
      <div className="relative flex-1 flex items-center justify-center">
        <div
          className={`${
            theme === "dark" ? "glassmorphism" : "bg-white"
          } w-[80%] sm:w-2/3 md:w-4/6 lg:w-4/6 xl:w-4/6 2xl:w-3/6 py-20 px-6 sm:px-6 md:px-12 lg:px-14 xl:px-20 flex flex-col items-center gap-4`}
        >
          <TitledInputDefault
            setText={(e) => {
              setFormValues((prev: any) => {
                return {
                  ...prev,
                  email: e.target.value,
                };
              });
            }}
            // title="Email: "
            name="email"
            type="email"
            placeHolder="Enter your email *"
            value={formValues?.email}
            width="w-full"
            className="bg-white bg-opacity-10"
            tabindex={1}
          />
          <TitledInputDefault
            setText={(e) => {
              setFormValues((prev: any) => {
                return {
                  ...prev,
                  details: e.target.value,
                };
              });
            }}
            title="Please provide as much detail as possible: "
            name="details"
            placeHolder="details about the bug..."
            value={formValues?.details}
            width="w-full"
            className="bg-white bg-opacity-10 h-[200px]"
            tabindex={2}
            multiple
          />

          <div className="flex w-[60%]">
            <BorderButtonSecondary
              isLoading={isLoading}
              text="Submit Bug Report"
              onClick={handleSubmit}
              childClassName="text-sm"
              tabIndex={2}
            />
          </div>

          <div
            className="absolute top-2 right-2 cursor-pointer"
            onClick={onClose}
            tabIndex={55}
          >
            <img
              src={Icons.GlowingClose}
              className="h-8 w-8 bg-gray-300 rounded-full dark:bg-transparent"
              alt="close"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
