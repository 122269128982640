import {
  SavedLocationTableDropDowns,
} from "constants/ArrayVariables";
import { truncateString } from "helpers/Utils";
import { useState } from "react";
import { SaveLocationTableColProps } from "types/Component";
import SavedLocationTableDropDown from "./SavedLocationTableDropDown";
import { globalNavigate } from "routes/GlobalRoutes";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { useSelector } from "react-redux";

function SaveLocationTableCol({ item, index }: SaveLocationTableColProps) {
  const [dropdownOpen, setDropDownOpen] = useState(false);
  const userProfile = useSelector(getUserProfileSelector);

  const renderLocationName = () => {
    if (!userProfile?.savedLocationId) return null;
    if (userProfile?.savedLocationId !== item?.id) return null;

    return (
      <div className="px-2 py-[1px] bg-[#0243DD] text-white text-xs ml-5 ">
        {truncateString("Home", 20)}
      </div>
    );
  };

  return (
    <tr
      key={index}
      className={`${
        index % 2 ? "dark:bg-[#151536] bg-white" : "dark:bg-[#1F1F3F] bg-[#fafafa]"
      } border-0 border-b border-slate-300 border-solid cursor-pointer dark:text-white text-[#2b2e48]`}

      onClick={() => {
        globalNavigate('/log-contacts/add-new-location', {
          state: { data: item, edit: true },
        });
      }}
    >
      <td
        className="pl-4 dark:text-[#17F9DA] text-[#1e40af] text-xs font-[Barlow] font-normal py-3"
        onClick={() => {}}
      >
        <div className="flex">
          {item.name}
          {renderLocationName()}
        </div>
      </td>
      <td
        className="pl-4 text-xs font-[Barlow] font-normal py-3"
        onClick={() => {}}
      >
        {item.state}
      </td>
      <td
        className="pl-4 text-xs font-[Barlow] font-normal py-3"
        onClick={() => {}}
      >
        {item.country}
      </td>
      <td
        className="pl-4 text-xs font-[Barlow] font-normal py-3"
        onClick={() => {}}
      >
        {item.lat}, {item.lng}
      </td>
      <td
        className="pl-4 text-xs font-[Barlow] font-normal py-3"
        onClick={() => {}}
      >
        {item.gridsquare}
      </td>
      <td
        className="pl-4 text-xs font-[Barlow] font-normal py-3"
        onClick={() => {}}
      >
        {item.qth || "--"}
      </td>
      <td
        className="text-xs text-white font-[Barlow] font-bold text-center py-3 w-16"
        onClick={(e) => e.stopPropagation()}
        onMouseEnter={() => {
          setDropDownOpen(true);
        }}
        onMouseLeave={() => {
          setDropDownOpen(false);
        }}
      >
        ...
        {dropdownOpen ? (
          <SavedLocationTableDropDown
            listItems={SavedLocationTableDropDowns}
            itemData={item}
            onClick={() => {}}
          />
        ) : null}
      </td>
    </tr>
  );
}

export default SaveLocationTableCol;
