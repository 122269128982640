import React, { useEffect, useRef, useState } from 'react';

type DropdownOption = {
  label: string;
  icon: JSX.Element;
  action: () => void;
};

type DropdownProps = {
  buttonLabel: string;
  buttonIcon: JSX.Element;
  dropdownOptions: DropdownOption[];
  onClick: (option: DropdownOption) => void;
};

const DropdownButton: React.FC<DropdownProps> = ({
  buttonLabel,
  buttonIcon,
  dropdownOptions,
  onClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement>(null); // Reference to the dropdown

  // Close the dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);


  const handleOptionClick = (option: DropdownOption) => {
    onClick(option); // Pass the clicked option to the parent component
    setIsOpen(false); // Close the dropdown after selection
  };

  return (
    <div ref={dropdownRef} className="relative inline-block text-left">
      <button
        className="inline-flex items-center px-4 py-2 dark:bg-[#17F9DA] bg-[#1e40af] dark:text-[#010A41] text-white rounded-md"
        onClick={() => setIsOpen(!isOpen)}
      >
        {/* Left icon and label */}
        <span className="flex items-center">
          {buttonIcon}
          <span className="ml-2 font-Play">{buttonLabel}</span>
        </span>

        {/* Right arrow icon */}
        <span
          className={`ml-2 transition-transform ${
            isOpen ? 'rotate-180' : ''
          }`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
            className="w-6 h-6"
          >
            <polygon points="6,9 18,9 12,15" />
          </svg>
        </span>
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="absolute right-0 mt-2 w-full text-xs dark:bg-[#1e1e45f1] bg-white rounded-sm shadow-lg">
          <ul className="py-1">
            {dropdownOptions.map((option, index) => (
              <li key={index} className='border dark:border-[#ffffff]/50 border-gray-200 hover:bg-gray-100 dark:hover:bg-[#1e1e45]'>
                <button
                  className="flex items-center w-full px-4 py-2 dark:text-white text-[#2b2e48] "
                  onClick={() => handleOptionClick(option)}
                >
                  <span className="mr-2">{option.icon}</span>
                  {option.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownButton;
