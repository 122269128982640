/* eslint-disable array-callback-return */
import {
  Awards,
  ContestTable,
  EnrolledUsers,
  LogBookContactTable,
  LogBookTable,
  UserDataTable,
} from "constants/Collections";
import { db } from "firebase-config";
import {
  DocumentData,
  addDoc,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
  deleteDoc,
  Timestamp,
} from "firebase/firestore";
import { extractDigit, extractMode, validateClassValue, validateSectionValue } from "helpers/Utils";
import { CONTEST_STATUS } from "types/Functions";
import {
  ContestLeadrBoard,
  ContestModel,
  ContestantDetail,
  ContestantUserDetail,
  EnrollSuccessData,
  LogBookContactModel,
  LogBookModel,
  UserAwards,
  UserProfile,
} from "types/Models";
var uniq = require("lodash.uniq");
interface ContestServiceType {
  storeContest(data: any): Promise<any>;
  fetchActiveContest(): Promise<ContestModel[] | null>;
  fetchUpcomingContest(): Promise<ContestModel[] | null>;
  fetchPassedContest(): Promise<ContestModel[] | null>;
  fetchContestDetail(id: string): Promise<ContestModel | null>;
  editContest(data: ContestModel, docId: string): Promise<boolean>;
  enrollContest(
    contest: ContestModel,
    user: UserProfile,
    locationDetails?: any
  ): Promise<EnrollSuccessData | null>;
  fetchContestantDetail(
    contestId: string,
    userId: string
  ): Promise<ContestantDetail | null>;
  fetchUserContestRanking(data: any): Promise<number | null>;
  fetchTopBandByHour(contestId: string): Promise<string | null>;
  // fetchContestLeader(contestIds: string[]): Promise<ContestLeader | null>;
  deleteContestById(id: string): Promise<boolean>;
  fetchContestLeaderBoard(
    payload: {contestId: string, filters?: Array<any>, contactCountFieldName?: string}
  ): Promise<ContestLeadrBoard[] | null>;
  fetchContestContacts(
    contestId: string
  ): Promise<LogBookContactModel[] | null>;
  fetchContestantUserDetail(data: any): Promise<ContestantUserDetail | null>;
  fetchAwardContest(): Promise<UserAwards[] | null>;
  fetchAward(): Promise<UserAwards[] | null>;
  updateContest(data: ContestModel): Promise<boolean>;
}

const ContestService: ContestServiceType = {
  storeContest: async (data: any) => {
    try {
      const contactRef = collection(db, ContestTable);
      await addDoc(contactRef, data);
    } catch (e) {
      return null;
    }
  },
  editContest: async (data, docId) => {
    try {
      if (docId) {
        const contactRef = doc(db, ContestTable, docId);
        await setDoc(contactRef, data, { merge: true });
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  },
  fetchActiveContest: async () => {
    try {
      const contestData: ContestModel[] = [];
      const currentDate = Timestamp.fromDate(new Date());

      // Query Firestore to retrieve active contest
      const contestRef = collection(db, ContestTable);
      const q = query(
        contestRef,
        where("startDate", "<=", currentDate),
        orderBy("startDate", "desc")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        const endDate = new Date(data.endDate.toDate());
        const currentDate = new Date();
        if (endDate > currentDate) {
          const eventData = { id: doc.id, ...data };
          contestData.push(eventData as ContestModel);
        }
      });
      return contestData;
    } catch (e) {
      return null;
    }
  },
  fetchUpcomingContest: async () => {
    try {
      const contestData: ContestModel[] = [];
      const currentDate = Timestamp.fromDate(new Date());

      // Query Firestore to retrieve upcoming contest
      const contestRef = collection(db, ContestTable);
      const q = query(contestRef, where("startDate", ">=", currentDate));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const eventData = { id: doc.id, ...doc.data() };
        contestData.push(eventData as ContestModel);
      });
      return contestData;
    } catch (e) {
      return null;
    }
  },
  fetchPassedContest: async () => {
    try {
      const contestData: ContestModel[] = [];
      const currentDate = Timestamp.fromDate(new Date());

      // Query Firestore to retrieve passed contest
      const contestRef = collection(db, ContestTable);
      const q = query(
        contestRef,
        where("endDate", "<", currentDate),
        orderBy("endDate", "desc")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        const eventData = { id: doc.id, ...doc.data() };
        contestData.push(eventData as ContestModel);
      });
      return contestData;
    } catch (e) {
      return null;
    }
  },
  fetchContestDetail: async (contestId) => {
    const contestRef = doc(db, ContestTable, contestId);
    const contestData: DocumentData = await getDoc(contestRef);
    if (contestData.exists()) {
      return {
        ...contestData.data(),
        id: contestId,
      } as ContestModel;
    } else {
      return null;
    }
  },
  enrollContest: async (contest, user, locationDetails) => {
    try {
      const logbookData = {
        name: `${user.callSign || user.firstName} - ${contest.name} Log`,
        contestId: contest.id,
        uid: user.uid,
        timestamp: serverTimestamp(),
        defaultCallSign: user.callSign || "",
        ...(locationDetails ? { defaultLocation: locationDetails } : {}),
        contest: {
          name: contest.name,
          image: contest.image,
          startDate: contest.startDate,
          endDate: contest.endDate,
          shortDescription: contest.shortDescription || "",
        },
        ...contest?.isWFDContest ? { logbookStyle: 'WFD', isWFDLogBook: true, contestTotalPoints: 0 }: {}
      };
      const logBookRef = doc(collection(db, LogBookTable));
      await setDoc(logBookRef, logbookData);

      const contestRef = doc(db, ContestTable, `${contest.id}`);
      const enrolledUsersCollectionRef = collection(contestRef, EnrolledUsers);

      const contestEnrollRef = doc(enrolledUsersCollectionRef, `${user.uid}`);

      const enrollData = {
        ...user,
        logBookId: logBookRef.id,
        logbookName: logbookData.name,
        enrolledTimestamp: serverTimestamp(),
      };
      await setDoc(contestEnrollRef, enrollData);

      let contestStatus;
      const now = new Date();
      const startDate = new Date(contest?.startDate.seconds * 1000);
      const endDate = new Date(contest?.endDate.seconds * 1000);
      if (
        now.getTime() > startDate.getTime() &&
        now.getTime() < endDate.getTime()
      ) {
        contestStatus = CONTEST_STATUS.ACTIVE;
      } else if (now.getTime() < startDate.getTime()) {
        contestStatus = CONTEST_STATUS.UPCOMING;
      } else {
        contestStatus = CONTEST_STATUS.PASSED;
      }
      const enrollSuccess: EnrollSuccessData = {
        contestId: contest.id!,
        contestName: contest.name,
        logbookName: logbookData.name,
        logbookId: logBookRef.id,
        contestStatus: contestStatus,
        startDate: contest.startDate,
        endDate: contest.endDate,
      };
      const contestDocRef = doc(db, ContestTable, `${contest.id}`);
      await setDoc(
        contestDocRef,
        {
          contestantCount: increment(1),
        },
        { merge: true }
      );

      let userContests = user.contests || [];
      userContests.push(contest.id!);
      userContests = uniq(userContests);

      const userDocRef = doc(db, UserDataTable, `${user.uid}`);
      await setDoc(
        userDocRef,
        {
          contests: userContests,
        },
        { merge: true }
      );

      return enrollSuccess;
    } catch (e) {
      return null;
    }
  },
  fetchContestantDetail: async (contestId, userId) => {
    try {
      const contestantsRef = query(
        collection(db, ContestTable, contestId, EnrolledUsers),
        orderBy("enrolledTimestamp", "desc"),
        limit(50)
      );
      const userContestRef = doc(
        db,
        ContestTable,
        contestId,
        EnrolledUsers,
        `${userId || "GUEST"}`
      );
      const contestants = await getDocs(contestantsRef);
      const contestantsData: UserProfile[] = [];
      contestants.forEach((doc) => {
        const data = doc.data();
        const userData = {
          ...data,
          id: doc.id,
        } as UserProfile;
        contestantsData.push(userData);
      });

      const userContestData: DocumentData = await getDoc(userContestRef);

      const countQuery = query(
        collection(db, ContestTable, contestId, EnrolledUsers)
      );

      const qsoCountQuery = query(
        collection(db, LogBookContactTable),
        where("contestId", "==", contestId),
      );

      const contestantCount = await getCountFromServer(countQuery);
      const qsoCount = await getCountFromServer(qsoCountQuery);

      const contestantDetail: ContestantDetail = {
        isUserEnrolled: userContestData.exists(),
        contestantCount: contestantCount.data().count,
        contestants: contestantsData,
        enrolledUser: userContestData.data(),
        qsos: qsoCount.data().count,
      };

      return contestantDetail;
    } catch (e) {
      return null;
    }
  },
  fetchUserContestRanking: async (data) => {
    try {
      const { contestId, contactCount, contactCountFieldName } = data;
      const contestContactRef = query(
        collection(db, LogBookTable),
        where("contestId", "==", contestId),
        where(contactCountFieldName, ">", contactCount)
      );
      const contactNumber = await getCountFromServer(contestContactRef);
      return contactNumber.data().count + 1;
    } catch (e) {
      return null;
    }
  },
  fetchTopBandByHour: async (contestId) => {
    try {
      // Calculate the UTC timestamp for one hour ago
      const now = new Date();
      const oneHourAgo = new Date(
        Date.UTC(
          now.getUTCFullYear(),
          now.getUTCMonth(),
          now.getUTCDate(),
          now.getUTCHours() - 1,
          now.getUTCMinutes(),
          now.getUTCSeconds(),
          now.getUTCMilliseconds()
        )
      );
  
      // Query to fetch contacts in the last hour
      const contestContactRef = query(
        collection(db, LogBookContactTable),
        where("contestId", "==", contestId),
        where("contactTimeStamp", ">", oneHourAgo) // Ensure "timestamp" is in UTC in Firestore
      );
  
      // Fetch contacts
      const snapshot = await getDocs(contestContactRef);
  
      // Track the frequency of each band
      const bandCounts: Record<string, number> = {};
      snapshot.forEach((doc) => {
        const band = doc.data().band ?  extractMode(doc.data().band) : doc.data().band;
        if (band) {
          bandCounts[band] = (bandCounts[band] || 0) + 1;
        }
      });
  
      // Determine the top band
      let topBand = null;
      let maxCount = 0;
      for (const [band, count] of Object.entries(bandCounts)) {
        if (count > maxCount) {
          topBand = band;
          maxCount = count;
        }
      }

      return topBand;
    } catch (e) {
      return null;
    }
  },
  
  deleteContestById: async (id) => {
    try {
      const contestRef = doc(db, ContestTable, id);
      await deleteDoc(contestRef);
      return true;
    } catch (e) {
      return false;
    }
  },
  fetchContestLeaderBoard: async (payload) => {
    try {

      const contestId = payload?.contestId;
      const contestDetails = await ContestService.fetchContestDetail(contestId);

      const filters = payload?.filters || [];
      const contactCountFieldName = contestDetails?.isWFDContest ? "contestTotalPoints" : "contactCount";

      let contestContactRef = query(
        collection(db, LogBookTable),
        where("contestId", "==", payload?.contestId),
        orderBy(contactCountFieldName, "desc"),
        limit(20)
      );
      
      // Add dynamic where clauses based on filters
      if (filters && filters.length > 0) {
        filters.forEach((filter) => {
          contestContactRef = query(contestContactRef, where(filter.field, filter.operator, filter.value));
        });
      }
      const userIds:any[] = [];
      
      const contestLogbook = await getDocs(contestContactRef);
      const contestLogbookData: LogBookModel[] = contestLogbook.docs.map(
        (doc) => {
          if (doc.data().uid){
            userIds.push(doc.data().uid);
          }
          return { id: doc.id, ...doc.data() };
        }
      );

      const usersInContest = query(
        collection(db, UserDataTable),
        where("uid", "in", userIds)
      );
      const usersInContestData = await getDocs(usersInContest);
      
      const usersInContestDataMap: UserProfile[] = usersInContestData.docs.map(
        (doc) => {
          const { 
            callsignSearchIndex,
            nameSearchIndex,
            modes,
            bands,
            timestamp,
            longBio, 
            ...rest 
          } = doc.data();
          
          return { ...rest };
        }
      );


      const result: ContestLeadrBoard[] = [];

      contestLogbookData.forEach((logbook) => {
        const user = usersInContestDataMap.find(
          (user) => user.uid === logbook.uid
        );
        if (user) {

          let contestContactCount = 0;
          let contestTotalPoints = 0;
          let contestMultiplier = 0;
  
          if (
            logbook?.contestObjectives &&
            logbook?.contestObjectives?.length > 0 &&
            contestDetails?.objectives?.length &&
            logbook?.contactCount
          ) {
            // const getSelectedTotalPoints = logbook?.contestObjectives?.reduce(
            //   (acc, optionId) => {
            //     const option = contestDetails?.objectives?.find(
            //       (o) => o.id === optionId
            //     );
            //     return acc + (option ? option.point : 0);
            //   },
            //   0
            // );
  
            const getCompletionPercentage =
              (logbook?.contestObjectives?.length /
                contestDetails?.objectives?.length) *
              100;
  
            contestMultiplier = getCompletionPercentage;
          }

          contestContactCount = logbook?.contactCount || 0;
          contestTotalPoints = logbook?.contestTotalPoints || 0;

          result.push({
            ...user,
            numberOfPoints: logbook.contactCount || 0,
            contestContactCount,
            contestTotalPoints,
            contestMultiplier,
            contestClass: logbook.contestClass || "",
            defaultCallSign: logbook.defaultCallSign || "",
          });
        }
      });

      return result;
    } catch (e) {         
      return null;
    }
  },
  fetchContestContacts: async (contestId) => {
    try {
      const oneHourAgo = Timestamp.now().toMillis() - 60 * 60 * 1000;
      const contestContactRef = query(
        collection(db, LogBookContactTable),
        where("contestId", "==", contestId),
        where("contactTimeStamp", ">=", Timestamp.fromMillis(oneHourAgo)),
        orderBy("contactTimeStamp", "desc"),
      );
      const contestContacts = await getDocs(contestContactRef);
      const contestContactsData: LogBookContactModel[] =
        contestContacts.docs.map((doc) => {
          const { myNameSearchIndex,nameSearchIndex,callSignSearchIndex, logBook, ...rest } = doc.data();
          return { id: doc.id, ...rest };
        });

      return contestContactsData;
    } catch (e) {
      return null;
    }
  },
  fetchContestantUserDetail: async (data) => {
    try {
      const { contestId, userId } = data;

      // Parallel fetch for contestContacts, contestCount, and userProfile
      const [contestContactsRes, contestCountRes, userProfileRes, contestDetails, contestLogBooks] =
        await Promise.all([
          getDocs(
            query(
              collection(db, LogBookContactTable),
              where("contestId", "==", contestId),
              where("uid", "==", userId),
              orderBy("contactTimeStamp", "desc")
            )
          ),
          getCountFromServer(
            query(
              collection(db, LogBookContactTable),
              where("contestId", "==", contestId),
              where("uid", "==", userId)
            )
          ),
          getDoc(doc(db, UserDataTable, userId)),
          getDoc(doc(db, ContestTable, contestId)),
          getDocs(
            query(
              collection(db, LogBookTable),
              where("contestId", "==", contestId),
              where("uid", "==", userId),
            )
          ),
        ]);

      const bands: any = {};
      const modes: any = {};
      const modePoints: any = {};
      const contactByHour: any = {};

      const contestStartDate = contestDetails?.data()?.startDate as Timestamp;
      const contestEndDate = contestDetails?.data()?.endDate as Timestamp;

      const dupContacts = new Set<string>();

      const contestContactsData = contestContactsRes.docs.map((doc) => {
        const contact = doc.data();

        const contactTimestamp = contact?.contactTimeStamp as Timestamp;

        // Check if the contactTimestamp falls within the range
        const isDateTimeValid =
          contactTimestamp &&
          contestStartDate &&
          contestEndDate &&
          contactTimestamp.toMillis() >= contestStartDate.toMillis() &&
          contactTimestamp.toMillis() <= contestEndDate.toMillis();

        const isExchangeValid = contact?.exchangeOne && contact?.exchangeTwo;
        const isExchangeValueValid = isExchangeValid && validateClassValue(contact?.exchangeOne) && validateSectionValue(contact?.exchangeTwo);

        let eligibilityMessage = ''
        
        if (!isDateTimeValid) {
          eligibilityMessage += 'Contact is not within contest time frame';
        }
        
        if (!isExchangeValid) {
          if (eligibilityMessage) {
            eligibilityMessage += ' & ';
          }

          eligibilityMessage += 'Contact is missing exchange information';
        } else if (!isExchangeValueValid) {
          if (eligibilityMessage) {
            eligibilityMessage += ' & ';
          }

          eligibilityMessage += 'Invalid exchange information';
        }

        const isDuplicate = contact?.duplicate || false;
        let shouldSkip = false;
        if (isDuplicate) {
          if (isDateTimeValid && isExchangeValid && isExchangeValueValid){
            const key = `${contact?.myCallSign}-${contact?.theirCallsign}-${contact?.userMode}-${contact?.logBookId}-${contact?.uid}`;

            if (dupContacts.has(key)) {
              shouldSkip = true;

              if (eligibilityMessage) {
                eligibilityMessage += ' & ';
              }
    
              eligibilityMessage += 'Contact is a duplicate, Only single contact will be counted';
          
            } else {
              dupContacts.add(key);
              shouldSkip = false;
            }
          }
        }
      
        const isEligible = isDateTimeValid && isExchangeValid && isExchangeValueValid && !shouldSkip;

        // Handle both Timestamp and string formats for contactTimeStamp
        let contactDate: Date | null;

        if (contact.contactTimeStamp?.seconds) {
          contactDate = new Date(contact.contactTimeStamp.seconds * 1000); // Convert seconds to milliseconds
        } else if (typeof contact.contactTimeStamp === 'string') {
          contactDate = new Date(contact.contactTimeStamp);
        } else {
          contactDate = null;
        }

        if (contactDate){
          // Format the date (e.g., "2024-12-31") to track by day
          const formattedDate = contactDate.toISOString().split('T')[0]; // Get the date in "YYYY-MM-DD" format

          // Get the UTC hour
          const utcHour = contactDate.getUTCHours();

          // Initialize the day in contactByDay if it doesn't exist yet
          if (!contactByHour[formattedDate]) {
            contactByHour[formattedDate] = {};
          }

          // Increment the contact count for the specific hour on the correct date
          if (!contactByHour[formattedDate][utcHour]) {
            contactByHour[formattedDate][utcHour] = 0;
          }

          contactByHour[formattedDate][utcHour]++;
        }

        const band = extractMode(contact.band);

        if (band) {
          if (bands[band]) {
            bands[band]++;
          } else {
            bands[band] = 1;
          }
        }

        const mode = contact.userMode;

        if (mode) {
          if (modes[mode.toLowerCase()]) {
            modes[mode.toLowerCase()]++;            
          } else {
            modes[mode.toLowerCase()] = 1;
          }

          if (isEligible) {
            if (!modePoints[mode.toLowerCase()]) {
              modePoints[mode.toLowerCase()] = contact?.contestPoints || 0;
            } else {
              modePoints[mode.toLowerCase()] += contact?.contestPoints || 0;
            }
          }
        }

        const { myNameSearchIndex,nameSearchIndex,callSignSearchIndex, ...rest } = contact;
        return ({
          id: doc.id,
          ...rest,
          isEligible,
          eligibilityMessage,
        })
      });

      const userProfileData = userProfileRes.data();
      const [first] = contestLogBooks?.docs;
      const logbook = first ? first?.data?.() : null;

      const contactCountFieldName = contestDetails?.data()?.isWFDContest ? "contestTotalPoints" : "contactCount";
      const contestCount = contestDetails?.data()?.isWFDContest ? logbook?.contestTotalPoints : contestCountRes.data().count;

      // Fetch userContestRank
      const userContestRankRes = await getCountFromServer(
        query(
          collection(db, LogBookTable),
          where("contestId", "==", contestId),
          where(contactCountFieldName, ">", contestCount)
        )
      );

      const userContestRank = userContestRankRes.data().count + 1;

      let contestContactCount = 0;
      let contestTotalPoints = 0;
      let contestMultiplier = 0;
      let contestObjectivesTotalPoints = 0;
      let contestUserObjectivesTotalPoints = 0;
      let contestTotalObjectives = 0;

      if (
        logbook &&
        logbook?.contestObjectives &&
        logbook?.contestObjectives?.length > 0 &&
        contestDetails?.data()?.objectives?.length
      ) {
        const getSelectedTotalPoints = logbook?.contestObjectives?.reduce(
          (acc: number, optionId: string) => {
            const option = contestDetails?.data()?.objectives?.find(
              (o: any) => o.id === optionId
            );
            return acc + (option ? option.point : 0);
          },
          0
        );

        const getCompletionPercentage =
          (logbook?.contestObjectives?.length /
            contestDetails?.data()?.objectives?.length) *
          100;

        contestTotalObjectives = logbook?.contestObjectives?.length;
        contestMultiplier = getCompletionPercentage;
        contestUserObjectivesTotalPoints = getSelectedTotalPoints;
        contestObjectivesTotalPoints = contestDetails?.data()?.objectives.reduce((acc: number, option: any) => acc + option.point, 0)
      }

      contestContactCount = logbook?.contactCount || 0;
      contestTotalPoints = (logbook?.contestTotalPoints || 0);

      // Construct and return the user detail
      return {
        user: userProfileData,
        userLogs: contestContactsData,
        totalQso: contestCountRes.data().count,
        contestRanking: userContestRank,
        contestContactCount,
        contestTotalPoints,
        contestMultiplier,
        contestObjectivesTotalPoints,
        contestUserObjectivesTotalPoints,
        options: contestDetails?.data()?.objectives,
        contestTotalObjectives,
        selectedOptions: logbook?.contestObjectives,
        userBands: bands,
        userModes: modes,
        userModePoints: modePoints,
        contactByHour
      } as ContestantUserDetail;
    } catch (e) {
      return null;
    }
  },
  fetchAwardContest: async () => {
    try {
      const contestAwardRef = query(
        collection(db, Awards),
        where("type", "==", "contest")
      );
      const contestAward = await getDocs(contestAwardRef);
      const contestAwardData: any[] = [];
      contestAward.docs.map((doc) => {
        contestAwardData.push({ id: doc.id, ...doc.data() });
      });
      return contestAwardData as UserAwards[];
    } catch (e) {
      return null;
    }
  },
  fetchAward: async () => {
    try {
      const contestAwardRef = query(collection(db, Awards));
      const contestAward = await getDocs(contestAwardRef);
      const contestAwardData: any[] = [];
      contestAward.docs.map((doc) => {
        contestAwardData.push({ id: doc.id, ...doc.data() });
      });
      return contestAwardData as UserAwards[];
    } catch (e) {
      return null;
    }
  },
  updateContest: async (data) => {
    try {
      if (data.id) {
        const contactRef = doc(db, ContestTable, data.id);
        await setDoc(
          contactRef,
          {
            ...data,
          },
          { merge: true }
        );
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  },
};

export default ContestService;
