import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import { LogbookTemplatesOptions } from "constants/Config";
import { useLogBookSettingsForm } from "hooks/useLogBookSettingsForm.hook";
import { FC } from "react";
import { globalNavigate } from "routes/GlobalRoutes";

export const BasicSettings: FC = () => {
  const { values, setFormValue } = useLogBookSettingsForm();
  return (
    <div className="flex flex-col dark:text-white text-[#2b2e48] gap-4">
      <div className="font-bold font-Play text-xl">Basic Settings</div>
      <div className="flex flex-wrap">
        <LogbookSettingDropdown
          list={LogbookTemplatesOptions}
          title="Default Template For New Logbook:"
          value={values?.logbookStyle || ""}
          setValue={(value) => {
            if (value === "view") {
              globalNavigate("/log-contacts/logbook-styles");
            } else {
              setFormValue("logbookStyle", value);
            }
          }}
          className="sm:w-[36%] md:w-[24%]"
        />
      </div>
    </div>
  );
};
