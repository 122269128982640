import React, { useEffect } from "react";

import InputWithButton from "components/Inputs/InputWithButton";
import { useDispatch } from "react-redux";
import {
  searchContacts,
  setSearchResults,
} from "store/Actions/logBook.actions";
import { useDebouncedCallback } from "use-debounce";
import { setCurrentSearchString } from "store/Actions/logBook.actions";

const AlgoliaSearch = (props: any) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.inputValue) {
      debouncedBoundChangeListener(props.inputValue);
    } else {
      dispatch(setSearchResults(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.inputValue]);

  const debouncedBoundChangeListener = useDebouncedCallback(
    (searchString) => {
      dispatch(setCurrentSearchString(searchString));
      dispatch(
        searchContacts({
          logbookId: props.logbookId,
          searchString: searchString,
          uid: props.userProfile.uid,
        })
      );
    },
    // delay in ms
    1000
  );

  return (
    <>
      {/* <Configure filters={searchString} /> */}
      <InputWithButton
        text={props.inputValue}
        value={props.inputValue}
        setText={(e) => props.setInputValue(e.target.value.toUpperCase())}
        placeHolder="Search by CallSign"
        width="w-full sm:w-[300px]"
        btnTxt="Clear"
        onBtnClick={() => {
          props.setInputValue("");
        }}
      />
    </>
  );
};

export default AlgoliaSearch;
