import React, { useState, useCallback, Fragment } from "react";
import {
  GoogleMap,
  useLoadScript,
  MarkerClusterer,
} from "@react-google-maps/api";
import { mapStylesDark, mapStylesLight } from "constants/MapStyles";
import RouteLoading from "components/Loading/RouteLoading";

import { useDispatch } from "react-redux";
import useMember from "hooks/member.hook";
import { getMapMembers } from "store/Actions/members.actions";
import MembershipMarker from "./MembershipMarker";
import { useDebouncedCallback } from "use-debounce";
import useTheme from "hooks/useTheme.hook";
const MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API;
const googleLibraries: any[] = ["geometry", "marker", "maps", "drawing"];

const MembershipMap = () => {
  const { userProfile, center, mapMembers } = useMember();
  const dispatch = useDispatch();
  const [map, setMap] = useState<google.maps.Map>();
  const onLoad = useCallback((map: google.maps.Map) => setMap(map), []);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: MAP_API_KEY!,
    libraries: googleLibraries,
  });

  const { theme } = useTheme();

  // console.log("mapMembers : ", mapMembers);

  const debouncedBoundChangeListener = useDebouncedCallback(
    () => {
      if (map) {
        const bounds = map.getBounds();
        const mapZoom = map.getZoom();
        if (bounds && mapZoom) {
          const neBound = bounds?.getNorthEast();
          const swBound = bounds?.getSouthWest();
          const northEastLat = neBound?.lat();
          const northEastLong = neBound?.lng();
          const southWestLat = swBound?.lat();
          const southWestLong = swBound?.lng();
          if (mapZoom < 15) {
            dispatch(
              getMapMembers({
                northEastLat,
                northEastLong,
                southWestLat,
                southWestLong,
                mapZoom,
              })
            );
          }
        }
      } else {
      }
    },
    // delay in ms
    1000
  );

  const options = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  };

  return (
    <div className="w-full h-[calc(100%-22px)]">
      {!isLoaded ? (
        <RouteLoading />
      ) : (
        <GoogleMap
          mapContainerClassName="map-container no-close-button-on-info-windows"
          center={center}
          onLoad={onLoad}
          onBoundsChanged={debouncedBoundChangeListener}
          zoom={userProfile?.coordinates ? 14 : 4}
          options={{
            styles: theme === "dark" ? mapStylesDark : mapStylesLight,
            // maxZoom: 16,
          }}
        >
          <MarkerClusterer options={options}>
            {(clusterer) => (
              <Fragment>
                {mapMembers.map((user, index) => (
                  <MembershipMarker
                    user={user}
                    index={index}
                    clusterer={clusterer}
                  />
                ))}
              </Fragment>
            )}
          </MarkerClusterer>
        </GoogleMap>
      )}
    </div>
  );
};

export default MembershipMap;
