import { useEditContact } from "hooks/useEditContact.hook";
import { useEffect } from "react";
import Icons from "assets/Icons";
import { EditContactForm } from "./EditContactForm";

export const EditContactModal = () => {
  const { setEditContactModalData, editContactModalData } = useEditContact();

  useEffect(() => {
    setEditContactModalData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!editContactModalData) return null;

  const allSelected = editContactModalData?.allSelected || false;
  const totalQSOs = editContactModalData?.totalQSOs || 0;

  const isBulkEdit = (editContactModalData as any)?.isBulkEdit || false;

  return (
    <main className="absolute w-full h-full z-50 glassmorphism">
      <div className="w-full h-full flex flex-col items-center p-4 md:p-20 overflow-x-hidden">
        <div className="bg-gradient-to-br from-fuchsia-500 via-violet-600 to-violet-900 p-[1px] inline-flex relative w-full shadow-lg">
          <div className="flex flex-col md:flex-row justify-between w-full dark:bg-[#0B0B38] bg-white p-6 text-white">
            <div className="flex flex-col w-full gap-6">
              {isBulkEdit ? (
                <>
                  <p className="font-[play] font-bold text-xl dark:text-white text-[#2b2e48] mb-[26px]">
                    You are editing{" "}
                    <span className="text-[#17F9DA]">
                      {allSelected
                        ? totalQSOs
                        : editContactModalData.selectedRows.length}
                    </span>{" "}
                    QSOs!
                    <span className="dark:text-white text-[#2b2e48] text-xs ml-2 italic font-normal">
                      {" "}
                      Only the fields that you change will be updated.
                    </span>
                  </p>
                </>
              ) : (
                <div className="font-bold font-Play text-xl dark:text-white text-[#2b2e48]">
                  Edit Contact
                </div>
              )}

              <div>
                <EditContactForm isBulkEdit={isBulkEdit} />
              </div>
            </div>

            <div
              className="absolute top-2 right-2 cursor-pointer"
              onClick={() => setEditContactModalData(null)}
              tabIndex={55}
            >
              <img
                src={Icons.GlowingClose}
                className="h-8 w-8 invert dark:filter-none dark:invert-0"
                alt="close"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
