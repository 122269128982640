import React, { useState } from "react";
import Icons from "assets/Icons";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { Award } from "types/Models";
import UploadService from "services/upload.service";
import { useAwards } from "hooks/useAwards.hook";

const AddAwardModal = () => {
  const [form, setForm] = useState<Award>({
    name: "",
    description: "",
    requirements: "",
    image: "",
    pdf: "",
  });
  const { setShowAwardModal, isLoading, addAward } = useAwards();
  const [loading, setLoading] = useState(false);

  const toast = (message: string) => {
    showToast({
      message: message,
      type: ToastTypes.WARN,
    });
  };

  const saveAward = async () => {
    try {
      setLoading(true);

      const imageUrl = form.image
        ? await UploadService.handleUploadAwardImage(form.image)
        : "";

      const pdfUrl = form.pdf
        ? await UploadService.handleUploadAwardImage(form.pdf)
        : "";

      if (imageUrl && pdfUrl) {
        const awardPayload = {
          ...form,
          image: imageUrl,
          pdf: pdfUrl,
        };

        addAward(awardPayload);
      }

      setLoading(false);
    } catch (error) {
      toast("Failed to save award");
      setLoading(false);
    }
  };

  return (
    <section className="absolute w-screen h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[25%] lg:px-[25%] overflow-scroll">
      <div className=" w-full">
        <div className="w-full">
          <div className="flex flex-col w-full bg-[#151541] px-6 py-5 text-white font-[Play] relative mt-10 md:mt-0 border border-[#451836]">
            <div
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer"
              onClick={() => {
                setShowAwardModal(false);
              }}
            >
              <img
                src={Icons.GlowingClose}
                className="h-8 w-8 bg-gray-300 rounded-full dark:bg-transparent"
                alt="close"
              />
            </div>
            <h5 className="font-[Play] font-bold text-white py-2 ml-3 w-full text-lg">
              Add Award
            </h5>
            <div className="px-1 md:px-14 mt-10 w-full">
              <TitledInputDefault
                setText={(e) => {
                  setForm((formValue) => ({
                    ...formValue,
                    name: e.target.value,
                  }));
                }}
                name="name"
                title="Name:"
                placeHolder="Enter award name"
                value={form.name}
                width={"w-[100%]"}
                className={"h-[36px] mt-[12px] mr-3"}
                tabindex={20}
                error={null}
              />

              <TitledInputDefault
                setText={(e) => {
                  setForm((formValue) => ({
                    ...formValue,
                    requirements: e.target.value,
                  }));
                }}
                name="requirements"
                title="Requirements:"
                placeHolder="Enter requirements"
                value={form.requirements}
                width={"w-[100%]"}
                className={"h-[36px] mt-[12px] mr-3"}
                tabindex={20}
                error={null}
              />
              <TitledInputDefault
                setText={(e) => {
                  setForm((formValue) => ({
                    ...formValue,
                    image: e.target.files?.[0],
                  }));
                }}
                name="name"
                title="Image:"
                placeHolder="Upload photo"
                // value={radioImage}
                type="file"
                width={"w-[100%]"}
                className={"h-[36px] mt-[12px] mr-3"}
                tabindex={20}
                error={null}
              />
              <TitledInputDefault
                setText={(e) => {
                  setForm((formValue) => ({
                    ...formValue,
                    pdf: e.target.files?.[0],
                  }));
                }}
                name="name"
                title="PDF:"
                placeHolder="Upload pdf"
                // value={radioImage}
                type="file"
                width={"w-[100%]"}
                className={"h-[36px] mt-[12px] mr-3"}
                tabindex={20}
                error={null}
              />
              <TitledInputDefault
                setText={(e) => {
                  setForm((formValue) => ({
                    ...formValue,
                    description: e.target.value,
                  }));
                }}
                name="name"
                title="Description:"
                placeHolder="Enter station name"
                value={form.description}
                multiple
                width={"w-[100%]"}
                className={"h-[100px] mt-[12px] mr-3 pt-2"}
                tabindex={20}
                error={null}
              />
            </div>
            <div className="flex w-full justify-center">
              <BorderButtonSecondary
                isLoading={loading || isLoading}
                text={"Save"}
                onClick={saveAward}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddAwardModal;
