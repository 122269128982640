import React, { FC, useEffect, useState } from "react";
import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { globalNavigate } from "routes/GlobalRoutes";
import { useEditContact } from "hooks/useEditContact.hook";
import { useFormikContext } from "formik";

type ModalProps = {
  onClose: () => void;
};

export const AntennaModal: FC<ModalProps> = ({ onClose }) => {
  const { savedAntennaOptions } = useLogbookSetting();
  const { editContactModalData } = useEditContact();
  const { values, setValues } = useFormikContext<any>();

  const [antenna, setAntenna] = useState<string>(
    editContactModalData?.myAntennaId || ""
  );

  const onSaveClickHandler = () => {
    const selectedAntenna = savedAntennaOptions.find(
      (antennaOption: any) => antennaOption.value === antenna
    );

    if (selectedAntenna) {
      setValues((prev: any) => ({
        ...prev,
        myAntennaId: selectedAntenna?.value,
        myAntenna: selectedAntenna?.label,
      }));
    }

    onClose();
  };

  useEffect(() => {
    if (values?.myAntennaId) {
      setAntenna(values?.myAntennaId);
    }
  }, [values?.myAntennaId]);

  return (
    <section className="absolute w-full h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[20%] lg:px-[25%] overflow-scroll">
      <div className=" w-full ">
        <div className="w-full ">
          <div className="flex flex-col w-full dark:bg-[#151541] bg-white dark:text-white text-[#2b2e48] font-[Play] relative mt-20 border border-[#451836] px-[10%] py-[10%] gap-8">
            <div className="flex w-full flex-wrap relative">
              <LogbookSettingDropdown
                list={savedAntennaOptions}
                title="My Antenna:"
                value={antenna || ""}
                setValue={(value) => {
                  if (value === "add") {
                    // globalNavigate("/log-contacts/my-station");
                    globalNavigate("/log-contacts/my-station?tab=My+Antennas");
                  } else {
                    setAntenna(value);
                  }
                }}
                className="w-[80%] sm:w-[60%] md:w-[60%]"
              />
            </div>

            <div className="flex w-full justify-center">
              <BorderButtonSecondary
                // isLoading={loading || logbookSettingLoading}
                text={"Save"}
                onClick={onSaveClickHandler}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
              />
            </div>
            <div
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer"
              onClick={onClose}
            >
              <img src={Icons.GlowingClose} className="h-8 w-8 invert dark:filter-none dark:invert-0" alt="close" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
