// src/components/Modal/ShareMapModal/LogBookLayout.tsx

import React from "react";
import LogBookSidebar from "./LogBookSidebar";
import LogBookFooter from "./LogBookFooter";
import Images from "assets/Images";
import Icons from "assets/Icons";
import { LogBookData } from ".";
import ContactMap from "components/Map/ContactMap";


const LogBookHeader: React.FC = () => {
  return (
    <div className="h-[35px] dark:bg-white/10 bg-gray-800/20 rounded-tr-[5px] rounded-br-[5px] backdrop-blur-[81.55px] mx-4 mt-4">
      <div className="h-full flex justify-center items-center gap-[5px] px-4">
        <div className="w-[35px] h-[35px] relative">
          <img
            src={Icons.MainLogo}
            className="w-full h-full object-contain"
            alt="World Radio League Logo"
          />
        </div>
        <p className="text-black dark:text-white text-md font-bold font-['Play']">
          www.WorldRadioLeague.com
        </p>
      </div>
    </div>
  );
};

interface LogBookLayoutProps {
  logBookData: LogBookData;
  theme: string;
  onMapLoaded?: () => void;
}

const LogBookLayout: React.FC<LogBookLayoutProps> = ({ logBookData, theme, onMapLoaded }) => {
  if (!logBookData) return null;

  const {
    userProfile,
    qsoStats,
    bandStats,
    modeStats,
    dxccEntities,
    equipment,
  } = logBookData;

  return (
    <div className="w-full h-full relative flex flex-col gap-3 pb-4">
      {/* Background Image */}
      <img
        src={theme === 'dark' ? Images.ShareLogbookMap : Images.ShareLogbookLight}
        alt="background"
        className="absolute inset-0 w-full h-full object-cover"
      />

      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="w-[542px] h-[368px] left-[371px] top-[184px] absolute bg-[#00ffef] rounded-full blur-[400px] opacity-20" />
      </div>

      {/* Main Layout */}
      <div className="relative z-10 flex-1 flex">
        <LogBookSidebar
          userProfile={userProfile}
          qsoStats={qsoStats}
          bandStats={bandStats}
          modeStats={modeStats}
          dxccEntities={dxccEntities}
          equipment={equipment}
        />

        <div className="flex-1 relative">
          <div className="absolute inset-0">
            <ContactMap
              className="w-full h-full flex justify-center items-center"
              containerClassName="h-full w-full relative"
              mapControls={{
                streetViewControl: false,
                rotateControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                zoomControl: false,
                scrollwheel: false,
                disableDefaultUI: true,
                disableDoubleClickZoom: true,
                draggable: false,
              }}
              restrictBounds={false}
              shouldFitBounds={true}
              useCustomMarkers={true}
              curved={false}
              onMapLoaded={onMapLoaded}
            />
          </div>
          <div className="relative z-20">
            <LogBookHeader />
          </div>
        </div>
      </div>

      {/* Footer */}
      {(Object.keys(bandStats?.bands || {}).length > 0 ||
        Object.keys(modeStats?.modes || {}).length > 0 ) && (
        <LogBookFooter
          bands={Object.keys(bandStats?.bands || {})}
          modes={Object.keys(modeStats?.modes || {})}
        />
      )}
    </div>
  );
};

export default LogBookLayout;
