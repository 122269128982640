import React, { useState, useEffect } from "react";
import Icons from "assets/Icons";
import { HintProps } from "types/Component";

function Hint({ text, warning }: HintProps) {
  return (
    <div
      className={`w-[100%] sm:w-[54%] md:w-[45%] md:mr-14 sm:mr-10 relative mb-4 border mt-2 flex pl-3 items-center pt-1 dark:bg-[#10103D] bg-white ${
        warning ? "border-[#FE6F46]" : "border-[#3EB7D4]"
      }`}
    >
      <img src={Icons.Info} className="w-[20px] h-[20px] mr-3 invert dark:filter-none dark:invert-0" alt="" />
      <div className="flex py-1">
        <p className="dark:text-white text-[#2b2e48] font-[Barlow] font-semibold text-xs">
          {warning ? "Warning" : "Hint"}:{" "}
        </p>
        <p className="dark:text-white text-[#2b2e48] font-[Barlow] text-xs">{text}</p>
      </div>
    </div>
  );
}

export default Hint;
