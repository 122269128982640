import React from "react";

interface YouTubeLiveProps {
  videoId: string;
  width?: string;
  height?: string;
  showChat?: boolean;
}

const YouTubeLiveMobile: React.FC<YouTubeLiveProps> = ({
  videoId,
  width = "100%",
  height = "360px",
  showChat = false,
}) => {
  return (
    <div className="flex flex-col md:!hidden justify-center w-full">
      <iframe
        width={"100%"}
        height={height}
        src={`https://www.youtube.com/embed/${videoId}?autoplay=0&live=1`}
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube Live Video"
      ></iframe>
      {showChat && (
        <iframe
          width="100%"
          height={height}
          src={`https://www.youtube.com/live_chat?v=${videoId}&embed_domain=${window.location.hostname}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube Live Chat"
        ></iframe>
      )}
    </div>
  );
};

export default YouTubeLiveMobile;
