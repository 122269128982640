import { BorderButtonSecondaryProps } from "types/Component";
import ActivityIndicator from "components/Loading/ActivityIndicator";

function SolidBorderButton({
  text,
  onClick,
  className,
  isLoading,
  fontStyle,
  height,
  childHeight,
  childClassName,
  icon,
  selected
}: BorderButtonSecondaryProps) {
  return (
    <div
      className={`w-full ${
        className ? className : ""
      } ${isLoading ? "pointer-events-none" : "cursor-pointer"} ${
        height ? height : "h-8"
      } rounded-full flex justify-center items-center shadow-lg`}
      onClick={onClick}
    >
      <div
        className={`${childHeight ? childHeight : ""} ${
          childClassName ? childClassName : "text-sm sm:text-base"
        } ${selected ? "dark:bg-[#002C87] bg-[#fff]" : "dark:bg-[#1D204A] bg-[#fff]"} w-[calc(100%-3px)] h-[calc(100%-3px)] rounded-full flex justify-center items-center font-Play dark:text-white text-[#2b2e48]`}
      >
        {icon ? icon : ""}
        {isLoading ? <ActivityIndicator size={16} /> : text}
      </div>
    </div>
  );
}

export default SolidBorderButton;
