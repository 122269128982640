import ActivityIndicator from "components/Loading/ActivityIndicator";
import { useState } from "react";
import { RiInformationLine } from "react-icons/ri";
import { TitledInputProps } from "types/Component";

function TitledInputDefault({
  placeHolder,
  name,
  type,
  value,
  setText,
  className,
  width,
  title,
  error,
  props,
  tabindex,
  info,
  onBlur,
  isLoading,
  status,
  statusBg,
  multiple,
  containerClass,
  disabled,
}: TitledInputProps) {
  const [infoOpen, setInfoOpen] = useState(false);
  return (
    <div
      className={`${width ? width : "w-full"} relative ${
        containerClass ? containerClass : ""
      }`}
    >
      <div className="flex items-center w-full">
        <p className="font-[Barlow] font-bold text-sm dark:text-white text-[#2b2e48]truncate">
          {title}
        </p>
        {info ? (
          <div
            onMouseEnter={info ? () => setInfoOpen(true) : () => {}}
            onMouseLeave={info ? () => setInfoOpen(false) : () => {}}
          >
            <RiInformationLine color="#999" className="ml-1" />
          </div>
        ) : null}
      </div>
      {multiple ? (
        <textarea
          className={`h-8 dark:bg-[#23234C] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none px-3 font-[Barlow] text-sm mt-1.5 w-full ${
            className ? className : ""
          } ${error ? "!border !border-orange-700 !border-solid" : ""}`}
          onChange={(e: any) => setText(e)}
          multiple={multiple}
          type={type ? type : "text"}
          name={name}
          id=""
          onBlur={onBlur ? onBlur : () => {}}
          placeholder={placeHolder}
          value={value}
          tabIndex={tabindex}
          {...props}
          disabled={disabled ? disabled : false}
        />
      ) : (
        <input
          className={`h-8 dark:bg-[#23234C] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none px-3 font-[Barlow] text-sm mt-1.5 w-full ${
            className ? className : ""
          } ${error ? "!border !border-orange-700 !border-solid" : ""}`}
          onChange={(e: any) => setText(e)}
          type={type ? type : "text"}
          name={name}
          id=""
          onBlur={onBlur ? onBlur : () => {}}
          placeholder={placeHolder}
          value={value}
          tabIndex={tabindex}
          {...props}
          disabled={disabled ? disabled : false}
        />
      )}

      {info && infoOpen ? (
        <div className="absolute top-[50%] left-0 right-0 z-[1000] px-[4px] py-[5px] lg:px-[6px] xl:px-[10px] text-[12px] font-[Play] bg-[#524A6F] text-white">
          {info}
        </div>
      ) : null}
      <div className="flex justify-between items-center h-[20px]">
        {status && !isLoading ? (
          <div
            className={`${
              statusBg ? statusBg : ""
            } text-[9px] font-[Barlow] font-semibold dark:text-white text-[#2b2e48] px-2 py-[1px] rounded-full`}
          >
            {status}
          </div>
        ) : null}
        <div className="w-[32px] h-[20px] flex justify-end absolute right-0">
          {isLoading ? <ActivityIndicator size={8} /> : null}
        </div>
      </div>
    </div>
  );
}

export default TitledInputDefault;
