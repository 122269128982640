import TableCol from "./TableCol";
import { FaSort } from "react-icons/fa6";
import { ActivityLogTableProps } from "types/Component";

function ActivityLogTable({ list, itemClick }: ActivityLogTableProps) {
  return (
    <div className="w-full overflow-y-hidden">
      <table className="bg-white w-[655px] md:w-full">
        <tbody>
          <tr className="bg-[#94a3b883] dark:bg-[#3C3C58] pb-2.5 border-0 border-b border-slate-300 border-solid">
            <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48] flex items-center">
              Date/Time <FaSort size={14} className="ml-4" />
            </th>
            <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48]">
              ID
            </th>
            <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48]">
              Type
            </th>
            <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48]">
              Description
            </th>
            <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48]">
              # of QSOs
            </th>
            <th className="text-sm font-[Barlow] font-bold text-left pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48]">
              Status
            </th>
            <th className="text-sm font-[Barlow] font-bold text-center pt-3 pb-2 dark:text-white text-[#2b2e48]">
              View
            </th>
          </tr>
          {list
            ? list.map((item, index) => {
                return (
                  <TableCol
                    key={index}
                    className={`${
                      index % 2 ? "dark:bg-[#151536] bg-white" : "dark:bg-[#1F1F3F] bg-[#fafafa]"
                    } border-0 border-b border-slate-300 border-solid cursor-pointer`}
                    item={item}
                    onClick={() => itemClick(item)}
                  />
                );
              })
            : null}
        </tbody>
      </table>
    </div>
  );
}

export default ActivityLogTable;
