import { TextInputProps } from "types/Component";

function TextInput({
  placeHolder,
  name,
  type,
  text,
  setText,
  error,
  className,
  multiple,
}: TextInputProps) {
  if (!multiple) {
    return (
      <input
        className={`w-full h-10 dark:bg-transparent bg-[#f1f3f4] dark:text-white text-[#2b2e48] border  border-solid border-[#dcdcdd] dark:border-white px-3 ${
          error ? "border-red" : ""
        }${className ? className : "mb-5"}`}
        placeholder={placeHolder}
        value={text}
        onChange={(e: any) => setText(e?.target?.value)}
        type={type ? type : "text"}
        name={name}
        id=""
      />
    );
  } else {
    return (
      <textarea
        className={`w-full h-min-10 dark:bg-transparent bg-[#f1f3f4] dark:text-white text-[#2b2e48] border  border-solid border-[#dcdcdd] dark:border-white py-2 px-3 ${
          className ? className : "mb-5"
        }`}
        value={text}
        placeholder={placeHolder}
        onChange={(e: any) => setText(e?.target?.value)}
        rows={5}
        cols={50}
        name={name}
        id="multiLineInput"
      ></textarea>
    );
  }
}

export default TextInput;
