export const HOME_ROUTE = "/";
export const MY_PROFILE_ROUTE = "/profile";
export const EDIT_PROFILE = "edit-profile";
export const MEMBERS_DIRECTORY = "/members-directory";
export const SPOTTING = "/spotting";
export const LOG_CONTACT_ROUTE = "/log-contacts";
export const ACTIVITIES = "/activities";
export const LEADERS_BOARD = "/leaderboard";
export const CONTACT = "/contact";
export const CONTEST = "/contest";
export const SETTING_ROUTE = "/membership";
export const HELP_CENTER_ROUTE = "/help-center";
export const CONTACT_US_ROUTE = "/contact-us";
export const REPORT_BUG = "/report-bug";
export const USER_SETTINGS = "/settings";
export const TERMS_AND_CONDITIONS =
  "https://worldradioleague.com/terms-and-conditions/";
export const PRIVACY_POLICY = "https://worldradioleague.com/privacy-policy/";
