import React, { useEffect, useState } from "react";
import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import Entries from "components/Entries";
import Header from "components/Header";
import RouteLoading from "components/Loading/RouteLoading";
import ContactMap from "components/Map/ContactMap";
import useContest from "hooks/contest.hook";
import { useLocation, useSearchParams } from "react-router-dom";
import { globalNavigate } from "routes/GlobalRoutes";
import CountryDxccFlag from "components/CountryDxccFlag";
import Tabs from "components/Tabs";
import MultiSelectList from "./Contest/MultiSelectList";
import BarChartComponent from "components/Charts/BarChart/BarChartBasic";
import { MY_PROFILE_ROUTE } from "constants/Links";
import { categorizeModeCounts } from "helpers/Utils";
import ModeCategoryTable from "./ModeCategoryTable";
import { useSelector } from "react-redux";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { NavigateBackButton } from "./NavigateBackButton";

function ObjectivesStat({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) {
  return (
    <div className="dark:bg-[#151541] bg-white flex flex-col flex-1 gap-1 justify-center cursor-pointer px-4 py-6 dark:text-[#AEB9E1] text-[#2b2e48] border border-[#2F3693] border-opacity-30 rounded-lg">
      <div className="font-medium text-[14px]">{title}</div>
      <div className="dark:text-white font-Barlow text-[28px] font-bold">
        {value}
      </div>
    </div>
  );
}

const UserLogbookDetail = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const userProfile = useSelector(getUserProfileSelector);
  const contestPassed = location?.state?.contestPassed || false;
  const {
    getContestantDetail,
    userContestantDetail,
    isUserContestantLoading,
    contestDetail,
    fetchContestDetail,
    storeShouldLoadUserDetails,
    shouldLoadUserDetails
  } = useContest();

  useEffect(() => {
    if (searchParams.get("contest_id") && searchParams.get("user_id")) {
      fetchContestDetail(searchParams.get("contest_id")!);
      getContestantDetail(
        searchParams.get("contest_id")!,
        searchParams.get("user_id")!
      );
    } else {
      globalNavigate("/contest");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldLoadUserDetails) {      
      fetchContestDetail(searchParams.get("contest_id")!);
      getContestantDetail(
        searchParams.get("contest_id")!,
        searchParams.get("user_id")!
      );
      storeShouldLoadUserDetails(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadUserDetails]);

  const [userBandsLabels, setUserBandsLabels] = useState<string[]>([]);
  const [userBandsData, setUserBandsData] = useState<any[]>([]);
  const [userModesLabels, setUserModesLabels] = useState<string[]>([]);
  const [userModesData, setUserModesData] = useState<any[]>([]);
  const [userContactByHourLabels, setUserContactByHourLabels] = useState<
    string[]
  >([]);
  const [userContactByHourData, setUserContactByHourData] = useState<any[]>([]);
  const [modeCategoryCount, setModeCategoryCount] = useState<any>({
    Phone: 0,
    CW: 0,
    Digital: 0,
    Other: 0,
  });
  const [modeCategoryPointsCount, setModeCategoryPointsCount] = useState<any>({
    Phone: 0,
    CW: 0,
    Digital: 0,
    Other: 0,
  });

  useEffect(() => {
    const bands = userContestantDetail?.userBands || [];
    const modes = userContestantDetail?.userModes || [];
    const modePoints = userContestantDetail?.userModePoints || [];
    const contactByHour = userContestantDetail?.contactByHour || [];

    const bandsLabels = Object.keys(bands);
    const bandsData = Object.values(bands);

    const modesLabels = Object.keys(modes);
    const modesData = Object.values(modes);

    // Combine all data into a single array
    const combinedData: { hour: number; count: number; date: string }[] = [];

    Object.keys(contactByHour)
      .sort()
      .forEach((date: any) => {
        const hoursData = contactByHour[date];
        for (let hour = 0; hour < 24; hour++) {
          if (hoursData[hour]) {
            combinedData.push({ hour, count: hoursData[hour], date });
          }
        }
      });

    const contactByHourLabels = combinedData.map((data) =>
      data.hour.toString()
    );
    const contactByHourData = combinedData.map((data) => data.count);

    setUserBandsLabels(bandsLabels);
    setUserBandsData(bandsData);
    setUserModesLabels(modesLabels);
    setUserModesData(modesData);
    setUserContactByHourLabels(contactByHourLabels);
    setUserContactByHourData(contactByHourData);

    // Count the number of contacts per mode category
    const modeCategoryCountData = categorizeModeCounts(modes as any);
    setModeCategoryCount(modeCategoryCountData);

    // Count the number of points per mode category
    const modeCategoryPointsCountData = categorizeModeCounts(modePoints as any);
    setModeCategoryPointsCount(modeCategoryPointsCountData);
  }, [userContestantDetail]);

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full">
      <Header title="LeaderBoard" />
      <div className="p-3 md:p-7 pb-10 dashboardWrapper overflow-x-hidden">
        <NavigateBackButton />
        {isUserContestantLoading ? (
          <RouteLoading noBg />
        ) : (
          <>
            <div className="flex flex-row w-full gap-4 mb-4">
              <div className="dark:bg-[#151541] bg-white flex flex-col flex-1 gap-1 justify-center cursor-pointer px-4 py-6 dark:text-[#AEB9E1] text-[#2b2e48] border border-[#2F3693] border-opacity-30 rounded-lg">
                <div
                  className="p-6 w-full h-full flex flex-col gap-4 cursor-pointer"
                  onClick={() =>
                    globalNavigate(
                      `${MY_PROFILE_ROUTE}/${userContestantDetail?.user?.callSign}`
                    )
                  }
                >
                  <div className="flex flex-row gap-6">
                    <img
                      src={
                        userContestantDetail?.user?.profilePic
                          ? userContestantDetail?.user?.profilePic
                          : Icons.User
                      }
                      alt=""
                      className={`w-[150px] h-[150px] rounded-md ${
                        userContestantDetail?.user?.profilePic
                          ? ""
                          : "invert dark:filter-none dark:invert-0"
                      }`}
                    />
                    <div className="flex flex-col justify-center font-['Urbanist'] gap-0">
                      <div className="dark:text-white text-[#2b2e48] font-Barlow font-medium text-3xl">
                        {userContestantDetail?.user?.callSign}
                      </div>
                      <div className="dark:text-white text-[#2b2e48] font-Barlow font-semibold text-3xl">
                        {`${userContestantDetail?.user?.firstName} ${userContestantDetail?.user?.lastName}`}
                      </div>

                      <div className="flex flex-row dark:text-white text-[#2b2e48] font-Barlow font-semibold text-md mt-2">
                        <div className=" inline-block min-w-[30px]">
                          <CountryDxccFlag
                            countryCode={
                              userContestantDetail?.user?.country || ""
                            }
                            flagCode={""}
                          />
                        </div>
                        {userContestantDetail?.user?.country} -{" "}
                        {userContestantDetail?.user?.stateLongName}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="dark:bg-[#151541] bg-white flex flex-col flex-1 gap-1 cursor-pointer px-4 py-6 dark:text-[#AEB9E1] text-[#2b2e48] border border-[#2F3693] border-opacity-30 rounded-lg">
                <div className="font-medium text-[14px]">
                  Your Position in Contest
                </div>
                <div className="dark:text-white font-Barlow text-[28px] font-bold">
                  1st Place
                </div>

                {contestPassed ? (
                  <AccessControl
                    permission={PERMISSIONS.declareContestWinner}
                    children={
                      <div className="w-full flex justify-end">
                        <div className="w-[220px]">
                          <div className="w-full md:w-[220px]">
                            <BorderButtonSecondary
                              className=""
                              height="h-11"
                              text={"Declare as Winner"}
                              onClick={() =>
                                declareWinner(searchParams.get("contest_id")!)
                              }
                              childClassName="text-sm md:text-base py-2 px-[10px]"
                            />
                          </div>
                        </div>
                      </div>
                    }
                  />
                ) : null}
              </div> */}
            </div>
            <div className="flex flex-col md:flex-row w-full gap-4">
              <ObjectivesStat
                title="Current Ranking"
                value={userContestantDetail?.contestRanking || 0}
              />
              <ObjectivesStat
                title="# of QSOs"
                value={userContestantDetail?.totalQso || 0}
              />
              {contestDetail?.isWFDContest ? (
                <ObjectivesStat
                  title="% Objectives"
                  value={
                    userContestantDetail?.contestMultiplier?.toFixed?.(0) || 0
                  }
                />
              ) : null}
              <ObjectivesStat
                title="Total Points"
                value={
                  (contestDetail?.isWFDContest
                    ? userContestantDetail?.contestTotalPoints
                    : userContestantDetail?.totalQso) || 0
                }
              />
            </div>

            <Tabs
              tabs={[
                {
                  id: "entries",
                  label: `LOG(${userContestantDetail?.totalQso || 0})`,
                },
                {
                  id: "map",
                  label: "Map",
                },
                {
                  id: "statistics",
                  label: "Statistics",
                },
                {
                  id: "objectives",
                  label: "Objectives",
                  hidden: !userContestantDetail?.options?.length,
                },
              ]}
              selectedTabId={searchParams.get("tab") || ""}
              setSelectedTabId={(tab) => {
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.set("tab", tab);
                setSearchParams(newSearchParams);
              }}
              className="mt-6"
            />

            {searchParams.get("tab") === "entries" ? (
              <Entries
                lists={userContestantDetail?.userLogs || []}
                editable={true}
                isWFDLogBook={!!userContestantDetail?.options?.length}
                guestUser={ userContestantDetail?.user?.uid !== userProfile?.uid }
              />
            ) : null}

            {searchParams.get("tab") === "map" ? (
              <ContactMap
                className="w-full h-[500px] relative"
                containerClassName="w-full"
                userContactMap={true}
                restrictBounds={false}
                shouldFitBounds={false}
              />
            ) : null}
            {searchParams.get("tab") === "objectives" &&
            userContestantDetail?.options?.length ? (
              <>
                <section className="dark:bg-[#151541] bg-white mt-2.5 w-full relative dark:text-white text-[#2b2e48] p-10">
                  <div className="flex flex-row w-full gap-4">
                    <ObjectivesStat
                      title="Objectives Completed"
                      value={userContestantDetail?.contestTotalObjectives || 0}
                    />
                    <ObjectivesStat
                      title="Total Multipliers"
                      value={
                        userContestantDetail?.contestUserObjectivesTotalPoints ||
                        0
                      }
                    />
                    <ObjectivesStat
                      title="Objectives Completion"
                      value={
                        userContestantDetail?.contestMultiplier?.toFixed?.(0) +
                        "%"
                      }
                    />
                  </div>
                  <div className="w-full mt-4">
                    <div className="font-Barlow text-base font-bold mb-2">
                      Mark the Box for All Objectives You Complete
                    </div>
                    <MultiSelectList
                      options={userContestantDetail?.options}
                      selectedOptions={
                        userContestantDetail?.selectedOptions || []
                      }
                      // loading={logbookLoading}
                      onChange={(values) => {
                        // setSelectedOptions(values);
                        // updateLogbookSettings({
                        //   contestObjectives: values,
                        // });
                      }}
                    />
                  </div>
                </section>
              </>
            ) : null}

            {searchParams.get("tab") === "statistics" ? (
              <div className="flex flex-col w-full p-10 dark:bg-[#151541] bg-white mt-2.5">
                <div className="flex flex-col md:flex-row w-full gap-4">
                  <div className="flex-1">
                    <div className="dark:text-white text-[#2b2e48] font-Barlow text-xl ml-10">
                      Contacts by Bands
                    </div>
                    <BarChartComponent
                      labels={userBandsLabels}
                      data={userBandsData}
                    />
                  </div>
                  <div className="flex-1">
                    <div className="dark:text-white text-[#2b2e48] font-Barlow text-xl ml-10">
                      Contacts by Modes
                    </div>
                    <BarChartComponent
                      labels={userModesLabels}
                      data={userModesData}
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full items-start justify-start">
                  <div className="dark:text-white text-[#2b2e48] font-Barlow text-xl ml-10">
                    QSO by Hour
                  </div>
                  <BarChartComponent
                    labels={userContactByHourLabels}
                    data={userContactByHourData}
                  />
                </div>

                {contestDetail?.isWFDContest ? (
                  <div className="flex flex-col w-full items-start justify-start gap-6 md:px-10">
                    <div className="dark:text-white text-[#2b2e48] font-Barlow text-xl">
                      Contest: CQWPXCW
                    </div>
                    <div className="flex w-full">
                      <ModeCategoryTable
                        qsoCounts={modeCategoryCount}
                        pointsCounts={modeCategoryPointsCount}
                        multipliers={
                          userContestantDetail?.contestUserObjectivesTotalPoints ||
                          1
                        }
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </>
        )}
      </div>
    </main>
  );
};

export default UserLogbookDetail;
