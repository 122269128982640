import React, { useRef, useState, useEffect } from 'react';

interface CarouselProps {
  children: React.ReactNode;
}

const Carousel: React.FC<CarouselProps> = ({ children }) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState(false);

  const checkScrollable = () => {
    if (carouselRef.current) {
      const { scrollWidth, clientWidth } = carouselRef.current;
      setIsScrollable(scrollWidth > clientWidth);
    }
  };

  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -carouselRef.current.offsetWidth / 2,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: carouselRef.current.offsetWidth / 2,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    checkScrollable();
    window.addEventListener('resize', checkScrollable);
    return () => {
      window.removeEventListener('resize', checkScrollable);
    };
  }, []);

  return (
    <div className="relative w-full flex items-center">
      {/* Left Arrow */}
      {isScrollable && (
        <button
          onClick={scrollLeft}
          className="flex justify-center items-center bg-gray-80 border w-6 h-6 dark:text-white text-[#2b2e48] p-2 rounded-full z-10 mr-4 dark:bg-white/20 bg-[#f1f3f4]"
        >
          ←
        </button>
      )}

      {/* Carousel Content */}
      <div
        ref={carouselRef}
        className="flex overflow-x-scroll carousel-scrollbar px-1 gap-4 space-x-4 snap-x snap-mandatory w-full items-center whitespace-nowrap"
      >
        {children}
      </div>

      {/* Right Arrow */}
      {isScrollable && (
        <button
          onClick={scrollRight}
          className="flex justify-center items-center bg-gray-80 border w-6 h-6  dark:text-white text-[#2b2e48] p-2 rounded-full z-10 ml-4 dark:bg-white/20 bg-[#f1f3f4]"
        >
          →
        </button>
      )}
    </div>
  );
};

export default Carousel;
