import Icons from "assets/Icons";
import { VerticalDivider } from "components/Divider";
import { UserAvatar } from "components/UserAvatar";
import { LocationMappings } from "constants/LocationMappings";
import { findCountryCode } from "helpers/Utils";
import { useCallsign } from "hooks/callsign.hook";
import Lottie from "lottie-react";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import LottieFiles from "assets/LottieFiles";
import { globalNavigate } from "routes/GlobalRoutes";
import { MY_PROFILE_ROUTE } from "constants/Links";
import CustomValidation from "helpers/Validation";
import { useLocation } from "react-router-dom";
import { CallsignModel } from "types/Models";
import GuestService from "services/guest.service";

export const CallSignSearchbar = () => {
  const [showResults, setShowResults] = useState(false);
  const location = useLocation();
  const [hoveredIndex, setHoveredIndex] = useState<number>(-1);
  const [profileImages, setProfileImages] = useState<{ [key: string]: string | undefined }>({});

  const {
    callsignSuggestionsData,
    callsignDataLoading,
    debouncedFetchCallSignData,
    callsignSearchKey,
    setCallsignSearchKey,
    setCallsignSuggestionsData,
  } = useCallsign();

  useEffect(() => {
    return () => {
      setCallsignSearchKey("");
      setCallsignSuggestionsData(null);
    };
  }, []);

  useEffect(() => {
    const fetchProfiles = async () => {
      if (callsignSuggestionsData) {
        const profiles: { [key: string]: string | undefined } = {};
        for (const suggestion of callsignSuggestionsData) {
          const profileData = await GuestService.getUserProfileDataByCallSign({ callSign: suggestion.callsign, userId: '' });
          if (profileData?.profilePic) {
            profiles[suggestion.callsign] = profileData?.profilePic;
          }
        }
        setProfileImages(profiles);
      }
    };

    fetchProfiles();
  }, [callsignSuggestionsData]);
  
  const handleInputChange = (e: any) => {
    const value = e.target.value;
    //change value to uppercase
    setCallsignSearchKey(value.toUpperCase());

    if (value) {
      setShowResults(true);
      debouncedFetchCallSignData(value, "FirestoreDB");
    } else {
      setCallsignSuggestionsData(null);
      setShowResults(false);
      setHoveredIndex(-1);
      //globalNavigate("/");
    }
  };

  const getLocationString = (record: CallsignModel) => {
    let locationString = "";

    if (record.city) {
      locationString += record.city.toLowerCase();
    }

    if (record.stateCode) {
      locationString += `, ${record.stateCode}`;
    } else {
      if (record.state) {
        locationString += `, ${record.state}`;
      }
    }

    if (record.country && !locationString) {
      locationString += `${record.country}`;
    }

    return locationString;
  };

  const keyDownHander = (event: any) => {
    if (
      event.key === "Enter" &&
      callsignSuggestionsData?.length &&
      hoveredIndex >= 0 &&
      hoveredIndex < 5
    ) {
      event.preventDefault();

      const callsign = callsignSuggestionsData?.[hoveredIndex]?.callsign || "";

      if (location?.pathname?.includes(MY_PROFILE_ROUTE)) {
        window.location.href = `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
          callsign
        )}`;
      } else {
        globalNavigate(
          `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(callsign)}`
        );
      }
    }

    if (event.key === "ArrowUp") {
      setHoveredIndex((hoveredIndexValue) => {
        if (hoveredIndexValue <= 0) return 4;
        return hoveredIndexValue - 1;
      });
    }

    if (event.key === "ArrowDown") {
      setHoveredIndex((hoveredIndexValue) => {
        if (hoveredIndexValue >= 4) return 0;
        return hoveredIndexValue + 1;
      });
    }
  };

  return (
    <div className="bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] p-[2px] inline-flex relative w-full shadow-lg rounded-full">
      <div className="flex items-center dark:bg-[#31336e] bg-[#f1f3f4] px-2 w-full gap-4 relative rounded-full">
        <img src={Icons.SearchBarIcon} alt="" className=" filter invert dark:filter-none dark:invert-0 h-5 ml-1" />
        <input
          type="text"
          className="w-full outline-none dark:bg-[#31336e] bg-[#f1f3f4] dark:text-[#E1E4FF] text-[#2b2e48] h-8 font-Play rounded-e-full"
          value={callsignSearchKey}
          onChange={handleInputChange}
          placeholder="Search Callsign or Name"
          onKeyDown={keyDownHander}
        />
        {callsignDataLoading ? (
          <Lottie
            className="absolute right-0 h-12 w-14"
            animationData={LottieFiles.ActivityIndicator}
            loop={true}
          />
        ) : null}
      </div>
      {callsignSuggestionsData && showResults && (
        <div className="absolute top-full -left-[15vw] w-[94vw] sm:left-0 sm:w-full mt-3 shadow-lg z-[1000] bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] p-[1px] inline-flex">
          <div className="w-full dark:bg-[#1b1c60] bg-white ">
            {callsignSuggestionsData.map((result, index: number) => (
              <div
                key={index}
                className={`px-4 py-1 dark:hover:bg-[#272869] hover:bg-[#f1f3f4] cursor-pointer text-white dark:bg-[#1b1c60] bg-white
                  ${hoveredIndex === index ? "dark:bg-[#272869] bg-[#f1f3f4]" : ""}`}
                // onClick={() => setQuery(result)}
                onClick={() => {
                  if (location?.pathname?.includes(MY_PROFILE_ROUTE)) {
                    window.location.href = `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
                      result?.callsign ? result?.callsign : ""
                    )}`;
                  } else {
                    globalNavigate(
                      `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
                        result?.callsign ? result?.callsign : ""
                      )}`
                    );
                  }
                }}
              >
                <div className="flex flex-row gap-3 h-10 items-center text-xs overflow-x-auto dark:text-white text-[#2b2e48]">
                  {profileImages[result.callsign] ?
                    (<img
                      src={profileImages[result.callsign] || ''}
                      alt={`${result.callsign} Profile Avatar`}
                      className="rounded-full object-cover w-7 h-7"
                    />
                    )
                    :
                    <UserAvatar className="w-7 h-7" />
                  }
                  <VerticalDivider />
                  <div>{result?.callsign}</div>
                  <VerticalDivider />
                  <div className="capitalize">{(result?.fullName || "").toLowerCase()}</div>
                  <VerticalDivider />

                  <div className="flex flex-row gap-2 items-center">
                    <ReactCountryFlag
                      countryCode={
                        findCountryCode({
                          name: LocationMappings[result?.country as string]
                            ? LocationMappings[result?.country as string]
                            : result?.country || "",
                        })?.alpha2
                      }
                      svg
                      style={{
                        width: "1.5em",
                        height: "1.5em",
                      }}
                      title={result?.country}
                    />
                    <div className="capitalize">{getLocationString(result)}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
