import Icons from "assets/Icons";
import YouTubeLive from "../../../../components/YouTubeLive/YoutubeLive";
import useContest from "hooks/contest.hook";
import Leaders from "./Leaders";
import ContestStatistics from "./ContestStatistics";
import ContestMap from "./ContestMap";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTopBand } from "store/Actions/contest.actions";
import { getTopBandSelector } from "store/Reducers/contest.reducer";
import YouTubeLiveMobile from "components/YouTubeLive/YoutubeLiveMobile";

function ObjectivesStat({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) {
  const [animatedValue, setAnimatedValue] = useState<number | string>(
    typeof value === "number" ? 0 : value
  );

  useEffect(() => {
    if (typeof value !== "number") {
      setAnimatedValue(value); // Directly set if value is not a number
      return;
    }

    const duration = 1000; // Animation duration in ms
    const startTime = performance.now();

    function animate(currentTime: number) {
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const currentValue = Math.floor(progress * (value as number));

      setAnimatedValue(currentValue);

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    }

    requestAnimationFrame(animate);
  }, [value]);

  return (
    <div className="dark:bg-[#000030] bg-white flex flex-col flex-1 gap-1 justify-center cursor-pointer px-4 py-6 dark:text-[#AEB9E1] text-[#2b2e48] border border-[#fff] border-opacity-30">
      <div className="font-medium text-[14px] text-[#12CAA7]">{title}</div>
      <div className="dark:text-white font-Barlow text-[32px] font-bold">
        {animatedValue}
      </div>
    </div>
  );
}

function ContestHome() {
  const { contestDetail, contestantDetails } = useContest();
  const dispatch = useDispatch();
  const topBand = useSelector(getTopBandSelector);

  useEffect(() => {
    if (contestDetail?.id) {
      dispatch(getTopBand(contestDetail.id));
    }
  }, [contestDetail]);

  return (
    <div className="flex flex-col items-center rounded-lg gap-10 px-8 py-4">
      <div className="flex flex-col md:flex-row w-full gap-4">
        <ObjectivesStat
          title="Live Viewers"
          value={contestantDetails?.contestantCount || 0}
        />
        <ObjectivesStat
          title="Contacts Logged"
          value={contestantDetails?.qsos || 0}
        />
        <ObjectivesStat
          title="Participants"
          value={contestantDetails?.contestantCount || 0}
        />
        <ObjectivesStat
          title="Top Band Last Hour"
          value={topBand ? topBand : "-"}
        />
      </div>

      {contestDetail?.streamVideoId ? (
        <div className="flex flex-col w-full gap-6">
          <div className="flex flex-row items-center dark:text-white text-[#2b2e48] gap-4 text-xl font-bold font-Play">
            <img src={Icons.YoutubeIcon} alt="" />
            WATCH LIVE STREAMING
          </div>
          <YouTubeLive
            videoId={contestDetail?.streamVideoId}
            width="100%"
            height="500px"
            showChat
          />
          <YouTubeLiveMobile
            videoId={contestDetail?.streamVideoId}
            width="100%"
            height="250px"
            showChat
          />
        </div>
      ) : null}

      {/* Leaders */}
      <Leaders />

      {/* Contest Map */}
      <ContestMap contestId={contestDetail?.id || ""} />

      {/* Statistics */}
      <ContestStatistics />
    </div>
  );
}

export default ContestHome;
