import TableCol from "components/Table/RecentContactTable/TableCol";
import React from "react";
import { RecentContactTableProps } from "types/Component";

function RecentContactTable({ lists, className }: RecentContactTableProps) {
  return (
    <div className={`w-full overflow-y-hidden mt-6 ${className}`}>
      <table className="w-[655px] md:w-full">
        <tbody>
          <tr className="bg-[#94a3b883] dark:bg-[#3C3C58]  pb-2.5 border-0 border-b border-slate-300 border-solid">
            <th className="text-sm font-[Barlow] font-bold text-left px-4 pt-3 pb-2 dark:text-white text-[#2b2e48] w-[140px]">
              From
            </th>
            <th className="text-sm font-[Barlow] text-left font-bold pt-3 pb-2 pl-4 dark:text-white text-[#2b2e48] w-[140px]">
              To
            </th>
            <th className="text-left text-sm font-[Barlow] font-bold pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48] w-[30px]">
              Band
            </th>
            <th className="text-left text-sm font-[Barlow] font-bold pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48] w-[30px]">
              Mode
            </th>
            <th className="text-left text-sm font-[Barlow] font-bold pl-4 pt-3 pb-2 dark:text-white text-[#2b2e48] w-[100px]">
              Distance
            </th>
            {/* <th className="text-left text-sm font-[Barlow] font-bold pl-4 pt-5 pb-2.5 text-white">
              Distance
            </th> */}
          </tr>
          {/* <TableCol
            item={{
              myCallSign: "SAMPLE",
              frequency: "10,000 PM",
              theirCallsign: "KALPE",
            }}
            className={`bg-[#151536] border-0 border-b border-slate-300 border-solid`}
            animate
            animateContacts={{}}
          /> */}
          {lists.map((item: any, index: number) => {
            return (
              <TableCol
                key={index}
                item={item}
                className={`${
                  index % 2 ? "dark:bg-[#151536] bg-[#f1f3f4]" : "dark:bg-[#1F1F3F] bg-white"
                } border-0 border-b border-slate-300 border-solid`}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default RecentContactTable;
