import { useState, useEffect } from "react";
import LogBookService from "services/logBook.service";
import { auth } from "firebase-config";

export const useDuplicateCheck = (values: {
  theirCallsign: string;
  band: string;
  myCallSign: string;
  date: string;
  time: string;
  userMode: string;
}, options?: any) => {
  const [duplicateContactInfo, setDuplicateContactInfo] = useState<any | null>(null);

  useEffect(() => {
    const checkDuplicate = async () => {
      if (!values.theirCallsign || !auth.currentUser?.uid) {
        setDuplicateContactInfo(null);
        return;
      }

      if (values.theirCallsign) {
        const result = await LogBookService.checkForDuplicate({
          ...values,
          uid: auth.currentUser.uid,
        }, options);

        setDuplicateContactInfo(result);
      } else {
        setDuplicateContactInfo(null);
      }
    };

    checkDuplicate();
  }, [
    values.theirCallsign,
    values.band,
    values.myCallSign,
    values.date,
    values.time,
    values.userMode,
  ]);

  return { duplicateContactInfo, setDuplicateContactInfo };
};
