import React, { useEffect, useState } from "react";

interface ButtonProps {
  icon?: string;
  label: string;
  active: boolean;
  tabIndex?: number;
  onClick: () => void;
  disabled?: boolean;
}

const ButtonComponent: React.FC<ButtonProps> = ({
  icon,
  label,
  active,
  onClick,
  tabIndex,
  disabled
}) => {
  return (
    <div
      tabIndex={tabIndex}
      className={`flex flex-row items-center text-sm gap-2 ${
        active
          ? "text-slate-900 font-bold bg-gradient-to-br from-indigo-500 to-cyan-300"
          : "dark:bg-white dark:bg-opacity-10 bg-[#f1f3f4] dark:text-white text-[#2b2e48]"
      } border border-neutral-400 p-1 px-4 rounded-full cursor-pointer ${disabled ? "pointer-events-none" : ""}`}
      onClick={onClick}
    >
      {icon ? <img className={`h-[16px] ${active ? "brightness-0": "invert dark:filter-none dark:invert-0"}`} src={icon} alt={label} /> : null}
      <div>{label}</div>
    </div>
  );
};

type Action = { id: number | string; icon?: string; label: string; tabIndex?: number};

interface SelectableButtonsGroupProps {
  actions: Array<Action>;
  onButtonChange: (action: Action | null) => void;
  initialSelected?: number | string;
  toggle?: boolean;
  disabled?: boolean;
}

export const SelectableButtonsGroup: React.FC<SelectableButtonsGroupProps> = ({
  actions,
  onButtonChange,
  initialSelected = -1,
  toggle,
  disabled,
}) => {
  const [selectedItem, setSelectedItem] = useState<number | string>(initialSelected);

  useEffect(()=>{
    if (initialSelected){
      setSelectedItem(initialSelected)
    }
  },[initialSelected])

  const handleItemClick = (action: Action) => {
    if (selectedItem === action.id && toggle) {
    setSelectedItem(-1);
    onButtonChange(null);
    }else{
      setSelectedItem(action.id);
      onButtonChange(action);
    }
  };

  return (
    <div className="flex gap-2 w-full flex-wrap mt-6 md:mt-0">
      {actions.map((action) => (
        <ButtonComponent
          key={action.id}
          icon={action.icon}
          label={action.label}
          active={selectedItem === action.id}
          onClick={() => handleItemClick(action)}
          // tabIndex={action.tabIndex}
          disabled={disabled}
        />
      ))}
    </div>
  );
};
