import React, { useEffect, useState } from "react";
import Icons from "assets/Icons";

import { AddUserAwardModalModalProps } from "types/Component";

import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { useDispatch, useSelector } from "react-redux";
import {
  addUserAwards,
  fetchAwards,
  removeUserAwards,
  setShowAddAwardModal,
} from "store/Actions/profile.actions";

import {
  getAwardsSelector,
  getUserAwardsAdminSelector,
} from "store/Reducers/profile.reducer";
import { UserAwards } from "types/Models";
import { getIsUserAwardLoadingSelector } from "store/Reducers/loading.reducer";
import { serverTimestamp } from "firebase/firestore";
import ActivityIndicator from "components/Loading/ActivityIndicator";
const AddUserAwardModal = ({ user }: AddUserAwardModalModalProps) => {
  const awards = useSelector(getAwardsSelector);
  const userAwardsAdmin = useSelector(getUserAwardsAdminSelector);
  const awardLoading = useSelector(getIsUserAwardLoadingSelector);
  const [selectedAwards, setSelectedAwards] = useState<UserAwards[]>([
    ...userAwardsAdmin,
  ]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAwards(user?.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedAwards([...userAwardsAdmin]);
  }, [userAwardsAdmin]);

  const handleAwardSelect = (item: UserAwards) => {
    const index = selectedAwards.findIndex(
      (award: any) => award.id === item.id
    );
    const nameIndex = selectedAwards.findIndex(
      (award: any) => award.name === item.name
    );
    if (index > -1 || nameIndex > -1) {
      const newArray = [...selectedAwards];
      newArray.splice(index, 1);
      setSelectedAwards(newArray);
    } else {
      setSelectedAwards([...selectedAwards, item]);
    }
  };

  const isAwardSelected = (item: UserAwards) => {
    return (
      selectedAwards.findIndex(
        (award: any) => award.id === item.id || award.name === item.name
      ) > -1
    );
  };

  const onSavePress = () => {
    const data: any = [];
    selectedAwards.forEach((item: UserAwards) => {
      if (
        userAwardsAdmin.findIndex((award: any) => award.name === item.name) < 0
      ) {
        data.push({
          description: item.description || "",
          image: item.image || "",
          name: item.name || "",
          type: item.type || "",
          pdf: item.pdf || "",
          uid: user?.uid,
          timestamp: serverTimestamp(),
        });
      }
    });
    dispatch(addUserAwards(data));
  };

  const onDeletePress = (item: UserAwards) => {
    var userResponse = window.confirm(
      "Are you sure you want to remove this award from the user?"
    );
    if (userResponse === true) {
      const index = userAwardsAdmin.findIndex(
        (award: any) => award.name === item.name
      );
      const userAward = userAwardsAdmin[index];
      // console.log("userAward", userAward);
      dispatch(
        removeUserAwards({
          awardId: userAward.id,
          userId: user?.uid,
        })
      );
    } else {
    }
  };

  return (
    <section className="absolute w-screen h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[5%] lg:px-[10%]">
      <div className="overflow-x-hidden w-full">
        <div className="w-full">
          <div className="flex flex-col w-full bg-[#151541] px-3 py-5 items-center">
            <h5 className="font-[Play] text-xl font-bold text-white py-2 ml-3 w-full">
              Add Award for User (Admin)
            </h5>
            {awardLoading ? (
              <ActivityIndicator size={36} />
            ) : (
              <>
                <div className="flex w-[100%] mt-4 mb-10 flex-wrap justify-center">
                  {awards.map((item) => (
                    <div
                      className={`w-50 h-50 mr-4 border rounded-lg p-4 cursor-pointer mb-4 ${
                        isAwardSelected(item) ? "bg-[#17F9DA]" : ""
                      } `}
                    >
                      <div
                        onClick={() => {
                          handleAwardSelect(item);
                        }}
                      >
                        <img
                          src={item.image}
                          alt={item.name}
                          className="w-[200px] h-[200px]"
                        />
                        <h3
                          className={`font-[Barlow] text-sm mt-3 ${
                            isAwardSelected(item) ? "text-black" : "text-white"
                          } `}
                        >
                          {item.name}
                        </h3>
                        <p
                          className={`${
                            isAwardSelected(item) ? "text-black" : "text-white"
                          }`}
                        >
                          {item.description}
                        </p>
                      </div>
                      {userAwardsAdmin.findIndex(
                        (award: any) => award.name === item.name
                      ) > -1 ? (
                        <div className="w-full justify-end flex">
                          <button
                            className="text-[red] align-end mt-5"
                            onClick={() => {
                              onDeletePress(item);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </div>

                <div className="w-full sm:w-[49%] md:w-[39%] xl:w-[27.5%] flex justify-between mb-8 mt-10 sm:mt-0">
                  <div className="flex items-end w-[47.5%]">
                    <BorderButtonSecondary
                      isLoading={false}
                      text={"Cancel"}
                      onClick={() => {
                        dispatch(setShowAddAwardModal(null));
                      }}
                      childClassName="text-sm"
                    />
                  </div>
                  <div className="flex items-end w-[47.5%]">
                    <BorderButtonSecondary
                      isLoading={awardLoading}
                      text={"Save"}
                      onClick={onSavePress}
                      childClassName="text-sm"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div
        className="absolute top-22 right-16 cursor-pointer"
        onClick={() => {
          dispatch(setShowAddAwardModal(null));
        }}
      >
        <img
          src={Icons.GlowingClose}
          className="h-8 w-8 bg-gray-300 rounded-full dark:bg-transparent"
          alt="close"
        />
      </div>
    </section>
  );
};

export default AddUserAwardModal;
