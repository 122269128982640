import { ButtonWithLoadingAnimationProps } from 'types/Component';
import LottieFiles from 'assets/LottieFiles';
import Lottie from 'lottie-react';
import { useEffect } from 'react';

function AnimatedPrimaryButton({
  text,
  onClick,
  className,
  isLoading,
  fontStyle,
  height,
  childHeight,
  childClassName,
  icon,
  selected,
  tabIndex,
  successState,
  endAnimation,
}: ButtonWithLoadingAnimationProps) {
  useEffect(() => {
    if (successState) {
      setTimeout(() => {
        endAnimation();
      }, 1500); // Adjust duration as needed
    }
  }, [successState]);

  return (
    <div
      tabIndex={tabIndex}
      className={`flex items-center justify-center ${
        className || ''
      } ${isLoading ? 'pointer-events-none' : 'cursor-pointer'} ${
        height || 'h-8'
      }`}
      onClick={onClick}
    >
      <div
        className={`${
          childClassName || 'text-sm sm:text-base'
        } rounded-full flex justify-center items-center font-Play ${
          selected ? "dark:bg-[#002C87] bg-[#fff]" : "dark:bg-[#1D204A] bg-[#fff]"
        } dark:text-white text-[#2b2e48] shadow-lg`}
      >
        {isLoading ? (
            <div className={`${childHeight || 'w-14 h-14'}`}>
            <Lottie animationData={LottieFiles.Loading} loop={true} />
            </div>
        ) : successState ? (
          <div className={`${childHeight || 'w-14 h-14'}`}>
            <Lottie animationData={LottieFiles.CheckMark} loop={false} />
          </div>
        ) : (
          icon || text
        )}
      </div>
    </div>
  );
}

export default AnimatedPrimaryButton;