import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";

export const allErrorHandler = (errorCode: any) => {
  if (errorCode === "field not filled") {
    showToast({
      message: "Invalid input, please fill all fields.",
      type: ToastTypes.ERROR,
    });
  } else if (errorCode === "added successfully") {
    showToast({
      message: "Contact added Successfully!.",
      type: ToastTypes.SUCCESS,
    });
  } else if (errorCode === "updated successfully") {
    showToast({
      message: "Updated Successfully!",
      type: ToastTypes.SUCCESS,
    });
  } else if (errorCode === "deleted successfully") {
    showToast({
      message: "Deleted Successfully!",
      type: ToastTypes.SUCCESS,
    });
  } else if (errorCode === "special words not allowed") {
    showToast({
      message:
        "Invalid characters, Special characters on callsign are not allowed.",
      type: ToastTypes.ERROR,
    });
  }else if(errorCode === "future date") {
    showToast({
      message: "Date and time can't be in the future!",
      type: ToastTypes.WARN,
    });
  } else if(errorCode === "Call sign not available"){
    showToast({
      message: "Call sign not available",
      type: ToastTypes.ERROR,
    });
  } 
  else {
    showToast({
      message: "Something went wrong, please try again.",
      type: ToastTypes.ERROR,
    });
  }
};
