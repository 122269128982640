import { formatBandValue } from 'helpers/Utils';
import React from 'react';

interface DuplicateIndicatorProps {
  band?: string;
  className?: string;
}

export const DuplicateIndicator: React.FC<DuplicateIndicatorProps> = ({ 
  band,
  className = "" 
}) => {
  if (!band) return null;

  return (
    <div className={`bg-gradient-to-r from-[#9B8647] to-[#C7B36D] text-[6px] font-[Barlow] font-semibold text-white px-1 py-[0.7px] rounded-full ${className}`}>
      <div className="px-2 py-[0.7px] text-center text-white text-sm font-bold font-['Play']">
        DUPE | {formatBandValue(band)}
      </div>
    </div>
  );
};