import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { LocationSource } from "components/DefaultLocationSettings";
import { useFormikContext } from "formik";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { useLogBookSettingsForm } from "hooks/useLogBookSettingsForm.hook";
import { useEffect } from "react";
import { LogbookSettingsForm } from "types/Models";

export const LogBookSettingsSaveAction = () => {
  const { submitForm, setValues } = useLogBookSettingsForm();
  const { logbookLoading, selectedLogbook } = useLogbookSetting();

  useEffect(() => {
    if (selectedLogbook) {
      setValues((prev) => ({
        ...prev,
        name: selectedLogbook?.name || "",
        description: selectedLogbook?.description || "",
        logbookStyle: selectedLogbook?.logbookStyle || "",
        defaultLocation: selectedLogbook?.defaultLocation?.id || "",
        radio: selectedLogbook?.radio?.id || "",
        antenna: selectedLogbook?.antenna?.id || "",
        defaultCallSign: selectedLogbook?.defaultCallSign || "",
        callSignType: selectedLogbook?.callSignType || "",
        defaultPower: selectedLogbook?.defaultPower || "",
        contestPower: selectedLogbook?.contestPower || "",
        numberOfTransmitters: selectedLogbook?.numberOfTransmitters || "",
        contestBand: selectedLogbook?.contestBand || "",
        assisted: selectedLogbook?.assisted || "",
        operator: selectedLogbook?.operator || "",
        myParks: selectedLogbook?.myParks || {},
        locationSource: selectedLogbook?.defaultLocation?.locationSource || LocationSource.NONE,

        // WFD Specific
        contestTransmitters: selectedLogbook?.contestTransmitters || "",
        contestClass: selectedLogbook?.contestClass || "",
        contestSection: selectedLogbook?.contestSection || "",
      }));
    }
  }, [selectedLogbook]);

  const saveClickHandler = () => submitForm();

  return (
    <div className="w-full flex justify-center">
      <div className="w-[220px] mt-10">
        <BorderButtonSecondary
          text={`Save Settings`}
          onClick={saveClickHandler}
          className="sm:mb-0 cursor-pointer"
          height="h-8"
          childClassName="text-xs"
          isLoading={logbookLoading}
        />
      </div>
    </div>
  );
};
