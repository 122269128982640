import User from "./user.svg";
import Chart from "./chart.svg";
import Grid from "./grid.svg";
import Log from "./log.svg";
import Setting from "./setting.svg";
import Warning from "./warning.svg";
import Chat from "./chat.svg";
import Logout from "./logout.svg";
import Facebook from "./facebook.svg";
import Google from "./google.svg";
import HeaderSearch from "./headerSearch.svg";
import Avatar from "./avatar.svg";
import ChevronDown from "./chevron-down.svg";
import GradientRectangle from "./gradientRectangle.svg";
import GradientRectanglePic from "./gradientRectanglePic.png";
import GradientRectangle2 from "./gradientRectangle-2.svg";
import Chatbox from "./chatbox.svg";
import EuropeAward from "./europe.svg";
import AfricaAward from "./africa.svg";
import NorthAmericaAward from "./northAmerica.svg";
import SouthAmericaAward from "./southAmerica.svg";
import OceaniaAward from "./oceania.svg";
import AsiaAward from "./asia.svg";
import EditPencil from "./editPencil.svg";
import GlowGlobe from "./glowGlobe.svg";
import GlowMap from "./glowMap.svg";
import MapHome from "./mapHome.svg";
import Certificate01 from "./certificate-01.svg";
import MainLogo from "./mainLogo.svg";
import MainLogoDark from './mainLogoDark.png';
import SubscriptionBasic from "./subscriptionBasic.svg";
import SubscriptionPremium from "./subscriptionPremium.svg";
import SubscriptionVip from "./subscriptionVip.svg";
import Wallet from "./wallet.svg";
import Location from "./location.svg";
import Search from "./search.svg";
import Sound from "./sound.svg";
import TopChart from "./topChart.svg";
import PricingBg from "./pricingBg.svg";
import GlowingClose from "./glowingClose.svg";
import SubCompBg from "./subCompBg.svg";
import ChatBubbles from "./chatBubbles.svg";
import LogInOutline from "./logInOutline.svg";
import Members from "./members.svg";
import BorderedSocialTwitterIcon from "./borderedSocialTwitterIcon.svg";
import BorderedSocialLinkedInIcon from "./borderedSocialLinkedInIcon.svg";
import BorderedSocialInstaIcon from "./borderedSocialInstaIcon.svg";
import BorderedSocialFacebookIcon from "./borderedSocialFacebookIcon.svg";
import Directory from "./directory.svg";
import Globe from "./globe.svg";
import TwitterX from "./twitter.svg";
import TwitterXWhite from "./twitterWhite.svg";
import UploadIcon from "./upload.svg";
import FileIcon from "./file.svg";
import Contest from "./contest.svg";
import Jupiter from "./jupiter.svg";
import ClipBoardTime from "./clipBoardTime.svg";
import LocationTimer from "./locationTimer.svg";
import ContactBook from "./contactBook.svg";
import ContestRule from "./rule.svg";

import Confirmed from "./confirmed.svg";
import HeaderNotification from "./headerNotification.svg";

import Frequency from "./frequency.svg";
import Distance from "./distance.svg";

import Mode from "./mode.svg";
import Freq from "./freq.svg";
import Band from "./band.svg";
import ContactDistance from "./contactDistance.svg";
import Power from "./power.svg";
import LeaderBg from "./leaderBg.svg";
import Spotting from "./spotting.svg";

import SpotGrid from "./gridIcon.svg";
import SpotFreq from "./spotFreq.svg";
import SpotTime from "./time.svg";

import View from "./view.svg";
import Info from "./info.svg";

import NumberIcon from "./number.svg";
import DoubleArrow from "./arrow.svg";
import CalenderIcon from "./cal.svg";
import CompassIcon from "./dir.svg";
import PotaIcon from "./pota.svg";
import PotaIconAdd from "./potaIcon.svg";
import PotaClose from "./potaClose.svg";
import CloseCircle from "./closeCircle.svg";
import CloseWhite from "./closeIconWhite.svg";
import CrossIcon from "./cross.svg";
import SettingsIcon from "./settings.svg";
import BackIcon from "./back.svg";
import PotaTree from "./potaTree.svg";
import ClockIcon from "./clock.svg";
import AntennaIcon from "./antena.svg";
import PinIcon from "./pin.svg";
import RadioIcon from "./radio.svg";
import SingleTreeIcon from "./singleTree.svg";
import clockWhiteIcon from "./clockWhite.svg";
import notesWhite from "./notes.svg";
import parkWhite from "./park.svg";
import parkToParkWhite from "./parkToPark.svg";
import powerWhite from "./potaPower.svg";
import tagsWhite from "./tags.svg";
import SendIcon from "./send.svg";
import DistanceIcon from "./distanc.svg";
import LocationPinIcon from "./locationPin.svg";
import QsoPerMinIcon from "./qsoPerMin.svg";
import RankIcon from "./rank.svg";
import GridsIcon from "./grids.svg";

import Import from './import.svg';
import Export from './export.svg';
import edit from './edit.svg';
import FilterIcon from './filter.svg';
import ColumnsIcon from './columns.svg';
import ExchangeIcon from './exchange.svg'
import ExchangeVerticalIcon from './exchangeVertical.svg';
import ExchangeVerticalCircularIcon from './exchangeVerticalCircular.svg';
import NotepadEditIcon from './notepadEdit.svg';
import TrashIcon from './trash.svg';
import ReversedSignal from './reversedSignal.svg'
import Signal from './signal.svg'
import Copy from './copy.svg'
import External from './external.svg'
import ExternalDark from './externalDark.svg'
import CopySpot from './copySpot.svg'
import Comments from './comments.svg'
import IOTAMarker from "./IOTAMarker.svg";
import POTAMarker from "./POTAMarker.svg";
import SOTAMarker from "./SOTAMarker.svg";
import WWFFMarker from "./WWFFMarker.svg";

import IotaLogo from "./iotaLogo.svg";
import SotaLogo from "./sotaLogo.svg";
import WwffLogo from "./wwffLogo.svg";

import WwffAutoSpot from "./wwffAutospot.svg";
import SotaAutoSpot from "./sotaAutospot.svg";
import IotaAutoSpot from "./iotaAutospot.svg";

import ActivityIOTA from "./activityIOTA.svg";
import ActivityPOTA from "./activityPOTA.svg";
import ActivitySOTA from "./activitySOTA.svg";
import ActivityWWFF from "./activityWWFF.svg";

import SotaIcon from "./sotaIcon.svg";
import SotaIconColor from "./sotaIconColor.svg";

import IotaIcon from "./iotaIcon.svg";
import WwffIcon from "./wwffIcon.svg";

import IotaColor from "./iotaColor.svg";
import WwffColor from "./wwffColor.svg";
import SotaColor from "./sotaColor.svg";
import PotaColor from "./potaColor.svg";

import LiveIcon from "./live.svg";
import MapIcon from "./map.svg";
import EditPenIcon from "./editPen.svg";
import SaveIcon from "./save.svg";
import MapCyan from "./mapCyan.svg";
import MyLocation from "./myLocation.svg";
import ParkCyan from "./parkCyan.svg";
import SearchBarIcon from "./searchicon.svg";
import StreetIcon from "./street.svg";
import LogbookIcon from "./logbook.svg";
import ContestIcon from "./contesticon.svg";
import ListIcon from "./list.svg";
import LeaderBoardWarning from "./leaderBoardWarning.svg";
import RedWarning from "./redwarning.svg";
import Apple from "./apple.svg";
import MarkerIcon from "./contactMarker.svg";
import AddLogBook from "./add_logbook.svg";
import ImportFile from "./file_import.svg";
import NewPota from "./new_pota.svg";
import NewLogbook from "./newlogbook.svg";

import MySotaIcon from "./MySota.svg";
import MyWwffIcon from "./MyWwff.svg";
import MyIotaIcon from "./MyIota.svg";

import WtoW from "./WtoW.svg";
import StoS from "./StoS.svg";
import ItoI from "./ItoI.svg";

import SunIcon from "./sun.svg";
import MoonIcon from "./moon.svg";
import SpeedometerIcon from "./speedometer.svg";

import YoutubeIcon from "./youtube.svg";
import LeadersIcon from "./leaders.svg";
import LivesIcon from "./lives.svg";
import StatisticsIcon from "./statistics.svg";

import Header from "./header.svg";
import LeftHead from "./left-head.svg";
import RightHead from "./right-head.svg";
import SubHeader from "./sub-header.svg";

import Pin1 from "./pin1.svg";
import Pin2 from "./pin2.svg";
import Pin3 from "./pin3.svg";
import Pin4 from "./pin4.svg";
import Pin5 from "./pin5.svg";

const Icons = {
  WtoW,
  StoS,
  ItoI,
  MySotaIcon,
  MyWwffIcon,
  MyIotaIcon,
  CloseWhite,
  CloseCircle,
  LiveIcon,
  IotaColor,
  WwffColor,
  SotaColor,
  PotaColor,
  PotaIconAdd,
  SotaIcon,
  IotaIcon,
  WwffIcon,
  SotaIconColor,
  Apple,
  PricingBg: PricingBg,
  MainLogo: MainLogo,
  ChevronDown: ChevronDown,
  GradientRectangle: GradientRectangle,
  GradientRectangle2: GradientRectangle2,
  GradientRectanglePic: GradientRectanglePic,
  HeaderSearch: HeaderSearch,
  Avatar: Avatar,
  User: User,
  Chart: Chart,
  Log: Log,
  Grid: Grid,
  Setting: Setting,
  Warning: Warning,
  Chat: Chat,
  Logout: Logout,
  Facebook: Facebook,
  Google: Google,
  Chatbox: Chatbox,
  AfricaAward: AfricaAward,
  EuropeAward: EuropeAward,
  NorthAmericaAward: NorthAmericaAward,
  SouthAmericaAward: SouthAmericaAward,
  OceaniaAward: OceaniaAward,
  AsiaAward: AsiaAward,
  EditPencil: EditPencil,
  GlowGlobe: GlowGlobe,
  Globe: Globe,
  GlowMap: GlowMap,
  MapHome: MapHome,
  Certificate01: Certificate01,
  SubscriptionBasic: SubscriptionBasic,
  SubscriptionPremium: SubscriptionPremium,
  SubscriptionVip: SubscriptionVip,
  Wallet: Wallet,
  Location: Location,
  Sound: Sound,
  Search: Search,
  TopChart: TopChart,
  GlowingClose: GlowingClose,
  SubCompBg: SubCompBg,
  ChatBubbles: ChatBubbles,
  LogInOutline: LogInOutline,
  Members: Members,
  BorderedSocialTwitterIcon: BorderedSocialTwitterIcon,
  BorderedSocialLinkedInIcon: BorderedSocialLinkedInIcon,
  BorderedSocialInstaIcon: BorderedSocialInstaIcon,
  BorderedSocialFacebookIcon: BorderedSocialFacebookIcon,
  Directory: Directory,
  TwitterX: TwitterX,
  TwitterXWhite: TwitterXWhite,
  UploadIcon: UploadIcon,
  FileIcon: FileIcon,
  Contest: Contest,
  Jupiter: Jupiter,
  ClipBoardTime: ClipBoardTime,
  LocationTimer: LocationTimer,
  ContactBook: ContactBook,
  ContestRule: ContestRule,
  Confirmed: Confirmed,
  HeaderNotification: HeaderNotification,
  Frequency: Frequency,
  Distance: Distance,
  Mode: Mode,
  Freq: Freq,
  Band: Band,
  ContactDistance: ContactDistance,
  Power: Power,
  LeaderBg: LeaderBg,
  Spotting: Spotting,
  SpotGrid: SpotGrid,
  SpotFreq: SpotFreq,
  SpotTime: SpotTime,
  View: View,
  Info: Info,
  NumberIcon,
  DoubleArrow,
  CalenderIcon,
  CompassIcon,
  PotaIcon,
  PotaClose,
  CrossIcon,
  SettingsIcon,
  BackIcon,
  PotaTree,
  ClockIcon,
  AntennaIcon,
  PinIcon,
  RadioIcon,
  SingleTreeIcon,
  clockWhiteIcon,
  notesWhite,
  parkWhite,
  parkToParkWhite,
  powerWhite,
  tagsWhite,
  SendIcon,
  DistanceIcon,
  LocationPinIcon,
  QsoPerMinIcon,
  RankIcon,
  GridsIcon,
  MapIcon,
  EditPenIcon,
  SaveIcon,
  MapCyan,
  ParkCyan,
  MyLocation,
  SearchBarIcon,
  StreetIcon,
  LogbookIcon,
  ContestIcon,
  ListIcon,
  LeaderBoardWarning,
  MarkerIcon,
  IOTAMarker,
  POTAMarker,
  SOTAMarker,
  WWFFMarker,
  IotaLogo,
  SotaLogo,
  WwffLogo,
  WwffAutoSpot,
  SotaAutoSpot,
  IotaAutoSpot,
  ActivityIOTA,
  ActivityPOTA,
  ActivitySOTA,
  ActivityWWFF,
  AddLogBook,
  ImportFile,
  NewPota,
  NewLogbook,
  Import,
  Export,
  edit,
  RedWarning,
  FilterIcon,
  ColumnsIcon,
  ExchangeIcon,
  ExchangeVerticalIcon,
  ExchangeVerticalCircularIcon,
  NotepadEditIcon,
  TrashIcon,
  ReversedSignal,
  Signal,
  Copy,
  External,
  ExternalDark,
  CopySpot,
  Comments,
  MainLogoDark,
  SunIcon,
  MoonIcon,
  YoutubeIcon,
  LeadersIcon,
  LivesIcon,
  StatisticsIcon,
  Header,
  LeftHead,
  RightHead,
  SubHeader,
  Pin1,
  Pin2,
  Pin3,
  Pin4,
  Pin5,
  SpeedometerIcon
};

export default Icons;
