import Icons from "assets/Icons";
import Images from "assets/Images";
import CountryDxccFlag from "components/CountryDxccFlag";
import useTheme from "hooks/useTheme.hook";

const WINNERS: any = {
  first: {
    place: "1st",
    color: "#efbf04",
    bg: Images.FirstImg,
  },
  second: {
    place: "2nd",
    color: "#c4c4c4",
    bg: Images.SecondImg,
  },
  third: {
    place: "3rd",
    color: "#967444",
    bg: Images.ThirdImg,
  },
};

function TopPlacement({ place, data, isWFDContest, onClick }: { place: string; data: any; isWFDContest?: boolean; onClick?: () => void }) {
  const { theme } = useTheme();
  const primaryColor = WINNERS[place].color;
  const placeName = WINNERS[place].place;
  const bg = WINNERS[place].bg;

  if (!primaryColor && !placeName && !bg) return null;  

  return (
    <div
      className="relative flex flex-col w-full md:w-1/3 gap-1 justify-center cursor-pointer dark:text-[#fff] text-[#2b2e48] border-l-4 rounded-lg"
      style={{ borderLeftColor: primaryColor }}
      onClick={onClick}
    >
      <img
        src={bg}
        className="absolute top-0 right-0 z-0 h-[160px] w-[160px]"
        alt="background"
      />
      <div className={`p-6 w-full h-full flex flex-col gap-4 ${ theme === 'dark' ? 'glassmorphism': 'bg-white'} `}>
        <div className="flex flex-row gap-6">
          <img
            src={ data?.profilePic ? data?.profilePic : Icons.User }
            alt=""
            className={`w-[90px] h-[90px] rounded-full ${ (theme !=='dark' && !data?.profilePic) ? 'filter invert': '' }`}
          />
          <div className="flex flex-col font-['Urbanist'] gap-0">
            <div className="text-3xl font-bold">
              <span style={{ color: primaryColor }}>{placeName}</span> Place
            </div>
            <div className="dark:text-white text-[#2b2e48] font-Barlow font-semibold text-lg mt-2">
              {`${data?.firstName} ${data?.lastName}`}
            </div>
            <div
              className="font-Play font-medium text-base"
              style={{ color: primaryColor }}
            >
              {data?.callSign}
            </div>
            <div className="flex flex-row dark:text-white text-[#2b2e48] font-Barlow font-semibold text-md mt-2">
              <div className=" inline-block min-w-[30px]">
                <CountryDxccFlag countryCode={data?.country} flagCode={""} />
              </div>
              {data?.country}
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-2 justify-center items-center">
          <div className="flex justify-center items-center dark:bg-[#fff] bg-[#f1f3f4] dark:bg-opacity-10 dark:text-[#17F9DA] text-[#1e40af] rounded-full px-3 py-1 font-Barlow text-xs">
            {data?.numberOfPoints} QSOs
          </div>
          {data?.contestMultiplier ? (
            <div className="flex justify-center items-center dark:bg-[#fff] bg-[#f1f3f4] dark:bg-opacity-10 dark:text-[#17F9DA] text-[#1e40af] rounded-full px-3 py-1 font-Barlow text-xs">
              {data?.contestMultiplier?.toFixed(0)}% { isWFDContest ? "Objectives" : "Multipliers" }
            </div>
          ) : null}
          <div className="flex justify-center items-center dark:bg-[#fff] bg-[#f1f3f4] dark:bg-opacity-10 dark:text-[#17F9DA] text-[#1e40af] rounded-full px-3 py-1 font-Barlow text-xs">
            { isWFDContest ?  data?.contestTotalPoints : data?.numberOfPoints} Points
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopPlacement;
