import React, { FC, useEffect, useState } from "react";
import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { useSelector } from "react-redux";
import { UserProfile } from "types/Models";
import { useEditContact } from "hooks/useEditContact.hook";
import { useFormikContext } from "formik";

type ModalProps = {
  onClose: () => void;
};

export const CallSignModal: FC<ModalProps> = ({ onClose }) => {
  const userProfile: UserProfile | null = useSelector(getUserProfileSelector);
  const { editContactModalData } = useEditContact();
  const { values, setValues } = useFormikContext<any>();
  
  const [logbookCallSign, setLogbookCallSign] = useState<string>(
    editContactModalData?.myCallSign || userProfile?.callSign || ""
  );

  const onSaveClickHandler = () => {
    setValues((prev: any) => ({
      ...prev,
      myCallSign: logbookCallSign,
    }));

    onClose();
  };

  useEffect(() => {
    if (values?.myCallSign) {
      setLogbookCallSign(values?.myCallSign);
    }
  }, [values?.myCallSign]);

  return (
    <section className="absolute w-full h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism py-[5%] px-[2%] md:px-[20%] lg:px-[25%] overflow-scroll">
      <div className=" w-full ">
        <div className="w-full ">
          <div className="flex flex-col w-full dark:bg-[#151541] bg-white dark:text-white text-[#2b2e48] font-[Play] relative mt-20 border border-[#451836] px-[10%] py-[10%] gap-8">
            <div className="flex w-full flex-wrap relative">
              <LogbookSettingDropdown
                title="My Callsign:"
                input
                placeholder="Callsign"
                value={logbookCallSign}
                setValue={(value) => {
                  if (value) {
                    setLogbookCallSign(value.toUpperCase());
                  } else {
                    setLogbookCallSign(value);
                  }
                }}
                className="w-[100%] sm:w-[100%] md:w-[80%]"
              />
              {/* <LogbookSettingDropdown
                list={CallSignTypes}
                title="Callsign Type:"
                value={callSignType}
                setValue={setCallSignType}
                className="w-[100%] sm:w-[100%] md:w-[80%]"
              /> */}
            </div>

            <div className="flex w-full justify-center">
              <BorderButtonSecondary
                // isLoading={loading || logbookSettingLoading}
                text={"Save"}
                onClick={onSaveClickHandler}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
              />
            </div>
            <div
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer"
              onClick={onClose}
            >
              <img src={Icons.GlowingClose} className="h-8 w-8 invert dark:filter-none dark:invert-0" alt="close" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
