import Icons from "assets/Icons";
import { NavMenuProps } from "types/Navigation";
import {
  MY_PROFILE_ROUTE,
  HOME_ROUTE,
  LOG_CONTACT_ROUTE,
  LEADERS_BOARD,
  SETTING_ROUTE,
  MEMBERS_DIRECTORY,
  CONTEST,
  SPOTTING,
  REPORT_BUG,
  ACTIVITIES,
  USER_SETTINGS,
  // CONTACT_US_ROUTE,
  // HELP_CENTER_ROUTE,
} from "./Links";

const navMenus: NavMenuProps[] = [
  {
    title: "Home",
    icon: Icons.Grid,
    link: HOME_ROUTE,
  },
  {
    title: "My Profile",
    icon: Icons.User,
    link: MY_PROFILE_ROUTE,
  },
  {
    title: "Log Contacts",
    icon: Icons.Log,
    link: LOG_CONTACT_ROUTE,
  },
  {
    title: "Activities",
    icon: Icons.MapIcon,
    link: ACTIVITIES,
  },
  {
    title: "Spotting",
    icon: Icons.Spotting,
    link: SPOTTING,
  },
  {
    title: "Leaderboard",
    icon: Icons.Chart,
    link: LEADERS_BOARD,
  },
  {
    title: "Contest",
    icon: Icons.Contest,
    link: CONTEST,
  },
  {
    title: "Community Chat",
    icon: Icons.ChatBubbles,
    link: "https://community.worldradioleague.com/session/sso?return_path=/",
    redirect: true,
  },
];

const navMenusSetting: NavMenuProps[] = [
  {
    title: "Report a Bug",
    icon: Icons.Chat,
    link: REPORT_BUG,
  },
  {
    title: "Send Feedback",
    icon: Icons.Warning,
    link: "https://community.worldradioleague.com/c/feature-requests/",
    redirect: true,
  },
  // {
  //   title: "Help Center",
  //   icon: Icons.Warning,
  //   link: HELP_CENTER_ROUTE,
  // },
  // {
  //   title: "Contact us",
  //   icon: Icons.Chat,
  //   link: CONTACT_US_ROUTE,
  // },
  {
    title: "Log out",
    icon: Icons.Logout,
    color: "text-red-one",
  },
];

export const navMenuLogIn: NavMenuProps = {
  title: "Login",
  icon: Icons.LogInOutline,
  link: "/login",
};

export const navMenuMembersDirectory: NavMenuProps = {
  title: "Find Members Near Me",
  icon: Icons.Directory,
  link: MEMBERS_DIRECTORY,
};

export const navMenuMembership: NavMenuProps = {
  title: "Become a Member",
  icon: Icons.Members,
  link: SETTING_ROUTE,
};

export const navMenuMembershipStatus: NavMenuProps = {
  title: "Manage Subscription",
  icon: Icons.Members,
  link: SETTING_ROUTE,
};

export const headerDropdownMenus: any = [
  {
    title: "My Profile",
    link: MY_PROFILE_ROUTE,
  },
  {
    title: "Log Contacts",
    link: LOG_CONTACT_ROUTE,
  },
  {
    title: "Manage Subscription",
    link: SETTING_ROUTE,
  },
  {
    title: "Settings",
    link: USER_SETTINGS,
  }
];

export const headerDropdownLogoutMenu = {
  title: "Log Out",
  color: "",
};

export const headerDropdownUserSettings: NavMenuProps = {
  title: "Settings",
  icon: Icons.Setting,
  link: USER_SETTINGS,
};

export const headerDropdown = {
  title: "Become a Member",
  link: SETTING_ROUTE,
};

const profileTabs = ["Logbook", "Biography", "Awards"];
const logbookTabs = ["Log Contacts", "Logbook Settings"];
const stationTabs = ["My Radios", "My Antennas", "Station Photos"];

const contestTabs = [
  {
    text: "Home",
    icon: Icons.Grid,
    link: "contest_home",
  },
  {
    text: "Leaderboard",
    link: "real_time_leaderboard",
    icon: Icons.ClipBoardTime,
  },
  {
    text: "Statistics",
    link: "contest_statistics",
    icon: Icons.Chart,
  },
  {
    text: "Map",
    link: "real_time_map",
    icon: Icons.LocationTimer,
  },
  {
    text: "Rules",
    icon: Icons.ContestRule,
    link: "contest_rules",
  },
  {
    text: "My Logbook",
    link: "log_contest_contacts",
    icon: Icons.ContactBook,
  },
];

export {
  navMenus,
  navMenusSetting,
  profileTabs,
  contestTabs,
  logbookTabs,
  stationTabs,
};
