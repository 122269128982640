import ButtonGlow from "components/Button/ButtonGlow";
import { contestTabs } from "constants/NavMenus";
import { TabNavGlowProps } from "types/Component";
import { CONTEST_STATUS } from "types/Functions";

function TabNavGlowContest({
  selectedIndex,
  setSelectedIndex,
  isUserEnrolled,
  contestStatus,
  contestantDetailLoading,
}: TabNavGlowProps) {
  const getInfoMessage = (itemLink: string) => {
    if (!isUserEnrolled && itemLink === "log_contest_contacts") {
      if (contestStatus === CONTEST_STATUS.PASSED) {
        return "Contest already completed";
      } else if (
        contestantDetailLoading &&
        itemLink === "log_contest_contacts"
      ) {
        return "Loading...";
      } else {
        return "Please enroll to participate in the contest.";
      }
    } else {
      if (contestStatus === CONTEST_STATUS.UPCOMING) {
        return "Contest not started yet";
      } else if (contestStatus === CONTEST_STATUS.PASSED) {
        return "";
      }
      return "";
    }
  };

  const filterHomeTab = (tab: any) => {
    return true;
    if (tab.link === "contest_home" && contestStatus === CONTEST_STATUS.UPCOMING) {
      return false;
    }

    return true;
  };

  return (
    <div className="flex justify-start md:justify-evenly mt-6 overflow-y-hidden w-full">
      {contestTabs.filter(filterHomeTab).map((item: any, index: number) => {
        return (
          <ButtonGlow
            key={index}
            disabled={!isUserEnrolled && item.link === "log_contest_contacts"}
            info={getInfoMessage(item.link)}
            selected={selectedIndex === item?.link}
            text={item?.text}
            onClick={() => setSelectedIndex(item?.link)}
            icon={item?.icon}
            showLoading={
              contestantDetailLoading && item.link === "log_contest_contacts"
            }
          />
        );
      })}
    </div>
  );
}

export default TabNavGlowContest;
