import Icons from "assets/Icons";
import BarChartComponent from "components/Charts/BarChart/BarChartBasic";
import { db } from "firebase-config";
import { doc, getDoc } from "firebase/firestore";
import useContest from "hooks/contest.hook";
import { useEffect, useState } from "react";

function ContestStatistics() {
  const { contestDetail } = useContest();
  const [userBandsLabels, setUserBandsLabels] = useState<string[]>([]);
  const [userBandsData, setUserBandsData] = useState<any[]>([]);
  const [userModesLabels, setUserModesLabels] = useState<string[]>([]);
  const [userModesData, setUserModesData] = useState<any[]>([]);
  const [userContactByHourLabels, setUserContactByHourLabels] = useState<string[]>([]);
  const [userContactByHourData, setUserContactByHourData] = useState<any[]>([]);

  useEffect(() => {
    const fetchContestSummary = async (contestId: string | undefined) => {
      if (!contestId) return;
      try {
        const docRef = doc(
          db,
          "Analytics",
          "Contest",
          contestId,
          "ContestSummary"
        );
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const bandsLabels = data?.bandsLabels || [];
          const bandsData = data?.bandsData || [];
          const modesLabels = data?.modesLabels || [];
          const modesData = data?.modesData || [];
          const contactByHourLabels = data?.contactByHourLabels || [];
          const contactByHourData = data?.contactByHourData || [];

          setUserBandsLabels(bandsLabels);
          setUserBandsData(bandsData);
          setUserModesLabels(modesLabels);
          setUserModesData(modesData);
          setUserContactByHourLabels(contactByHourLabels);
          setUserContactByHourData(contactByHourData);
        }
      } catch (err) {
        console.error("Error fetching contest summary:", err);
      }
    };

    // Fetch immediately when `contestDetail.id` changes
    fetchContestSummary(contestDetail?.id);

    const intervalId = setInterval(() => {
      fetchContestSummary(contestDetail?.id);
    }, 60 * 1000); // 1.5 minutes in milliseconds

    return () => {
      clearInterval(intervalId);
    };
  }, [contestDetail]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex flex-row items-center dark:text-white text-[#2b2e48] gap-4 text-xl font-bold font-Play uppercase">
        <img src={Icons.StatisticsIcon} alt="" />
        Statistics
      </div>

      <div className="mt-4 overflow-y-hidden">
        <div className="w-full">
          <div className="flex flex-col w-full p-10 dark:bg-[#151541] bg-white mt-2.5">
            <div className="flex flex-col md:lex-row w-full gap-4">
              <div className="flex-1">
                <div className="dark:text-white text-[#2b2e48] font-Barlow text-xl ml-10">
                  Bands
                </div>
                <BarChartComponent
                  labels={userBandsLabels}
                  data={userBandsData}
                />
              </div>
              <div className="flex-1">
                <div className="dark:text-white text-[#2b2e48] font-Barlow text-xl ml-10">
                  Modes
                </div>
                <BarChartComponent
                  labels={userModesLabels}
                  data={userModesData}
                />
              </div>
            </div>
            <div className="flex flex-col w-full items-start justify-start">
              <div className="dark:text-white text-[#2b2e48] font-Barlow text-xl ml-10">
                QSO by Hour
              </div>
              <BarChartComponent
                labels={userContactByHourLabels}
                data={userContactByHourData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContestStatistics;
