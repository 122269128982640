import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import Header from "components/Header";
import TableWrapperContacts from "components/Table/TableWrapperContacts";
import useLogBook from "hooks/logBook.hook";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { globalNavigate } from "routes/GlobalRoutes";
import Globe from "react-globe.gl";
import ReactResizeDetector from "react-resize-detector";
import useGlobe from "hooks/globe.hook";
import ContactMap from "components/Map/ContactMap";
import { IoCaretBack, IoCaretForward } from "react-icons/io5";
import RegionDropDownPicker from "components/DropDown/RegionDropDownPicker";
import CountryDropDownPicker from "components/DropDown/CountryDropDownPicker";
import { Modes, ModesFilter } from "constants/ArrayVariables";
import InputWithButton from "components/Inputs/InputWithButton";
import FilterDropDown from "components/DropDown/DropDown/FilterDropDown";
import { FiMenu } from "react-icons/fi";
import { SlLocationPin } from "react-icons/sl";
import BorderDisplayComp from "components/Button/BorderDisplayComp";
import { getStateShortCode } from "helpers/Utils";

const RecentContacts = () => {
  const [isWebGLEnabled, setIsWebGLEnabled] = useState(false);

  const {
    getPaginatedContacts,
    getPaginatedContactsWithPagination,
    allPaginatedContacts,
    isLoading,
    filter,
    setFilters,
    state,
    setState,
    country,
    setCountry,
    callSign,
    setCallSign,
  } = useLogBook();
  const [searchParams] = useSearchParams();
  const {
    logBookArc,
    OPACITY,
    hoverArc,
    setHoverArc,
    selectedGlobe,
    setSelectedGlobe,
  } = useGlobe();
  const { getAllContactData } = useLogBook();

  useEffect(() => {
    getAllContactData();
    getPaginatedContacts(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchParams.get("tab") === "globe") {
      setSelectedGlobe(true);
    } else if (searchParams.get("tab") === "map") {
      setSelectedGlobe(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("tab")]);

  useEffect(() => {
    const canvas = document.createElement("canvas");
    const context =
      canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
    if (context) {
      setIsWebGLEnabled(true);
    }
  }, []);

  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full flex items-center flex-col">
      <Header title="Most Recent Contacts" />
      <div className="p-3 md:p-7 pb-10 dashboardWrapper overflow-x-hidden w-full">
        <div className="flex mb-4">
          <div className="w-[120px] mr-3">
            <BorderButtonSecondary
              text={`List View`}
              onClick={() => globalNavigate("/most-recent-contacts?tab=list")}
              className="sm:mb-0"
              icon={<FiMenu color="#fff" className="mr-2 invert dark:filter-none dark:invert-0" />}
              selected={searchParams.get("tab") === "list"}
            />
          </div>
          <div className="w-[120px] mr-3">
            <BorderButtonSecondary
              text={`Globe View`}
              onClick={() => globalNavigate("/most-recent-contacts?tab=globe")}
              className="sm:mb-0"
              icon={<img src={Icons.Globe} alt="" className="mr-2 invert dark:filter-none dark:invert-0" />}
              selected={searchParams.get("tab") === "globe"}
            />
          </div>
          <div className="w-[120px] mr-3">
            <BorderButtonSecondary
              text={`Map View`}
              onClick={() => globalNavigate("/most-recent-contacts?tab=map")}
              className="sm:mb-0"
              icon={<SlLocationPin color="#fff" className="mr-2 invert dark:filter-none dark:invert-0" />}
              selected={searchParams.get("tab") === "map"}
            />
          </div>
        </div>
        {searchParams.get("tab") === "globe" ? (
          <div className="w-full flex justify-center items-center">
            {isWebGLEnabled ? (
              <ReactResizeDetector handleWidth>
                {({ width }) => {
                  return (
                    <Globe
                      globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
                      arcsData={logBookArc.slice(0, 500)}
                      arcDashLength={() => Math.random()}
                      arcDashGap={() => Math.random()}
                      arcDashAnimateTime={() => 4000}
                      width={width ? width : 650}
                      height={width ? width : 650}
                      backgroundColor="rgba(0,0,0,0)"
                      arcColor={(d: any) => {
                        const op = !hoverArc
                          ? OPACITY
                          : d === hoverArc
                          ? 0.9
                          : OPACITY / 4;
                        return [
                          `rgba(207, 9, 113, ${op})`,
                          `rgba(73, 103, 128, ${op})`,
                          `rgba(55, 164, 186, ${op})`,
                        ];
                      }}
                      onArcHover={(d: any) => {
                        setHoverArc(d);
                      }}
                      // pointsData={logBookArc}
                      pointColor={() => "orange"}
                      pointAltitude={0}
                      pointRadius={0.8}
                      pointsMerge={true}
                      arcStroke={0.5}
                    />
                  );
                }}
              </ReactResizeDetector>
            ) : null}
          </div>
        ) : searchParams.get("tab") === "map" ? (
          <ContactMap
            allContactMode
            className="w-full h-[calc(100%-22px)]"
            containerClassName="h-full w-full relative"
            setHoverArc={setHoverArc}
            limit={200}
            restrictBounds={false}
            shouldFitBounds={false}
          />
        ) : (
          <div className="w-full dark:bg-[#0B0B38] bg-white h-fit mb-10 sm:mb-0">
            <div className="w-full border-b border-solid border-b-white py-[15px] px-[24px]">
              <div className="flex items-center">
                <img className="h-5 w-5 invert dark:filter-none dark:invert-0" src={Icons.TopChart} alt="icon" />
                <p className="dark:text-white text-[#2b2e48]font-[play] font-bold text-base ml-[15px]">
                  MOST RECENT <span className="text-[#17F9DA]">CONTACTS</span>
                </p>
              </div>
              <div className="flex justify-between flex-wrap mt-3 w-full">
                <InputWithButton
                  text={callSign}
                  value={callSign.toUpperCase()}
                  setText={(e) => {
                    const value = e.target.value.toUpperCase();
                    setCallSign(value);
                    if (setFilters && e.target?.value?.length) {
                      setFilters((prev) => {
                        return { ...prev, callSign: value };
                      });
                    } else if (setFilters && !e.target?.value?.length) {
                      setFilters((prev) => {
                        return { ...prev, callSign: "" };
                      });
                    }
                  }}
                  placeHolder="Search by Callsign or Name"
                  width="w-[47.5%] sm:w-[23.5%] md:w-[25%]"
                  btnTxt="Search"
                  btnWidth={"w-20"}
                  onBtnClick={() => {
                    if (filter) {
                      getPaginatedContacts(filter);
                    }
                  }}
                />
                <FilterDropDown
                  placeHolder=""
                  list={ModesFilter}
                  onChange={(e: any) => {
                    getPaginatedContacts({ ...filter, mode: e.target.value });
                    if (setFilters && e.target?.value?.length) {
                      setFilters((prev) => {
                        return { ...prev, mode: e.target.value };
                      });
                    } else if (setFilters && !e.target?.value?.length) {
                      setFilters((prev) => {
                        return { ...prev, mode: "" };
                      });
                    }
                  }}
                  value={filter?.mode || ""}
                  width={"w-[47.5%] sm:w-[23.5%] md:w-[22%]"}
                  className=""
                />
                <div className="w-[47.5%] sm:w-[23.5%] md:w-[23%]">
                  <CountryDropDownPicker
                    setText={(txt) => {
                      getPaginatedContacts({
                        ...filter,
                        country: txt,
                        state: "",
                      });
                      if (setFilters && txt?.length) {
                        setFilters((prev) => {
                          return { ...prev, country: txt, state: "" };
                        });
                      } else if (setFilters && !txt?.length) {
                        setFilters((prev) => {
                          return { ...prev, country: "", state: "" };
                        });
                      }
                      setCountry(txt);
                    }}
                    name="country"
                    title="Their Country"
                    info="Country of the other operator."
                    placeHolder="Country"
                    value={country}
                    className={"mt-3 sm:mt-0"}
                    tabindex={7}
                    error={undefined}
                  />
                </div>
                <div className="w-[47.5%] sm:w-[23.5%] md:w-[22%]">
                  {country && country?.length ? (
                    <RegionDropDownPicker
                      setText={(txt) => {
                        getPaginatedContacts({
                          ...filter,
                          state: getStateShortCode(txt),
                        });
                        if (setFilters && txt?.length) {
                          setFilters((prev) => {
                            return { ...prev, state: txt };
                          });
                        } else if (setFilters && !txt?.length) {
                          setFilters((prev) => {
                            return { ...prev, state: "" };
                          });
                        }
                        setState(txt);
                      }}
                      name="state"
                      title="Their State"
                      info="State of the other operator."
                      placeHolder="State"
                      tabindex={8}
                      className={"mt-3 sm:mt-0"}
                      value={state}
                      error={undefined}
                      country={country}
                    />
                  ) : null}
                </div>
              </div>
            </div>
            <TableWrapperContacts
              isLoading={isLoading}
              list={
                allPaginatedContacts?.contacts
                  ? allPaginatedContacts?.contacts
                  : []
              }
            />
            {!isLoading &&
            allPaginatedContacts &&
            allPaginatedContacts?.count ? (
              <div className="flex justify-between items-center w-full pb-7 px-[20px]">
                {allPaginatedContacts?.page *
                allPaginatedContacts?.contacts?.length ? (
                  <p className="font-[Barlow] text-xs text-white">
                    Showing{" "}
                    {((allPaginatedContacts?.page || 1) - 1) * 50 + 1}
                    {" - "}
                    {allPaginatedContacts?.count &&
                    allPaginatedContacts?.page * 50 >= allPaginatedContacts?.count
                      ? allPaginatedContacts?.count
                      : allPaginatedContacts?.page * 50}{" "}
                    of {allPaginatedContacts?.count}
                  </p>
                ) : null}
                <div className="flex justify-between items-center">
                  {allPaginatedContacts?.page > 1 ? (
                    <div
                      className="bg-[#0243DD] h-7 w-7 flex justify-center items-center mr-3"
                      onClick={() => {
                        getPaginatedContactsWithPagination({
                          move: -1,
                          page: allPaginatedContacts?.page - 1,
                          conditions: filter,
                        });
                      }}
                    >
                      <IoCaretBack color="#fff" />
                    </div>
                  ) : null}
                  {allPaginatedContacts?.count &&
                  allPaginatedContacts?.contacts &&
                  allPaginatedContacts?.page * 50 <
                    allPaginatedContacts?.count ? (
                    <div
                      className="bg-[#0243DD] h-7 w-7 flex justify-center items-center"
                      onClick={() => {
                        getPaginatedContactsWithPagination({
                          move: 1,
                          page: allPaginatedContacts?.page + 1,
                          conditions: filter,
                        });
                      }}
                    >
                      <IoCaretForward color="#fff" />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
      {hoverArc ? (
        <BorderDisplayComp
          contactDetail={hoverArc}
          selectedGlobe={selectedGlobe}
          positionClassName="top-44 sm:top-auto sm:bottom-20"
        />
      ) : null}
    </main>
  );
};

export default RecentContacts;
