import { useEffect, useState } from 'react';
import { collection, query, orderBy, limit, getDocs, doc } from 'firebase/firestore';
import { db } from 'firebase-config';
import { UserProfile } from 'types/Models';
import { ContestTable } from 'constants/Collections';

const useContestants = (contestId: string, userId?: string) => {
  const [contestants, setContestants] = useState<UserProfile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!contestId) return;

    const fetchContestants = async () => {
      setLoading(true);
      setError(null);
      try {
        const contestantsRef = query(
          collection(db, ContestTable, contestId, 'EnrolledUsers'),
          orderBy('enrolledTimestamp', 'desc'),
        );

        const snapshot = await getDocs(contestantsRef);
        const contestantsData: UserProfile[] = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          contestantsData.push({ ...data, id: doc.id } as UserProfile);
        });

        setContestants(contestantsData);
      } catch (err) {
        setError('Failed to fetch contestants');
      } finally {
        setLoading(false);
      }
    };

    fetchContestants();
  }, [contestId]);

  return { contestants, loading, error };
};

export default useContestants;
