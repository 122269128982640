import { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  limit,
} from "firebase/firestore";
import { db } from "firebase-config";
import { LogBookContactModel } from "types/Models";
import { LogBookContactTable } from "constants/Collections";

const useRecentContacts = (contestId: string, contactsLimit = 10) => {
  const [contacts, setContacts] = useState<LogBookContactModel[]>([]);

  useEffect(() => {
    if (!contestId) return;

    const contactsRef = collection(db, LogBookContactTable);
    const contactsQuery = query(
      contactsRef,
      where("contestId", "==", contestId),
      orderBy("contactTimeStamp", "desc"),
      limit(contactsLimit)
    );

    const unsubscribe = onSnapshot(contactsQuery, (snapshot) => {
      const recentContacts = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as LogBookContactModel[];
      setContacts(recentContacts);
    });

    return () => unsubscribe();
  }, [contestId]);

  return contacts;
};

export default useRecentContacts;
