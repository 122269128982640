import { useState } from "react";
import { MarkerF, InfoWindowF } from "@react-google-maps/api";
import { ContactMarkerProps } from "types/Component";
import Icons from "assets/Icons";
import { UserAvatar } from "components/UserAvatar";
import CountryDxccFlag from "components/CountryDxccFlag";
import CustomGradientButton from "components/Button/CustomGradientButton";
import IconCopy from "components/icons/IconCopy";

const SpotMarker = ({ position, index, onCopyPressed }: ContactMarkerProps) => {
  const [infoVisibile, setInfoVisibile] = useState(false);
  return (
    <MarkerF
      position={position}
      onClick={() => {
        if (position.payload) {
          setInfoVisibile(!infoVisibile);
        }
      }}
      onMouseOver={(e) => {
        if (!position.payload) {
          setInfoVisibile(true);
        }
      }}
      onMouseOut={(e) => {
        if (!position.payload) {
          setInfoVisibile(false);
        }
      }}
      icon={{
        url: Icons.MarkerIcon,
        scaledSize: new window.google.maps.Size(20, 20),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(10, 20),
      }}
    >
      {infoVisibile ? (
        <InfoWindowF
          onCloseClick={() => {
            setInfoVisibile(false);
          }}
          options={{ disableAutoPan: true }}
          position={position}
        >
          {position.contactInfo ? (
            <div
              className={`${
                onCopyPressed ? "h-[120px]" : "h-[85px]"
              } rounded-[8px] bg-white py-2 px-3 flex items-start shadow-lg border-2 border-[#ff0000]`}
            >
              {/* Avatar and Flag */}
              <div className="relative mr-3 flex-shrink-0">
                {position.contactInfo?.image ? (
                  <img
                    className="w-[40px] h-[40px] rounded-full bg-[#f2f2f2] object-cover -mt-2"
                    src={position.contactInfo?.image}
                    alt=""
                  />
                ) : (
                  <UserAvatar className="w-[40px] h-[40px] rounded-full bg-[#f2f2f2] mt-1" />
                )}
                {/* Flag positioned at bottom right of avatar */}
                <div className="absolute bottom-0 right-0 transform translate-x-1/4 translate-y-1/4">
                  <CountryDxccFlag
                    countryCode={position.contactInfo.country || ""}
                    flagCode={position.contactInfo.flagCode || ""}
                  />
                </div>
              </div>

              {/* Text Content */}
              <div className="flex flex-col justify-center">
                <p className="font-[Play] text-[#010A41] text-[14px] leading-tight">
                  {position.contactInfo.callSign} -{" "}
                  {position.contactInfo.country}
                </p>
                <p className="font-[Play] text-[#010A41] text-[10px]">
                  {position.contactInfo.lastSpottedTimestamp}
                </p>
                <div className="flex items-center mt-1">
                  <img
                    src={Icons.Frequency}
                    alt=""
                    className="w-[13px] h-[13px] mr-1"
                  />
                  <p className="font-[Play] text-[11px] text-[#010A41]">
                    {position.contactInfo.frequency} MHz (
                    {position.contactInfo.mode})
                  </p>
                </div>

                {position.contactInfo?.logged ? (
                  <p className="text-xs  text-[green] mt-2 flex">
                    <b>Spot Already Logged</b>{" "}
                    <img
                      src={Icons.Confirmed}
                      alt=""
                      className="w-4 h-4 ml-2"
                    />
                  </p>
                ) : null}

                {/* Copy to Log Button*/}
                {position?.payload && (
                  <CustomGradientButton
                    icon={IconCopy}
                    iconClass="w-[12px] h-[12px]"
                    text="Copy to Log"
                    textClass="text-[8px] text-[#010A41]"
                    className="mt-2 w-[80px] h-[20px] from-[#828aff] to-[#8ae9ff] rounded-full flex items-center justify-center cursor-pointer"
                    onClick={() => {
                      onCopyPressed && onCopyPressed(position.payload);
                    }}
                  />
                )}
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </InfoWindowF>
      ) : null}
    </MarkerF>
  );
};

export default SpotMarker;
