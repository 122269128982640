import Icons from "assets/Icons";
import ContestMapComponent from "../ContestMap";
import RecentContactTable from "components/Table/RecentContactTable";
import useRecentContacts from "hooks/useRecentContestContacts";
import { useSearchParams } from "react-router-dom";

function ContestMap({
  contestId,
  containerClass,
  hideCTA = false,
  noOfContacts = 8,
}: {
  contestId: string;
  containerClass?: string;
  hideCTA?: boolean;
  noOfContacts?: number;
}) {
  //contestId
  const recentContacts = useRecentContacts(contestId, noOfContacts);
  let [searchParams, setSearchParams] = useSearchParams();

  const openMapTab = ()=>{
    setSearchParams((prev: any) => {
      return {
        ...prev,
        tab: "real_time_map",
        contest_id: searchParams.get("contest_id"),
      };
    });
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex mt-4">
        <div className="w-full">
          <div
            className={`flex flex-col md:flex-row w-full p-4 dark:bg-[#151541] bg-white mt-2.5 gap-6 ${containerClass}`}
          >
            <div className="flex flex-col flex-[1] gap-6 justify-center">
              <div className="flex flex-row items-center dark:text-white text-[#2b2e48] gap-4 text-xl font-bold font-Play uppercase">
                <img
                  src={Icons.TopChart}
                  alt=""
                  className="filter invert dark:invert-0"
                />
                PARTICIPANTS ON THE AIR NOW!
              </div>
              <div className="max-h-[450px]">
                <ContestMapComponent contestId={contestId} />
              </div>
              {!hideCTA ? (
                <div className="dark:text-[#17F9DA] text-[#1e40af] text-center cursor-pointer" onClick={openMapTab}>
                  See Full Map
                </div>
              ) : null}
            </div>

            <div className="flex flex-col flex-[1] gap-6">
              <div className="flex flex-row items-center dark:text-white text-[#2b2e48] gap-4 text-xl font-bold font-Play uppercase">
                <img
                  src={Icons.TopChart}
                  alt=""
                  className="filter invert dark:invert-0"
                />
                LATEST CONTACTS
              </div>
              <div className={!hideCTA ? "max-h-[450px]" : ""}>
                <RecentContactTable
                  lists={recentContacts}
                  className={!hideCTA ? "!mt-0 !h-[500px]" : "!mt-0"}
                />
              </div>
              {!hideCTA ? (
                <div className="dark:text-[#17F9DA] text-[#1e40af] text-center cursor-pointer" onClick={openMapTab} >
                  See All Contacts
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContestMap;
