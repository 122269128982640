import React, { useState } from "react";
import { MarkerF, InfoWindowF } from "@react-google-maps/api";

import { ContactMarkerProps } from "types/Component";
import contactMarkerIcon from "../../assets/Icons/contactMarker.svg";
import { findCountryCode, convertDistance, getShortDistanceUnit } from "helpers/Utils";
import ReactCountryFlag from "react-country-flag";
import Icons from "assets/Icons";
import { UserAvatar } from "components/UserAvatar";
import CountryDxccFlag from "components/CountryDxccFlag";
import { useUserSettings } from "hooks/useUserSettings.hook";

const ContactMarker = ({ position, index, useCustomMarker = false }: ContactMarkerProps) => {
  const [infoVisibile, setInfoVisibile] = useState(false);
  const { settings } = useUserSettings();

  const getMarkerIcon = () => {
    if (!useCustomMarker || !position.contactInfo?.mode) {
      return {
        url: contactMarkerIcon,
        scaledSize: new window.google.maps.Size(20, 20),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(10, 20),
      };
    }

    const mode = position.contactInfo.mode.toUpperCase();
    const strokeColor = "#010A41"; // Common border color

    // Color schemes for different modes (container and shape colors)
    const getModeColors = () => {
      switch (mode) {
        case 'CW':
          return {
            container: "#CCFF00", // Bright Lime
            shape: "#006600",      // Dark Green for strong contrast
          };
        case 'SSB':
          return {
            container: "#00FFA3", // Aquamarine
            shape: "#004D40",      // Dark Teal for excellent contrast
          };
        case 'FM':
          return {
            container: "#00C2FF", // Light Blue
            shape: "#003366",      // Navy Blue for clear visibility
          };
        case 'FT8':
          return {
            container: "#F812A6", // Magenta
            shape: "#99004C",      // Dark Magenta for enhanced contrast
          };
        case 'LSB':
          return {
            container: "#597BF5", // Periwinkle Blue
            shape: "#003399",      // Deep Blue for optimal contrast
          };
        default:
          return {
            container: "#5e3d9c", // Light Steel Blue
            shape: "#2E0854",      // Dark Purple for strong contrast
          };
      }
    };

    const colors = getModeColors();
    
    const getShapePath = () => {
      switch (mode) {
        case 'CW':
          // Circle inside circle
          return `data:image/svg+xml,${encodeURIComponent(`
            <svg width="10" height="10" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="8" fill="${colors.container}" stroke="${strokeColor}" stroke-width="1"/>
              <circle cx="10" cy="10" r="5" fill="${colors.shape}" stroke="${strokeColor}" stroke-width="1"/>
            </svg>
          `)}`;
        case 'SSB':
          // Triangle inside circle
          return `data:image/svg+xml,${encodeURIComponent(`
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="8" fill="${colors.container}" stroke="${strokeColor}" stroke-width="1"/>
              <path d="M10 6L6 14H14L10 6Z" fill="${colors.shape}" stroke="${strokeColor}" stroke-width="1"/>
            </svg>
          `)}`;
        case 'FM':
          // Pentagon inside circle
          return `data:image/svg+xml,${encodeURIComponent(`
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="8" fill="${colors.container}" stroke="${strokeColor}" stroke-width="1"/>
              <path d="M10 6L7 9L8 13H12L13 9L10 6Z" fill="${colors.shape}" stroke="${strokeColor}" stroke-width="1"/>
            </svg>
          `)}`;
        case 'FT8':
          // Star inside circle
          return `data:image/svg+xml,${encodeURIComponent(`
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="8" fill="${colors.container}" stroke="${strokeColor}" stroke-width="1"/>
              <path d="M10 5L11.2 8.3H14.7L11.8 10.4L12.7 13.7L10 11.8L7.3 13.7L8.2 10.4L5.3 8.3H8.8L10 5Z" fill="${colors.shape}" stroke="${strokeColor}" stroke-width="1"/>
            </svg>
          `)}`;
        case 'LSB':
          // Arrow inside circle
          return `data:image/svg+xml,${encodeURIComponent(`
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="8" fill="${colors.container}" stroke="${strokeColor}" stroke-width="1"/>
              <g transform="translate(10,10) rotate(310) translate(-10,-10)">
                <path d="M5 8 L11 8 L11 5 L15 10 L11 15 L11 12 L5 12 Z" fill="${colors.shape}" stroke="${strokeColor}" stroke-width="1"/>
              </g>
            </svg>
          `)}`;
        default:
          // Square inside circle
          return `data:image/svg+xml,${encodeURIComponent(`
            <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="8" fill="${colors.container}" stroke="${strokeColor}" stroke-width="1"/>
              <rect x="7" y="7" width="6" height="6" fill="${colors.shape}" stroke="${strokeColor}" stroke-width="1"/>
            </svg>
          `)}`;
      }
    };

    return {
      url: getShapePath(),
      scaledSize: new window.google.maps.Size(20, 20),
      origin: new window.google.maps.Point(0, 0),
      anchor: new window.google.maps.Point(10, 10),
    };
  };

  return (
    <MarkerF
      key={`${position.lat}-${position.lng}-${index}`}
      position={position}
      icon={getMarkerIcon()}
      onMouseOver={() => setInfoVisibile(true)}
      onMouseOut={() => setInfoVisibile(false)}
    >
      {infoVisibile ? (
        <InfoWindowF
          onCloseClick={() => {
            setInfoVisibile(false);
          }}
          options={{ disableAutoPan: true }}
          position={position}
        >
          {position.contactInfo ? (
            <div className="h-[55px] rounded-sm bg-[#fff] py-2 pr-6 pl-2 flex">
              <div className="relative flex items-center mr-3">
                {position.contactInfo?.image ? (
                  <img
                    className="w-[40px] h-[40px] rounded-full bg-[#f2f2f2]"
                    src={position.contactInfo?.image}
                    alt=""
                  />
                ) : (
                  <UserAvatar className="h-8 w-8" />
                )}
                <div className="absolute bottom-[0px] right-[-2px]">
                  <CountryDxccFlag
                    countryCode={position.contactInfo?.country || ""}
                    flagCode={position.contactInfo?.flagCode || ""}
                  />
                </div>
              </div>
              <div className="">
                <p className="font-[Play] font-normal text-[#010A41]">
                  {position.contactInfo?.name} - {position.contactInfo?.callSign}
                </p>
                <div className="flex gap-1">
                  <img
                    src={Icons.Frequency}
                    alt=""
                    className="w-[13px] h-[13px]"
                  />
                  {position.contactInfo.frequency ? (
                    <p className="font-[Play] text-[11px] text-[#010A41]">
                      {position.contactInfo?.frequency} MHz{" "}
                      {`(${position.contactInfo?.mode})`}
                    </p>
                  ) : (
                    <p className="font-[Play] text-[11px] text-[#010A41]">--</p>
                  )}
                </div>
                <div className="flex gap-1">
                  <img
                    src={Icons.Distance}
                    alt=""
                    className="w-[13px] h-[13px]"
                  />
                  <p className="font-[Play] text-[11px] text-[#010A41]">
                    {position.contactInfo?.distance
                      ? `${convertDistance(
                          position.contactInfo.distance,
                          settings.distanceUnit
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        })} ${getShortDistanceUnit(settings.distanceUnit)}`
                      : "--"}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </InfoWindowF>
      ) : null}
    </MarkerF>
  );
};

export default ContactMarker;
