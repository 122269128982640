import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { Templates } from "constants/Config";
import { showToast } from "helpers/Toast";
import useLogBook from "hooks/logBook.hook";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { useLogBookSettingsForm } from "hooks/useLogBookSettingsForm.hook";
import { FC, useState } from "react";
import { ToastTypes } from "types/Component";
var isEmpty = require("lodash.isempty");

export const ExportSettings: FC = () => {
  const { selectedLogbook, saveLogbookSettings } = useLogbookSetting();
  const { getLogbookContactForAdifExport, isLogContactForAdifLoading } =
    useLogBook();
  const { values, setValues } = useLogBookSettingsForm();

  const exportAdifClickHandler = (
    logbookId: string,
    parkReference?: string,
    activityType?: string
  ) => {
    if (selectedLogbook?.exportInProgress) {
      showToast({
        message: "Export in progress, please wait and try again!",
        type: ToastTypes.WARN,
      });
    } else {
      if (logbookId && parkReference) {
        getLogbookContactForAdifExport(logbookId || "", {
          parkReference,
          activityType,
        });
      } else {
        getLogbookContactForAdifExport(logbookId || "");
      }
    }
  };

  const renderParkExportOptions = () => {
    if (
      selectedLogbook?.logbookStyle !== Templates.POTA &&
      !selectedLogbook?.activitiesData
    )
      return null;

    const parks = selectedLogbook?.activitiesData
      ? selectedLogbook?.activitiesData
      : Object.values(selectedLogbook.myParks || {});

    return (
      <>
        {parks.map((park) => {
          return (
            <div className="w-[20%]" key={park}>
              <BorderButtonSecondary
                text={`Export ${park?.reference} ADIF`}
                onClick={() => {
                  exportAdifClickHandler(
                    selectedLogbook?.id || "",
                    park?.reference,
                    park.type
                  );
                }}
                className="sm:mb-0 cursor-pointer"
                height="h-8"
                childClassName="text-xs"
                isLoading={isLogContactForAdifLoading}
              />
            </div>
          );
        })}
      </>
    );
  };

  const renderPOTAExportButton = () => {
    if (
      selectedLogbook?.logbookStyle === Templates.POTA &&
      selectedLogbook?.activitiesData?.length === 0
    ) {
      return (
        <div className="w-[20%]">
          <BorderButtonSecondary
            text={`Export POTA ADIF`}
            onClick={() => {
              if (selectedLogbook?.activitiesData?.length === 0) {
                showToast({
                  message:
                    "Please add a park and save your settings to export for POTA",
                  type: ToastTypes.WARN,
                });
              } else {
                saveLogbookSettings(values);
              }
            }}
            className="sm:mb-0 cursor-pointer"
            height="h-8"
            childClassName="text-xs"
            isLoading={isLogContactForAdifLoading}
          />
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col dark:text-white text-[#2b2e48] gap-4">
      <div className="font-bold font-Play text-xl">Export Log Files</div>
      <div className="flex flex-row  gap-4">
        <div className="w-[40%] sm:w-[20%]">
          <BorderButtonSecondary
            text={`Export ADIF`}
            onClick={() => exportAdifClickHandler(selectedLogbook?.id || "")}
            className="sm:mb-0 cursor-pointer"
            height="h-8"
            childClassName="text-xs"
            isLoading={isLogContactForAdifLoading}
          />
        </div>
        {renderParkExportOptions()}
        {renderPOTAExportButton()}
      </div>
    </div>
  );
};
