import React, { useEffect } from "react";
import { TableEntriesProps } from "types/Component";
import TableEntColCompact from "./TableColCompact";

function groupContactsByDateWithHeading(contacts: any[]) {
  try {
    const groupedContacts: any = {};
    // Group contacts by date in UTC
    contacts.forEach((contact) => {
      const dateObj = new Date(contact.contactTimeStamp.seconds * 1000);
      const utcYear = dateObj.getUTCFullYear();
      const utcMonth = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
      const utcDay = String(dateObj.getUTCDate()).padStart(2, "0");
      // Construct the UTC date string (YYYY-MM-DD)
      const dateString = `${utcYear}-${utcMonth}-${utcDay}`;
      if (!groupedContacts[dateString]) {
        groupedContacts[dateString] = [];
      }
      groupedContacts[dateString].push(contact);
    });
    // Convert the grouped object to an array with heading
    const result: any[] = [];
    Object.keys(groupedContacts).forEach((date) => {
      const formattedDate = new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
      }); // Convert to human-readable format
      result.push({ type: "heading", heading: formattedDate });
      result.push(...groupedContacts[date]);
    });
    return result;
  } catch (error) {
    return [];
  }
}

function TableEntriesCompact({
  lists,
  editable,
  missingFields,
  guestUser,
  showExchange,
  exchangeOne,
  exchangeTwo,
  isWFDLogBook,
}: TableEntriesProps) {
  const [contacts, setContacts] = React.useState<any[]>([]);

  useEffect(() => {
    const result = groupContactsByDateWithHeading(lists);
    setContacts(result);
  }, [lists]);

  return (
    <div className="w-full overflow-scroll mt-4">
      <table className="w-full min-w-max table-auto">
        <thead className="">
          <tr className="bg-[#94a3b883] dark:bg-[#3C3C58] py-2.5 border-0 border-b border-slate-300 border-solid dark:text-white text-[#2b2e48]">
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              User
            </th>
            {showExchange ? (
              <>
                <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
                  { isWFDLogBook ? "Class" : exchangeOne }
                </th>

                <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
                  {isWFDLogBook ? "Section" : exchangeTwo}
                </th>
              </>
            ) : (
              <>
                <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
                  Sent
                </th>
                <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
                  Rcvd
                </th>
              </>
            )}
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Frequency
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Mode
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Distance
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Tags
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Conf.
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left"></th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((item: any, index: number) => {
            if (item.type === "heading") {
              return (
                <tr
                  key={`${item?.heading}_${index}`}
                  className="dark:bg-[#1F1F3F] bg-[#fafafa] border-0 border-b border-slate-300 border-solid"
                >
                  <td
                    colSpan={9}
                    className="pl-2 py-3 font-[Barlow] text-sm md:text-left dark:text-white text-[#2b2e48]"
                  >
                    {item?.heading}
                  </td>
                </tr>
              );
            }

            return (
              <TableEntColCompact
                key={`${item.id}_${index}`}
                item={item}
                className={`${
                  index % 2
                    ? "dark:bg-[#151536] bg-white"
                    : "dark:bg-[#1F1F3F] bg-[#fafafa]"
                } border-0 border-b border-slate-300 border-solid`}
                editable={editable}
                missingFields={missingFields}
                guestUser={guestUser}
                showExchange={showExchange}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableEntriesCompact;
