import React, { useRef } from "react";
import { createRoot, Root } from "react-dom/client";
import { persistor, store } from "store/index";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import ThemeProvider from "contexts/theme.context";
// const { store, persistor } = configureStore();

type PopupRef = {
  window: Window | null;
  root: Root | null;
};

export const useProgrammaticPopup = () => {
  const popupRef = useRef<PopupRef>({ window: null, root: null });

  const openPopup = (content: React.ReactNode, title = "Popup Window") => {
    // Reuse existing popup if already open
    if (popupRef.current.window && !popupRef.current.window.closed) {
      popupRef.current.window.focus();
      if (popupRef.current.root) {
        // Re-render the content if the popup is already open
        popupRef.current.root.render(
          <ReduxProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider>
                <BrowserRouter>{content}</BrowserRouter>
              </ThemeProvider>
            </PersistGate>
          </ReduxProvider>
        );
      }
      return;
    }

    // Open a new popup window
    const popupWindow = window.open(
      "",
      "_blank",
      "width=600,height=400,left=200,top=200,toolbar=no,location=no,status=no,menubar=no"
    );

    if (!popupWindow) {
      console.error("Failed to open popup window.");
      return;
    }

    popupWindow.document.title = title;

    const link = popupWindow.document.createElement("link");
    link.rel = "stylesheet";
    link.href = "/index.css"; // Replace with your CSS file URL or path
    popupWindow.document.head.appendChild(link);

    const script = popupWindow.document.createElement("script");
    script.src = "https://cdn.tailwindcss.com"; // Tailwind CSS via CDN script
    script.defer = true;
    popupWindow.document.head.appendChild(script);

    // Create a container div in the popup's DOM
    const containerDiv = popupWindow.document.createElement("div");
    popupWindow.document.body.appendChild(containerDiv);

    // Create a React root for rendering
    const root = createRoot(containerDiv);

    // Render the content into the popup
    root.render(
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider>
            <BrowserRouter>{content}</BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </ReduxProvider>
    );

    // Store references for cleanup
    popupRef.current = { window: popupWindow, root };

    // Cleanup when the popup is closed
    popupWindow.addEventListener("beforeunload", () => {
      popupRef.current.root?.unmount();
      popupRef.current.window = null;
      popupRef.current.root = null;
    });

    return null;
  };

  const closePopup = () => {
    if (popupRef.current.window && !popupRef.current.window.closed) {
      popupRef.current.root?.unmount(); // Clean up React rendering
      popupRef.current.window.close();
      popupRef.current.window = null;
      popupRef.current.root = null;
    }
  };

  return { openPopup, closePopup };
};
