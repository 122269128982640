import { useField } from "formik";
import { InputWithLabelProps } from "types/Component";

function TextAreaWithLabel({
  multiline,
  label,
  placeholder,
  setText,
  defaultValue,
  error,
  name,
  ...props
}: InputWithLabelProps) {
  const [field] = useField({
    name: name ? name : "",
  });
  return (
    <div
      className={`w-full dark:bg-[#FFFFFF0F] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none relative px-[15px] py-1 h-min-[141px] ${
        error ? "!border !border-orange-700 !border-solid" : ""
      }`}
    >
      <p className="dark:text-[#17F9DA] text-[#1e40af] text-xs font-[Play] mb-1.5">{label}</p>
      <textarea
        placeholder={placeholder ? placeholder : ""}
        className="w-full bg-transparent font-medium font-[Barlow] text-sm dark:text-white text-[#2b2e48]"
        rows={4}
        cols={30}
        {...field}
        name={name}
        onChange={setText}
        id=""
      ></textarea>
    </div>
  );
}

export default TextAreaWithLabel;
