import Images from "assets/Images";
import TextInput from "components/Inputs/TextInput";
import BorderButtonPrimary from "components/Button/BorderButtonPrimary";
import useAuth from "hooks/auth.hook";
import { ReportBug } from "./ReportBug";
import { useState } from "react";
import useTheme from "hooks/useTheme.hook";

function ForgotPassword() {
  const { theme } = useTheme();
  const {
    email,
    setEmail,
    isAuthLoading,
    navigateToRegister,
    sendVerificationEmail,
    navigateLogin,
    forgetLoading,
  } = useAuth();

  const [showReportBug, setShowReportBug] = useState(false);

  if (showReportBug)
    return <ReportBug onClose={() => setShowReportBug(false)} />;

  return (
    <section className="relative w-full h-full flex justify-center items-center dark:bg-[#000030] bg-[#f1f3f4]">
      <img
        src={theme === 'dark' ? Images.WorldBg: Images.SpaceBg}
        className="absolute top-0 left-0 z-0 h-full w-full object-cover"
        alt="background"
      />
      <div className="relative flex-1 flex items-center justify-center">
        <div className={`${theme === 'dark' ? 'glassmorphism': 'bg-white' } w-11/12 sm:w-5/6 md:w-[590px] h-[340px] sm:h-[450px] flex flex-col items-center justify-center relative`}>
          <div className="flex flex-col relative w-64 sm:w-80">
            <h2 className='text-3xl mb-7 dark:text-white text-[#2b2e48] font-["Play"] font-bold text-2xl sm:text-3xl'>
              Forgot Password
            </h2>
            <TextInput
              text={email}
              setText={setEmail}
              placeHolder="Enter your email or callsign*"
              name="username"
            />
            <BorderButtonPrimary
              text={"Send Email"}
              onClick={sendVerificationEmail}
              isLoading={isAuthLoading || forgetLoading}
            />
            <p className='dark:text-white text-[#2b2e48] text-sm font-["Athiti"] font-medium text-center mt-3 sm:mt-0 mb-3'>
              Go back to{` `}
              <span
                className="font-bold cursor-pointer underline"
                onClick={navigateLogin}
              >
                Login Page
              </span>
            </p>
            <p className='dark:text-white text-[#2b2e48] text-sm font-["Athiti"] font-medium text-center mt-3 sm:mt-0'>
              Do not have account? Sign up for{" "}
              <span
                className="font-bold cursor-pointer"
                onClick={navigateToRegister}
              >
                Free
              </span>
            </p>
            <p className='dark:text-white text-[#2b2e48] text-sm font-["Athiti"] font-medium text-center mt-2'>
              Having an issue?{" "}
              <span
                className="font-bold cursor-pointer"
                onClick={() => {
                  setShowReportBug(true);
                }}
              >
                Report a bug
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
