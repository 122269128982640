import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import CountryDxccFlag from "components/CountryDxccFlag";
import { UserAvatar } from "components/UserAvatar";
import { spottingSourceColor } from "constants/Config";
import { findCountryCode } from "helpers/Utils";
import moment from "moment";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import { SpotItemProps } from "types/Component";

function SpotItem({ spot, onCopyPressed, onReSpotClicked }: SpotItemProps) {
  return (
    <div className="md:w-[350px] w-[100%] mb-4 md:mr-4">
      <div
        className={`bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] w-full rounded-lg flex justify-center items-center`}
        onClick={() => {}}
      >
        <div
          className={` text-white bg-[#1D204A] w-[calc(100%-1px)] h-[calc(100%-1px)] rounded-lg justify-center items-center font-Play pb-2`}
        >
          <div className="w-full bg-[#4C4D6E] h-[45px] rounded-tl-lg rounded-tr-lg px-4 pt-1">
            <div className="flex justify-between">
              <div className="flex items-center">
                <img
                  src={Icons.SpotFreq}
                  alt=""
                  className="w-[20px] h-[20px]"
                />
                <div className="flex flex-col ml-2">
                  <span className="font-bold text-sm font-[Barlow] ">
                    {spot.frequency} MHz (
                    <span className="text-[#17F9DA]">{spot.mode}</span>)
                  </span>
                  <span className="text-xs font-light font-[Barlow]">
                    Band: {spot.band}
                  </span>
                </div>
              </div>
              <div className="flex items-center">
                <img
                  src={Icons.SpotTime}
                  alt=""
                  className="w-[20px] h-[20px]"
                />
                <div className="flex flex-col ml-2">
                  <span className="font-bold text-sm font-[Barlow] ">
                    {moment(spot?.lastSpottedTimestamp).fromNow()}
                  </span>
                  <span className="text-xs font-light font-[Barlow] text-[#17F9DA]">
                    {moment(spot?.lastSpottedTimestamp)
                      .utc()
                      .format("HH:mm:ss")}{" "}
                    UTC
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-[#303057] h-[35px] px-2 pt-1 mt-[5px] flex justify-around">
            <div className="flex items-center">
              {spot.profilePic ? (
                <img
                  className="w-[25px] h-[25px] rounded-full mr-2"
                  src={spot.profilePic}
                  alt=""
                />
              ) : (
                <UserAvatar className="w-[25px] h-[25px] lg:w-[25px] lg:h-[25px] mr-2" />
              )}
              <span className="font-[Barlow] text-xs">
                {spot.callSign} {spot.name ? ` | ${spot.name} ` : ` `}
              </span>
            </div>
            <div className="flex items-center">
              <CountryDxccFlag
                countryCode={spot?.country || ""}
                flagCode={spot?.flagCode || ""}
              />
              <span className="font-[Barlow] text-xs">
                {spot.country} | {spot.state}
              </span>
            </div>
            <div className="flex items-center w-[100px] justify-end">
              <img
                src={Icons.SpotGrid}
                alt=""
                className="w-[15px] h-[15px] mr-1"
              />
              <span className="font-[Barlow] text-xs">{spot.grid}</span>
            </div>
          </div>
          <div className="mt-2 font-[Barlow] px-4">
            {spot.spotterList?.length > 0 && (
              <p className="text-xs">
                <b>Spotted by : </b> {spot.spotterList[0]?.callSign}
                {(spot?.spotterCount || 0) > 1 && (
                  <span className="text-[#17F9DA]">
                    + {(spot?.spotterCount || 1) - 1} others
                  </span>
                )}
              </p>
            )}
            <p className="text-xs">
              <b>Location : </b> {spot.activityName}
            </p>
            <p className="text-xs">
              <b>Comment : </b> {spot.comments}
            </p>
            {spot.activityNumber ? (
              <p className="text-xs">
                <b>Park Number : </b> {spot.activityNumber}
              </p>
            ) : null}
            {spot.logged ? (
              <p className="text-xs  text-[lightgreen] mt-2 flex">
                <b>Spot Already Logged</b>{" "}
                <img src={Icons.Confirmed} alt="" className="w-4 h-4 ml-2" />
              </p>
            ) : null}
          </div>
          <div className="flex mt-3 px-4">
            <div
              className={`px-2 rounded-full mr-2`}
              style={{
                backgroundColor: spottingSourceColor[`${spot.source}`],
              }}
            >
              <p className="font-[Barlow] font-bold text-[10px] text-center text-shadow ">
                {spot.source}
              </p>
            </div>
            {spot.spotterNearYou ? (
              <div className="bg-[#1DD0B7] px-2 rounded-full mr-2">
                <p className="font-[Barlow] font-bold text-[10px] text-center text-shadow ">
                  Spotter Near You
                </p>
              </div>
            ) : null}
          </div>
          <div className="flex justify-evenly w-full mt-2">
            <div className="w-[90px]">
              <BorderButtonSecondary
                text={`Copy to Log`}
                onClick={() => {
                  onCopyPressed(spot);
                }}
                className="sm:mb-0 cursor-pointer"
                height="h-6"
                childClassName="text-xs"
              />
            </div>
            <div className="w-[70px]">
              <BorderButtonSecondary
                text={`Re-spot`}
                onClick={() => onReSpotClicked(spot)}
                className="sm:mb-0 cursor-pointer"
                height="h-6"
                childClassName="text-xs"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpotItem;
