import Icons from "assets/Icons";
import SolidBorderButton from "components/Button/SolidBorderButton";
import { TAGS } from "constants/Config";
import { globalNavigate } from "routes/GlobalRoutes";

export const NewPotaActivation = () => {
  return (
    <SolidBorderButton
      text={`Start POTA Activation`}
      onClick={() =>
        globalNavigate(`/log-contacts/add-logbook?tag=${TAGS.POTA}`)
      }
      className="sm:mb-0 border border-emerald-500"
      icon={
        <img className="h-5 w-5 mr-2 filter invert dark:filter-none dark:invert-0" alt="pota icon" src={Icons.parkWhite} />
      }
    />
  );
};
