import CountryDxccFlag from "components/CountryDxccFlag";
import { UserAvatar } from "components/UserAvatar";
import { CONTACT, MY_PROFILE_ROUTE } from "constants/Links";
import { convertDistance, findCountryCode, getShortDistanceUnit } from "helpers/Utils";
import CustomValidation from "helpers/Validation";
import { useUserSettings } from "hooks/useUserSettings.hook";
import moment from "moment";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import { IoEyeOutline } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { globalNavigate } from "routes/GlobalRoutes";
import { setContactDetails } from "store/Actions/logBook.actions";
import { TableRecentContactColProps } from "types/Component";

function TableRecentContactCol({ contact, index }: TableRecentContactColProps) {
  const dispatch = useDispatch();
  const { settings } = useUserSettings();
  const navigateToProfile = () => {
    globalNavigate(
      `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
        contact?.profileCallSign
          ? contact.profileCallSign
          : contact?.myCallSign
          ? contact.myCallSign
          : ""
      )}?tab=Logbook&contact-tab=entries`,
      {
        state: contact?.uid,
      }
    );
  };
  return (
    <tr className="border-0 border-b border-slate-300 border-solid dark:bg-[#1F1F48] bg-white cursor-pointer">
      <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3">
        <p className="pl-4">
          {contact?.time}{" "}
          <span className="text-[#9D9D9D] ml-2">{contact?.date}</span>
        </p>
      </td>
      <td
        className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3 "
        onClick={() => navigateToProfile()}
      >
        <div className=" h-full flex justify-center items-center">
          {contact?.myProfilePic ? (
            <img
              className="w-7 h-7 rounded-full object-cover"
              src={contact.myProfilePic}
              alt=""
            />
          ) : (
            <UserAvatar className="w-7 h-7" />
          )}
        </div>
      </td>
      <td
        className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3"
        onClick={() => navigateToProfile()}
      >
        <p className="pl-4">{contact?.myCallSign}</p>
      </td>
      <td
        className="text-xs dark:text-white text-[#2b2e48] text-center font-[Barlow] font-normal py-3"
        onClick={() => navigateToProfile()}
      >
        <CountryDxccFlag
          countryCode={contact?.myCountry || ""}
          flagCode={contact?.myFlagCode || ""}
        />
      </td>
      <td
        className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3"
        onClick={() => navigateToProfile()}
      >
        <p className="pl-4">{contact?.userGrid}</p>
      </td>
      <td
        className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3 "
        onClick={() => navigateToProfile()}
      >
        <div className=" h-full flex justify-center items-center">
          {contact?.theirProfilePic ? (
            <img
              className="w-7 h-7 rounded-full object-cover"
              src={contact.theirProfilePic}
              alt=""
            />
          ) : (
            <UserAvatar className="w-7 h-7" />
          )}
        </div>
      </td>
      <td
        className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3"
        onClick={() => navigateToProfile()}
      >
        <p className="pl-4">{contact?.theirCallsign}</p>
      </td>
      <td className="text-xs dark:text-white text-[#2b2e48] text-center font-[Barlow] font-normal py-3">
        <CountryDxccFlag
          countryCode={contact?.theirCountry || contact?.country || ""}
          flagCode={contact?.flagCode || ""}
        />
      </td>
      <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3">
        <p className="pl-4">{contact?.theirGrid}</p>
      </td>
      <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3">
        <p className="pl-3">{contact?.frequency}</p>
      </td>
      <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3">
        <p className="pl-3">{contact?.userMode}</p>
      </td>
      <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3">
        <p className="pl-3">
          {contact?.distance
            ? `${convertDistance(
                contact?.distance,
                settings.distanceUnit
              ).toLocaleString(undefined, {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              })} ${getShortDistanceUnit(settings.distanceUnit)}`
            : "--"}
        </p>
      </td>
      <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-3">
        <div className="flex">
          <p className="pl-3">{contact?.time}</p>
          <div className="">
            <div
              className="flex justify-center items-center dark:text-white text-[#2b2e48] w-12 cursor-pointer "
              onClick={(e) => {
                dispatch(setContactDetails({}));
                globalNavigate(`${CONTACT}?contactId=${contact?.id}`, {
                  state: { item: contact },
                });
              }}
            >
              <IoEyeOutline size={20} />
            </div>
          </div>
        </div>
      </td>
    </tr>
  );
}

export default TableRecentContactCol;
