import React, { useState, useEffect } from "react";

const LeaderBoardTimer = () => {
  const [remainingHours, setRemainingHours] = useState(0);
  const [remainingMinutes, setRemainingMinutes] = useState(0);
  const [remainingSeconds, setRemainingSeconds] = useState(0);

  const calculateRemainingTime = () => {
    const now = new Date();
    const currentDay = now.getUTCDay();
    const daysRemaining = (7 - currentDay) % 7; // If it's Sunday, daysRemaining should be 0
    const hoursRemaining = 23 - now.getUTCHours();
    const minutesRemaining = 59 - now.getUTCMinutes();
    const secondsRemaining = 59 - now.getUTCSeconds();

    setRemainingHours(hoursRemaining + daysRemaining * 24);
    setRemainingMinutes(minutesRemaining);
    setRemainingSeconds(secondsRemaining);
  };

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      calculateRemainingTime();
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, []);
  return (
    <div className="timer-bg flex w-full h-[140px] mb-4 items-center justify-center">
      <div className="w-[97%] h-[65%] dark:bg-[#000957] bg-white flex items-center justify-center">
        <p className="font-[Play] font-bold text-[15px] md:text-[20px] dark:text-white text-[#2b2e48] mr-10 md:mr-16">
          Time Left:{" "}
        </p>
        <div className="flex items-center">
          <div className="dark:bg-[#1A1D57] bg-[#f1f3f4] w-[69px] h-[60px] md:w-[89px] md:h-[80px] rounded-md items-center justify-center flex flex-col text-[30px] font-bold">
            <h2 className="font-[Play] dark:text-white text-[#2b2e48] text-[18px] md:text-[26px]">
              {remainingHours}
            </h2>
            <p className="font-[Barlow] dark:text-[#17F9DA] text-[#1e40af] text-[12px]">HOURS</p>
          </div>
          <p className="dark:text-white text-[#2b2e48] text-[35px] font-[Play] mx-2">:</p>
          <div className="dark:bg-[#1A1D57] bg-[#f1f3f4] w-[69px] h-[60px] md:w-[89px] md:h-[80px] rounded-md items-center justify-center flex flex-col text-[30px] font-bold">
            <h2 className="font-[Play] dark:text-white text-[#2b2e48] text-[18px] md:text-[26px]">
              {remainingMinutes}
            </h2>
            <p className="font-[Barlow] dark:text-[#17F9DA] text-[#1e40af] text-[12px]">MINUTES</p>
          </div>
          <p className="dark:text-white text-[#2b2e48] text-[35px] font-[Play] mx-2">:</p>
          <div className="dark:bg-[#1A1D57] bg-[#f1f3f4] w-[69px] h-[60px] md:w-[89px] md:h-[80px] rounded-md items-center justify-center flex flex-col text-[30px] font-bold">
            <h2 className="font-[Play] dark:text-white text-[#2b2e48] text-[18px] md:text-[26px]">
              {remainingSeconds}
            </h2>
            <p className="font-[Barlow] dark:text-[#17F9DA] text-[#1e40af] text-[12px]">SECONDS</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaderBoardTimer;
