import ActivityIndicator from "components/Loading/ActivityIndicator";
import React from "react";

interface DynamicButtonProps {
  leftElement?: React.ReactNode;
  rightElement?: React.ReactNode;
  text?: string;
  onClick?: () => void;
  className?: string;
  isLoading?: boolean;
  inverse?: boolean;
}

const DynamicButton: React.FC<DynamicButtonProps> = ({
  leftElement,
  rightElement,
  text = "",
  onClick,
  className = "",
  isLoading = false,
  inverse = true,
}) => {
  if (isLoading)
    return (
      <div
        className={`relative w-[100px] dark:bg-[#444467] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none h-[30px] flex items-center justify-center rounded-md mb-3 ml-2 cursor-pointer ${className}`}
      >
        <ActivityIndicator size={16} />{" "}
      </div>
    );

  return (
    <div
      className={`relative w-[100px] dark:bg-[#444467] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none h-[30px] flex items-center justify-center rounded-md mb-3 ml-2 cursor-pointer ${className}`}
      onClick={onClick}
    >
      {/* Left Element (Checkbox, Icon, etc.) */}
      {leftElement && <div className={`left-element ${inverse ? 'invert dark:filter-none dark:invert-0':''}`}>{leftElement}</div>}

      {/* Button Text */}
      {text && (
        <p className="font-[Play] dark:text-white text-[#2b2e48] text-center text-xs">{text}</p>
      )}

      {/* Right Element (Icon, Image, etc.) */}
      {rightElement && <div className="right-element ml-2 invert dark:filter-none dark:invert-0">{rightElement}</div>}
    </div>
  );
};

export default DynamicButton;
