import { FaCopy, FaSort } from "react-icons/fa6";
import { SpottingViewProps } from "types/Component";
import moment from "moment";
import { UserAvatar } from "components/UserAvatar";
import CountryDxccFlag from "components/CountryDxccFlag";
import { toggleSortOrder } from "helpers/Utils";
import Icons from "assets/Icons";
import { Tooltip } from "components/Tooltip";

function SpottingTableViewCompact({
  spotsData,
  onCopyPressed,
  setSortConfig,
}: SpottingViewProps) {
  const handleSort = (column: string) => {
    setSortConfig &&
      setSortConfig((prevSortConfig) =>
        toggleSortOrder(prevSortConfig, column)
      );
  };

  return (
    <div className="w-full overflow-scroll">
      <table className="w-full min-w-max table-auto">
        <thead className="">
          <tr className="bg-[#94a3b883] dark:bg-[#3C3C58] py-2.5 border-0 border-b border-slate-300 border-solid dark:text-white text-[#2b2e48]">
            <th className="py-3 ps-3 font-[Barlow] text-sm md:text-left">
              <div className="flex items-center">
                <span>Date/Time</span>
                <div
                  className="ml-2 mt-1 cursor-pointer"
                  onClick={() => handleSort("lastSeen")}
                >
                  <FaSort className="" />
                </div>
              </div>
            </th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">
              <div className="flex items-center md:ml-0">
                <span>Frequency</span>
                <div
                  className="ml-2 mt-1 cursor-pointer"
                  onClick={() => handleSort("frequency")}
                >
                  <FaSort className="" />
                </div>
              </div>
            </th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">Mode</th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">Band</th>
            <th className="py-3 font-[Barlow] text-sm md:text-left">
              Operator
            </th>
            <th className="py-3 font-[Barlow] text-sm"></th>
          </tr>
        </thead>
        <tbody className="">
          {spotsData?.map((item, index) => (
            <tr
              key={`${item.id}`}
              className={`${
                item.logged
                  ? "dark:bg-[green] bg-[lightgreen]"
                  : "dark:bg-[#1F1F3F] bg-white"
              } border-0 border-b border-slate-300 border-solid cursor-pointer`}
              onDoubleClick={(e) => {
                const row = e.currentTarget;
                row.classList.add("bg-white/30"); // Apply the highlight
                onCopyPressed(item); // Call the action
                setTimeout(() => row.classList.remove("bg-white/30"), 300); // Remove it after 300ms
              }}
            >
              <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-2 ps-3">
                {moment(item.lastSpottedTimestamp).fromNow()}
              </td>
              <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-2">
                {item.frequency} MHz
              </td>
              <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-2">
                {item.mode}
              </td>
              <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-2">
                {item.band}
              </td>
              <td className="text-xs dark:text-white text-[#2b2e48] font-[Barlow] font-normal py-2">
                <div className="flex items-center gap-3">
                  {item.profilePic ? (
                    <img
                      className="w-[22px] h-[22px] rounded-full"
                      src={item.profilePic}
                      alt=""
                    />
                  ) : (
                    <UserAvatar className="w-[22px] h-[22px]" />
                  )}

                  <span className="w-[60px]">{item.callSign}</span>

                  <CountryDxccFlag
                    countryCode={item?.country || ""}
                    flagCode={item?.flagCode || ""}
                  />
                  <span>{item?.country}</span>
                </div>
              </td>
              <td
                className="pe-2 py-2 flex flex-row gap-3 mt-1"
                onClick={() => {
                  onCopyPressed(item);
                }}
              >
                <Tooltip
                  message={item?.comments || ""}
                  className="w-[200px] -left-[180px] top-6"
                  showTooltip={!!item?.comments}
                >
                  <img
                    className="h-4 w-4 cursor-pointer invert dark:filter-none dark:invert-0"
                    src={Icons.CopySpot}
                    alt=""
                  />
                </Tooltip>

                <img
                  className="h-4 w-4 cursor-pointer invert dark:filter-none dark:invert-0"
                  src={Icons.Comments}
                  alt=""
                />
                {/* <FaCopy color="#fff" className="cursor-pointer" />
                <FaCopy color="#fff" className="cursor-pointer" /> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default SpottingTableViewCompact;
