import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RETRY_EMAIL_UPDATE } from "store/Actions/profile.actions";
import Icons from "assets/Icons";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { SHOW_REAUTHENTICATE_MODAL } from "store/Actions/profile.actions";
import { getIsProfileLoadingSelector } from "store/Reducers/loading.reducer";

export const ReAuthenticateModal: React.FC = () => {
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const loading = useSelector(getIsProfileLoadingSelector);

  const handleSubmit = async () => {
    dispatch({
      type: RETRY_EMAIL_UPDATE,
      payload: { password },
    });
  };

  const handleClose = () => {
    dispatch({
      type: SHOW_REAUTHENTICATE_MODAL,
      payload: false,
    });
  };

  return (
    <section className="absolute w-screen h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[25%] lg:px-[25%] overflow-scroll">
      <div className="w-full">
        <div className="w-full">
          <div className="flex flex-col w-full dark:bg-[#151541] bg-white px-6 py-5 dark:text-white text-[#2b2e48] font-[Play] relative mt-10 md:mt-0 border border-[#451836]">
            <div
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer"
              onClick={handleClose}
            >
              <img
                src={Icons.GlowingClose}
                className="h-8 w-8 invert dark:filter-none dark:invert-0"
                alt="close"
              />
            </div>

            <h5 className="font-[Play] font-bold dark:text-white text-[#2b2e48] py-2 ml-3 w-full text-lg">
              Confirm Your Password
            </h5>

            <div className="px-1 md:px-14 mt-10 w-full">
              <p className="text-sm mb-4">
                For security reasons, please enter your password to continue
                with the email change.
              </p>

              <TitledInputDefault
                setText={(e) => setPassword(e.target.value)}
                name="password"
                title="Password"
                type="password"
                placeHolder="Enter your password"
                value={password}
                width={"w-[100%]"}
                className={"h-[36px] mt-[12px] mr-3"}
                tabindex={1}
                error={null}
              />
            </div>

            <div className="flex w-full justify-center">
              <BorderButtonSecondary
                isLoading={loading}
                text={"Cancel"}
                onClick={handleClose}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
              />
              <BorderButtonSecondary
                isLoading={loading}
                text={"Continue"}
                onClick={handleSubmit}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3 ml-2"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
