import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import { useSupport } from "hooks/useBugReport.hook";
import { FC, useState } from "react";

type InviteModalProps = {
  theirCallsign: string;
  onClose: () => void;
};

export const InviteModal: FC<InviteModalProps> = ({
  theirCallsign,
  onClose,
}) => {
  const [inviteForm, setInviteForm] = useState({
    theirEmail: "",
    theirCallsign,
    // comments: `Hi ${theirCallsign}, I want to invite you to join World Radio League.`,
  });

  const { isLoading, sendInvite } = useSupport();

  const submitInviteHandler = () => {
    sendInvite(inviteForm, () => {
      setInviteForm({
        theirEmail: "",
        theirCallsign: "",
      });
      onClose();
    });
  };

  return (
    <div className="w-full dashboardWrapper flex items-center justify-center">
      <div className="glassmorphism w-5/6 sm:w-2/3 md:w-1/2 py-11 px-5 sm:px-7 md:px-9 lg:p-12 flex flex-col items-center">
        <div className="w-full">
          <div className="font-Play text-xl text-white mb-2.5 font-bold">
            Send an invite to{" "}
            <span className="text-[#17F9DA]">{theirCallsign}</span>
          </div>
          <TitledInputDefault
            setText={(e) => {
              setInviteForm((prev: any) => {
                return {
                  ...prev,
                  theirEmail: e.target.value,
                };
              });
            }}
            title="Their Email"
            name="details"
            placeHolder="Enter email"
            value={inviteForm?.theirEmail}
            width="w-full"
            className="bg-white bg-opacity-10 h-[40px]"
            tabindex={1}
          />

          {/* <TitledInputDefault
            setText={(e) => {
              setInviteForm((prev: any) => {
                return {
                  ...prev,
                  comments: e.target.value,
                };
              });
            }}
            title="Comments "
            name="comments"
            placeHolder="comments about the invite..."
            value={inviteForm?.comments}
            width="w-full"
            className="bg-white bg-opacity-10 h-[100px]"
            tabindex={1}
            multiple
          /> */}
        </div>
        <BorderButtonSecondary
          isLoading={isLoading}
          text={"Send Invite"}
          onClick={submitInviteHandler}
          className="w-full lg:w-80 mt-8 mb-5"
        />

        <div
          className="absolute -top-3 -right-3 cursor-pointer"
          onClick={onClose}
        >
          <img
            src={Icons.GlowingClose}
            className="h-8 w-8 bg-gray-300 rounded-full dark:bg-transparent"
            alt="close"
          />
        </div>
      </div>
    </div>
  );
};
