import ActivityIndicator from "components/Loading/ActivityIndicator";
import React from "react";
import { ContestTableCompProps } from "types/Component";
import TableContestEntries from "../TableContestEntries";

function ContestTableComp({
  lists,
  isLoading,
  live,
  userEnrolledContests,
  onEdit,
  onDelete,
  passed,
}: ContestTableCompProps) {
  if (!isLoading && lists && lists.length) {
    return (
      <TableContestEntries
        lists={lists}
        live={live}
        passed={passed}
        userEnrolledContests={userEnrolledContests}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    );
  } else if (isLoading) {
    return (
      <div className="h-[200px] w-full">
        <ActivityIndicator size={36} />
      </div>
    );
  } else if (lists && lists.length === 0) {
    return (
      <div className="h-[200px] w-full dark:text-white text-[#2b2e48] font-[play] flex justify-center items-center">
        No Contests Found
      </div>
    );
  } else {
    return (
      <div className="h-[200px] w-full dark:text-white text-[#2b2e48] font-[play] flex justify-center items-center">
        Something Went Wrong
      </div>
    );
  }
}

export default ContestTableComp;
