import { showToast } from 'helpers/Toast';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editUserProfile } from 'store/Actions/profile.actions';
import { getUserProfileSelector } from 'store/Reducers/profile.reducer';
import { ToastTypes } from 'types/Component';
import { DistanceUnit, UserSettings } from 'types/Models';

export const useUserSettings = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  
const userProfile = useSelector(getUserProfileSelector);  
const settings = userProfile?.settings || { distanceUnit: DistanceUnit.MILES };

  const updateSettings = async (newSettings: Partial<UserSettings>) => {
    try {
      setIsLoading(true);
      
      const updatedSettings = {
        ...settings,
        ...newSettings
      };

      await dispatch(editUserProfile({
        settings: updatedSettings
      }));

      showToast({
        message: "Settings updated successfully!",
        type: ToastTypes.SUCCESS,
      });

    } catch (error) {
      console.error('Error updating settings:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    settings,
    updateSettings,
    isLoading
  };
}; 