import { VerticalDivider } from "components/Divider";
import React, { useState } from "react";

type Option = {
  id: string;
  objective: string;
  point: number;
};

interface MultiSelectListProps {
  options: Option[];
  selectedOptions: string[];
  onChange: (selected: string[]) => void;
  loading?: boolean;
}

const MultiSelectList: React.FC<MultiSelectListProps> = ({
  options,
  selectedOptions,
  onChange,
  loading = false,
}) => {
  const handleSelect = (id: string) => {
    if (selectedOptions.includes(id)) {
      onChange(selectedOptions.filter((optionId) => optionId !== id));
    } else {
      onChange([...selectedOptions, id]);
    }
  };

  return (
    <div className="w-full">
      <ul className="">
        {options.map((option) => (
          <li
            key={option.id}
            className={`flex justify-between items-center py-2 px-3 cursor-pointer mb-2 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            } dark:bg-[#FFFFFF0F] bg-[#f1f3f4]`}
            onClick={() => !loading && handleSelect(option.id)}
          >
            <div className="flex flex-row flex-1">
              <div className="text-sm dark:text-white text-[#2b2e48] w-[70%]">
                {option.objective}
              </div>
              <VerticalDivider />
              <div className="text-sm dark:text-white text-[#2b2e48]">{option.point} point</div>
            </div>
            <input
              type="checkbox"
              checked={selectedOptions.includes(option.id)}
              onChange={() => handleSelect(option.id)}
              className="form-checkbox h-4 w-4 text-blue-600"
              disabled={loading}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MultiSelectList;
