import Icons from "assets/Icons";
import TableLeaderBoard from "components/Table/TableLeaderBoard";
import useContest from "hooks/contest.hook";
import { CONTEST_STATUS } from "types/Functions";
import TopPlacement from "./TopPlacement";
import { SliderButtonGroup } from "components/SelectableButtonsGroup";
import { useMemo, useState } from "react";
import Lottie from "lottie-react";
import LottieFiles from "assets/LottieFiles";
import { useDispatch } from "react-redux";
import { setUserContestDetail } from "store/Actions/contest.actions";
import { globalNavigate } from "routes/GlobalRoutes";
import { CONTEST } from "constants/Links";

function Leaders() {
  const {
    contestDetail,
    contestLeaderBoard,
    contestStatus,
    fetchContestLeaderBoard,
    isContestLeaderBoardLoading,
  } = useContest();
  const [selectedFilter, setSelectedFilter] = useState<string | number>("all");
  const dispatch = useDispatch();
  const topThreeLeaders = useMemo(() => contestLeaderBoard.slice(0, 3), [contestLeaderBoard]);
  const topTenLeaders = useMemo(() => contestLeaderBoard.slice(0, 10), [contestLeaderBoard]);

  return (
    <>
      <div className="flex flex-col w-full gap-6">
        <div className="flex flex-row items-center dark:text-white text-[#2b2e48] gap-4 text-xl font-bold font-Play">
          <img src={Icons.LeadersIcon} alt="" />
          CURRENT LEADERS
        </div>

        <div className="flex flex-col md:flex-row w-full gap-4 px-4">
          {
            // display top three leaders
            topThreeLeaders.map((leader, index) => (
              <TopPlacement
                key={index}
                place={index === 0 ? "first" : index === 1 ? "second" : "third"}
                data={leader}
                isWFDContest={contestDetail?.isWFDContest}
                onClick={() => {
                  dispatch(setUserContestDetail(null));
                  globalNavigate(
                    `${CONTEST}/user-logbook-detail?contest_name=${contestDetail?.name}&contest_id=${contestDetail?.id}&user_id=${leader.uid}&tab=entries`
                  );
                }}
              />
            ))
          }
        </div>
      </div>

      <div className="flex flex-col w-full">
        <div className="flex flex-col md:flex-row w-full justify-between gap-4">
          <div className="flex flex-row items-center dark:text-white text-[#2b2e48] gap-4 text-xl font-bold font-Play w-full md:w-[40%]">
            <img src={Icons.LivesIcon} alt="" />
            TOP 10 LEADERBOARD
            {isContestLeaderBoardLoading ? (
              <div className={`w-${10} h-${10}`}>
                <Lottie
                  animationData={LottieFiles.ActivityIndicator}
                  loop={true}
                />
              </div>
            ) : null}
          </div>

          {contestDetail?.isWFDContest ? (
            <div className="w-full md:w-[50%] flex flex-row gap-4">
              <SliderButtonGroup
                initialSelected={selectedFilter}
                disabled={false}
                sliderClass="w-full"
                buttonClass="w-1/5"
                actions={[
                  {
                    id: "all",
                    label: "All",
                  },
                  {
                    id: "H",
                    label: "H - Home",
                  },
                  {
                    id: "I",
                    label: "I - Indoor",
                  },
                  {
                    id: "O",
                    label: "O - Outdoor",
                  },
                  {
                    id: "M",
                    label: "M - Mobile",
                  },
                ]}
                onButtonChange={(action) => {
                  if (action && action.id && contestDetail?.id) {
                    setSelectedFilter(action.id);

                    if (action.id === "all") {
                      fetchContestLeaderBoard(contestDetail?.id);
                    } else {
                      const filters = [
                        {
                          field: "contestClass",
                          operator: "==",
                          value: action.id,
                        },
                      ];
                      fetchContestLeaderBoard(contestDetail?.id, filters);
                    }
                  }
                }}
              />
            </div>
          ) : null}
        </div>

        <div className="mt-4 overflow-y-hidden">
          <div className="w-full">
            <TableLeaderBoard
              list={topTenLeaders}
              selectedTab={""}
              contest
              contestId={contestDetail?.id}
              contestName={contestDetail?.name}
              contestPassed={contestStatus === CONTEST_STATUS.PASSED}
              isWFDLogBook={contestDetail?.isWFDContest}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Leaders;
