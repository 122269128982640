import React, { FC, useState } from "react";
import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { globalNavigate } from "routes/GlobalRoutes";
import { LogbookTemplatesOptions } from "constants/Config";
import useConfig from "hooks/config.hook";

type ModalProps = {
  onClose: () => void;
};

export const LogBookStyleModal: FC<ModalProps> = ({ onClose }) => {
  const { selectedLogbook, saveLogbookSettings } = useLogbookSetting();
  const [logbookStyle, setlogbookStyle] = useState<string>(
    selectedLogbook?.logbookStyle || ""
  );
  const { handlePrimaryModal } = useConfig();
  const onSaveClickHandler = () => {
    saveLogbookSettings({ logbookStyle });
    onClose();
  };

  return (
    <section className="absolute w-full h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[20%] lg:px-[25%] overflow-scroll">
      <div className=" w-full ">
        <div className="w-full mt-20 bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] p-[1px]">
          <div className="flex flex-col w-full dark:bg-[#151541] bg-white dark:text-white text-[#2b2e48] font-[Play] relative px-[10%] py-[10%] gap-8">
            <div className="flex w-full flex-wrap relative">
              <LogbookSettingDropdown
                list={LogbookTemplatesOptions}
                title="Logbook Style:"
                value={logbookStyle || ""}
                setValue={(value) => {
                  if (value === "view") {
                    globalNavigate("/log-contacts/logbook-styles");
                  } else {
                    //logbook is not a contest logbook but user is try to change logbook style to WFD contest
                    if (value === "WFD" && !selectedLogbook?.contestId) {
                      onClose(); //close current modal
                      handlePrimaryModal({
                        title:
                          'To participate in the Winter Field Day contest, please go the the contest section and enroll in the "Winter Field Day 2025" contest. This action will generate a new logbook for use in the contest which can be accessed from the "My Logbook" tab on the Winter Field Day contest page.',
                        type: "Enroll to Winter Field Day 2025?",
                        onCancelPressed: () => handlePrimaryModal(null),
                        onSubmitPressed: () => {
                          globalNavigate(
                            "/contest/contest-detail?tab=contest_rules&contest_id=cCkt5FsidQkXim4Grhx6"
                          );
                          handlePrimaryModal(null);
                        },
                        submitText: "Take Me There",
                        cancelText: "Cancel",
                      });
                    } else {
                      setlogbookStyle(value);
                    }
                  }
                }}
                className="w-[80%] sm:w-[60%] md:w-[60%]"
              />
            </div>

            <div className="flex w-full justify-center">
              <BorderButtonSecondary
                // isLoading={loading || logbookSettingLoading}
                text={"Save"}
                onClick={onSaveClickHandler}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
              />
            </div>
            <div
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer"
              onClick={onClose}
            >
              <img
                src={Icons.GlowingClose}
                className="h-8 w-8 invert dark:filter-none dark:invert-0"
                alt="close"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
