import React, { Fragment, useEffect } from "react";

import Header from "components/Header";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  MarkerClustererF,
} from "@react-google-maps/api";
import { mapStylesDark, mapStylesLight } from "constants/MapStyles";
import RouteLoading from "components/Loading/RouteLoading";
import useActivity from "hooks/activity.hook";
import { useSelector } from "react-redux";
import { getActivitiesMapSelector } from "store/Reducers/pota.reducer";
import ActivityMarker from "components/ActivityMarker";
import { getIsActivitiesMapLoadingSelector } from "store/Reducers/loading.reducer";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import Icons from "assets/Icons";
import customMarkerIcon from "../../../assets/Icons/memberMarker.svg";
import useTheme from "hooks/useTheme.hook";
import CustomGradientButton from "components/Button/CustomGradientButton";
import IconTable from "components/icons/IconTable";
import IconGrid3x3 from "components/icons/IconGrid3x3";
import ToggleSwitch from "components/Button/ToggleSwitchButton";
import ActivityList from "components/ActivityList";

const MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API;
const googleLibraries: any[] = ["geometry", "marker", "maps", "drawing"];
function Activities() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: MAP_API_KEY!,
    libraries: googleLibraries,
  });

  const { theme } = useTheme();
  const activitiesMap = useSelector(getActivitiesMapSelector);
  const isActivitiesLoading = useSelector(getIsActivitiesMapLoadingSelector);

  const [showMapView, setShowMapView] = React.useState(true);

  const {
    getUserLocation,
    center,
    debouncedBoundChangeListener,
    onLoad,
    activityFilter,
    setActivityFilter,
    onCreateLogbook,
    userCoordinate,
    setInfoWindowOpen,
    debouncedDragListener,
    zoomLevel,
    debouncedSearchActivity,
    setSearchQuery,
    searchQuery,
  } = useActivity();
  const mapRef = React.useRef<any>();

  useEffect(() => {
    if (isLoaded) {
      getUserLocation();
    }
  }, [isLoaded]);

  const options = {
    imagePath:
      "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
  };
  return (
    <main className="relative w-full h-full">
      <Header title="Logbook" />
      <div className="w-full flex justify-center p-3.5 md:p-7 dashboardWrapper overflow-x-hidden">
        <div
          className={`${
            theme === "dark" ? "glassmorphism" : "bg-white"
          } w-full h-fit pb-6`}
        >
          <div className="px-3 md:px-7 py-5 flex items-center justify-between">
            <div className="flex flex-col sm:flex-row gap-2 md:gap-10 lg:gap-10 xl:gap-10 2xl:gap-10">
              <p className="font-[play] font-bold text-lg dark:text-white text-[#2b2e48]">
                POTA AND ACTIVITY{" "}
                <span className="dark:text-[#17F9DA] text-[#1e40af]">
                  SITES
                </span>
              </p>
            </div>
          </div>
          <div className="h-[0.5px] w-full bg-[#1b1d63]" />
          <div className="flex w-full justify-between items-center mt-3 mb-5">
            <div className="flex px-5 flex-wrap">
              <div className="relative">
                <img
                  src={Icons.SearchBarIcon}
                  alt="search icon"
                  className="absolute w-4 h-4 mt-2 ml-2"
                />
                <input
                  className="w-[250px] h-[30px] pl-[40px] mr-2 mb-2 flex items-center text-xs text-white justify-between rounded-sm px-3 border border-[#5a5a7a] bg-gradient-to-r from-[#3a3a5f] to-[#444467] transition-all duration-150 ease-in-out shadow-inner no-select"
                  placeholder="Search by Name or Reference"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value.toUpperCase());
                    debouncedSearchActivity(e.target.value);
                  }}
                />
              </div>
              {activityFilter.map((filter, index) => (
                <div key={index} className="mr-2 mb-2">
                  <ToggleSwitch
                    icon={filter.icon}
                    label={filter.name}
                    checked={filter.selected}
                    onChange={() => {
                      setActivityFilter((prev) => {
                        const newFilter = [...prev];
                        newFilter[index].selected = !newFilter[index].selected;
                        return newFilter;
                      });
                    }}
                    height={12}
                    width={25}
                  />
                </div>
              ))}
            </div>
            {isActivitiesLoading ? (
              <div className="h-[30px]">
                <ActivityIndicator size={14} />
              </div>
            ) : null}
          </div>
          <div className="flex justify-between w-full mb-7 px-5 ">
            <div className="flex flex-wrap">
              <CustomGradientButton
                icon={IconGrid3x3}
                iconClass={`w-[15px] h-[15px] ${
                  showMapView ? "text-[#000000]" : "text-white"
                }`}
                text="Map View"
                textClass={`text-[12px] ${
                  showMapView ? "text-[#010a41]" : "text-white"
                } ml-2`}
                className={`w-[130px] h-8 mb-2 rounded-[3px] cursor-pointer border ${
                  showMapView
                    ? "from-[#828aff] to-[#8ae9ff] border-[#505bf9]"
                    : "bg-gradient-to-r from-[#3a3a5f] to-[#444467] hover:from-[#4c4c6d] hover:to-[#5a5a7a] border-[#5a5a7a] transition-all duration-150 ease-in-out shadow-inner"
                }`}
                onClick={() => {
                  setShowMapView(true);
                }}
              />
              <CustomGradientButton
                icon={IconTable}
                iconClass={`w-[15px] h-[15px] ${
                  showMapView ? "text-white" : "text-[#000000]"
                }`}
                text="List View"
                textClass={`text-[12px] ${
                  showMapView ? "text-white" : "text-[#010a41]"
                } ml-2`}
                className={`md:ml-3 lg:ml-3 mb-2 w-[130px] h-8 rounded-[3px] cursor-pointer border ${
                  showMapView
                    ? "bg-gradient-to-r from-[#3a3a5f] to-[#444467] hover:from-[#4c4c6d] hover:to-[#5a5a7a] border-[#5a5a7a] transition-all duration-150 ease-in-out shadow-inner"
                    : "from-[#828aff] to-[#8ae9ff] border-[#505bf9]"
                }`}
                onClick={() => {
                  setShowMapView(false);
                }}
              />
            </div>
          </div>
          {showMapView ? (
            <div className="w-full h-[75vh] relative mt-3">
              {!isLoaded && !center ? (
                <RouteLoading />
              ) : (
                <>
                  <GoogleMap
                    ref={mapRef}
                    mapContainerClassName="map-container no-close-button-on-info-windows"
                    center={center}
                    onLoad={onLoad}
                    onBoundsChanged={debouncedBoundChangeListener}
                    onDrag={debouncedDragListener}
                    zoom={10}
                    options={{
                      styles: [
                        ...(theme === "dark" ? mapStylesDark : mapStylesLight),
                        {
                          featureType: "poi", // Points of Interest (landmarks, etc.)
                          elementType: "all",
                          stylers: [{ visibility: "off" }], // Hide all POIs
                        },
                      ],
                    }}
                  >
                    <MarkerClustererF options={options}>
                      {(clusterer) => (
                        <Fragment>
                          {activitiesMap.map((activity, index) => (
                            <ActivityMarker
                              key={`${activity.latitude}-${activity?.longitude}-${index}`}
                              activity={activity}
                              index={index}
                              onCreateLogbook={onCreateLogbook}
                              clusterer={zoomLevel >= 10 ? null : clusterer} // if user is zoomed in we dont want to cluster
                              setInfoWindowOpen={setInfoWindowOpen}
                            />
                          ))}
                        </Fragment>
                      )}
                    </MarkerClustererF>

                    {userCoordinate ? (
                      <MarkerF
                        position={{
                          lat: userCoordinate?.latitude,
                          lng: userCoordinate?.longitude,
                        }}
                        icon={{
                          url: customMarkerIcon,
                          scaledSize: new window.google.maps.Size(40, 40),
                        }}
                      ></MarkerF>
                    ) : null}
                  </GoogleMap>
                  <div className="flex justify-center relative">
                    <button
                      className="dark:bg-[#010A41] bg-white px-4 py-1 rounded-xl border-[0.5px] border-white absolute top-[-60px] flex items-center cursor-pointer"
                      onClick={getUserLocation}
                    >
                      <img
                        src={Icons.Location}
                        alt=""
                        className="w-[17px] h-[17px] mr-3 invert dark:filter-none dark:invert-0"
                      />
                      <h3 className="font-[Play] dark:text-white text-[#2b2e48] font-bold">
                        Find My Location
                      </h3>
                    </button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <ActivityList onCreateLogbook={onCreateLogbook} />
          )}
        </div>
      </div>
    </main>
  );
}

export default Activities;
