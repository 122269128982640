import Icons from "assets/Icons";
import Header from "components/Header";
// import ActivityIndicator from "components/Loading/ActivityIndicator";
// import TableLeaderBoard from "components/Table/TableLeaderBoard";
import TableWrapperLeaderBoard from "components/Table/TableWrapperLeaderBoard";
import LeaderBoardTab from "components/TopTab/LeaderBoardTab";
import { Modes } from "constants/ArrayVariables";
// import Awards, { AwardsType } from "constants/Awards";
import useLeaderBoard from "hooks/leaderBoard.hook";
// import useProfile from "hooks/profile.hook";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import LeaderBoardFilterControls from "components/LeaderBoardFilterControls";
import LeaderBoardTimer from "components/LeaderBoardTimer";
import { getEndOfWeekDate, getISOWeek, getWeekRange } from "helpers/Utils";

import MapChart from "components/UsMap";
import WorldMap from "components/WorldMap";
import { useDispatch, useSelector } from "react-redux";
import {
  getDXLeaderBoard,
  getDistanceLeaderBoard,
  getLeaderBoardByWeek,
  getLeaderBoardByWeekByMode,
} from "store/Actions/leaderBoard.action";
import {
  getIsDXLeaderBoardLoadingSelector,
  getIsDistanceLeaderBoardLoadingSelector,
  getIsLeaderBoardByModeWeekLoadingSelector,
  getIsLeaderBoardByWeekLoadingSelector,
} from "store/Reducers/loading.reducer";
import {
  getDXLeadersSelector,
  getDistanceLeadersSelector,
  getWeekLeadersSelector,
  getWeekModeLeadersSelector,
} from "store/Reducers/leaderBoard.reducer";
import CountryPicker from "components/Inputs/CountryPicker/CountryPicker";
import { globalNavigate } from "routes/GlobalRoutes";
import Warning from "components/Warning";
// import { BsPersonCircle } from "react-icons/bs";
function LeaderBoard() {
  // const { userProfile } = useProfile();
  const dispatch = useDispatch();

  const { setSelectedTab, selectedTab } = useLeaderBoard();

  const isWeekLeaderboardLoading = useSelector(
    getIsLeaderBoardByWeekLoadingSelector
  );
  const isWeekModeLeaderboardLoading = useSelector(
    getIsLeaderBoardByModeWeekLoadingSelector
  );
  const isDistanceLeaderLoading = useSelector(
    getIsDistanceLeaderBoardLoadingSelector
  );
  const isDXLeaderboardLoading = useSelector(getIsDXLeaderBoardLoadingSelector);

  const weekLeaders = useSelector(getWeekLeadersSelector);
  const weekModeLeaders = useSelector(getWeekModeLeadersSelector);
  const distanceLeaders = useSelector(getDistanceLeadersSelector);
  const dxLeaders = useSelector(getDXLeadersSelector);
  let [searchParams, setSearchParams] = useSearchParams();
  // const [selectedMode, setSelectedMode] = useState<string>(
  //   LEADER_BOARD_MODE.WEEKLY
  // );

  useEffect(() => {
    setSelectedTab(
      searchParams.get("mode-tab") ? searchParams.get("mode-tab")! : Modes[0]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (!searchParams.get("show")) {
      const newSearchParams = new URLSearchParams(searchParams);
      if (!searchParams.get("week")) {
        newSearchParams.set("week", `${getISOWeek()}`);
      }
      newSearchParams.set("show", "weekly");
      setSearchParams(newSearchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const week = searchParams.get("week")
      ? parseInt(searchParams.get("week")!)
      : getISOWeek();
    if (searchParams.get("show") === "weekly") {
      dispatch(getLeaderBoardByWeek({ week }));
      dispatch(getDistanceLeaderBoard({ week }));

      dispatch(getLeaderBoardByWeekByMode(week, selectedTab));
      dispatch(
        getDXLeaderBoard({
          week,
        })
      );
    } else if (searchParams.get("show") === "alltime") {
      dispatch(
        getLeaderBoardByWeek({
          week: null,
        })
      );
      dispatch(getDistanceLeaderBoard({ week: null }));

      dispatch(getLeaderBoardByWeekByMode(undefined, selectedTab));
      dispatch(
        getDXLeaderBoard({
          week: null,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("week"), searchParams.get("show")]);

  const setSelectedMode = (value: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("show", value);
    setSearchParams(newSearchParams);
  };
  const increaseWeek = () => {
    const currentISOWeek = parseInt(searchParams.get("week")!);
    if (currentISOWeek < 52) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("week", `${currentISOWeek + 1}`);
      setSearchParams(newSearchParams);
    }
  };
  const decreaseWeek = () => {
    const currentISOWeek = parseInt(searchParams.get("week")!);
    if (currentISOWeek > 1) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set("week", `${currentISOWeek - 1}`);
      setSearchParams(newSearchParams);
    }
  };


  return (
    <main className="relative w-full flex-grow overflow-x-auto h-full">
      <Header title="LeaderBoard" />
      <div className="p-3 md:p-7 pb-10 dashboardWrapper overflow-x-hidden">
        <LeaderBoardFilterControls
          selectedMode={searchParams.get("show")!}
          setSelectedMode={setSelectedMode}
          increaseWeek={increaseWeek}
          decreaseWeek={decreaseWeek}
          currentWeek={searchParams.get("week")!}
        />
        {searchParams.get("week") &&
        parseInt(searchParams.get("week")!) < getISOWeek() ? (
          <p className="dark:text-[#17F9DA] text-[#1e40af] text-[18px] font-[Play] font-bold text-center mb-2">
            {getWeekRange(parseInt(searchParams.get("week")!) - 1)}
          </p>
        ) : searchParams.get("show") === "alltime" ? null : (
          <p className="dark:text-[#17F9DA] text-[#1e40af] text-[18px] font-[Play] font-bold text-center mb-2">
            Week ends Sunday at {getEndOfWeekDate()} midnight
          </p>
        )}

        {searchParams.get("week") &&
        parseInt(searchParams.get("week")!) <
          getISOWeek() ? null : searchParams.get("show") ===
          "alltime" ? null : (
          <LeaderBoardTimer />
        )}

        <Warning message="Imported Contacts do not count towards the leaderboard - you must use the WRL Logger." />

        <div className="flex flex-wrap justify-start sm:justify-between">
          <div className="w-full sm:w-[49%] dark:bg-[#0B0B38] bg-white h-fit mb-10 sm:mb-0">
            <div className="w-full border-b border-solid dark:border-b-white flex items-center py-[15px] pl-[24px]">
              <img className="h-5 w-5 invert dark:filter-none dark:invert-0" src={Icons.TopChart} alt="icon" />
              <p className="dark:text-white text-[#2b2e48] font-[play] font-bold text-base ml-[15px]">
                MOST CONTACTS <span className="dark:text-[#17F9DA] text-[#1e40af]">OVERALL</span>
              </p>
            </div>
            <TableWrapperLeaderBoard
              isLoading={isWeekLeaderboardLoading}
              list={weekLeaders || []}
              leaderboard={true}
              weekly={searchParams.get("show") === "weekly"}
            />
          </div>
          <div className="w-full sm:w-[49%] dark:bg-[#0B0B38] bg-white h-fit">
            <div className="border-b border-solid dark:border-b-white w-full pb-[10px]">
              <div className="w-full flex items-center py-[15px] pl-[24px] mb-0">
                <img className="h-5 w-5 invert dark:filter-none dark:invert-0" src={Icons.TopChart} alt="icon" />
                <p className="dark:text-white text-[#2b2e48] font-[play] font-bold text-base ml-[15px]">
                  TOP OPERATORS <span className="dark:text-[#17F9DA] text-[#1e40af]">BY MODE</span>
                </p>
              </div>
              <LeaderBoardTab
                tabs={Modes}
                setSelectedTab={(tab) => {
                  const newSearchParams = new URLSearchParams(searchParams);
                  newSearchParams.set("mode-tab", tab);
                  setSearchParams(newSearchParams);
                }}
                selectedTab={selectedTab}
                fetchUsersDataByMode={(item) => {
                  const week = searchParams.get("week")
                    ? parseInt(searchParams.get("week")!)
                    : getISOWeek();
                  dispatch(
                    getLeaderBoardByWeekByMode(
                      searchParams.get("show")! === "alltime"
                        ? undefined
                        : week,
                      item
                    )
                  );
                }}
              />
            </div>
            <TableWrapperLeaderBoard
              isLoading={isWeekModeLeaderboardLoading}
              list={weekModeLeaders || []}
              selectedTab={selectedTab}
              leaderboard={true}
              weekly={searchParams.get("show") === "weekly"}
            />
          </div>
          <div className="w-full sm:w-[49%] dark:bg-[#0B0B38] bg-white h-fit mb-10 sm:mb-0">
            <div className="w-full border-b border-solid dark:border-b-white flex items-center py-[15px] pl-[24px]">
              <img className="h-5 w-5 invert dark:filter-none dark:invert-0" src={Icons.TopChart} alt="icon" />
              <p className="dark:text-white text-[#2b2e48] font-[play] font-bold text-base ml-[15px]">
                LONGEST <span className="dark:text-[#17F9DA] text-[#1e40af]">DISTANCE</span>
              </p>
            </div>
            <TableWrapperLeaderBoard
              isLoading={isDistanceLeaderLoading}
              list={distanceLeaders || []}
              distance={true}
              leaderboard={true}
              weekly={searchParams.get("show") === "weekly"}
            />
          </div>
          <div className="w-full sm:w-[49%] dark:bg-[#0B0B38] bg-white h-fit mb-10 sm:mb-0 mt-4">
            <div className="w-full border-b border-solid dark:border-b-white flex items-center py-[15px] pl-[24px]">
              <img className="h-5 w-5 invert dark:filter-none dark:invert-0" src={Icons.TopChart} alt="icon" />
              <p className="dark:text-white text-[#2b2e48] font-[play] font-bold text-base ml-[15px]">
                MOST <span className="dark:text-[#17F9DA] text-[#1e40af]">DX CONTACTS</span>
              </p>
            </div>
            <TableWrapperLeaderBoard
              isLoading={isDXLeaderboardLoading}
              list={
                dxLeaders && Array.isArray(dxLeaders)
                  ? dxLeaders?.sort(
                      (a, b) => b.leaderBoardCount! - a.leaderBoardCount!
                    )
                  : []
              }
              leaderboard={true}
              weekly={searchParams.get("show") === "weekly"}
            />
          </div>
          <div className="w-full sm:w-[100%] dark:bg-[#0B0B38] bg-white h-fit mb-10 sm:mb-0 mt-4">
            <div className="w-full border-b border-solid dark:border-b-white flex items-center py-[15px] pl-[24px]">
              <img className="h-5 w-5 invert dark:filter-none dark:invert-0" src={Icons.TopChart} alt="icon" />
              <p className="dark:text-white text-[#2b2e48] font-[Play] font-bold text-base ml-[15px]">
                US STATE <span className="dark:text-[#17F9DA] text-[#1e40af]">LEADERBOARDS</span>
              </p>
            </div>
            <div className="w-full ml-0 md:ml-[10%] md:w-[80%] dark:bg-[#0B0B38] bg-white justify-center items-center overflow-visible">
              <MapChart />
            </div>
          </div>
          <div className="w-full sm:w-[100%] dark:bg-[#0B0B38] bg-white h-fit mb-10 sm:mb-0 mt-4">
            <div className="w-full border-b border-solid dark:border-b-white flex items-center py-[15px] pl-[24px]">
              <img className="h-5 w-5 invert dark:filter-none dark:invert-0" src={Icons.TopChart} alt="icon" />
              <p className="dark:text-white text-[#2b2e48]e font-[Play] font-bold text-base ml-[15px] w-[50%]">
                COUNTRY <span className="dark:text-[#17F9DA] text-[#1e40af]">LEADERBOARDS</span>
              </p>
              <div className="w-full flex justify-end">
                <div className="w-[200px]">
                  <CountryPicker
                    label="Country"
                    placeholder="Please input new information"
                    name="country"
                    value={""}
                    setText={(val) => {
                      globalNavigate(
                        `/leaderboard/detail?country=${val}&week=${
                          searchParams.get("week")
                            ? searchParams.get("week")
                            : 1
                        }&show=${
                          searchParams.get("show")
                            ? searchParams.get("show")
                            : "weekly"
                        }`
                      );
                    }}
                    error={""}
                    className="w-[100px]"
                  />
                </div>
              </div>
            </div>
            <div className="w-full ml-0 md:ml-[10%] md:w-[80%] dark:bg-[#0B0B38] bg-white justify-center items-center">
              <WorldMap />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default LeaderBoard;
