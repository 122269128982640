import { useState, useMemo } from "react";
import { SearchCountryPickerProps } from "types/Component";
import Select from "react-select";
import { dxccCountryList } from "constants/DXCCCountryList";
import { DXCCList } from "types/Models";
import { useField } from "formik";
import { RiInformationLine } from "react-icons/ri";
import useTheme from "hooks/useTheme.hook";

function SearchCountryPicker({
  name,
  title,
  info,
  placeHolder,
  tabindex,
  width,
  containerClass,
  setText,
  value,
  dxccNumber,
  countryCode,
  error,
}: SearchCountryPickerProps) {
  const { theme } = useTheme();
  const [infoOpen, setInfoOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");

  const [field] = useField({
    name: name ? name : "",
  });

  const handleChange = (option: any) => {
    setText(option.label);
  };
  const transformData = (data: any) => {
    return data.map((item: any) => ({
      value: item.flagCode,
      label: item.country,
    }));
  };

  const options = useMemo(
    () => transformData(dxccCountryList),
    [dxccCountryList]
  );

  return (
    <div
      className={`${width ? width : "w-full"} relative ${
        containerClass ? containerClass : ""
      }`}
      title={value || ""}
    >
      <div className="flex items-center">
        <label className="font-[Barlow] font-bold text-sm dark:text-white text-[#2b2e48] truncate">
          {title}
        </label>

        {info ? (
          <div
            onMouseEnter={info ? () => setInfoOpen(true) : () => {}}
            onMouseLeave={info ? () => setInfoOpen(false) : () => {}}
          >
            <RiInformationLine color="#999" className="ml-1" />
          </div>
        ) : null}
      </div>
      <Select
        tabIndex={tabindex}
        onChange={handleChange}
        placeholder={placeHolder}
        options={options}
        value={{
          label: value,
          value: countryCode,
        }}
        isSearchable={true}
        styles={{
          control: (provided) => ({
            ...provided,
            minHeight: "2rem",
            marginBottom: "1rem",
            backgroundColor: theme ==="dark" ? "#23234C" :"#f1f3f4",
            marginTop: "0.375rem",
            height: "2rem",
            borderRadius: 0,
            borderColor: error ? "rgb(194 65 12)" : theme ==="dark" ? "transparent": "#dcdcdd",
            "&:hover": {
              borderColor: "#888",
            },
            borderWidth: 1,
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#6c757d" : "#00022F",
            color: state.isSelected ? "#fff" : "#fff",
            borderWidth: 1,
            borderColor: "#23234C",
            "&:hover": {
              backgroundColor: "#6c757d",
              color: "#fff",
            },
            margin: 0,
            padding: "8px 12px",
            fontFamily: "Barlow",
            fontSize: "0.875rem",
          }),
          menu: (provided) => ({
            ...provided,
            backgroundColor: "#f5f5f5",
            marginTop: 0,
            paddingTop: 0,
            fontFamily: "Barlow",
            fontSize: "0.875rem",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: theme === "dark" ?  "#fff" : "#2b2e48" ,
            fontFamily: "Barlow",
            fontSize: "0.875rem",
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "#888",
            fontFamily: "Barlow",
            fontSize: "0.875rem",
          }),
          input: (provided) => ({
            ...provided,
            color: theme === "dark" ?  "#fff" : "#2b2e48" ,
            fontFamily: "Barlow",
            fontSize: "0.875rem",
          }),
        }}
      />
      {dxccNumber ? (
        <span className="dark:text-white text-[#2b2e48] font-[Play] absolute text-xs top-[80%]">
          <span className="dark:text-[#16F8DA] text-[#1e40af]">DXCC Number:</span> {dxccNumber}
        </span>
      ) : null}

      {info && infoOpen ? (
        <div className="absolute top-[50%] left-0 right-0 z-[1000] px-[4px] py-[5px] lg:px-[6px] xl:px-[10px] text-[12px] font-[Play] bg-[#524A6F] text-white">
          {info}
        </div>
      ) : null}
    </div>
  );
}

export default SearchCountryPicker;
