import React, { useState } from 'react';

type AccordionProps = {
  title: string;
  children: React.ReactNode;
};

const Accordion: React.FC<AccordionProps> = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="dark:text-white text-[#2b2e48] mt-2">
      <button
        className="w-full flex justify-between items-center py-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center">
          <span
            className={`mr-2 transition-transform transform ${
              isOpen ? 'rotate-90' : ''
            }`}
          >
            {/* Replace with your preferred open/close icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </span>
          <h3 className="font-['Barlow'] text-base font-bold">{title}</h3>
        </div>
      </button>
      {isOpen && (
        <div className='flex'>{children}</div>
      )}
    </div>
  );
};

export default Accordion;
