import { Field } from "formik";
import { InputWithLabelProps } from "types/Component";

function InputWithLabel({
  multiline,
  label,
  placeholder,
  name,
  setText,
  error,
  onBlur,
}: InputWithLabelProps) {
  return (
    <div
      className={`w-full dark:bg-[#FFFFFF0F] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none relative px-[15px] py-1 h-[55px] ${
        error ? "!border !border-orange-700 !border-solid" : ""
      }`}
    >
      <p className="dark:text-[#17F9DA] text-[#1e40af] text-xs font-[Play] mb-1.5">{label}</p>
      {/* <input
        multiple={multiline}
        className={`w-full bg-transparent font-medium font-[Barlow] text-base text-white`}
        placeholder={placeholder ? placeholder : ""}
        type="text"
        name={name}
        id=""
        onChange={setText}
      /> */}
      <Field
        multiple={multiline}
        className={`w-full bg-transparent font-medium font-[Barlow] text-sm dark:text-white text-[#2b2e48]`}
        onChange={setText}
        type={"text"}
        name={name}
        id=""
        onBlur={onBlur ? onBlur : () => {}}
        placeholder={placeholder}
        // {...props}
      />
    </div>
  );
}

export default InputWithLabel;
