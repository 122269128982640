import { LogBookContactTable, UserDataTable } from "constants/Collections";
import { frequencyRanges } from "constants/FrequencyRanges";
import { db, auth, getZonesFromCoordinate } from "firebase-config";
import { PDFDocument, rgb } from "pdf-lib";
import ReactCountryFlag from "react-country-flag";
import * as geofire from "geofire-common";

import fontkit from "@pdf-lib/fontkit";
import {
  collection,
  endBefore,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  where,
  Timestamp,
} from "firebase/firestore";
import moment from "moment";
import { CONTEST_STATUS, FindCode } from "types/Functions";
import {
  DXCCList,
  LogBookContactModel,
  UserProfile,
  UserAwards,
  ParkReferenceModel,
  Activities,
  SortConfig,
} from "types/Models";
import { SUPER_ADMIN } from "constants/Permissions";
import { CountryRegionData } from "react-country-region-selector";
import { usStatesCoordinates, WFDClassOptions, WFDSectionOptions } from "constants/Config";

import { dxccCountryList } from "constants/DXCCCountryList";
import { geohashQueryBounds, Geopoint } from "geofire-common";
import Icons from "assets/Icons";
import { Sort } from "types/QSOManager.types";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import Maidenhead from "maidenhead";

const countryData = require("../../constants/CountryCodes.json");
var find = require("lodash.find");
var isEmpty = require("lodash.isempty");

export * from "./azimuth";

export const hasAccessTo = async (permission: string) => {
  //fetch user id token and verify if the have a custom claim for the permission
  try {
    const user = auth.currentUser;
    if (user) {
      const idTokenResult = await user.getIdTokenResult();
      if (
        idTokenResult.claims[permission] ||
        idTokenResult.claims[SUPER_ADMIN]
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } catch (e) {
    // console.log(e);
    return false;
  }
};

export const findBandAndModeForFrequency = (frequency: number) => {
  // First, check for an exact match (where start and end are the same)
  for (const item of frequencyRanges) {
    if (frequency === item.start && item.start === item.end) {
      return {
        band: item.band,
        mode: item.mode,
      };
    }
  }

  // If no exact match, check the broader ranges
  for (const item of frequencyRanges) {
    if (frequency >= item.start && frequency <= item.end) {
      return {
        band: item.band,
        mode: item.mode,
      };
    }
  }

  return null; // Return null if no match is found
};

export const getFormattedDate = (date: any) => {
  if (!date) {
    return "";
  }
  // Split the date string into [year, month, day]
  const parts = date.split("-");

  // Ensure the year, month, and day are in the correct format
  const year = parts[0];
  const month = parts[1].length === 1 ? `0${parts[1]}` : parts[1];
  const day = parts[2].length === 1 ? `0${parts[2]}` : parts[2];

  // Concatenate the year, month, and day into the ADIF format
  return `${year}-${month}-${day}`;
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case "Complete":
      return "text-[#00FF87]";
    case "Failed":
      return "text-[#FF3737]";
    case "Complete With Errors":
      return "text-[#FFF500]";
    case "In Progress":
      return "text-[#fff]";
    default:
      return "text-[#fff]";
  }
};

export const getLogBookErrorMessages = (errorCode: string) => {
  if (errorCode === "QUOTA_EXCEEDED") {
    return "You have reached your import quota, please upgrade to import more contacts. Note that you can still add your contacts manually.";
  } else if (errorCode === "MISSING_REQUIRED_FIELDS") {
    return "We found some missing fields while importing your contacts, we still imported your contacts but you will need to manually add the missing fields from the table below. Required fields will be marked yellow.";
  } else if (errorCode === "UNKNOWN_ERROR") {
    return "An unknown error occurred while importing your contacts, please try again later.";
  }
};

export const getLogbookErrorColors = (errorCode: string) => {
  if (errorCode === "QUOTA_EXCEEDED" || errorCode === "UNKNOWN_ERROR") {
    return ["bg-[#FEF1F2]", "text-[#9C2624]", "text-[#B91C1B]"];
  } else if (errorCode === "MISSING_REQUIRED_FIELDS") {
    return ["bg-[#FEFCE8]", "text-[#844D0F]", "text-[#A16207]"];
  } else {
    return ["", "", ""];
  }
};

export const openInNewTab = (url: string | undefined) => {
  if (url) {
    window.open(url, "_blank", "noreferrer");
  }
};

export const getContactDateForAdif = (date: string | undefined) => {
  if (!date) {
    return "";
  }
  const dateStr = date.replaceAll("-", "");
  return dateStr;
};

export const getContactTimeForAdif = (time: string | undefined) => {
  if (!time) {
    return "";
  }
  const timeStr = time.replaceAll(":", "");
  return timeStr;
};

export const findCountryCode = (opts: FindCode) => {
  if (!isEmpty(opts)) {
    return find(countryData, opts);
  } else {
    return undefined;
  }
};

export const splitText = (character: string, text: string | null) => {
  if (text) {
    return text.split(character);
  } else {
    return [];
  }
};

export const formatFirebaseDate = (format: string, seconds?: number) => {
  if (seconds) {
    const miliSecs = seconds * 1000;
    return moment(new Date(miliSecs)).format(format);
  } else {
    return "";
  }
};

export const getStartOfWeek = () => {
  const today = new Date();
  const dayOfWeek = today.getUTCDay();
  const startOfWeek = new Date(today);
  startOfWeek.setUTCHours(0, 0, 0, 0);
  return startOfWeek.setUTCDate(today.getUTCDate() - dayOfWeek);
};

export const getUidWithMostRecords = (array: any[]): any => {
  let count: any = {};

  // Count each "uid" occurrence
  for (let obj of array) {
    if (obj.uid in count) {
      count[obj.uid]++;
    } else {
      count[obj.uid] = 1;
    }
  }

  // Find the most repeated "uid"
  let maxUID = null;
  let maxCount = -1;
  for (let uid in count) {
    if (count[uid] > maxCount) {
      maxCount = count[uid];
      maxUID = uid;
    }
  }

  return maxUID;
};

export const sanitizeData: any = (data: any) => {
  if (typeof data !== "object" || data === null) {
    // Base case: if data is not an object or is null, return it as is
    return data;
  }

  if (Array.isArray(data)) {
    // If it's an array, map over its elements and sanitize each one
    return data.map((item) => sanitizeData(item));
  }

  // If it's an object, iterate over its properties and sanitize each value
  const sanitizedObject: any = {};
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const sanitizedValue: any = sanitizeData(data[key]);
      sanitizedObject[key] = sanitizedValue !== undefined ? sanitizedValue : "";
    }
  }

  return sanitizedObject;
};

export const savePDFAward = async (
  date: string,
  fullName: string,
  callsign: string,
  foundingNumber: string,
  download: boolean,
  award?: UserAwards
) => {
  const url =
    award?.name?.includes("Field Day") && award?.pdf
      ? award?.pdf
      : "/assets/FoundingAward.pdf";

  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  pdfDoc.registerFontkit(fontkit);

  // const boldFontBytes = await fetch(
  //   "/assets/fonts/DigitalSansBoldItalic.otf"
  // ).then((res) => res.arrayBuffer());
  // const lightFontBytes = await fetch("/assets/fonts/DigitalSansLight.otf").then(
  //   (res) => res.arrayBuffer()
  // );
  // const regularFontBytes = await fetch(
  //   "/assets/fonts/DigitalSansRegular.otf"
  // ).then((res) => res.arrayBuffer());

  // const boldFont = await pdfDoc.embedFont(boldFontBytes);
  // const regularFont = await pdfDoc.embedFont(regularFontBytes);
  // const lightFont = await pdfDoc.embedFont(lightFontBytes);

  const pages = pdfDoc.getPages();
  const firstPage = pages[0];

  if (award?.name?.includes("Field Day")) {
    firstPage.drawText(`${fullName} - ${callsign}`, {
      x: 50,
      y: 225,
      size: 30,
      // font: boldFont,
      color: rgb(45 / 255, 49 / 255, 146 / 255),
    });

    firstPage.drawText(foundingNumber, {
      x: 160,
      y: 80,
      size: 10,
      // font: boldFont,
      color: rgb(45 / 255, 49 / 255, 146 / 255),
    });

    firstPage.drawText(date, {
      x: 182,
      y: 46,
      size: 10,
      // font: boldFont,
      color: rgb(45 / 255, 49 / 255, 146 / 255),
    });
  } else {
    firstPage.drawText(fullName, {
      x: 140,
      y: 295,
      size: 35,
      // font: boldFont,
      color: rgb(45 / 255, 49 / 255, 146 / 255),
    });
    firstPage.drawText(callsign, {
      x: 510,
      y: 295,
      size: 35,
      // font: boldFont,
      color: rgb(45 / 255, 49 / 255, 146 / 255),
    });

    firstPage.drawText(foundingNumber, {
      x: 130,
      y: 135,
      size: 10,
      // font: boldFont,
      color: rgb(45 / 255, 49 / 255, 146 / 255),
    });

    firstPage.drawText(date, {
      x: 170,
      y: 95,
      size: 10,
      // font: boldFont,
      color: rgb(45 / 255, 49 / 255, 146 / 255),
    });
  }
  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  if (download) {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = award?.name?.includes("Field Day")
      ? "WorldRadioLeagueFieldDayAward.pdf"
      : "WorldRadioLeagueFoundingMemberAward.pdf";
    link.click();
  } else {
    const blobUrl = URL.createObjectURL(blob);
    const iframe = document.createElement("iframe");
    // Set the position offscreen instead of display: none;
    iframe.style.position = "fixed";
    iframe.style.right = "0";
    iframe.style.bottom = "0";
    iframe.style.width = "1px";
    iframe.style.height = "1px";
    iframe.style.border = "none";

    document.body.appendChild(iframe);

    iframe.onload = () => {
      // Add a delay before printing to ensure PDF is ready
      setTimeout(() => {
        if (iframe.contentWindow) {
          iframe.contentWindow.print();
        } else {
        }
      }, 500); // Delay for 500 milliseconds before printing
    };

    iframe.src = blobUrl;
  }
};

export const getISOWeek = () => {
  const currentDate = new Date();
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const startOfYearDay = startOfYear.getDay(); // Day of week the year starts on

  // Adjust so that the week starts on Monday (1) instead of Sunday (0)
  const dayAdjustment = startOfYearDay === 0 ? 6 : startOfYearDay - 1;
  const adjustedStartOfYear = new Date(startOfYear);
  adjustedStartOfYear.setDate(startOfYear.getDate() - dayAdjustment);

  const daysBetween = Math.floor(
    (currentDate.getTime() - adjustedStartOfYear.getTime()) /
      (24 * 60 * 60 * 1000)
  );
  const isoWeek = Math.ceil((daysBetween + 1) / 7);

  return isoWeek;
};

export const getStartAndEndDate = (weekNumber: number, year: number) => {
  var startDate = new Date(year, 0, 1 + (weekNumber - 1) * 7);
  var endDate = new Date(startDate.getTime());
  endDate.setDate(endDate.getDate() + 6);

  // Adjust for weeks that cross a year boundary
  if (startDate.getFullYear() < year) {
    startDate.setDate(
      startDate.getDate() + (year - startDate.getFullYear()) * 7
    );
    endDate.setDate(endDate.getDate() + (year - startDate.getFullYear()) * 7);
  }

  return {
    start: startDate,
    end: endDate,
  };
};

export const getEndOfWeekDate = () => {
  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay(); // 0 for Sunday, 6 for Saturday

  // If it's already Sunday, return the current date (end of week)
  if (currentDayOfWeek === 0) {
    // Format as MM/DD/YY
    return (
      currentDate.getMonth() +
      1 +
      "/" +
      currentDate.getDate() +
      "/" +
      currentDate.getFullYear().toString().substr(-2)
    );
  }

  // If not Sunday, calculate days until end of week (next Sunday)
  const daysUntilEndOfWeek = 7 - currentDayOfWeek;

  // Add the required number of days to the current date
  currentDate.setDate(currentDate.getDate() + daysUntilEndOfWeek);

  // Format as MM/DD/YY
  return (
    currentDate.getMonth() +
    1 +
    "/" +
    currentDate.getDate() +
    "/" +
    currentDate.getFullYear().toString().substr(-2)
  );
};

export const getWeekRange = (weekNumber: number) => {
  const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1); // January 1st of the current year
  const dayOfWeek = firstDayOfYear.getDay(); // Day of the week (0-6, Sunday-Saturday)

  // Days to the first Monday
  const daysToFirstMonday = dayOfWeek === 0 ? 1 : 8 - dayOfWeek;

  // Calculate the start (Monday) of the specified week
  const startOfWeek = new Date(firstDayOfYear);
  startOfWeek.setDate(
    startOfWeek.getDate() + daysToFirstMonday + (weekNumber - 1) * 7
  );

  // Calculate the end (Sunday) of the specified week
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(endOfWeek.getDate() + 6);

  // Format the dates
  const formatDate = (date: any) =>
    `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  return `Monday ${formatDate(startOfWeek)} to Sunday ${formatDate(endOfWeek)}`;
};

export const truncateString = (str: string, num: number) => {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
};

export const calculateMaidenhead = (param1: any, param2: any) => {
  let lat: any = 0.0;
  let lon: any = 0.0;

  // Utility function to convert and validate floating point numbers.
  function toNum(x: any) {
    if (typeof x === "number") {
      return x;
    }

    if (typeof x === "string") {
      return parseFloat(x);
    }

    // If the parameter was a function...
    // dont call a function property here because of binding issue
    return;
  }

  // support Chris Veness 2002-2012 LatLon library and
  // other objects with lat/lon properties
  // properties could be numbers, or strings
  if (typeof param1 === "object") {
    if (param1.length === 2) {
      // first parameter is an array `[lat, lon]`
      lat = toNum(param1[0]);
      lon = toNum(param1[1]);
      return gridForLatLon(lat, lon);
    }

    if ("lat" in param1 && "lon" in param1) {
      lat =
        typeof param1.lat === "function"
          ? toNum(param1.lat())
          : toNum(param1.lat);
      lon =
        typeof param1.lon === "function"
          ? toNum(param1.lon())
          : toNum(param1.lon);
      return gridForLatLon(lat, lon);
    }

    if ("latitude" in param1 && "longitude" in param1) {
      lat =
        typeof param1.latitude === "function"
          ? toNum(param1.latitude())
          : toNum(param1.latitude);
      lon =
        typeof param1.longitude === "function"
          ? toNum(param1.longitude())
          : toNum(param1.longitude);
      return gridForLatLon(lat, lon);
    }
    return;
  }

  lat = toNum(param1);
  lon = toNum(param2);
  return gridForLatLon(lat, lon);
};

export const getUsStateFullName = (
  lowerCaseCountry: string,
  usState: string
) => {
  let stateName = usState;
  if (
    lowerCaseCountry === "united states" ||
    lowerCaseCountry === "usa" ||
    lowerCaseCountry === "us" ||
    lowerCaseCountry === "united states of america"
  ) {
    //some states have the full name
    if (usState.length > 2) {
      stateName = usState;
    } else {
      // some state are coming like FL,US instead of just FL, thats why we are splitting it here with ,
      if (usState.split(",").length >= 1) {
        const stateData = usStatesCoordinates.filter(
          (state) =>
            state.shortCode.toLowerCase() ===
            usState.split(",")[0]?.toLowerCase()
        );
        if (stateData && stateData[0]) {
          const data = stateData[0];
          stateName = data.name;
        }
      }
    }
  }
  return stateName;
};

const gridForLatLon = (latitude: any, longitude: any) => {
  const UPPERCASE = "ABCDEFGHIJKLMNOPQRSTUVWX";
  const LOWERCASE = UPPERCASE.toLowerCase();
  var adjLat: number;
  var adjLon: number;
  let fieldLat;
  let fieldLon;
  let squareLat;
  let squareLon;
  let subLat;
  let subLon;
  let rLat;
  let rLon;

  // Parameter Validataion
  const lat = parseFloat(latitude);
  if (isNaN(lat)) {
    return;
  }

  if (Math.abs(lat) === 90.0) {
    return;
  }

  if (Math.abs(lat) > 90) {
    return;
  }

  const lon = parseFloat(longitude);
  if (isNaN(lon)) {
    return;
  }

  if (Math.abs(lon) > 180) {
    return;
  }

  // Latitude
  var adjLat = lat + 90;
  fieldLat = UPPERCASE[Math.trunc(adjLat / 10)];
  squareLat = "" + Math.trunc(adjLat % 10);
  rLat = (adjLat - Math.trunc(adjLat)) * 60;
  subLat = LOWERCASE[Math.trunc(rLat / 2.5)];

  // Logitude
  var adjLon = lon + 180;
  fieldLon = UPPERCASE[Math.trunc(adjLon / 20)];
  squareLon = "" + Math.trunc((adjLon / 2) % 10);
  rLon = (adjLon - 2 * Math.trunc(adjLon / 2)) * 60;
  subLon = LOWERCASE[Math.trunc(rLon / 5)];

  return fieldLon + fieldLat + squareLon + squareLat + subLon + subLat;
};

export const buildDynamicQuery = (conditions: any) => {
  const membershipStatus = conditions?.membershipStatus
    ? conditions?.membershipStatus?.toLowerCase()
    : null;
  const callSign = conditions?.callSign;
  const state = conditions?.state;
  const country = conditions?.country;
  let collectionRef: any = collection(db, UserDataTable);

  let q: any = query(collectionRef, orderBy("timestamp", "desc"), limit(50));

  if (membershipStatus && callSign && country && state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (membershipStatus && callSign && country) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (membershipStatus && country && state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (membershipStatus && callSign) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (membershipStatus && country) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (country && callSign && !membershipStatus && !state) {
    q = query(
      collectionRef,
      where("callSign", "==", callSign),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (country && callSign && state && !membershipStatus) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("callSign", "==", callSign),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (membershipStatus && !country && !callSign) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (country && state) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (country) {
    q = query(
      collectionRef,
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  } else if (callSign) {
    q = query(
      collectionRef,
      where("callSign", "==", callSign),
      orderBy("timestamp", "desc"),
      limit(50)
    );
  }

  return q;
};

export const buildDynamicQueryForPaginationCount = (conditions: any) => {
  const membershipStatus = conditions?.membershipStatus
    ? conditions?.membershipStatus?.toLowerCase()
    : null;
  const callSign = conditions?.callSign;
  const state = conditions?.state;
  const country = conditions?.country;
  let collectionRef: any = collection(db, UserDataTable);

  let q: any = query(collectionRef, orderBy("timestamp", "desc"));

  if (membershipStatus && callSign && country && state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc")
    );
  } else if (membershipStatus && callSign && country) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      where("country", "==", country),
      orderBy("timestamp", "desc")
    );
  } else if (membershipStatus && country && state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc")
    );
  } else if (membershipStatus && callSign) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      orderBy("timestamp", "desc")
    );
  } else if (membershipStatus && country) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      orderBy("timestamp", "desc")
    );
  } else if (country && callSign && !membershipStatus && !state) {
    q = query(
      collectionRef,
      where("callSign", "==", callSign),
      where("country", "==", country),
      orderBy("timestamp", "desc")
    );
  } else if (country && callSign && state && !membershipStatus) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("callSign", "==", callSign),
      where("state", "==", state),
      orderBy("timestamp", "desc")
    );
  } else if (membershipStatus && !country && !callSign) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      orderBy("timestamp", "desc")
    );
  } else if (country && state) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc")
    );
  } else if (country) {
    q = query(
      collectionRef,
      where("country", "==", country),
      orderBy("timestamp", "desc")
    );
  } else if (callSign) {
    q = query(
      collectionRef,
      where("callSign", "==", callSign),
      orderBy("timestamp", "desc")
    );
  }

  return q;
};

export const createCallSignIndex = (text: string) => {
  let substrings = [];
  for (let i = 0; i < text.length; i++) {
    for (let j = i + 1; j <= text.length; j++) {
      substrings.push(text.substring(i, j));
    }
  }
  return substrings;
};

export const buildDynamicQueryForPaginationNext = (
  conditions: any,
  docRef: UserProfile
) => {
  const membershipStatus = conditions?.membershipStatus
    ? conditions?.membershipStatus?.toLowerCase()
    : null;
  const callSign = conditions?.callSign;
  const state = conditions?.state;
  const country = conditions?.country;
  let collectionRef: any = collection(db, UserDataTable);

  let q: any = query(
    collectionRef,
    orderBy("timestamp", "desc"),
    startAfter(docRef),
    limit(50)
  );

  if (membershipStatus && callSign && country && state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (membershipStatus && callSign && country) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (membershipStatus && country && state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (membershipStatus && callSign) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (membershipStatus && country) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (country && callSign && !membershipStatus && !state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (country && callSign && state && !membershipStatus) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("callSign", "==", callSign),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (membershipStatus && !country && !callSign) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (country && state) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (country) {
    q = query(
      collectionRef,
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (callSign) {
    q = query(
      collectionRef,
      where("callSign", "==", callSign),
      orderBy("timestamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  }

  return q;
};

export const buildDynamicQueryForPaginationPrev = (
  conditions: any,
  docRef: UserProfile
) => {
  const membershipStatus = conditions?.membershipStatus
    ? conditions?.membershipStatus?.toLowerCase()
    : null;
  const callSign = conditions?.callSign;
  const state = conditions?.state;
  const country = conditions?.country;
  let collectionRef: any = collection(db, UserDataTable);

  let q: any = query(
    collectionRef,
    orderBy("timestamp", "desc"),
    endBefore(docRef),
    limitToLast(50)
  );

  if (membershipStatus && callSign && country && state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (membershipStatus && callSign && country) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (membershipStatus && country && state) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (membershipStatus && callSign) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("callSign", "==", callSign),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (membershipStatus && country) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (country && callSign && !membershipStatus && !state) {
    q = query(
      collectionRef,
      where("callSign", "==", callSign),
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (country && callSign && state && !membershipStatus) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("callSign", "==", callSign),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (membershipStatus && !country && !callSign) {
    q = query(
      collectionRef,
      where("membershipStatus", "==", membershipStatus),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (country && state) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("state", "==", state),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (country) {
    q = query(
      collectionRef,
      where("country", "==", country),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (callSign) {
    q = query(
      collectionRef,
      where("callSign", "==", callSign),
      orderBy("timestamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  }

  return q;
};

export const buildDynamicContactQuery = (conditions: any) => {
  const mode = conditions?.mode;
  const callSign = conditions?.callSign;
  const state = conditions?.state;
  const country = conditions?.country;
  let collectionRef: any = collection(db, LogBookContactTable);

  let q: any = query(
    collectionRef,
    orderBy("contactTimeStamp", "desc"),
    limit(50)
  );

  if (mode && callSign && country && state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (mode && callSign && country) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (mode && country && state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (mode && callSign) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (mode && country) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (country && callSign && !mode && !state) {
    q = query(
      collectionRef,
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (country && callSign && state && !mode) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("myCallSign", "==", callSign),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (mode && !country && !callSign) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (country && state) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (country) {
    q = query(
      collectionRef,
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  } else if (callSign) {
    q = query(
      collectionRef,
      where("myCallSign", "==", callSign),
      orderBy("contactTimeStamp", "desc"),
      limit(50)
    );
  }

  return q;
};

export const buildDynamicContactQueryForPaginationCount = (conditions: any) => {
  const mode = conditions?.mode;
  const callSign = conditions?.callSign;
  const state = conditions?.state;
  const country = conditions?.country;
  let collectionRef: any = collection(db, LogBookContactTable);

  let q: any = query(collectionRef, orderBy("contactTimeStamp", "desc"));

  if (mode && callSign && country && state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (mode && callSign && country) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (mode && country && state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (mode && callSign) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (mode && country) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (country && callSign && !mode && !state) {
    q = query(
      collectionRef,
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (country && callSign && state && !mode) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("myCallSign", "==", callSign),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (mode && !country && !callSign) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (country && state) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (country) {
    q = query(
      collectionRef,
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc")
    );
  } else if (callSign) {
    q = query(
      collectionRef,
      where("myCallSign", "==", callSign),
      orderBy("contactTimeStamp", "desc")
    );
  }

  return q;
};

export const buildDynamicContactQueryForPaginationNext = (
  conditions: any,
  docRef: LogBookContactModel
) => {
  const mode = conditions?.mode;
  const callSign = conditions?.callSign;
  const state = conditions?.state;
  const country = conditions?.country;
  let collectionRef: any = collection(db, LogBookContactTable);

  let q: any = query(
    collectionRef,
    orderBy("contactTimeStamp", "desc"),
    startAfter(docRef),
    limit(50)
  );

  if (mode && callSign && country && state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (mode && callSign && country) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (mode && country && state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (mode && callSign) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (mode && country) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (country && callSign && !mode && !state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (country && callSign && state && !mode) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("myCallSign", "==", callSign),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (mode && !country && !callSign) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (country && state) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (country) {
    q = query(
      collectionRef,
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  } else if (callSign) {
    q = query(
      collectionRef,
      where("myCallSign", "==", callSign),
      orderBy("contactTimeStamp", "desc"),
      startAfter(docRef),
      limit(50)
    );
  }

  return q;
};

export const buildDynamicContactQueryForPaginationPrev = (
  conditions: any,
  docRef: LogBookContactModel
) => {
  const mode = conditions?.mode;
  const callSign = conditions?.callSign;
  const state = conditions?.state;
  const country = conditions?.country;
  let collectionRef: any = collection(db, LogBookContactTable);

  let q: any = query(
    collectionRef,
    orderBy("contactTimeStamp", "desc"),
    endBefore(docRef),
    limitToLast(50)
  );

  if (mode && callSign && country && state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (mode && callSign && country) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (mode && country && state) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (mode && callSign) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("myCallSign", "==", callSign),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (mode && country) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (country && callSign && !mode && !state) {
    q = query(
      collectionRef,
      where("myCallSign", "==", callSign),
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (country && callSign && state && !mode) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("myCallSign", "==", callSign),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (mode && !country && !callSign) {
    q = query(
      collectionRef,
      where("userMode", "==", mode),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (country && state) {
    q = query(
      collectionRef,
      where("country", "==", country),
      where("state", "==", state),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (country) {
    q = query(
      collectionRef,
      where("country", "==", country),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  } else if (callSign) {
    q = query(
      collectionRef,
      where("myCallSign", "==", callSign),
      orderBy("contactTimeStamp", "desc"),
      endBefore(docRef),
      limitToLast(50)
    );
  }

  return q;
};

export const getRandomAvatar = () => {
  const randomImage = Math.floor(Math.random() * 13) + 1;
  return `../../assets/avatars/avatar_${randomImage}.jpg`;
};

export function findStateName(countryName: string, stateCode: string) {
  // Find the index of the country name in the array
  const index = CountryRegionData?.findIndex(
    (item) => item?.[0] === countryName
  );

  // If country name is found
  if (index !== -1) {
    // Get the region part of the data
    const regionData = CountryRegionData?.[index]?.[2];

    // Split the region data into an array of regions
    const regions = regionData?.split("|");

    // Iterate through the regions to find the matching region code
    for (const region of regions) {
      const [name, code] = region?.split("~");
      if (code === stateCode) {
        return name;
      }
    }
  }

  // If no matching region code is found, return null
  return null;
}

export const getCQAndITUZonesFromCoordinates = async (
  latitude: number,
  longitude: number
) => {
  const response: any = await getZonesFromCoordinate({
    latitude: latitude,
    longitude: longitude,
  });
  if (response && response.data) {
    return {
      cqZone: response.data?.cqZone || "",
      ituZone: response.data?.ituZone || "",
    };
  }

  return { cqZone: "", ituZone: "" };
};

export const latLonFromGrid = (grid: string) => {
  try {
    const coords = Maidenhead.toLatLon(grid);

    if (coords && Array.isArray(coords) && coords.length === 2) {
      return { latitude: coords[0], longitude: coords[1] };
    }
  } catch (e) {
    console.log("Error getting location from Maidenhead library: ", e);
  }

  var lat = 0.0;
  var lon = 0.0;

  function lat4(g: any) {
    return (
      10 * (g.charCodeAt(1) - "A".charCodeAt(0)) + parseInt(g.charAt(3)) - 90
    );
  }

  function lon4(g: any) {
    return (
      20 * (g.charCodeAt(0) - "A".charCodeAt(0)) +
      2 * parseInt(g.charAt(2)) -
      180
    );
  }

  if (grid.length != 4 && grid.length != 6) {
    return null;
  }

  if (/^[A-Xa-x][A-Xa-x][0-9][0-9]$/.test(grid)) {
    // Decode 4-character grid square
    lat = lat4(grid.toUpperCase()) + 0.5;
    lon = lon4(grid.toUpperCase()) + 1;
  } else if (/^[A-Xa-x][A-Xa-x][0-9][0-9][A-Xa-x][A-Xa-x]$/.test(grid)) {
    // Decode 6-character grid square
    lat =
      lat4(grid.toUpperCase()) +
      (1.0 / 60.0) * 2.5 * (grid.charCodeAt(5) - "a".charCodeAt(0) + 0.5);
    lon =
      lon4(grid.toUpperCase()) +
      (1.0 / 60.0) * 5 * (grid.charCodeAt(4) - "a".charCodeAt(0) + 0.5);
  } else {
    return null;
  }

  return { latitude: lat, longitude: lon };
};

export function generateUniqueFileName(originalName: string) {
  const timestamp = Date.now();
  const randomString = Math.random().toString(36).substring(2, 15); // Generates a random string
  return `${timestamp}_${randomString}_${originalName}`;
}

export const getContestStatus = (contestDetail: any) => {
  const now = new Date();
  //determine if a contest is ongoing in the future or past based on fields from contestDetail.startDate and contestDetail.endDate
  const startDate = new Date(contestDetail?.startDate?.seconds * 1000);
  const endDate = new Date(contestDetail?.endDate?.seconds * 1000);
  if (contestDetail) {
    if (
      now.getTime() > startDate.getTime() &&
      now.getTime() < endDate.getTime()
    ) {
      return CONTEST_STATUS.ACTIVE;
    } else if (now.getTime() < startDate.getTime()) {
      return CONTEST_STATUS.UPCOMING;
    } else {
      return CONTEST_STATUS.PASSED;
    }
  }
};

export const setTabIndexForTimeInputs = (
  hour: string,
  minute: string,
  close: string
) => {
  const hourElement = document.querySelector(
    ".react-time-picker__inputGroup__hour"
  );
  const minuteElement = document.querySelector(
    ".react-time-picker__inputGroup__minute"
  );
  const clearButtonElement = document.querySelector(
    ".react-time-picker__clear-button"
  );

  if (hourElement) {
    hourElement.setAttribute("tabindex", hour);
  }

  if (minuteElement) {
    minuteElement.setAttribute("tabindex", minute);
  }

  if (clearButtonElement) {
    clearButtonElement.setAttribute("tabindex", close);
  }
};

export const getDxDataFromCountry = (country: string): DXCCList | null => {
  let dxData = dxccCountryList.find((x) => x.country === country);
  if (dxData) {
    return dxData;
  } else {
    return null;
  }
};

export const getCountryByDXNumber = (dxccNumber: number) => {
  return dxccCountryList.find((x) => x.dxccNumber === dxccNumber)?.country;
};

export function compareObjects(
  newObject: { [key: string]: any },
  prevObject: { [key: string]: any }
) {
  const addedProps: string[] = [];
  const removedProps: string[] = [];

  // Check for added properties
  for (const key in newObject) {
    if (!(key in prevObject)) {
      addedProps.push(key);
    }
  }

  // Check for removed properties
  for (const key in prevObject) {
    if (!(key in newObject)) {
      removedProps.push(key);
    }
  }

  return {
    added: addedProps,
    removed: removedProps,
  };
}

export const getStateData = (state: string) => {
  if (state) {
    const stateData = usStatesCoordinates.find(
      (x) =>
        x.name.toLowerCase() === state.toLowerCase() ||
        x.shortCode.toLowerCase() === state.toLowerCase()
    );
    if (stateData) {
      return stateData;
    }
  }

  return null;
};

export const getStateShortCode = (state: string) => {
  let newState = state;
  if (state) {
    try {
      //handle comma separated state for parks
      const commaSeparatedState = state.split(",");
      if (commaSeparatedState.length > 1) {
        return "";
      }
    } catch (e) {}
    const stateShortName = usStatesCoordinates.find(
      (x) => x.name.toLowerCase() === state.toLowerCase()
    );
    if (stateShortName) {
      return stateShortName?.shortCode;
    }
  }
  return newState;
};

export const getStateLongName = (state: string) => {
  let newState = state;
  if (state) {
    const stateLongName = usStatesCoordinates.find(
      (x) => x.shortCode.toLowerCase() === state.toLowerCase()
    );
    if (stateLongName) {
      return stateLongName?.name;
    }
  }
  return newState;
};
export const createSearchIndex = (text: string, startFrom?: number) => {
  let substrings = [];
  for (let i = startFrom || 2; i <= text.length; i++) {
    substrings.push(text.substring(0, i));
  }
  return substrings;
};

export const correctUSCountryName = (country: string) => {
  if (country) {
    if (
      country.toLowerCase() === "united states of america" ||
      country.toLowerCase() === "united states" ||
      country.toLowerCase() === "usa" ||
      country.toLowerCase() === "us"
    ) {
      return "United States";
    }
  }
  return country || "";
};

export const suggestionsFormatter = (suggestion: ParkReferenceModel) => {
  let formatedReference = `${suggestion.reference} \u00A0|\u00A0 ${
    suggestion.name
  } \u00A0 ${
    suggestion.locationName ? `|\u00A0${suggestion.locationName}` : ""
  } \u00A0 ${
    suggestion.referencePrefix ? `|\u00A0${suggestion.referencePrefix}` : ""
  }`;

  if (suggestion?.distanceInKm?.toFixed(2)) {
    formatedReference += ` \u00A0|\u00A0 ${suggestion?.distanceInKm?.toFixed(
      2
    )} km`;
  }

  return formatedReference;
};

// Generate a curve path between two points, it will iterate about 29 times for each pair of points
export const generateCurvePath = (start: any, end: any) => {
  // Calculate the distance between start and end points
  const distance = Math.sqrt(
    Math.pow(end.lat - start.lat, 2) + Math.pow(end.lng - start.lng, 2)
  );

  // Adjust control point based on distance
  const curveHeight = distance * 0.2; // Adjust this factor to control the steepness
  const controlPoint = {
    lat: (start.lat + end.lat) / 2 + curveHeight,
    lng: (start.lng + end.lng) / 2,
  };

  let curvePath = [];
  for (let t = 0; t <= 1; ) {
    const x =
      (1 - t) * (1 - t) * start.lng +
      2 * (1 - t) * t * controlPoint.lng +
      t * t * end.lng;
    const y =
      (1 - t) * (1 - t) * start.lat +
      2 * (1 - t) * t * controlPoint.lat +
      t * t * end.lat;
    curvePath.push({ lat: y, lng: x });

    // Smaller increments at the beginning and end, larger in the middle
    if (t < 0.1 || t > 0.9) {
      t += 0.02; // Fine increments near the start and end
    } else {
      t += 0.1; // Coarser increments in the middle
    }
  }

  return curvePath;
};

export const convertBandValue = (value: string, unit: string): number => {
  const meterValue = parseFloat(value);

  switch (unit) {
    case "cm":
      return meterValue * 100; // Convert meters to centimeters
    case "mm":
      return meterValue * 1000; // Convert meters to millimeters
    default:
      return meterValue; // Return the original value if unit is meters or unknown
  }
};

export const formatBandValue = (str: string) => {
  const result = str.match(/\d+(\.\d+)?/);
  if (result) {
    let value = parseFloat(result[0]);
    if (value < 1) {
      value = Math.abs(value * 100); // Convert to cm
      if (value < 1) {
        return `${value * 10}mm`; // Convert to mm if less than 1 cm
      }
      return `${value}cm`;
    }
    return `${value}m`;
  }
  return "";
};

export const obfuscateEmail = (email: string) => {
  try {
    // Split the email into parts using the '@' symbol
    const [localPart, domain] = email.split("@");

    let obfuscatedLocal;

    // Obfuscate the local part based on its length
    if (localPart.length > 4) {
      // For longer emails, keep the first and last characters
      obfuscatedLocal =
        localPart[0] + "*".repeat(localPart.length - 2) + localPart.slice(-1);
    } else {
      // For shorter emails, show only the first character and mask the rest
      obfuscatedLocal = localPart[0] + "*".repeat(localPart.length - 1);
    }

    // Return the obfuscated email
    return `${obfuscatedLocal}@${domain}`;
  } catch (e) {
    return "Email not available";
  }
};

export const getFirebaseProviderName = (providerId: string) => {
  switch (providerId) {
    case "google.com":
      return "Google";
    case "password":
      return "Email & Password";
    case "apple.com":
      return "Apple";

    default:
      return "Unknown";
  }
};

export const validEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const validCallSign = (text: string) => {
  const callSignRegex = /\b[A-Z0-9]{1,2}[0-9][A-Z0-9]{1,5}\b/gi;
  const callSigns = text.match(callSignRegex);
  return callSigns;
};

export const prioritizeExactParkMatchesAndFlipUSParksOrder = (
  search: string,
  parks: ParkReferenceModel[]
): ParkReferenceModel[] => {
  // Normalize the search term by trimming and converting it to lowercase
  const normalizedSearch = search.trim().toLowerCase();

  // Find all parks that start with "US-" (case-insensitive) and reverse their order
  const usParks = parks
    .filter((park) => park.reference.toLowerCase().startsWith("us-"))
    .reverse();

  // Find the remaining parks that do not start with "US-"
  const nonUsParks = parks.filter(
    (park) => !park.reference.toLowerCase().startsWith("us-")
  );

  // Combine reversed US parks and non-US parks
  let combinedParks = [...usParks, ...nonUsParks];

  // Find the index of the exact match (case-insensitive)
  const exactMatchIndex = combinedParks.findIndex(
    (park) => park.reference.toLowerCase() === normalizedSearch
  );

  // If there's an exact match, move it to the top of the list
  if (exactMatchIndex > -1) {
    const exactMatch = combinedParks[exactMatchIndex];
    // Remove the exact match from its current position
    combinedParks.splice(exactMatchIndex, 1);
    // Move the exact match to the top
    combinedParks.unshift(exactMatch);
  }

  // Return the list with the exact match at the top, followed by reversed US parks and the rest
  return combinedParks;
};

export const formatTimestamp = (timestamp: any) => {
  if (!timestamp) return "N/A";

  let date: Date;

  // Check if the timestamp is a Firestore Timestamp instance
  if (timestamp instanceof Timestamp) {
    date = timestamp.toDate();
  }
  // Check if timestamp is an object with _seconds and _nanoseconds (manual Firestore timestamp-like object)
  else if (
    timestamp._seconds !== undefined &&
    timestamp._nanoseconds !== undefined
  ) {
    date = new Date(
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
    );
  }
  // Handle other cases, such as string or Date objects
  else if (typeof timestamp === "string" || timestamp instanceof Date) {
    date = new Date(timestamp);
  } else {
    return "Invalid Date";
  }

  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  return date;
};

export const calculateRadius = (
  sw: google.maps.LatLng,
  ne: google.maps.LatLng
): number => {
  const centerLat = (sw.lat() + ne.lat()) / 2;
  const centerLng = (sw.lng() + ne.lng()) / 2;
  const center: Geopoint = [centerLat, centerLng];
  const nePoint: Geopoint = [ne.lat(), ne.lng()];

  // Use geofire-common's distanceBetween function
  const radiusInKm = geofire.distanceBetween(center, nePoint);
  const radiusInM = radiusInKm * 1000; // Convert to meters

  return radiusInM;
};

export const getActivityIcon = (type: string) => {
  if (type === Activities.SOTA) {
    return Icons.SOTAMarker;
  } else if (type === Activities.WWFF) {
    return Icons.WWFFMarker;
  } else if (type === Activities.IOTA) {
    return Icons.IOTAMarker;
  } else {
    return Icons.POTAMarker;
  }
};

export const getMyActivityIcon = (type: string) => {
  if (type === Activities.SOTA) {
    return Icons.MySotaIcon;
  } else if (type === Activities.WWFF) {
    return Icons.MyWwffIcon;
  } else if (type === Activities.IOTA) {
    return Icons.MyIotaIcon;
  } else {
    return Icons.ParkCyan;
  }
};

export const getActivityLogo = (type: string) => {
  if (type === Activities.SOTA) {
    return Icons.SotaLogo;
  } else if (type === Activities.WWFF) {
    return Icons.WwffLogo;
  } else if (type === Activities.IOTA) {
    return Icons.IotaLogo;
  } else {
    return Icons.PotaTree;
  }
};

export const getAddActivityIcon = (type: string) => {
  if (type === Activities.SOTA) {
    return Icons.SotaColor;
  } else if (type === Activities.WWFF) {
    return Icons.WwffColor;
  } else if (type === Activities.IOTA) {
    return Icons.IotaColor;
  } else {
    return Icons.PotaColor;
  }
};

export const getCountryName = (country: string) => {
  if (country) {
    const lowerCaseCountry = country.toLowerCase();
    if (
      lowerCaseCountry === "usa" ||
      lowerCaseCountry === "us" ||
      lowerCaseCountry === "united states" ||
      lowerCaseCountry === "united states of america"
    ) {
      return "United States";
    }
  }
  return country;
};

export function filterValidProps<T extends Record<string, any>>(
  obj: T
): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  ) as Partial<T>;
}

export function filterDescription(filter: Record<string, any>): string {
  // Get all keys of the filter object
  const keys = Object.keys(filter);

  // Map over the keys and create the description string
  const description = keys
    .map((key) => {
      const value = filter[key];
      // Check if the value is an array or a single value
      if (Array.isArray(value)) {
        return `${key} equal to [${value.join(", ")}]`;
      } else {
        return `${key} equal to ${value}`;
      }
    })
    .join(" AND "); // Join the conditions with 'AND'

  return `Where ${description}`;
}
export const capitalizeFirstLetter = (word: string | undefined) => {
  if (!word) return word;
  return word[0] === word[0].toUpperCase()
    ? word
    : word[0].toUpperCase() + word.slice(1);
};

export const autocompleteCountry = (
  searchTerm: string,
  dxccCountryList: DXCCList[]
): DXCCList[] => {
  const lowerCaseSearchTerm = searchTerm.toLowerCase();

  return dxccCountryList.filter((item) => {
    return (
      item.country && item.country.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });
};

export const getPageContacts = (
  contacts: any[],
  currentPage: number,
  limit: number
): any[] => {
  const startIndex = (currentPage - 1) * limit;
  const endIndex = startIndex + limit;
  return contacts.slice(startIndex, endIndex);
};

export const sortArray = <T extends Record<string, any>>(
  array: T[],
  sortConfig: Sort
): T[] => {
  return array.sort((a, b) => {
    const { key, direction } = sortConfig;

    let aValue = a[key];
    let bValue = b[key];

    // Handle special case for contactTimeStamp
    if (key === "contactTimeStamp") {
      aValue = a[key]?._seconds ?? 0;
      bValue = b[key]?._seconds ?? 0;
    }

    if (direction === "asc") {
      return aValue > bValue ? 1 : -1;
    } else {
      return aValue < bValue ? 1 : -1;
    }
  });
};

export const toUTCDate = (date: Date) => {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
  );
};

export function objectToString(obj: { [key: string]: any }): string {
  return Object.entries(obj)
    .map(([key, value]) => {
      if (typeof value === "object") {
        return `${key}`;
      }
      return `${key}: ${value}`;
    })
    .join(", ");
}

export const isActivityRelatedField = (key: string) => {
  if (
    key === "activitiesData" ||
    key === "activities" ||
    key === "activitiesReferences"
  ) {
    return true;
  }
  return false;
};

export const parseActivityFieldsToString = (activity: any, key: string) => {
  if (key === "activitiesData") {
    return activity.map((item: any) => item?.reference).join(", ");
  } else if (key === "activities") {
    return activity.join(", ");
  } else if (key === "activitiesReferences") {
    return activity.join(", ");
  }
  return "";
};

export const checkAndAddActivityFields = (values: any) => {
  const newValues = { ...values };
  if (values.activitiesData) {
    newValues.activitiesReferences = values.activitiesData?.map(
      (activity: any) => activity?.reference
    );
  }

  return newValues;
};

export const getParkTypeName = (type: string) => {
  if (type === Activities.POTA) {
    return "Park";
  } else if (type === Activities.WWFF) {
    return "WWFF";
  } else if (type === Activities.SOTA) {
    return "Summit";
  } else if (type === Activities.IOTA) {
    return "Island";
  } else {
    return "Park";
  }
};

export const getCloseActivityIcon = (type: string) => {
  if (type === Activities.POTA) {
    return Icons.PotaIconAdd;
  } else if (type === Activities.WWFF) {
    return Icons.WwffIcon;
  } else if (type === Activities.SOTA) {
    return Icons.SotaIcon;
  } else if (type === Activities.IOTA) {
    return Icons.IotaIcon;
  }
};

export const updateMyParks = (prev: any, suggestion: any) => {
  return { ...prev.myParks, [suggestion?.reference]: suggestion };
};

// Appends a new activity to the 'activitiesData' array, creating the array if it doesn't exist.
export const getUpdatedActivitiesData = (
  prev: any,
  suggestion: any,
  activityType: any
) => {
  return prev.activitiesData
    ? [...prev.activitiesData, { ...suggestion, type: activityType }]
    : [{ ...suggestion, type: activityType }];
};

// Determines the primary activity type. Defaults to the first activity in the data or the provided activityType.
export const getPrimaryActivity = (prev: any, activityType: any) => {
  return prev.activitiesData && prev.activitiesData.length
    ? prev.activitiesData[0].type
    : activityType;
};

// Generates a unique list of activity types, ensuring no duplicates.
export const getUniqueActivities = (
  prev: any,
  suggestion: any,
  activityType: any
) => {
  const updatedActivities = prev.activities
    ? [...prev.activities, { ...suggestion, type: activityType }]
    : [{ ...suggestion, type: activityType }];

  return [
    ...new Set(updatedActivities.map((item) => item?.type || Activities.POTA)),
  ];
};

export const validateNumberOfSelectedActivities = (
  activityType: string,
  formData: any
) => {
  const selectedParks: any[] = formData?.activitiesData || [];
  const numberOfSotaSelected = selectedParks.filter(
    (park) => park.type === Activities.SOTA
  ).length;
  const numberOfPotaSelected = selectedParks.filter(
    (park) => park.type === Activities.POTA
  ).length;
  const numberOfIotaSelected = selectedParks.filter(
    (park) => park.type === Activities.IOTA
  ).length;
  const numberOfWwffSelected = selectedParks.filter(
    (park) => park.type === Activities.WWFF
  ).length;

  if (numberOfPotaSelected >= 5 && activityType === Activities.POTA) {
    showToast({
      message: "You can only select up to 5 POTA locations",
      type: ToastTypes.ERROR,
    });
    return false;
  }
  if (numberOfSotaSelected >= 1 && activityType === Activities.SOTA) {
    showToast({
      message: "You can only select 1 SOTA location at a time",
      type: ToastTypes.ERROR,
    });
    return false;
  }
  if (numberOfWwffSelected >= 1 && activityType === Activities.WWFF) {
    showToast({
      message: "You can only select 1 WWFF location at a time",
      type: ToastTypes.ERROR,
    });
    return false;
  }
  if (numberOfIotaSelected >= 1 && activityType === Activities.IOTA) {
    showToast({
      message: "You can only select 1 IOTA location at a time",
      type: ToastTypes.ERROR,
    });
    return false;
  }
  return true;
};

export const getActivityToActivityIcon = (type: string) => {
  if (type === Activities.POTA) {
    return Icons.parkToParkWhite;
  } else if (type === Activities.WWFF) {
    return Icons.WtoW;
  } else if (type === Activities.SOTA) {
    return Icons.StoS;
  } else if (type === Activities.IOTA) {
    return Icons.ItoI;
  }
  return Icons.parkToParkWhite;
};
export const toggleSortOrder = (
  currentSortConfig: SortConfig,
  column: string
): SortConfig => {
  if (currentSortConfig.column === column) {
    // Toggle the sort order
    return {
      column,
      order: currentSortConfig.order === "asc" ? "desc" : "asc",
    };
  } else {
    // Set to ascending order for new column
    return {
      column,
      order: "asc",
    };
  }
};

export const getGridGeohashBounds = (sw: any, ne: any, numGrids: number) => {
  if (!sw || !ne || !numGrids || numGrids <= 0) {
    throw new Error("Invalid inputs");
  }

  const gridSize = Math.sqrt(numGrids);
  if (!Number.isInteger(gridSize)) {
    throw new Error("numGrids must be a perfect square (e.g., 4, 9, 16, etc.)");
  }

  // Extract latitudes and longitudes from sw and ne, handling functions
  const swLat = typeof sw.lat === "function" ? sw.lat() : sw.lat;
  const swLng = typeof sw.lng === "function" ? sw.lng() : sw.lng;
  const neLat = typeof ne.lat === "function" ? ne.lat() : ne.lat;
  const neLng = typeof ne.lng === "function" ? ne.lng() : ne.lng;

  const latStep = (neLat - swLat) / gridSize; // Latitude increment per grid cell
  const lngStep = (neLng - swLng) / gridSize; // Longitude increment per grid cell

  const geohashBoundsArray = [];

  for (let i = 0; i < gridSize; i++) {
    for (let j = 0; j < gridSize; j++) {
      // Calculate SW and NE corners of the grid cell
      const cellSW = {
        lat: swLat + i * latStep,
        lng: swLng + j * lngStep,
      };
      const cellNE = {
        lat: swLat + (i + 1) * latStep,
        lng: swLng + (j + 1) * lngStep,
      };

      // Calculate the center of the grid cell
      const centerLat = (cellSW.lat + cellNE.lat) / 2;
      const centerLng = (cellSW.lng + cellNE.lng) / 2;
      const center: Geopoint = [centerLat, centerLng];

      // Calculate distances to the cell boundaries in meters
      const deltaLat = (cellNE.lat - cellSW.lat) / 2;
      const deltaLng = (cellNE.lng - cellSW.lng) / 2;

      // Approximate meters per degree at the center latitude
      const metersPerDegreeLat = 111320; // Constant value
      const metersPerDegreeLng = 111320 * Math.cos(centerLat * (Math.PI / 180));

      const latDistanceInM = deltaLat * metersPerDegreeLat;
      const lngDistanceInM = deltaLng * metersPerDegreeLng;

      // Use the minimum distance to ensure the circle is within the grid cell
      const radiusInM = Math.min(latDistanceInM, lngDistanceInM);

      // Get geohash query bounds for this grid cell
      const geohashBounds = geohashQueryBounds(center, radiusInM);

      // Store the bounds along with the cell coordinates (optional)
      geohashBoundsArray.push({
        gridCell: {
          sw: cellSW,
          ne: cellNE,
          center: { lat: centerLat, lng: centerLng },
        },
        geohashBounds,
      });
    }
  }

  return geohashBoundsArray;
};

export const adjustedCoordinates = (
  latitude: number,
  longitude: number,
  index: number
) => ({
  lat: latitude + index * 0.00001,
  lng: longitude + index * 0.00001,
});
export function isValidMaidenheadGrid(gridSquare: string) {
  // // Validate format with a regular expression
  // const regex = /^[A-R]{2}\d{2}([a-x]{2}(\d{2})?)?$/;
  // if (!regex.test(gridSquare)) {
  //   return false;
  // }

  // Ensure the length is 4, 6, or 8 characters
  const validLengths = [4, 6, 8];
  return validLengths.includes(gridSquare?.length || 0);
}

export const transformSpottingFilters = (
  array: Record<string, any>[]
): Record<string, any> => {
  return array.reduce((obj, item) => {
    const [key, value] = Object.entries(item)[0]; // Extract key-value pair
    if (Array.isArray(value) && value.every((v) => v.name)) {
      // Transform array of objects with 'name' into array of strings
      obj[key] = value.map((v) => v.name);
    } else {
      obj[key] = value; // Assign value directly if not an array of objects
    }
    return obj;
  }, {});
};

export const extractDigit = (str: string) => {
  const result = str.match(/\d+(\.\d+)?/);
  return result ? result[0] : null;
};

export const extractMode = (str: string) => {
  const result = str.match(/\d+(\.\d+)?/);
  if (result) {
    let value = parseFloat(result[0]);
    if (value < 1) {
      value = Math.abs(value * 100); // Convert to cm
      if (value < 1) {
        return `${value * 10}mm`; // Convert to mm if less than 1 cm
      }
      return `${value}cm`;
    }
    return `${value}m`;
  }
  return "";
};

export interface ChartData {
  labels: string[];
  values: number[];
}

export function getLabelsAndValues(data: Record<string, number>): ChartData {
  const labels = Object.keys(data);
  const values = Object.values(data);
  return { labels, values };
}
export const getFlatActivityIcon = (type: string) => {
  if (type === Activities.SOTA) {
    return Icons.ActivitySOTA;
  } else if (type === Activities.WWFF) {
    return Icons.ActivityWWFF;
  } else if (type === Activities.IOTA) {
    return Icons.ActivityIOTA;
  } else {
    return Icons.ActivityPOTA;
  }
};
export const convertDistance = (distance: number, unit: string): number => {
  switch (unit.toLowerCase()) {
    case "kilometers":
    case "km":
      return distance; // Assuming the input is already in kilometers
    case "miles":
    case "mi":
      return distance * 0.621371; // Convert km to miles
    default:
      return distance; // Default to kilometers
  }
};

export const getShortDistanceUnit = (unit: string): string => {
  switch (unit.toLowerCase()) {
    case "kilometers":
    case "km":
      return "km";
    case "miles":
    case "mi":
      return "mi";
    default:
      return "km";
  }
};


export function appendCopyToADI(url: string): string {
  // Use a regular expression to find and replace '.adi' with '.adi_copy'
  return url.replace(/\.adi(\?|$)/, '.adi_copy$1');
}


export const isDateTimeInFuture = (date: any, time: any): boolean => {
  const combinedDateTime = new Date(`${date}T${time}Z`); // 'Z' denotes UTC time
  const dateNow = new Date(); // Current date and time in UTC

  // Comparing UTC time
  if (combinedDateTime.getTime() > dateNow.getTime()) {
    return true;
  } else {
    return false;
  }
};


export function getRelativeTime(timestampInSeconds: number): string {
  const now = moment();
  const inputTime = moment.unix(timestampInSeconds);
  
  return inputTime.from(now); // Returns relative time like 'a few seconds ago'
}

export const getModeCategory = (mode: string): string => {
  const phoneModes = ["SSB", "USB", "LSB", "D-STAR", "FM", "AM", "Fusion", "DMR"];
  const cwModes = ["CW"];
  const digitalModes = ["DIG", "TV", "FT4", "FT8", "RTTY", "PSK", "JS8", "OLIVIA", "SSTV", "Packet"];

  const modeUpper = mode.toUpperCase();

  if (phoneModes.includes(modeUpper)) {
    return "Phone";
  } else if (cwModes.includes(modeUpper)) {
    return "CW";
  } else if (digitalModes.includes(modeUpper)) {
    return "Digital";
  } else {
    return "Other";
  }
};

export const categorizeModeCounts = (modesCount: Record<string, number>): Record<string, number> => {
  const phoneModes = ["SSB", "USB", "LSB", "D-STAR", "FM", "AM", "Fusion", "DMR"];
  const cwModes = ["CW"];
  const digitalModes = ["DIG", "TV", "FT4", "FT8", "RTTY", "PSK", "JS8", "OLIVIA", "SSTV", "Packet"];

  const result = { Phone: 0, CW: 0, Digital: 0, Other: 0 };

  // Loop through the modesCount object and categorize each mode
  for (const mode in modesCount) {
    const count = modesCount[mode];
    const modeUpper = mode.toUpperCase();

    if (phoneModes.includes(modeUpper)) {
      result.Phone += count;
    } else if (cwModes.includes(modeUpper)) {
      result.CW += count;
    } else if (digitalModes.includes(modeUpper)) {
      result.Digital += count;
    } else {
      result.Other += count;
    }
  }

  return result;
};

export const validateClassValue = (value: string): boolean => {
  const trimmedValue = typeof value === "string" ? value.trim() : value || "";

  if (trimmedValue.length !== 2) return false;

  const firstChar = trimmedValue.charAt(0);
  const secondChar = trimmedValue.charAt(1);

  const isValidNumber = /^[1-9]$/.test(firstChar);
  const isValidClass = WFDClassOptions.some(
      (option) => option.value === secondChar
  );

  return isValidNumber && isValidClass;
};

export const validateSectionValue = (value: string): boolean => {
  const upperValue = typeof value === "string" ? value.trim().toUpperCase() : value || "";
  return WFDSectionOptions.some((option) => option.value === upperValue);
};