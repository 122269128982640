import React, { useEffect, useState } from "react";
import InputWithButton from "components/Inputs/InputWithButton";
import TitledInput from "components/Inputs/TitledInput";
import TitledTimeInput from "components/Inputs/TitledTimeInput";
import DropDownTitled from "components/DropDown/DropDownTitled";
import { AllBandsOption, Modes } from "constants/ArrayVariables";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import TitledInputGradient from "components/Inputs/TitledInputGradient";
import { useSelector } from "react-redux";
import { getContestDetailsSelector } from "store/Reducers/contest.reducer";
import { useSearchParams } from "react-router-dom";
import useLogbookSetting from "hooks/logbookSetting.hook";
import usePota from "hooks/pota.hook";
import { useDebouncedCallback } from "use-debounce";
import Icons from "assets/Icons";
import { SelectableButtonsGroup } from "components/SelectableButtonsGroup";
import {
  convertBandValue,
  findBandAndModeForFrequency,
  formatBandValue,
  getActivityToActivityIcon,
  getDxDataFromCountry,
  getParkTypeName,
  setTabIndexForTimeInputs,
} from "helpers/Utils";
import { Form, Formik } from "formik";
import { LocationMappings } from "constants/LocationMappings";
import SearchCountryPicker from "components/Inputs/SearchCountryPicker";
import TitledRegionPicker from "components/Inputs/TitledRegionPicker";
import moment from "moment";
import { TheirParkField } from "./TheirParksField";
import { Activities } from "types/Models";
import LogContactButton from "components/Button/AnimatedPrimaryButton";
import AnimatedPrimaryButton from "components/Button/AnimatedPrimaryButton";

import {
  collection,
  getDocs,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
  doc,
  deleteDoc,
  increment,
  getDoc,
  DocumentData,
  limit,
  getCountFromServer,
  endBefore,
  limitToLast,
  startAfter,
  writeBatch,
  startAt,
  addDoc,
  Timestamp,
  deleteField,
} from "firebase/firestore";
import { auth, db } from "firebase-config";
import { useDuplicateCheck } from "hooks/useDuplicateCheck";
import { Templates } from "constants/Config";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
const POTALog = ({
  setValues,
  values,
  errors,
  handleChange,
  touched,
  initialData,
  setTouched,
  onLogPress,
  display,
  callSignBlurPopupHandler,
  userMode,
  setUserMode,
  userBand,
  setUserBand,
  setUserCallSign,
  selectedLogBook,
  isSavingOnLoading,
  date,
  setDate,
  setTime,
  currentTime,
  getUserFromHamApiData,
  isCallSignDataLoading,
  callSignData,
  handleClearAddContactForm,
  setStopRealTimeChange,
  userTimeInput,
  setUserTimeInput,
  checkLocationPermission,
  theirPark,
  setTheirPark,
  callSignRef,
  getUserCountryFromGrid,
  selectedSecondaryField,
  setSelectedSecondaryField,
  contactSuccess,
  endSuccessAnimation,
  spottingCopied,
  debounceChangeFrequencyCATControl,
  freqFromRadio,
  currentDate,
  setDuplicateInfo,
}: any) => {
  const contestDetail = useSelector(getContestDetailsSelector);
  let [searchParams] = useSearchParams();
  const [comments, setComments] = useState("");
  const { updateLogbookSettings } = useLogbookSetting();
  const { duplicateContactInfo } = useDuplicateCheck(values);
  const {
    isParkReferenceDataLoading,
    parkReferenceData,
    getParkByReference,
    autoSpotData,
    setAutoSpotData,
    addPotaAutoSpot,
  } = usePota();

  useEffect(() => {
    if (freqFromRadio) {
      if (freqFromRadio !== values.frequency) {
        setValues((prev: any) => {
          return {
            ...prev,
            frequency: freqFromRadio,
          };
        });
      }
    }
  }, [freqFromRadio]);

  useEffect(() => {
    console.log("autoSpotData : ", autoSpotData);
    if (autoSpotData?.frequency) {
      const { frequency, mode } = autoSpotData;

      const freqNumber = parseFloat(frequency);
      const bandMode = findBandAndModeForFrequency(freqNumber);
      setTimeout(() => {
        if (bandMode) {
          setValues((prev: any) => {
            return {
              ...prev,
              userMode: mode || bandMode?.mode,
              band: bandMode?.band,
              frequency,
            };
          });
          setUserMode(mode || bandMode?.mode);
          setUserBand(bandMode?.band);
        }

        setAutoSpotData(null);
      }, 1000);
      //create a delay to assign the frequency and mode to the form so they dont get overwritten
    }
  }, [autoSpotData]);

  useEffect(() => {
    if (callSignData === "NOT_FOUND") {
      setValues((prev: any) => {
        let data = {
          ...prev,
          country: "",
          grid: "",
          theirName: "",
          qth: "",
          state: "",
        };
        return data;
      });
    }
  }, [callSignData]);

  useEffect(() => {
    if (duplicateContactInfo) {
      setDuplicateInfo(duplicateContactInfo);
    } else {
      setDuplicateInfo(null);
    }
  }, [duplicateContactInfo]);

  const debouncedGetParkByReference = useDebouncedCallback(
    (searchString) => getParkByReference(searchString),
    200
  );

  const onFrequencyBlur = () => {
    debounceChangeFrequencyCATControl(values.frequency);
    const freqNumber = parseFloat(values.frequency);
    const bandMode = findBandAndModeForFrequency(freqNumber);

    if (bandMode) {
      setValues((prev: any) => {
        return { ...prev, userMode: bandMode?.mode, band: bandMode?.band };
      });
      setUserMode(bandMode?.mode);
      setUserBand(bandMode?.band);
    } else {
      setUserMode("");
      setUserBand("");
    }
  };

  useEffect(() => {
    if (selectedSecondaryField === 1) {
      setTabIndexForTimeInputs("13", "14", "15");
    }
  }, [selectedSecondaryField]);

  const renderSecondaryFields = () => {
    if (selectedSecondaryField === 1) {
      return (
        <div className="flex items-center w-full gap-3">
          <TitledTimeInput
            setText={(e) => {
              setStopRealTimeChange(true);
              setUserTimeInput(e);
              setTime(e);
              setValues((prev: any) => {
                return { ...prev, time: e };
              });
            }}
            name="time"
            // title="Time (UTC)"
            placeHolder="00:00 AM"
            className="!bg-white dark:!bg-opacity-10 h-[40px] mt-[0px]"
            width="w-[68.5%] sm:w-[30.5%] md:w-[28%] xl:w-[20%]"
            type="time"
            value={userTimeInput ? values.time : currentTime}
            error={errors?.time && touched?.time ? errors?.time : undefined}
            containerClass={"mb-[22px]"}
          />
          <TitledInput
            setText={(e) => {
              setValues((prev: any) => {
                return { ...prev, date: e.target.value };
              });
              setDate(e.target.value);
            }}
            name="date"
            // title="Date"
            placeHolder="dd/mm/yyyy"
            tabindex={16}
            width="w-[68.5%] sm:w-[30.5%] md:w-[28%] xl:w-[20%]"
            type="date"
            value={date ? date : currentDate}
            className="!bg-white dark:!bg-opacity-10 h-[40px] mt-[0px]"
            error={errors?.date && touched?.date ? errors?.date : undefined}
            props={
              {
                // max: new Date().toJSON().slice(0, 10),
              }
            }
            // containerClass={display ? "pointer-events-none" : ""}
          />
          <button
            type="button"
            tabIndex={17}
            className="flex items-center justify-center mb-[20px] p-2 bg-blue-600  h-[40px] w-[40px]"
            onClick={() => setSelectedSecondaryField(0)}
          >
            <img src={Icons.SendIcon} alt="" />
          </button>
        </div>
      );
    } else if (selectedSecondaryField === 2) {
      return (
        <div className="flex items-start w-full gap-3">
          {/* <TheirParkField
            {...{
              values,
              setValues,
              touched,
              errors,
              primaryActivity:
                selectedLogBook?.primaryActivity || Activities.POTA,
            }}
            name="theirPark"
            placeHolder="Their Park"
            onBlur={() => {
              if (values.theirPark) {
                debouncedGetParkByReference({
                  value: values.theirPark,
                  key: "theirPark",
                });
              }
            }}
            status={parkReferenceData?.theirPark ? "Park Match" : null}
            statusBg={
              parkReferenceData?.theirPark
                ? "bg-gradient-to-r from-[#8A24CA] to-[#651FFF]"
                : "bg-gradient-to-r from-[#FD9D82] to-[#FF6F46]"
            }
            isLoading={isParkReferenceDataLoading?.theirPark}
            width="w-[68.5%] sm:w-[30.5%] md:w-[20%] xl:w-[20%]"
            value={values.theirPark}
            className="!bg-white dark:!bg-opacity-10 h-[40px] mt-[0px]"
          /> */}
          <TheirParkField
            {...{
              values,
              setValues,
              touched,
              errors,
              primaryActivity:
                selectedLogBook?.primaryActivity || Activities.POTA,
            }}
          />
          <button
            type="button"
            className="flex items-center justify-center mb-[20px] p-2 bg-blue-600  h-[40px] w-[40px]"
            onClick={() => setSelectedSecondaryField(0)}
          >
            <img src={Icons.SendIcon} alt="" />
          </button>
        </div>
      );
    } else if (selectedSecondaryField === 3) {
      const addPotaAutoSpotHandler = () => {
        const parkReference = Object.keys(selectedLogBook?.myParks || {})?.[0] || selectedLogBook?.activitiesReferences?.[0];

        if (!parkReference) {
          showToast({
            message: "Please add a park reference to your logbook first", type: ToastTypes.ERROR
          });
          return
        }

        if (parkReference && values?.myCallSign && values?.frequency) {
          // add spot
          addPotaAutoSpot(
            {
              activator: values?.myCallSign,
              spotter: values?.myCallSign,
              reference: parkReference,
              comments: comments,
              frequency: `${parseFloat(values?.frequency) * 1000}`,
              mode: values?.userMode,
              band: values?.band,
            },
            selectedLogBook?.id
          );
        }

        setSelectedSecondaryField(0);
        setComments("");
      };

      return (
        <div className="flex flex-col w-full">
          <div className="flex dark:text-white text-[#2b2e48] font-[Play] text-sm mb-4 font-bold">
            Spot yourself to POTA automatically
          </div>
          <div className="flex flex-row gap-x-1 items-center w-full flex-wrap">
            <InputWithButton
              setText={(e) => {
                handleChange(e);
                setValues((prev: any) => ({
                  ...prev,
                  frequency: e.target.value,
                }));
              }}
              title="Frequency"
              name="frequency"
              placeHolder="Frequency"
              onFieldBlur={onFrequencyBlur}
              width="w-[46%] sm:w-[20.5%] md:w-[20%]"
              value={values.frequency}
              btnTxt="MHz"
              fitBtn
              onBtnClick={() => {}}
              error={
                errors?.frequency && touched?.frequency
                  ? errors?.frequency
                  : undefined
              }
              className="!bg-white dark:!bg-opacity-10 h-[40px]"
              btnHeight="h-[40px]"
              containerClass="-mt-[25px]"
            />
            <DropDownTitled
              onChange={(e) => {
                handleChange(e);
                setUserBand(e.target.value);
              }}
              name="band"
              info="The band you made the contact on."
              value={userBand ? userBand : ""}
              title="Band"
              placeHolder="Band"
              tabindex={5}
              className={"mb-1 !bg-white dark:!bg-opacity-10 h-[40px]"}
              width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[16%]"
              lists={AllBandsOption}
              getTitle={(item) =>
                convertBandValue(item.name, item.unit) + item.unit
              }
              getValue={(item) => item.name}
              error={errors?.band && touched?.band ? errors?.band : undefined}
              containerClass="-mt-[20px]"
            />

            <DropDownTitled
              onChange={(e) => {
                handleChange(e);
                // setUserMode(e.target.value);
                setValues((prev: any) => ({
                  ...prev,
                  userMode: e.target.value,
                }));
              }}
              name="userMode"
              value={values.userMode}
              title="Mode"
              placeHolder="Mode"
              tabindex={6}
              width="w-[46%] sm:w-[20.5%] md:w-[16%]"
              lists={Modes}
              error={
                errors?.userMode && touched?.userMode
                  ? errors?.userMode
                  : undefined
              }
              containerClass="-mt-[25px]"
              className="!bg-white dark:!bg-opacity-10 h-[40px]"
            />
            <TitledInput
              setText={(e) => {
                setComments(e.target.value);
              }}
              title="Comment"
              name="comments"
              placeHolder="Comments"
              onBlur={() => {}}
              width="w-[46%] sm:w-[30.5%] md:w-[30%] xl:w-[26%]"
              value={comments}
              className="!bg-white dark:!bg-opacity-10 h-[40px] mb-1"
            />
            <button
              type="button"
              className="flex items-center justify-center mb-[20px] md:mb-0 p-2 bg-blue-600  h-[40px] w-[40px]"
              onClick={addPotaAutoSpotHandler}
            >
              <img src={Icons.SendIcon} alt="" />
            </button>
            {selectedLogBook?.lastSpottedAt?.seconds ? (
              <p className="dark:text-white text-[#2b2e48] font-[Play] text-sm">
                POTA Auto Spot sent{" "}
                <span className="text-[#1e40af] dark:text-[#17F9DA]">
                  {moment.utc(
                    new Date(selectedLogBook?.lastSpottedAt?.seconds * 1000)
                  ).fromNow()}
                </span>{" "}
                on{" "}
                <span className="text-[#1e40af] dark:text-[#17F9DA]">
                  {selectedLogBook?.lastSpottedFrequency}/
                  {selectedLogBook?.lastSpottedBand}
                </span>{" "}
                {selectedLogBook?.lastSpottedComment
                  ? selectedLogBook?.lastSpottedComment.substring(0, 10)
                  : ""}
              </p>
            ) : null}
          </div>
        </div>
      );
    } else if (selectedSecondaryField === 4) {
      const powerSaveHandler = () => {
        updateLogbookSettings({ defaultPower: values?.power });
        setSelectedSecondaryField(0);
      };

      return (
        <div className="flex items-center w-full gap-3">
          <TitledInput
            setText={(e) => {
              setValues((prev: any) => {
                return {
                  ...prev,
                  power: e.target.value,
                };
              });
            }}
            name="power"
            placeHolder="Power (W)"
            width="w-[68.5%] sm:w-[30.5%] md:w-[20%] xl:w-[20%]"
            value={values.power}
            className="!bg-white dark:!bg-opacity-10 h-[40px] mt-[0px]"
            tabindex={7}
            error={errors?.power && touched?.power ? errors?.power : undefined}
            containerClass={display ? "pointer-events-none" : ""}
          />
          <button
            type="button"
            className="flex items-center justify-center mb-[20px] p-2 bg-blue-600  h-[40px] w-[40px]"
            onClick={powerSaveHandler}
          >
            <img src={Icons.SendIcon} alt="" />
          </button>
        </div>
      );
    } else if (selectedSecondaryField === 5) {
      return (
        <div className="flex items-center w-full gap-3">
          <TitledInput
            setText={(e) => {
              setValues((prev: any) => {
                return {
                  ...prev,
                  notes: e.target.value,
                };
              });
            }}
            name="notes"
            placeHolder="Notes"
            value={values.notes}
            width="w-[68.5%] sm:w-[50.5%] md:w-[50%] xl:w-[50%]"
            className="!bg-white dark:!bg-opacity-10 h-[40px] mt-[0px]"
            tabindex={20}
            error={errors?.notes && touched?.notes ? errors?.notes : undefined}
            containerClass={display ? "pointer-events-none" : ""}
          />
          <button
            type="button"
            className="flex items-center justify-center mb-[20px] p-2 bg-blue-600  h-[40px] w-[40px]"
            onClick={() => setSelectedSecondaryField(0)}
          >
            <img src={Icons.SendIcon} alt="" />
          </button>
        </div>
      );
    } else if (selectedSecondaryField === 7) {
      return (
        <div className="flex items-center w-full gap-3">
          <div className="flex flex-col lg:flex-row items-center gap-1 w-full">
            <Formik initialValues={{}} onSubmit={() => {}}>
              <Form className="flex flex-row gap-3 flex-wrap w-full">
                <SearchCountryPicker
                  name="country"
                  title="Their Country"
                  info="Country of the other operator."
                  tabindex={8}
                  value={values?.country}
                  countryCode={values?.countryCode}
                  placeHolder="Country"
                  dxccNumber={values?.dxccNumber || ""}
                  width={
                    values?.country && values?.country?.length
                      ? "w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[27%]"
                      : "w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[27.5%]"
                  }
                  containerClass={display ? "pointer-events-none" : ""}
                  setText={(val) => {
                    const dxccData = getDxDataFromCountry(val);
                    setValues((prev: any) => {
                      return {
                        ...prev,
                        country: val,
                        grid: val !== values?.country ? "" : values?.grid,
                        dxccNumber: dxccData?.dxccNumber || "",
                        continent: dxccData?.continent || "",
                        flagCode: dxccData?.flagCode || "",
                        countryCode: dxccData?.countryCode || "",
                        state: val !== values?.country ? "" : values?.state,
                      };
                    });
                  }}
                />

                {values?.country && values?.country.length ? (
                  <TitledRegionPicker
                    setText={(val) => {
                      setValues((prev: any) => {
                        return {
                          ...prev,
                          state: val,
                          grid: val !== values?.state ? "" : values?.grid,
                        };
                      });
                    }}
                    name="state"
                    title="Their State"
                    info="State of the other operator."
                    placeHolder="State"
                    tabindex={9}
                    width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] xl:w-[20%]"
                    className={
                      "mb-1 dark:!bg-[#23234C] !bg-[#f1f3f4] dark:!text-white !text-[#2b2e48]"
                    }
                    value={values?.state}
                    error={
                      errors?.state && touched?.state
                        ? errors?.state
                        : undefined
                    }
                    country={
                      LocationMappings[`${values?.country}`] || values?.country
                    }
                    containerClass={display ? "pointer-events-none" : ""}
                  />
                ) : null}
                <TitledInput
                  setText={(e) => {
                    const value = e.target.value;
                    setValues((prev: any) => ({
                      ...prev,
                      grid: value,
                    }));
                  }}
                  name="grid"
                  title="Their Grid"
                  value={values?.grid}
                  placeHolder="Grid"
                  className="bg-[#23234C]"
                  width="w-[46%] sm:w-[30.5%] md:w-[20%]"
                  containerClass=""
                  onBlur={() => {
                    if (values.grid) {
                      getUserCountryFromGrid(values.grid, setValues);
                    }
                  }}
                />
                <button
                  type="button"
                  className="flex items-center justify-center mt-[24px] p-2 bg-blue-600  h-[36px] w-[36px]"
                  onClick={() => setSelectedSecondaryField(-1)}
                >
                  <img src={Icons.SendIcon} alt="" />
                </button>
              </Form>
            </Formik>
          </div>
        </div>
      );
    }

    return null;
  };

  const debouncedCallsignInformation = useDebouncedCallback((searchString) => {
    if (searchString) {
      getUserFromHamApiData(searchString, setValues);
    } else {
      handleClearAddContactForm(initialData, setTouched, setValues);
    }

    callSignBlurPopupHandler(searchString);
  }, 800);

  const theirCallSignChangeHandler = (e: any) => {
    const theirCallSign = e.target.value.toUpperCase().trim();
    setValues((prev: any) => {
      return {
        ...prev,
        theirCallsign: theirCallSign,
      };
    });
    debouncedCallsignInformation(theirCallSign);
  };

  const getTheirParkLabel = () => {
    const theirParks = Object.keys(values?.theirParks || {});
    if (theirParks) {
      if (theirParks.length > 1) {
        return "Multiple Parks";
      } else if (theirParks.length === 1) {
        return theirParks[0];
      }
      return `${getParkTypeName(
        selectedLogBook?.primaryActivity || Activities.POTA
      )} to ${getParkTypeName(
        selectedLogBook?.primaryActivity || Activities.POTA
      )}`;
    }
  };

  const getPotaLogControlOptions = () => {
    const options = [
      {
        id: 1,
        icon: Icons.clockWhiteIcon,
        label: `${userTimeInput ? values.time : currentTime || "00:00:00"} z`,
        tabIndex: 8,
      },
      {
        id: 2,
        icon: getActivityToActivityIcon(
          selectedLogBook?.primaryActivity || Activities.POTA
        ),
        label: getTheirParkLabel(),
        tabIndex: 9,
      },
      {
        id: 7,
        icon: Icons.LocationPinIcon,
        label: values?.grid || "Their Location",
        tabIndex: 13,
      },
      {
        id: 4,
        icon: Icons.powerWhite,
        label: values.power ? `${values.power} Watts` : "Power",
        tabIndex: 11,
      },
      {
        id: 5,
        icon: Icons.notesWhite,
        label: "Notes",
        tabIndex: 12,
      },
      // Hide tags for now
      // {
      //   id: 6,
      //   icon: Icons.tagsWhite,
      //   label: "Tags",
      // },
    ];

    if (
      selectedLogBook?.primaryActivity === Activities.POTA ||
      (selectedLogBook?.activities &&
        selectedLogBook?.activities?.includes(Activities.POTA)) ||
        selectedLogBook?.logbookStyle === Templates.POTA
    ) {
      const potaAutoSpotOption = {
        id: 3,
        icon: Icons.parkWhite,
        label: "POTA Autospot",
        tabIndex: 10,
      };
      options.splice(1, 0, potaAutoSpotOption);
    }

    return options;
  };

  return (
    <div className="w-full">
      <div className="flex flex-wrap">
        <TitledInput
          setText={theirCallSignChangeHandler}
          name="theirCallsign"
          title="Their Callsign"
          placeHolder="Their Callsign"
          tabindex={1}
          status={
            duplicateContactInfo && callSignData !== "NOT_FOUND" && callSignData
              ? `DUPE | ${formatBandValue(duplicateContactInfo.band)}`
              : callSignData !== "NOT_FOUND" && callSignData
              ? "Callsign Match"
              : null
          }
          statusBg={
            duplicateContactInfo && callSignData !== "NOT_FOUND" && callSignData
              ? "bg-gradient-to-r from-[#9B8647] to-[#C7B36D]"
              : callSignData !== "NOT_FOUND" && callSignData
              ? "bg-gradient-to-r from-[#8A24CA] to-[#651FFF]"
              : "bg-gradient-to-r from-[#FD9D82] to-[#FF6F46]"
          }
          isLoading={isCallSignDataLoading}
          width="w-[48.5%] sm:w-[19.5%] md:w-[18%] lg:w-[35%] xl:w-[22%]"
          value={values.theirCallsign}
          className={"mb-1.5"}
          error={
            errors?.theirCallsign && touched?.theirCallsign
              ? errors?.theirCallsign
              : undefined
          }
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
          props={{ innerRef: callSignRef }}
          flashField={spottingCopied}
        />
        <TitledInput
          setText={handleChange}
          name="rstSent"
          title="RST Sent"
          info='Signal report that you gave to the other operator. "59" is a good signal.'
          placeHolder="RST Sent"
          value={values.rstSent}
          tabindex={2}
          className={"mb-1"}
          width="w-[48.5%] sm:w-[15.5%] md:w-[12%] lg:w-[25%] xl:w-[16%]"
          error={
            errors?.rstSent && touched?.rstSent ? errors?.rstSent : undefined
          }
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
          flashField={spottingCopied}
        />
        <TitledInput
          setText={handleChange}
          name="rstRCVD"
          title="RST RCVD"
          info='Signal report that you received from the other operator. "59" would be a good signal.'
          placeHolder="RST RCVD"
          value={values.rstRCVD}
          tabindex={3}
          className={"mb-1"}
          width="w-[48.5%] sm:w-[15.5%] md:w-[12%] lg:w-[25%] xl:w-[16%]"
          error={
            errors?.rstRCVD && touched?.rstRCVD ? errors?.rstRCVD : undefined
          }
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
          flashField={spottingCopied}
        />
        <InputWithButton
          setText={(e) => {
            handleChange(e);
            setValues((prev: any) => ({
              ...prev,
              frequency: e.target.value,
            }));
            // debounceChangeFrequencyCATControl(e.target.value);
          }}
          title="Frequency"
          name="frequency"
          placeHolder="Frequency"
          onFieldBlur={onFrequencyBlur}
          width="w-[48.5%] sm:w-[21.5%] md:w-[18%] lg:w-[35%] xl:w-[24%]"
          value={values.frequency}
          btnTxt="MHz"
          fitBtn
          onBtnClick={() => {}}
          error={
            errors?.frequency && touched?.frequency
              ? errors?.frequency
              : undefined
          }
          className="mb-1"
          btnHeight="h-8"
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
          tabindex={4}
          flashField={spottingCopied}
        />
        {/* </div>
      <div className="flex flex-wrap mb-8"> */}
        <DropDownTitled
          onChange={(e) => {
            handleChange(e);
            setUserBand(e.target.value);
          }}
          name="band"
          info="The band you made the contact on."
          value={userBand ? userBand : ""}
          title="Band"
          placeHolder="Band"
          tabindex={5}
          className={"mb-1"}
          width="w-[48.5%] sm:w-[23.5%] md:w-[13%] lg:w-[25%] xl:w-[22%]"
          lists={AllBandsOption}
          getTitle={(item) =>
            convertBandValue(item.name, item.unit) + item.unit
          }
          getValue={(item) => item.name}
          error={errors?.band && touched?.band ? errors?.band : undefined}
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
        />
        <DropDownTitled
          onChange={(e) => {
            handleChange(e);
            setUserMode(e.target.value);
            setValues((prev: any) => ({
              ...prev,
              userMode: e.target.value,
            }));
          }}
          name="userMode"
          info="The mode of operation you are using on your radio."
          value={userMode ? userMode : ""}
          title="Mode"
          placeHolder="Mode"
          tabindex={6}
          className={"mb-1"}
          width="w-[48.5%] sm:w-[15.5%] md:w-[13%] lg:w-[25%] xl:w-[16%]"
          lists={Modes}
          error={
            errors?.userMode && touched?.userMode ? errors?.userMode : undefined
          }
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
        />
        <div className="flex items-center gap-4 w-[78.5%] sm:w-[39%] md:w-[30%] lg:w-[50%] xl:w-[44%] mt-4 md:mt-1 mb-1 md:mb-4 lg:my-6">
          <div className="flex-1">
            <AnimatedPrimaryButton
              isLoading={isSavingOnLoading}
              text={"Log Contact"}
              onClick={() => {
                // setUserBand("");
                // setUserMode("");
                // setDate(getCurrentDate());
                onLogPress && onLogPress();
              }}
              childClassName="text-sm dark:text-white text-[#2b2e48] w-[calc(100%-3px)] h-[calc(100%-3px)]"
              className={`${
                display ? "pointer-events-none" : ""
              } rounded-full bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] w-full`}
              tabIndex={7}
              successState={contactSuccess}
              endAnimation={endSuccessAnimation}
            />
          </div>
          <div className="flex-1" tabIndex={8}>
            <BorderButtonSecondary
              isLoading={false}
              text={"Clear"}
              onClick={() =>
                handleClearAddContactForm(
                  initialData,
                  setTouched,
                  setValues,
                  true
                )
              }
              childClassName="text-sm"
              className={display ? "pointer-events-none" : ""}
            />
          </div>
        </div>
      </div>

      {/* POta secondary fields */}

      <SelectableButtonsGroup
        initialSelected={selectedSecondaryField}
        toggle
        disabled={display}
        actions={getPotaLogControlOptions()}
        onButtonChange={(action) => {
          if (action && (action.id as number) > 0) {
            setSelectedSecondaryField(action.id as number);
          } else {
            setSelectedSecondaryField(-1);
          }
        }}
      />

      {selectedSecondaryField > 0 ? (
        <div className="flex w-full bg-indigo-500 bg-opacity-10  mt-4 items-center p-4">
          {renderSecondaryFields()}
        </div>
      ) : null}

      <div className="flex mt-4">
        {searchParams.get("contest_id") || contestDetail ? (
          <div className="flex flex-wrap gap-6 w-full items-center">
            {contestDetail?.exchangeOne ||
            contestDetail?.exchangeTwo ||
            contestDetail?.exchangeThree ||
            contestDetail?.exchangeFour ? (
              <p className=" dark:text-white text-[#2b2e48] font-[Play] text-sm font-bold">
                CONTEST EXCHANGE
              </p>
            ) : null}

            {contestDetail?.exchangeOne ? (
              <TitledInputGradient
                setText={handleChange}
                name="exchangeOne"
                title={contestDetail?.exchangeOne}
                placeHolder="Enter Answer"
                value={values.exchangeOne}
                width={"w-full sm:w-[47.5%] md:w-[18%] xl:w-[18%]"}
                className={"placeholder-[#000]"}
                tabindex={17}
                error={undefined}
              />
            ) : null}
            {contestDetail?.exchangeTwo ? (
              <TitledInputGradient
                setText={handleChange}
                name="exchangeTwo"
                title={contestDetail?.exchangeTwo}
                placeHolder="Enter Answer"
                value={values.exchangeTwo}
                width={"w-full sm:w-[47.5%] md:w-[18%] xl:w-[18%]"}
                className={"placeholder-[#000]"}
                tabindex={18}
                error={undefined}
              />
            ) : null}
            {contestDetail?.exchangeThree ? (
              <TitledInputGradient
                setText={handleChange}
                name="exchangeThree"
                title={contestDetail?.exchangeThree}
                placeHolder="Enter Answer"
                value={values.exchangeThree}
                width={"w-full sm:w-[47.5%] md:w-[18%] xl:w-[18%]"}
                className={"placeholder-[#000]"}
                tabindex={19}
                error={undefined}
              />
            ) : null}
            {contestDetail?.exchangeFour ? (
              <TitledInputGradient
                setText={handleChange}
                name="exchangeFour"
                title={contestDetail?.exchangeFour}
                placeHolder="Enter Answer"
                value={values.exchangeFour}
                width={"w-full sm:w-[47.5%] md:w-[18%] xl:w-[18%]"}
                className={"placeholder-[#000]"}
                tabindex={20}
                error={undefined}
              />
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default POTALog;
