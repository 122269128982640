/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Templates } from "constants/Config";
import { showToast } from "helpers/Toast";
import useConfig from "hooks/config.hook";
import useLogBook from "hooks/logBook.hook";
import { useUserActivity } from "hooks/useUserActivity.hook";
import { globalNavigate } from "routes/GlobalRoutes";
import {
  TableDropDownItemsProps,
  TableDropDownProps,
  ToastTypes,
} from "types/Component";
import { UserActivityTypeDisplayName } from "../ActivityLogTable/TableCol";
import { useEffect, useRef, useState } from "react";

function TableDropDown({ listItems, itemData, onClick }: TableDropDownProps) {
  const { handlePrimaryModal, primaryModalData } = useConfig();
  const {
    deleteLogBook,
    getLogbookContactForAdifExport,
    showMergeLogbookModal,
  } = useLogBook();
  const { userActivityType, userActivityStatus } = useUserActivity();
  const listOptions = [...listItems];

  const preparePotaExportOptions = () => {
    if (itemData?.logbookStyle !== Templates.POTA && !itemData?.primaryActivity)
      return [];

    const options: TableDropDownItemsProps[] = [];
    const parks = itemData?.activitiesReferences
      ? itemData.activitiesReferences
      : Object.keys(itemData?.myParks || {});

    parks.forEach((park: any) => {
      options.push({
        text: `Export ${park} ADIF`,
        meta: park,
        type: itemData?.primaryActivity || "",
      });
    });

    return options;
  };

  const insertPosition = listOptions.length - 1;
  listOptions.splice(insertPosition, 0, ...preparePotaExportOptions());

  const [position, setPosition] = useState<"top" | "bottom">("bottom");
  const [align, setAlign] = useState<"left" | "right">("left");
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handlePosition = () => {
      if (dropdownRef.current) {
        const rect = dropdownRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;

        if (rect.bottom > viewportHeight && rect.top > rect.height) {
          setPosition("top");
        } else {
          setPosition("bottom");
        }

        // Check for right overflow
        if (rect.right > viewportWidth) {
          setAlign("right");
        } else {
          setAlign("left");
        }
      }
    };

    handlePosition();
    window.addEventListener("resize", handlePosition);
    return () => window.removeEventListener("resize", handlePosition);
  }, []);

  return (
    <div
      ref={dropdownRef}
      className={`absolute bg-white shadow-md w-[200px] z-20 ${
        position === "top" ? "bottom-full -mb-2" : "top-full -mt-2"
      } ${align === "right" ? "right-0" : "left-0"}`}
    >
      {listOptions.map((item, index) => {
        return (
          <div
            className={`text-white font-[Barlow] flex font-normal text-xs px-3 py-1.5 cursor-pointer bg-[#3C3C58] hover:bg-[#3C3C75] ${
              item?.color ? "!text-red-400 !font-bold" : ""
            }`}
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
              if (item?.text === "Delete") {
                if (userActivityStatus === "In Progress") {
                  showToast({
                    message: `${UserActivityTypeDisplayName?.[userActivityType]} is in progress. Please complete the activity first.`,
                    type: ToastTypes.WARN,
                  });
                  return;
                }
                if (primaryModalData) {
                  handlePrimaryModal(null);
                } else {
                  handlePrimaryModal({
                    title:
                      "Are you sure you want to delete this logbook and all the contacts associated with it? This cannot be undone",
                    type: "Delete Logbook",
                    subType: itemData?.name,
                    onCancelPressed: () => handlePrimaryModal(null),
                    onSubmitPressed: () => {
                      deleteLogBook(
                        itemData?.id ? itemData.id : "",
                        itemData?.contestId
                      );
                      handlePrimaryModal(null);
                    },
                    submitText: "Delete",
                    cancelText: "Cancel",
                  });
                }
              } else if (item?.text === "Export ADIF") {
                if (itemData?.exportInProgress) {
                  showToast({
                    message: "Export in progress, please wait and try again!",
                    type: ToastTypes.WARN,
                  });
                } else {
                  getLogbookContactForAdifExport(itemData?.id || "");
                }
              } else if (item?.text === `Export ${item?.meta} ADIF`) {
                if (itemData?.exportInProgress) {
                  showToast({
                    message: "Export in progress, please wait and try again!",
                    type: ToastTypes.WARN,
                  });
                } else {
                  getLogbookContactForAdifExport(itemData?.id || "", {
                    parkReference: item?.meta,
                    activityType: item?.type || "",
                  });
                }
              } else if (item?.text === "View Import Progress") {
                globalNavigate("/log-contacts/import-summary");
              } else if (item?.link) {
                globalNavigate(item.link, {
                  state: itemData,
                });
              } else if (item?.text === "Merge to Other Logbook") {
                showMergeLogbookModal(itemData);
              } else if (item?.text === "Import ADIF to this Logbook") {
                globalNavigate("/log-contacts/import-contact", {
                  state: {
                    preSelectedLogbook: itemData?.id,
                    logbookName: itemData?.name,
                  },
                });
              }
            }}
          >
            {item.text}
          </div>
        );
      })}
    </div>
  );
}

export default TableDropDown;
