import { InputWithButtonProps } from "types/Component";
import { HiPause } from "react-icons/hi2";
import { RiInformationLine } from "react-icons/ri";
import { useEffect, useState } from "react";

function InputWithButton({
  placeHolder,
  name,
  type,
  text,
  setText,
  className,
  width,
  btnTxt,
  fitBtn,
  onBtnClick,
  title,
  error,
  onFieldBlur,
  tabindex,
  value,
  info,
  btnWidth,
  containerClass,
  btnHeight,
  flashField,
}: InputWithButtonProps) {
  const [infoOpen, setInfoOpen] = useState(false);
  const [flash, setFlash] = useState(false);

  useEffect(() => {
    if (flashField) {
      // Trigger the flash state
      setFlash(true);
      // Remove flash after 500ms or any suitable duration
      const timer = setTimeout(() => {
        setFlash(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [flashField]);
  return (
    <div
      className={`${width ? width : "w-full"} relative ${
        containerClass ? containerClass : ""
      }`}
    >
      {title ? (
        <div className="flex items-center">
          <label className="font-[Barlow] font-bold text-sm dark:text-white text-[#2b2e48]">
            {title}
          </label>

          {info ? (
            <div
              onMouseEnter={info ? () => setInfoOpen(true) : () => {}}
              onMouseLeave={info ? () => setInfoOpen(false) : () => {}}
            >
              <RiInformationLine color="#999" className="ml-1" />
            </div>
          ) : null}
        </div>
      ) : null}
      <div className={`flex w-full ${!name ? "" : "mt-1.5"}`}>
        <input
          className={`dark:bg-[#23234C] bg-[#f1f3f4] border border-[#dcdcdd] dark:border-none px-3 text-sm dark:text-white text-[#2b2e48] w-full font-[Barlow] ${
            className ? `${className} h-8` : "h-10"
          } ${error ? "!border !border-orange-700 !border-solid" : ""} ${
            flash ? "flash-animation" : ""
          }`}
          placeholder={placeHolder}
          onChange={(e: any) => setText(e)}
          onBlur={onFieldBlur}
          type={type ? type : "text"}
          name={name}
          value={value}
          id=" "
          tabIndex={tabindex}
        />
        {onBtnClick ? (
          <div
            className={`bg-[#0243DD] font-[Barlow] text-sm cursor-pointer ${
              btnWidth ? btnWidth : btnTxt && !fitBtn ? "w-10" : "w-12"
            } h-10 flex justify-center items-center ${btnHeight}`}
            onClick={onBtnClick}
          >
            {btnTxt ? (
              <p className={`text-white font-[Barlow] ${fitBtn ? "" : "px-3"}`}>
                {btnTxt}
              </p>
            ) : (
              <HiPause color="white" />
            )}
          </div>
        ) : null}
      </div>
      {info && infoOpen ? (
        <div className="absolute top-[50%] left-0 right-0 z-[1000] px-[4px] py-[5px] lg:px-[6px] xl:px-[10px] text-[12px] font-[Play] bg-[#524A6F] text-white">
          {info}
        </div>
      ) : null}
    </div>
  );
}

export default InputWithButton;
