import { Icon } from "components/icons/Icon";
import React from "react";

interface CustomGradientButtonProps {
  icon?: any;
  text: string;
  textClass?: string;
  iconClass?: string;
  className?: string;
  onClick?: () => void;
}

const CustomGradientButton: React.FC<CustomGradientButtonProps> = ({
  icon = null,
  iconClass = "w-[12px] h-[12px]",
  text,
  className = "w-[55px] h-[11px] from-[#828aff] to-[#8ae9ff] rounded-[50px]",
  textClass = "text-[5px] text-[#010a41]",
  onClick,
}) => {
  return (
    <div
      className={`px-1.5 py-1 bg-gradient-to-br backdrop-blur-[50px] justify-center items-center gap-2.5 inline-flex ${className}`}
      onClick={onClick}
    >
      <div className="justify-start items-center gap-0.5 flex">
        {icon && <Icon className={`${iconClass}`} icon={icon} />}
        <div className={`text-center font-['Play'] ${textClass}`}>{text}</div>
      </div>
    </div>
  );
};

export default CustomGradientButton;
