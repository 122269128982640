import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import CountDown from "components/CountDown";
import { showToast } from "helpers/Toast";
import useContest from "hooks/contest.hook";
import { FaFacebook, FaXTwitter, FaLinkedin, FaLink } from "react-icons/fa6";
import { useSearchParams } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { ToastTypes } from "types/Component";
import { CONTEST_STATUS } from "types/Functions";

function ContestHeader() {
  const {
    contestDetail,
    contestStatus,
    contestantDetails,
    setSelectedLogbook,
    isContestantDetailLoading,
    enrollToContest,
    isEnrollContestLoading,
  } = useContest();

  let [searchParams, setSearchParams] = useSearchParams();

  const getContestStatusText = () => {
    if (contestStatus === CONTEST_STATUS.UPCOMING) {
      return "CONTEST STARTS IN:";
    } else if (contestStatus === CONTEST_STATUS.ACTIVE) {
      return "TIME LEFT:";
    } else if (contestStatus === CONTEST_STATUS.PASSED) {
      return "CONTEST ENDED:";
    } else {
      return "CONTEST ENDED:";
    }
  };

  const getHeaderStatusText = () => {
    if (contestStatus === CONTEST_STATUS.UPCOMING) {
      return "WAITING TO START";
    } else if (contestStatus === CONTEST_STATUS.ACTIVE) {
      return "CONTEST IS LIVE!";
    } else if (contestStatus === CONTEST_STATUS.PASSED) {
      return "CONTEST ENDED";
    } else {
      return "CONTEST ENDED";
    }
  };

  const goToLogContact = () => {
    if (contestStatus === CONTEST_STATUS.ACTIVE) {
      setSelectedLogbook({
        id: contestantDetails?.enrolledUser.logBookId,
        name: contestantDetails?.enrolledUser.logbookName,
        uid: contestantDetails?.enrolledUser.uid,
        contestId: contestDetail?.id,
        contest: {
          name: contestDetail?.name || "",
          startDate: contestDetail?.startDate,
          endDate: contestDetail?.endDate,
          image: contestDetail?.image || "",
          shortDescription: contestDetail?.shortDescription || "",
        },
      });
      setSearchParams((prev: any) => {
        return {
          ...prev,
          tab: "log_contest_contacts",
          contest_id: searchParams.get("contest_id"),
          "logbook-id": contestantDetails?.enrolledUser.logBookId,
          "logbook-name": contestantDetails?.enrolledUser.logbookName,
        };
      });
    } else {
      showToast({
        message: "Contest hasn't started yet.",
        type: ToastTypes.WARN,
      });
    }
  };

  return (
    <div className="flex flex-col md:flex-row w-full mb-6 relative md:h-[110px] mt-[10px]">
      <div className="h-[50px] flex md:hidden">
        <div className="flex flex-col w-full h-full">
          <div className="z-[10] flex flex-1 h-full justify-center items-center dark:text-white text-[#2b2e48] font-[Play] font-bold text-lg md:text-3xl relative overflow-visible">
            <img
              src={Icons.Header}
              className="absolute top-[-3px] w-full h-full overflow-visible"
              style={{
                objectFit: "cover",
                transform: "scaleX(1.05)", // This will stretch the image on both sides
                transformOrigin: "center", // Ensures it stretches from the center
              }}
              alt="background"
            />
            <div className="z-[11] text-white pb-2 flex flex-row items-center gap-2">
              {contestDetail?.image ? (
                <img
                  className="w-6 h-6 border rounded-md overflow-hidden"
                  src={contestDetail.image}
                  alt={contestDetail.name}
                />
              ) : null}
              {contestDetail?.name}
            </div>
          </div>
          {/* <div className="flex flex-1 h-full relative justify-center items-center">
            <img
              src={Icons.SubHeader}
              className="absolute top-[-3px] w-[80%] h-full object-cover overflow-visible"
              style={{ objectFit: "cover" }}
              alt="background"
            />
            <div className="z-[11] text-[#010A41] font-Barlow font-semibold text-[20px] tracking-tight">
              {getHeaderStatusText()}
            </div>
          </div> */}
        </div>
      </div>

      <div className="flex flex-row w-full h-[100px] text-white">
        {/* letf */}
        <div className="flex flex-[3] md:flex-[1] md:h-full relative overflow-visible">
          <img
            src={Icons.LeftHead}
            className="absolute top-0 right-0 z-0 w-full h-full object-cover overflow-visible"
            alt="background"
          />
          <div className="flex flex-col w-full z-10 h-full  px-4 py-3 font-Barlow font-semibold">
            <div className="z-[11] font-normal text-base text-sm md:text-[18px]">
              {getContestStatusText()}
            </div>
            <div className="z-[11]">
              {contestDetail?.startDate?.seconds &&
                contestDetail?.endDate?.seconds && (
                  <CountDown
                    startDate={
                      new Date(
                        new Date(
                          contestDetail?.startDate?.seconds * 1000
                        ).toISOString()
                      )
                    }
                    endDate={
                      new Date(
                        new Date(
                          contestDetail?.endDate?.seconds * 1000
                        ).toISOString()
                      )
                    }
                  />
                )}
            </div>
          </div>
        </div>
        {/* mid */}
        <div className="flex-[3] h-full hidden md:flex">
          <div className="flex flex-col w-full h-full">
            <div className="z-[10] flex flex-1 h-full justify-center items-center dark:text-white text-[#2b2e48] font-[Play] font-bold text-3xl relative overflow-visible">
              <img
                src={Icons.Header}
                className="absolute top-[-3px] w-full h-full overflow-visible"
                style={{
                  objectFit: "cover",
                  transform: "scaleX(1.05)", // This will stretch the image on both sides
                  transformOrigin: "center", // Ensures it stretches from the center
                }}
                alt="background"
              />
              <div className="z-[11] text-white pb-2 flex flex-row items-center gap-2">
                {contestDetail?.image ? (
                  <img
                    className="w-6 h-6 border rounded-md overflow-hidden"
                    src={contestDetail.image}
                    alt={contestDetail.name}
                  />
                ) : null}
                {contestDetail?.name}
              </div>
            </div>
            <div className="flex flex-1 h-full relative justify-center items-center">
              <img
                src={Icons.SubHeader}
                className="absolute top-[-3px] w-[80%] h-full object-cover overflow-visible"
                style={{ objectFit: "cover" }}
                alt="background"
              />
              <div className="z-[11] text-[#010A41] font-Barlow font-semibold text-[20px] tracking-tight">
                {getHeaderStatusText()}
              </div>
            </div>
          </div>
        </div>
        <div className="flex-[1] flex md:hidden"></div>
        {/* right */}
        <div className="flex flex-[3] md:flex-[1] md:h-full relative overflow-visible">
          <img
            src={Icons.RightHead}
            className="absolute top-0 left-0 w-full h-full overflow-visible"
            style={{ objectFit: "cover" }}
            alt="background"
          />
          <div className="flex flex-col w-full z-10 h-full px-4 pt-4 pb-1 font-Barlow font-semibold">
            <div className="z-[11] pl-11 flex flex-col h-full justify-between items-end w-full">
              <div className="w-[70%]">
                <BorderButtonSecondary
                  text={
                    contestantDetails?.isUserEnrolled
                      ? "My Logbook"
                      : "Join for FREE!"
                  }
                  onClick={() => {
                    if (contestantDetails?.isUserEnrolled) {
                      goToLogContact();
                    } else if (contestDetail) {
                      enrollToContest(contestDetail);
                    }
                  }}
                  className="sm:mb-0 cursor-pointer z-[11]"
                  height=""
                  childClassName=""
                  isLoading={
                    isEnrollContestLoading || isContestantDetailLoading
                  }
                />
              </div>

              <div className="z-[11] flex items-center rounded-[10px] gap-2">
                <p className="text-white font-bold">Share:</p>
                <FacebookShareButton
                  url={`${process.env.REACT_APP_SHARE_URL}contest/${contestDetail?.id}`}
                  quote={"Check out this Contest on World Radio League"}
                  hashtag="#WorldRadioLeague"
                >
                  <div className="w-[20px] h-[20px] rounded-full bg-[#505BF9] flex items-center justify-center">
                    <FaFacebook size="12px" color="#fff" />
                  </div>
                </FacebookShareButton>
                <TwitterShareButton
                  url={`${process.env.REACT_APP_SHARE_URL}contest/${contestDetail?.id}`}
                  title={"Check out this Contest on World Radio League"}
                  hashtags={["WorldRadioLeague"]}
                >
                  <div className="w-[20px] h-[20px] rounded-full bg-[#505BF9] flex items-center justify-center">
                    <FaXTwitter size="12px" color="#fff" />
                  </div>
                </TwitterShareButton>

                <LinkedinShareButton
                  url={`${process.env.REACT_APP_SHARE_URL}contest/${contestDetail?.id}`}
                  title="Check out this Contest on World Radio League"
                  summary="Check out this Contest on World Radio League"
                  source="https://www.worldradioleague.com"
                >
                  <div className="w-[20px] h-[20px] rounded-full bg-[#505BF9] flex items-center justify-center">
                    <FaLinkedin size="12px" color="#fff" />
                  </div>
                </LinkedinShareButton>

                <div
                  className="w-[20px] h-[20px] rounded-full bg-[#505BF9] flex items-center justify-center cursor-pointer"
                  onClick={async () => {
                    await navigator.clipboard.writeText(
                      `${process.env.REACT_APP_SHARE_URL}contest/${contestDetail?.id}`
                    );
                    showToast({
                      message: "Share link copied to clipboard!",
                      type: ToastTypes.SUCCESS,
                    });
                  }}
                >
                  <FaLink size="12px" color="#fff" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContestHeader;
