import TableCol from "components/Table/TableEntries/TableCol";
import React, { useEffect } from "react";
import { TableEntriesProps } from "types/Component";
import { FaSort } from "react-icons/fa";
import TableEntColCompact from "./TableColContest";

function groupContactsByDateWithHeading(contacts: any[]) {
  try {
    const groupedContacts: any = {};
    // Group contacts by date in UTC
    contacts.forEach((contact) => {
      const dateObj = new Date(contact.contactTimeStamp.seconds * 1000);
      const utcYear = dateObj.getUTCFullYear();
      const utcMonth = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-based
      const utcDay = String(dateObj.getUTCDate()).padStart(2, "0");
      // Construct the UTC date string (YYYY-MM-DD)
      const dateString = `${utcYear}-${utcMonth}-${utcDay}`;
      if (!groupedContacts[dateString]) {
        groupedContacts[dateString] = [];
      }
      groupedContacts[dateString].push(contact);
    });
    // Convert the grouped object to an array with heading
    const result: any[] = [];
    Object.keys(groupedContacts).forEach((date) => {
      const formattedDate = new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
      }); // Convert to human-readable format
      result.push({ type: "heading", heading: formattedDate });
      result.push(...groupedContacts[date]);
    });
    return result;
  } catch (error) {
    return [];
  }
}

function TableEntriesContest({
  lists,
  editable,
  missingFields,
  guestUser,
  showExchange,
  exchangeOne,
  exchangeTwo,
  showSort,
  setDateOrder,
}: TableEntriesProps) {
  // console.log(lists);

  return (
    <div className="w-full overflow-scroll mt-4">
      <table className="w-full min-w-max table-auto">
        <thead className="">
          <tr className="bg-[#94a3b883] dark:bg-[#3C3C58] py-2.5 border-0 border-b border-slate-300 border-solid dark:text-white text-[#2b2e48]">
            <th className="py-3 font-[Barlow] text-sm md:text-left flex items-center">
              <div className="md:ml-4">Date</div>
              {showSort ? (
                <div
                  className="ml-2 mt-1 cursor-pointer"
                  onClick={() => setDateOrder && setDateOrder()}
                >
                  <FaSort className="" />
                </div>
              ) : null}
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              User
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Section
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Class
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Frequency
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Band
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Mode
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Points
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left">
              Conf.
            </th>
            <th className="pl-2 py-3 font-[Barlow] text-sm md:text-left"></th>
          </tr>
        </thead>
        <tbody>
          {lists.map((item: any, index: number) => {
            return (
              <TableEntColCompact
                key={`${item.id}_${index}`}
                item={item}
                className={`${
                  index % 2
                    ? "dark:bg-[#151536] bg-white"
                    : "dark:bg-[#1F1F3F] bg-[#fafafa]"
                } border-0 border-b border-slate-300 border-solid`}
                editable={editable}
                missingFields={missingFields}
                guestUser={guestUser}
                showExchange={showExchange}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TableEntriesContest;
