import Icons from "assets/Icons";
import TitledInput from "components/Inputs/TitledInput";
import { Formik, Form, useField } from "formik";
import { useDebouncedCallback } from "use-debounce";
import usePota from "hooks/pota.hook";
import { Activities, ParkReferenceModel } from "types/Models";
import InputWithButton from "components/Inputs/InputWithButton";
import DropDownTitled from "components/DropDown/DropDownTitled";
import { Modes } from "constants/ArrayVariables";
import { globalNavigate } from "routes/GlobalRoutes";
import {
  findBandAndModeForFrequency,
  getParkTypeName,
  getPrimaryActivity,
  getStateLongName,
  getStateShortCode,
  getUniqueActivities,
  getUpdatedActivitiesData,
  suggestionsFormatter,
  updateMyParks,
  validateNumberOfSelectedActivities,
} from "helpers/Utils";
import { useState } from "react";
import { fetchCoordinates } from "helpers/Location/GeoLocation";
import { getUserProfileSelector } from "store/Reducers/profile.reducer";
import { useSelector } from "react-redux";
import { Tooltip } from "components/Tooltip";
import CustomValidation from "helpers/Validation";
import { showToast } from "helpers/Toast";
import { ToastTypes } from "types/Component";
import { HorizontalDivider } from "components/Divider";
import useLogBook from "hooks/logBook.hook";
import { Templates } from "constants/Config";
import { DefaultLocationSettings } from "components/DefaultLocationSettings";
import { Timestamp } from "firebase/firestore";
import { ActivitiesColorMapping } from "../../../constants/index";
import ActivityIndicator from "components/Loading/ActivityIndicator";
import useTheme from "hooks/useTheme.hook";
var uniq = require("lodash.uniq");
interface CheckboxProps {
  name: string;
  label: string;
}

export type PotaActivationForm = {
  myPark: "";
  frequency: "";
  userMode: "";
  myParks: Record<string, ParkReferenceModel>;
  country: string;
  state: string;
  grid: string;
  latitude: string | number;
  longitude: string | number;
};

const Checkbox: React.FC<CheckboxProps> = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        {...field}
        {...props}
        className="form-checkbox h-5 w-5 bg-[#0243DD]"
      />
      <label
        htmlFor={props.name}
        className="ml-2 dark:text-white text-[#2b2e48] text-sm font-Play "
      >
        {label}
      </label>
    </div>
  );
};

interface PotaActivationProps {
  multiActivities?: boolean;
  activityType?: string;
  onActivityClose?: () => void;
  formikRef?: any;
  formData?: any;
  setFormData?: any;
  locationData?: any;
}

export function PotaActivation({
  multiActivities,
  activityType,
  onActivityClose,
  formikRef,
  formData,
  setFormData,
  locationData,
}: PotaActivationProps) {
  const userProfile = useSelector(getUserProfileSelector);
  const { theme } = useTheme();
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const {
    isParkReferenceDataLoading,
    parkReferenceSuggestionsData,
    getParkByReference,
    getParReferenceSuggestions,
    setParReferenceSuggestions,
    setParkByReference,
    getNearbyParkReferences,
    nearbyParkReferencesData,
    setAutoSpotData,
    addPotaAutoSpot,
    setNearbyParkReferences,
    isFindMyParkLoading,
  } = usePota();

  const { createNewLogBook } = useLogBook();

  const debouncedParkReferenceChangeHandler = useDebouncedCallback(
    (searchString) =>
      getParReferenceSuggestions(searchString, activityType || Activities.POTA),
    1000
  );

  const debouncedGetParkByReference = useDebouncedCallback(
    (searchString) =>
      getParkByReference(searchString, activityType || Activities.POTA),
    200
  );

  const resetParkReferenceSuggestions = (key: string) => {
    setTimeout(() => setParReferenceSuggestions({ key, value: null }), 200);
  };

  const hideParkSuggestions = () => {
    setTimeout(() => setShowSuggestions(false), 200);
  };

  const setNearbyParkReferencesNull = () => {
    setTimeout(() => setNearbyParkReferences(null), 200);
  };

  const showParkSuggestions = (key: string, setValues: any) => {
    if (!showSuggestions) return {};
    return {
      suggestions: parkReferenceSuggestionsData?.[key]
        ? parkReferenceSuggestionsData?.[key]
        : nearbyParkReferencesData,
      suggestionsFormatter,
      onSuggestionClick: (suggestion: ParkReferenceModel) => {
        if (!validateNumberOfSelectedActivities(activityType || "", formData)) {
          return; // Stop further execution
        }

        // after setting value remove suggestions
        resetParkReferenceSuggestions(key);
        // Updates state for values related to parks and activities.
        setValues((prev: any) => {
          const updatedMyParks = updateMyParks(prev, suggestion); // Update 'myParks' with the new suggestion.
          const updatedActivitiesData = getUpdatedActivitiesData(
            prev,
            suggestion,
            activityType
          ); // Add new activity data.
          const updatedPrimaryActivity = getPrimaryActivity(prev, activityType); // Determine the primary activity type.
          const uniqueActivities = getUniqueActivities(
            prev,
            suggestion,
            activityType
          ); // Ensure activities are unique.

          return {
            ...prev,
            myParks: updatedMyParks,
            myPark: "", // Reset the 'myPark' field.
            activitiesData: updatedActivitiesData,
            primaryActivity: updatedPrimaryActivity,
            activities: uniqueActivities,
          };
        });
        // Updates form data with new parks and activity details.
        setFormData &&
          setFormData((prev: any) => {
            const updatedMyParks = updateMyParks(prev, suggestion); // Update 'myParks' with the new suggestion.
            const updatedActivitiesData = getUpdatedActivitiesData(
              prev,
              suggestion,
              activityType
            ); // Add new activity data.
            const updatedPrimaryActivity = getPrimaryActivity(
              prev,
              activityType
            ); // Determine the primary activity type.
            const uniqueActivities = getUniqueActivities(
              prev,
              suggestion,
              activityType
            ); // Ensure activities are unique.

            return {
              ...prev,
              myParks: updatedMyParks,
              myPark: "", // Reset the 'myPark' field.
              activitiesData: updatedActivitiesData,
              primaryActivity: updatedPrimaryActivity,
              activities: uniqueActivities,
            };
          });

        // hide suggestions
        hideParkSuggestions();
      },
    };
  };

  const findMyParkHandler = async () => {
    const coor = await fetchCoordinates();
    if (coor?.latitude && coor?.longitude) {
      getNearbyParkReferences(
        [coor?.latitude, coor?.longitude],
        activityType || Activities.POTA
      );
      setShowSuggestions(true);
    } else {
      showToast({
        type: ToastTypes.ERROR,
        message:
          "Unable to fetch your location, please check your browser settings",
      });
    }
  };

  const renderSelectedParks = (setValues: any) => {
    if (
      !Object.keys(formData?.myParks || {}).length &&
      !formData?.activitiesData?.length
    )
      return null;

    const removePark = (key: string) => {
      //remove park from form data as well as key
      setFormData &&
        setFormData((prev: any) => {
          const { [key]: omit, ...rest } = prev.myParks;
          return { ...prev, myParks: rest };
        });

      // also update formit
      setValues((prev: any) => {
        const { [key]: omit, ...rest } = prev.myParks;
        return { ...prev, myParks: rest };
      });
      // remove from activitiesData
      setFormData &&
        setFormData((prev: any) => {
          return {
            ...prev,
            activitiesData: prev.activitiesData.filter(
              (park: any) => park.reference !== key
            ),
          };
        });
    };
    return (
      <div className="flex flex-row dark:text-white text-[#2b2e48] text-[11px] gap-2 -mt-[10px] flex-wrap">
        {formData.activitiesData?.map((data: any, index: number) => {
          const type = data?.type || Activities.POTA;
          if (type !== activityType) return null;

          return (
            <div
              key={index}
              className="flex flex-row rounded-[10px] border border-neutral-400 gap-2 px-2 py-1 items-center bg-neutral-400 bg-opacity-25 "
            >
              <Tooltip
                message={`${data?.reference} - ${
                  data?.parktypeDesc || data?.name
                }`}
                className="w-[180px]"
              >
                <div>{`${data?.reference}`}</div>
              </Tooltip>

              <img
                src={Icons.CrossIcon}
                className="h-3 w-3 invert dark:filter-none dark:invert-0 cursor-pointer"
                alt="close"
                onClick={() => removePark(data?.reference)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={
        multiActivities
          ? "w-full"
          : `mt-[50px] md:mt-10 w-[90%] sm:w-2/3 md:w-4/6 lg:w-4/6 xl:w-4/6 2xl:w-3/6 py-11 px-6 sm:px-6 md:px-12 lg:px-14 xl:px-20 flex flex-col items-center border-2 border-emerald-500 ${
              theme === "dark" ? "glassmorphism" : "bg-white relative"
            }`
      }
    >
      <Formik
        initialValues={{
          myPark: "",
          frequency: "",
          userMode: "",
          myParks: {},
          country: "",
          state: "",
          grid: "",
          latitude: "",
          band: "",
          longitude: "",
          autospot: false,
          logbookStyle: Templates.POTA,
          defaultCallSign: userProfile?.callSign || "",
          locationSource: "",
          activitiesData: [],
          activities: [],
          primaryActivity: "",
        }}
        innerRef={formikRef}
        validationSchema={CustomValidation.PotaActivationValidationSchema}
        onSubmit={async (values) => {
          if (values?.frequency || values?.userMode) {
            setAutoSpotData({
              frequency: values?.frequency,
              mode: values?.userMode,
            });

            if (values?.autospot && values?.myParks) {
              const parkReference = Object.keys(values?.myParks)?.[0];

              if (parkReference) {
                // add spot
                addPotaAutoSpot({
                  activator: values?.defaultCallSign,
                  spotter: values?.defaultCallSign,
                  reference: parkReference,
                  comments: "Starting POTA Activation",
                  frequency: `${parseFloat(values?.frequency) * 1000}`,
                  mode: values?.userMode,
                  band: values?.band,
                });
              }
            }
          }

          let name = "";
          name = `${values?.defaultCallSign} @ `;
          name += formData.activitiesData
            ? formData.activitiesData[0]?.reference
            : Object.keys(values?.myParks || {}).join(", ");

          const defaultLocation = {
            isCustom: true,
            country: locationData?.country || values.country,
            state: getStateShortCode(locationData?.state || values.state),
            stateLongName: getStateLongName(
              locationData?.state || values.state
            ),
            qth: "",
            lat: parseFloat(locationData?.latitude || values?.latitude),
            lng: parseFloat(locationData?.longitude || values?.longitude),
            gridsquare: locationData?.grid || values?.grid,
            id: "",
            locationType: "",
            default: false,
            name: "",
            uid: "",
          };
          let newLogbookData: any = {
            name,
            defaultLocation,
            logbookStyle: values?.logbookStyle || Templates.POTA,
            defaultCallSign: values?.defaultCallSign || "",
            myParks: values?.myParks || {},
            activitiesData: formData?.activitiesData || [],
            activitiesReferences: formData?.activitiesData?.map(
              (activity: any) => activity.reference
            ),
            activities: formData?.activitiesData?.length
              ? uniq(
                  formData.activitiesData.map((activity: any) => {
                    if (!activity.type) {
                      return Activities.POTA;
                    } else {
                      return activity.type;
                    }
                  })
                )
              : [],
            primaryActivity: formData?.primaryActivity || "",
          };
          if (values?.autospot && values?.myParks) {
            const parkReference = Object.keys(values?.myParks)?.[0];

            if (parkReference) {
              // add spot
              newLogbookData.lastSpottedAt = Timestamp.now();
              newLogbookData.lastSpottedFrequency = values?.frequency;
              newLogbookData.lastSpottedMode = values?.userMode;
              newLogbookData.lastSpottedBand = values?.band;
              newLogbookData.lastSpottedComment = "Starting POTA Activation";
            }
          }

          createNewLogBook(newLogbookData, true);
        }}
        // innerRef={formikRef}
      >
        {({ errors, touched, handleChange, submitForm, values, setValues }) => {
          const onFrequencyBlur = () => {
            const freqNumber = parseFloat(values.frequency);
            const bandMode = findBandAndModeForFrequency(freqNumber);
            setValues((prev: any) => {
              return {
                ...prev,
                userMode: bandMode?.mode,
                band: bandMode?.band,
              };
            });
            setFormData &&
              setFormData((prev: any) => ({
                ...prev,
                userMode: bandMode?.mode,
                band: bandMode?.band,
              }));
          };

          const handleKeyDown = (event: any) => {
            if (event.key === "Enter") {
              event.preventDefault();
              submitForm();
            }
          };

          return (
            <Form
              className="flex flex-col w-full gap-4"
              onKeyDown={handleKeyDown}
            >
              {multiActivities ? null : (
                <div className="flex flex-col">
                  <div className="flex flex-row gap-3 justify-center items-center md:justify-start lg:justify-start xl:justify-start">
                    <img
                      src={Icons.parkWhite}
                      className="h-8 invert dark:filter-none dark:invert-0"
                      alt="close"
                    />
                    <div className="dark:text-white text-[#2b2e48] text-3xl font-bold font-['Play']">
                      New POTA Activation
                    </div>
                  </div>
                </div>
              )}
              <div
                className="flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row gap-4 w-full items-center md:items-start"
                style={multiActivities ? { marginTop: 15 } : {}}
              >
                <div className={`w-[68%]`}>
                  <TitledInput
                    setText={(e) => {
                      setValues((prev: any) => {
                        return {
                          ...prev,
                          myPark: e.target.value.toUpperCase(),
                        };
                      });

                      setFormData &&
                        setFormData((prev: any) => ({
                          ...prev,
                          myPark: e.target.value.toUpperCase(),
                        }));

                      if (e.target.value.length > 1) {
                        debouncedParkReferenceChangeHandler({
                          key: "myPark",
                          value: e.target.value.toUpperCase().trim(),
                        });
                      }

                      // reset park data on change
                      setParkByReference({ key: "myPark", value: null });
                    }}
                    onBlur={() => {
                      if (values.myPark) {
                        debouncedGetParkByReference({
                          value: values.myPark,
                          key: "myPark",
                        });
                      }

                      // remove suggestions on blur
                      resetParkReferenceSuggestions("myPark");
                      setNearbyParkReferencesNull();
                      // hide suggestions
                      hideParkSuggestions();
                    }}
                    onFocus={() => setShowSuggestions(true)}
                    isLoading={!!isParkReferenceDataLoading?.myPark}
                    name="myPark"
                    title={`Select Your ${getParkTypeName(
                      activityType || Activities.POTA
                    )} (s)`}
                    placeHolder="Type the name of the park here"
                    width="w-full"
                    tabindex={15}
                    activityType={activityType || Activities.POTA}
                    value={values.myPark}
                    error={
                      errors?.myParks && touched?.myParks
                        ? errors?.myParks
                        : undefined
                    }
                    containerClass={"m-0"}
                    {...showParkSuggestions("myPark", setValues)}
                    className={"h-10"}
                    onActivityClose={onActivityClose}
                  />

                  {renderSelectedParks(setValues)}
                </div>

                <button
                  onClick={() => {
                    resetParkReferenceSuggestions("myPark");
                    setNearbyParkReferences(null);
                    findMyParkHandler();
                  }}
                  className="text-play dark:text-white text-[#2b2e48] rounded-full px-4 py-1 border-2 mt-6"
                  style={{
                    borderColor: ActivitiesColorMapping[activityType || "POTA"],
                  }}
                  disabled={isFindMyParkLoading}
                >
                  {isFindMyParkLoading ? (
                    <div className="w-[32px] h-[20px]">
                      {isFindMyParkLoading ? (
                        <ActivityIndicator size={8} />
                      ) : null}
                    </div>
                  ) : (
                    `Find My ${getParkTypeName(
                      activityType || Activities.POTA
                    )}`
                  )}
                </button>
              </div>
              {multiActivities ? null : (
                <>
                  <HorizontalDivider />
                  <div className="flex flex-col gap-3 items-center md:items-start">
                    <div className="flex font-Play dark:text-white text-[#2b2e48] w-[68%] md:w-full">
                      You can preset frequency & mode now or wait until you’re
                      logging:
                    </div>
                    <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row gap-4 w-full items-center">
                      <InputWithButton
                        setText={(e) => {
                          handleChange(e);
                          setFormData &&
                            setFormData((prev: any) => ({
                              ...prev,
                              frequency: e.target.value,
                            }));
                        }}
                        name="frequency"
                        title="Frequency"
                        placeHolder="Frequency"
                        onFieldBlur={onFrequencyBlur}
                        width="w-[68%] md:w-[48%] lg:w-[48%] xl:w-[48%] 2xl:w-[48%]"
                        value={values.frequency}
                        btnTxt="MHz"
                        fitBtn
                        onBtnClick={() => {}}
                        error={
                          errors?.frequency && touched?.frequency
                            ? errors?.frequency
                            : undefined
                        }
                        className="h-8"
                        btnHeight="h-8"
                        //   containerClass={display ? "pointer-events-none" : ""}
                      />

                      <DropDownTitled
                        onChange={(e) => {
                          handleChange(e);
                          // setUserMode(e.target.value);
                          setFormData &&
                            setFormData((prev: any) => ({
                              ...prev,
                              userMode: e.target.value,
                            }));
                        }}
                        name="userMode"
                        value={values.userMode}
                        title="Mode"
                        placeHolder="Mode"
                        tabindex={6}
                        width="w-[68%] md:w-[48%] lg:w-[48%] xl:w-[48%] 2xl:w-[48%]"
                        lists={Modes}
                        error={
                          errors?.userMode && touched?.userMode
                            ? errors?.userMode
                            : undefined
                        }
                        containerClass=""
                        className={"h-11"}
                      />
                    </div>
                  </div>
                </>
              )}

              {formData && formData.frequency && formData.userMode ? (
                <div className="w-full flex flex-row justify-center md:justify-start lg:justify-start xl:justify-start -mt-2">
                  <Checkbox
                    name="autospot"
                    label={`Autospot to POTA ${formData?.frequency} MHz ${formData?.userMode}`}
                  />
                </div>
              ) : null}
              <HorizontalDivider />
              {multiActivities ? null : <DefaultLocationSettings />}

              {multiActivities ? null : (
                <div className="w-full flex flex-row justify-center">
                  <button
                    type="submit"
                    className="mt-10 w-[68%] md:w-[50%] lg:w-[50%] xl:w-[50%] 2xl:w-[40%] dark:text-white text-[#2b2e48] text-xl font-normal font-['Play'] border rounded-full px-3 py-1.5 border-2 border-emerald-500"
                  >
                    Start Logging
                  </button>
                </div>
              )}

              <div className="absolute top-2 right-2 cursor-pointer">
                <img
                  onClick={() => globalNavigate("/log-contacts")}
                  src={Icons.PotaClose}
                  className="h-8 w-8"
                  alt="close"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
