import NavBar from "components/NavBar";
import Images from "assets/Images";
import { Outlet } from "react-router-dom";
import PrimaryModal from "components/Modal/PrimaryModal";
import CongratulationModal from "components/Modal/CongratulationModal";
import useNotification from "hooks/notification.hook";
import ShareMapModal from "components/Modal/ShareMapModal";
import { useEffect } from "react";
import AddContestModal from "components/Modal/AddContestModal";
import useContest from "hooks/contest.hook";
import EnrollSuccessModal from "components/Modal/EnrollSuccessModal";
import EditContestModal from "components/Modal/EditContestModal";
import { useSelector } from "react-redux";
import {
  getAdminUserControlSelector,
  getShowAwardModalSelector,
  getUserProfileSelector,
  getReauthenticateModalSelector,
} from "store/Reducers/profile.reducer";
import AddUserAwardModal from "components/Modal/AddUserAwardModal";
import AdminControlUser from "components/Modal/AdminControlUser";
import {
  getAddAntennaModalSelector,
  getAddRadioModalSelector,
  getAddSpotModalSelector,
  getAddStationModalSelector,
} from "store/Reducers/logBook.reducer";
import AddSpotModal from "components/Modal/AddSpotModal";
import AddRadioModal from "components/Modal/AddRadioModal";
import AddAntennaModal from "components/Modal/AddAntennaModal";
import { Unsubscribe } from "firebase/firestore";
import { UserProfile } from "types/Models";
import { EditContactModal } from "pages/Dashboard/LogBook/EditContact";
import DeleteAccountModal from "components/Modal/DeleteAccountModal";
import AddAwardModal from "components/Modal/AddAwardModal";
import { getShowAwardModalSelector as getShowAwardModal } from "store/Reducers/award.reducer";
import AddStationModal from "components/Modal/AddStationModal";
import MergeLogbookModal from "components/Modal/MergeLogbookModal";
import { ConfirmChangesModal } from "pages/Dashboard/LogBook/EditContact/ConfirmChanges/ConfirmChangesModal";
import { useUserActivity } from "hooks/useUserActivity.hook";
import { ReAuthenticateModal } from "components/ReAuthenticateModal";
import { getContactMapModalStatus } from "store/Reducers/config.reducer";
import * as Sentry from "@sentry/react";

function MainLayout({ children }: any) {
  const {
    notificationData,
    setNotificationData,
    listenToNotifications,
    listenToNormlNotifications,
  } = useNotification();
  const { showAddContestModal, showEditContestModal, showEnrollSuccessData } =
    useContest();
  const showAwardUserData = useSelector(getShowAwardModalSelector);
  const adminControlUser = useSelector(getAdminUserControlSelector);
  const addSpotModal = useSelector(getAddSpotModalSelector);
  const addRadioModal = useSelector(getAddRadioModalSelector);
  const addAntennaModal = useSelector(getAddAntennaModalSelector);
  const addStationModal = useSelector(getAddStationModalSelector);
  const userProfile: UserProfile | null = useSelector(getUserProfileSelector);
  const showAwardModal = useSelector(getShowAwardModal);
  const { getAllUserActivities, setUserActivities } = useUserActivity();
  const showReauthModal = useSelector(getReauthenticateModalSelector);
  const contactMapModalStatus = useSelector(getContactMapModalStatus);
  useEffect(() => {
    const userId = userProfile?.uid;
    let unsubscribe: Unsubscribe | null = null;

    if (userId) {
      unsubscribe = getAllUserActivities(userId, (data: any) => {
        setUserActivities(data);
      });
      if (process.env.NODE_ENV !== "development") {
        Sentry.setUser({
          id: userId,
          email: userProfile?.email || "",
          username: userProfile?.callSign || "",
        });
      }
    }

    return () => {
      unsubscribe && unsubscribe();
    };
  }, [userProfile?.uid]);

  useEffect(() => {
    let unsubscribe1: Unsubscribe;
    let unsubscribe2: Unsubscribe;

    if (userProfile?.uid) {
      unsubscribe1 = listenToNotifications(userProfile?.uid);
      unsubscribe2 = listenToNormlNotifications(userProfile?.uid);
    }

    return () => {
      if (unsubscribe1) {
        unsubscribe1();
      }
      if (unsubscribe2) {
        unsubscribe2();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile?.uid]);

  return (
    <section className="relative w-screen h-screen flex overflow-hidden dark:bg-[#000030] bg-[#f1f3f4]">
      <img
        src={Images.SpaceBg}
        className="absolute top-0 left-0 z-0 h-full w-full object-cover"
        alt="background"
      />
      {showAddContestModal ? <AddContestModal /> : null}
      {showEditContestModal ? <EditContestModal /> : null}
      {adminControlUser ? <AdminControlUser /> : null}

      {addSpotModal ? <AddSpotModal /> : null}

      {addRadioModal ? <AddRadioModal /> : null}
      {addAntennaModal ? <AddAntennaModal /> : null}
      {showAwardModal ? <AddAwardModal /> : null}
      {addStationModal ? <AddStationModal /> : null}
      {showReauthModal ? <ReAuthenticateModal /> : null}

      {showEnrollSuccessData ? <EnrollSuccessModal /> : null}
      {showAwardUserData ? (
        <AddUserAwardModal user={showAwardUserData} />
      ) : null}
      {notificationData ? (
        <CongratulationModal
          notificationData={notificationData}
          setNotificationData={setNotificationData}
        />
      ) : null}
      {contactMapModalStatus ? <ShareMapModal className="" /> : null}
      <NavBar />
      <Outlet />
      <PrimaryModal />
      <EditContactModal />
      <DeleteAccountModal />
      <MergeLogbookModal />
      <ConfirmChangesModal />
    </section>
  );
}

export default MainLayout;
