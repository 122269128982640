import Icons from "assets/Icons";
import BorderButton from "components/Button/BorderButton";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import useProfile from "hooks/profile.hook";
import { useAccount } from "hooks/useAccount.hook";
import { useDispatch, useSelector } from "react-redux";
import { setShowAccountDeleteModal } from "store/Actions/profile.actions";
import { getShowAccountDeleteModalSelector } from "store/Reducers/profile.reducer";

function DeleteAccountModal() {
  const { userProfile } = useProfile();
  const dispatch = useDispatch();
  const showModal = useSelector(getShowAccountDeleteModalSelector);
  const { deleteUserAccount } = useAccount();

  if (!showModal) return null;

  const closeModal = () => {
    dispatch(setShowAccountDeleteModal(false));
  };

  const handleDeleteAccount = () => {
    deleteUserAccount(userProfile?.uid!);
    closeModal();
  };

  return (
    <section
      className={`h-full w-full flex-grow overflow-x-auto absolute top-0 right-0 glassmorphism-secondary flex items-center justify-center`}
    >
      <div className="bg-gradient-to-br from-fuchsia-500 via-violet-600 to-violet-900 p-[1px] inline-flex relative w-[95vw] sm:w-auto sm:max-w-[600px] md:max-w-[800px] shadow-lg">
        <div className="bg-[#0B0B39] w-full h-full  px-8 sm:px-14 pt-10 pb-6">
          <p className="font-[Play] font-bold text-white text-center text-2xl mb-6">
            Are you sure you want to delete your profile?
          </p>
          <p className="text-white text-center font-[Play] text-lg mb-7 mb-6">
            This action is permanent and cannot be undone. All of your data,
            including your logs, awards, etc, will be permanently deleted.
          </p>
          <div className="flex justify-between mt-10">
            <div className="w-[48%]">
              <BorderButtonSecondary
                text={"Yes, Delete"}
                childClassName="bg-[#FF3B30]"
                onClick={handleDeleteAccount}
              />
            </div>
            <div className="w-[48%]">
              {/* <BorderButtonSecondary text={"Cancel"} onClick={() => {}} /> */}
              <BorderButton
                text="Cancel"
                onClick={closeModal}
                className="cursor-pointer items-center"
                height="h-8"
              />
            </div>
          </div>

          <div
            className="absolute top-2 right-2 cursor-pointer"
            onClick={closeModal}
            tabIndex={55}
          >
            <img
              src={Icons.GlowingClose}
              className="h-8 w-8 bg-gray-300 rounded-full dark:bg-transparent"
              alt="close"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default DeleteAccountModal;
