import React, { useState } from "react";
import Icons from "assets/Icons";

import { useDispatch, useSelector } from "react-redux";
import {
  addAntennas,
  deleteAntennas,
  setAddAntennaModal,
} from "store/Actions/logBook.actions";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import { AntennaTypes } from "constants/Config";
import useLogbookSetting from "hooks/logbookSetting.hook";
import { ToastTypes } from "types/Component";
import { showToast } from "helpers/Toast";
import UploadService from "services/upload.service";
import { Antenna } from "types/Models";
import { getAddAntennaModalSelector } from "store/Reducers/logBook.reducer";
const AddAntennaModal = () => {
  const dispatch = useDispatch();
  const addAntennaModal = useSelector(getAddAntennaModalSelector);

  const [antennaName, setAntennaName] = useState(addAntennaModal?.name || "");
  const [antennaBrand, setAntennaBrand] = useState(
    addAntennaModal?.brand || ""
  );
  const [antennaModel, setAntennaModel] = useState(
    addAntennaModal?.model || ""
  );
  const [antennaImage, setAntennaImage] = useState("");
  const [antennaType, setAntennaType] = useState(addAntennaModal?.type || "");
  const [antennaDescription, setAntennaDescription] = useState(
    addAntennaModal?.description || ""
  );
  const [yearPurchased, setYearPurchased] = useState(
    addAntennaModal?.yearPurchased || ""
  );
  const [yearManufactured, setYearManufactured] = useState(
    addAntennaModal?.yearManufactured || ""
  );

  const [loading, setLoading] = useState(false);
  const { logbookSettingLoading } = useLogbookSetting();

  const toast = (message: string) => {
    showToast({
      message: message,
      type: ToastTypes.WARN,
    });
  };

  const saveAntenna = async () => {
    try {
      setLoading(true);
      if (antennaName) {
        if (antennaBrand) {
          if (antennaModel) {
            if (antennaType) {
              let imageUrl;
              if (antennaImage && antennaImage.length > 0) {
                imageUrl = await UploadService.handleUploadContestImage(
                  antennaImage[0],
                  "antennas"
                );
              }
              let antennaData: Antenna = {
                name: antennaName,
                brand: antennaBrand,
                model: antennaModel,
                photoUrl: imageUrl || addAntennaModal?.photoUrl || "",
                type: antennaType,
                description: antennaDescription,
                yearPurchased: yearPurchased,
                yearManufactured: yearManufactured,
                numberOfQSO: addAntennaModal?.numberOfQSO || 0,
              };
              if (addAntennaModal?.id) {
                antennaData.id = addAntennaModal.id;
              }
              dispatch(addAntennas(antennaData));
            } else {
              toast("Please select antenna type");
            }
          } else {
            toast("Please enter antenna model");
          }
        } else {
          toast("Please enter antenna brand");
        }
      } else {
        toast("Please select antenna anme");
      }
    } catch (e) {}
    setLoading(false);
  };

  const deleteUserAntenna = () => {
    if (addAntennaModal?.id) {
      //confirm delete with native browser confirmation alert
      if (window.confirm("Are you sure you want to delete this antenna?")) {
        dispatch(deleteAntennas(addAntennaModal.id));
      }
    }
  };

  return (
    <section className="absolute w-screen h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[25%] lg:px-[25%] overflow-scroll">
      <div className=" w-full ">
        <div className="w-full ">
          <div className="flex  flex-col w-full dark:bg-[#151541] bg-white px-6 py-5 dark:text-white text-[#2b2e48] font-[Play] relative mt-10 md:mt-0 border border-[#451836]">
            <div
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer"
              onClick={() => {
                dispatch(setAddAntennaModal(false));
              }}
            >
              <img
                src={Icons.GlowingClose}
                className="h-8 w-8 invert dark:filter-none dark:invert-0"
                alt="close"
              />
            </div>
            <h5 className="font-[Play] font-bold dark:text-white text-[#2b2e48] py-2 ml-3 w-full text-lg">
              Add Antenna
            </h5>
            <div className="px-1 md:px-14 mt-10 w-full ">
              <TitledInputDefault
                setText={(e) => {
                  setAntennaName(e.target.value);
                }}
                name="name"
                title="Name:"
                placeHolder="Enter antenna name"
                value={antennaName}
                width={"w-[100%]"}
                className={"h-[36px] mt-[12px] mr-3"}
                tabindex={20}
                error={null}
              />
              <TitledInputDefault
                setText={(e) => {
                  setAntennaBrand(e.target.value);
                }}
                name="name"
                title="Brand:"
                value={antennaBrand}
                placeHolder="Enter antenna brand"
                width={"w-[100%]"}
                className={"h-[36px] mt-[12px] mr-3"}
                tabindex={20}
                error={null}
              />
              <TitledInputDefault
                setText={(e) => {
                  setAntennaModel(e.target.value);
                }}
                name="name"
                title="Model:"
                placeHolder="Enter antenna model"
                value={antennaModel}
                width={"w-[100%]"}
                className={"h-[36px] mt-[12px] mr-3"}
                tabindex={20}
                error={null}
              />
              <TitledInputDefault
                setText={(e) => {
                  setAntennaImage(e.target.files);
                }}
                name="name"
                title="Photo:"
                placeHolder="Enter station name"
                type="file"
                width={"w-[100%]"}
                className={"h-[36px] mt-[12px] mr-3"}
                tabindex={20}
                error={null}
              />
              <LogbookSettingDropdown
                list={AntennaTypes}
                title="Type:"
                value={antennaType}
                className="w-[99.9%] sm:w-[99.9%] md:w-[99.9%] md:mr-14 sm:mr-10 relative mb-4"
                setValue={(value) => {
                  setAntennaType(value);
                }}
              />
              <TitledInputDefault
                setText={(e) => {
                  setAntennaDescription(e.target.value);
                }}
                name="name"
                title="Description/Info:"
                placeHolder="Enter station name"
                value={antennaDescription}
                multiple
                width={"w-[100%]"}
                className={"h-[100px] mt-[12px] mr-3"}
                tabindex={20}
                error={null}
              />
              <div className="flex justify-between">
                <TitledInputDefault
                  setText={(e) => {
                    setYearPurchased(e.target.value);
                  }}
                  name="name"
                  title="Year Purchased:"
                  placeHolder="Enter station name"
                  value={yearPurchased}
                  type="date"
                  width={"w-[40%]"}
                  className={" mt-[12px] mr-3"}
                  tabindex={20}
                  error={null}
                />
                <TitledInputDefault
                  setText={(e) => {
                    setYearManufactured(e.target.value);
                  }}
                  name="name"
                  title="Year Manufactured (if known)::"
                  placeHolder="Enter station name"
                  value={yearManufactured}
                  type="date"
                  width={"w-[40%]"}
                  className={" mt-[12px] mr-3"}
                  tabindex={20}
                  error={null}
                />
              </div>
            </div>
            <div className="flex w-full justify-center">
              <BorderButtonSecondary
                isLoading={loading || logbookSettingLoading}
                text={"Save"}
                onClick={saveAntenna}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
              />
              {addAntennaModal?.id ? (
                <BorderButtonSecondary
                  isLoading={loading || logbookSettingLoading}
                  text={"Delete"}
                  onClick={deleteUserAntenna}
                  childClassName="text-sm"
                  className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3 ml-2"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddAntennaModal;
