import Icons from "assets/Icons";
import Images from "assets/Images";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import { MY_PROFILE_ROUTE } from "constants/Links";
import CustomValidation from "helpers/Validation";
import useNotification from "hooks/notification.hook";
import useProfile from "hooks/profile.hook";
import React, { useEffect } from "react";
import { globalNavigate } from "routes/GlobalRoutes";
import { CongratulationModalProps } from "types/Component";

const CongratulationModal = ({
  notificationData,
  setNotificationData,
}: CongratulationModalProps) => {
  const { updateNotifications } = useNotification();
  const { userProfile } = useProfile();
  useEffect(() => {
    updateNotifications(notificationData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="absolute w-screen h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism-secondary py-[5%] px-[2%] md:px-[5%] lg:px-[10%]">
      <div className="overflow-x-hidden w-full">
        <div className="relative w-full">
          <img
            className="h-full w-full object-cover absolute z-0"
            src={Images.CongratulationBg}
            alt="bg"
          />
          <div className="w-full h-full relative flex flex-col items-center">
            <h6 className="font-[Barlow] font-black text-white text-[25px] sm:text-[35px] md:text-[45px] lg:text-[80px] mt-12 sm:mt-7">
              {notificationData.title}
            </h6>
            <p className="font-[Barlow] text-center text-[16px] sm:text-[20px] md:text-[25px] lg:text-[30px] text-white">
              {notificationData.body}
            </p>
            <div className="w-[300px] md:w-[600px] h-[300px] glassmorphism mt-7 rounded-[20px] flex items-center justify-center">
              <img
                className="w-[90%] md:w-full h-full md:h-[80%] object-contain"
                src={notificationData.image}
                alt="certificate"
              />
            </div>
            <div className="w-[95%] sm:w-[400px] h-[135px] sm:h-[115px] glassmorphism mt-7 mb-12 rounded-[15px] relative">
              <div className="flex justify-center items-center px-[7%] mt-6">
                <div className="w-[48%]">
                  {notificationData.buttonTitle ? (
                    <BorderButtonSecondary
                      text={notificationData.buttonTitle}
                      onClick={() => {
                        setNotificationData(null);
                        window.location.href = `${notificationData.buttonLink}`;
                      }}
                      className="w-full"
                      height="h-8"
                    />
                  ) : (
                    <BorderButtonSecondary
                      text={"See Award"}
                      onClick={() => {
                        setNotificationData(null);
                        window.location.href = `${MY_PROFILE_ROUTE}/${CustomValidation.encodeSlash(
                          userProfile?.callSign ? userProfile?.callSign : ""
                        )}?tab=Logbook&contact-tab=entries`;
                      }}
                      className="w-full"
                      height="h-8"
                    />
                  )}
                </div>
                {/* <div className="w-[48%]">
                  <BorderButtonSecondary
                    text={"Save"}
                    onClick={() => {}}
                    className="w-full"
                    height="h-8"
                  />
                </div> */}
              </div>
              {/* <div className="flex flex-col sm:flex-row items-center justify-center mt-2 sm:mt-4">
                <p className="font-[Barlow] font-bold text-white text-lg mb-2 sm:mb-0">
                  Share to socials:
                </p>
                <div className="flex items-center">
                  <RiFacebookFill
                    color="#fff"
                    className="mx-3 w-4 h-4 lg:w-5 lg:h-5 cursor-pointer"
                    onClick={() => {}}
                  />
                  <SiLinkedin
                    color="#fff"
                    className="mx-3 w-4 h-4 lg:w-5 lg:h-5 cursor-pointer"
                    onClick={() => {}}
                  />
                  <SiTwitter
                    color="#fff"
                    className="mx-3 w-4 h-4 lg:w-5 lg:h-5 cursor-pointer"
                    onClick={() => {}}
                  />
                  <PiInstagramLogoFill
                    color="#fff"
                    className="mx-3 w-4 h-4 lg:w-5 lg:h-5 cursor-pointer"
                    onClick={() => {}}
                  />
                </div>
              </div> */}
            </div>
          </div>
          <div
            className="absolute top-5 right-5 cursor-pointer"
            onClick={() => {
              updateNotifications(notificationData);
              setNotificationData(null);
              globalNavigate("/");
            }}
          >
            <img
              src={Icons.GlowingClose}
              alt="close"
              className="h-8 w-8 bg-gray-300 rounded-full dark:bg-transparent"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CongratulationModal;
