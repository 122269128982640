import { LogBookState } from "types/State";
import { RootState } from "store/Reducers";
import {
  EDIT_CONTACT_MODAL_DATA,
  LogBookActionPayload,
  RESET_LOGBOOK_DATA,
  SAVE_ALL_CONTACTS_DATA,
  SAVE_ALL_CONTACTS_PAGINATED_DATA,
  SAVE_ALL_PAGINATED_USER_CONTACTS_DATA,
  SAVE_ALL_USER_CONTACTS_DATA,
  SAVE_CONTACTS_DATA,
  SAVE_LOGBOOKS_DATA,
  SAVE_WORKED_USER_COUNTRIES,
  SAVE_WORKED_USER_GRID_SQUARE,
  SELECTED_LOGBOOK_DATA,
  SET_CALLSIGN_DATA,
  SAVE_ALL_PAGINATED_LOGBOOK_CONTACTS_DATA,
  SET_CONTACT_DETAIL,
  SET_SEARCH_RESULTS,
  SET_DAILY_LEADERS,
  ADD_SPOT_MODAL,
  ADD_ANTENNA_MODAL,
  ADD_RADIO_MODAL,
  SET_SAVED_LOCATION,
  SET_ANTENNAS,
  SET_RADIOS,
  SAVE_CONTACT_POPUP_SUMMARY,
  ADD_STATIONS_MODAL,
  SET_STATIONS,
  MERGE_LOGBOOK_CONTACTS,
  CONFIRM_CHANGES_MODAL_DATA,
  SET_CURRENT_SEARCH_STRING,
  QUICK_SETTINGS_CURRENT_TAB
} from "store/Actions/logBook.actions";
const defaultState: LogBookState = {
  logBooks: [],
  selectedLogBook: null,
  logBookContacts: [],
  allContacts: [],
  callSignLookup: null,
  editContactModalData: null,
  allUserContactsData: [],
  workedUserCountries: [],
  workedUserGridSquare: [],
  allContactsListPaginated: [],
  allProfilePaginatedContacts: null,
  allLogBookPaginatedContacts: null,
  contactDetail: null,
  searchResults: null,
  dailyLeaders: [],
  addSpotModal: null,
  addRadioModal: null,
  addAntennaModal: null,
  savedLocations: [],
  antennas: [],
  radios: [],
  addContactPopupSummary: null,
  addStationModal: null,
  stations: [],
  mergeLogbook: null,
  confirmChangesModalData: null,
  currentSearchString: "",
  quickSettingsCurrentTab: null
};

export default function logBookReducer(
  state = defaultState,
  action: LogBookActionPayload
) {
  switch (action.type) {
    case SET_CALLSIGN_DATA:
      return {
        ...state,
        callSignLookup: action.payload,
      };
    case SAVE_LOGBOOKS_DATA:
      return {
        ...state,
        logBooks: action.payload,
      };
    case SELECTED_LOGBOOK_DATA:
      return {
        ...state,
        selectedLogBook: action.payload,
      };
    case SAVE_CONTACTS_DATA:
      return {
        ...state,
        logBookContacts: action.payload,
      };
    case SAVE_ALL_CONTACTS_DATA:
      return {
        ...state,
        allContacts: action.payload,
      };
    case SAVE_ALL_CONTACTS_PAGINATED_DATA:
      return {
        ...state,
        allContactsListPaginated: action.payload,
      };
    case EDIT_CONTACT_MODAL_DATA:
      return {
        ...state,
        editContactModalData: action.payload,
      };
    case CONFIRM_CHANGES_MODAL_DATA:
        return {
          ...state,
          confirmChangesModalData: action.payload,
        };
    case SAVE_ALL_USER_CONTACTS_DATA:
      return {
        ...state,
        allUserContactsData: action.payload,
      };
    case SAVE_ALL_PAGINATED_USER_CONTACTS_DATA:
      return {
        ...state,
        allProfilePaginatedContacts: action.payload,
      };
    case SAVE_WORKED_USER_COUNTRIES:
      return {
        ...state,
        workedUserCountries: action.payload,
      };
    case SAVE_WORKED_USER_GRID_SQUARE:
      return {
        ...state,
        workedUserGridSquare: action.payload,
      };
    case SAVE_ALL_PAGINATED_LOGBOOK_CONTACTS_DATA:
      return {
        ...state,
        allLogBookPaginatedContacts: action.payload,
      };
    case SET_CONTACT_DETAIL:
      return {
        ...state,
        contactDetail: action.payload,
      };
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };
    case SET_DAILY_LEADERS:
      return {
        ...state,
        dailyLeaders: action.payload,
      };
    case ADD_SPOT_MODAL:
      return {
        ...state,
        addSpotModal: action.payload,
      };
    case ADD_RADIO_MODAL:
      return {
        ...state,
        addRadioModal: action.payload,
      };
    case ADD_ANTENNA_MODAL:
      return {
        ...state,
        addAntennaModal: action.payload,
      };
    case SET_SAVED_LOCATION:
      return {
        ...state,
        savedLocations: action.payload,
      };
    case SET_ANTENNAS:
      return {
        ...state,
        antennas: action.payload,
      };
    case SET_RADIOS:
      return {
        ...state,
        radios: action.payload,
      };
    case SAVE_CONTACT_POPUP_SUMMARY:
      return {
        ...state,
        addContactPopupSummary: action.payload,
      };
    case ADD_STATIONS_MODAL:
      return {
        ...state,
        addStationModal: action.payload,
      };
    case SET_STATIONS:
      return {
        ...state,
        stations: action.payload,
      };
    case MERGE_LOGBOOK_CONTACTS:
      return {
        ...state,
        mergeLogbook: action.payload,
      };
    case RESET_LOGBOOK_DATA:
      return {
        ...state,
        logBooks: [],
        selectedLogBook: null,
        logBookContacts: [],
        allContacts: [],
        callSignLookup: null,
        editContactModalData: null,
        allUserContactsData: [],
        workedUserCountries: [],
        workedUserGridSquare: [],
        contactDetail: null,
        searchResults: [],
        dailyLeaders: [],
        addSpotModal: false,
        addRadioModal: false,
        addAntennaModal: false,
        savedLocations: [],
        antennas: [],
        radios: [],
        addContactPopupSummary: null,
        addStationModal: null,
        stations: [],
        mergeLogbook: null,
        confirmChangesModalData: null,
        currentSearchString: "",
        quickSettingsCurrentTab: null
      };
    case SET_CURRENT_SEARCH_STRING:
      return {
        ...state,
        currentSearchString: action.payload
      };
    case QUICK_SETTINGS_CURRENT_TAB:
      return {
        ...state,
        quickSettingsCurrentTab: action.payload
      };
    default:
      return state;
  }
}

// GET STATE
export const getLogBookListSelector = (state: RootState) => {
  return state.logBook.logBooks || [];
};

export const getSelectedLogBookSelector = (state: RootState) => {
  return state.logBook.selectedLogBook;
};

export const getLogBookContactListSelector = (state: RootState) => {
  return state.logBook.logBookContacts || [];
};

export const getAllContactsListSelector = (state: RootState) => {
  return state.logBook.allContacts || [];
};

export const getAllContactsListPaginatedSelector = (state: RootState) => {
  return state.logBook.allContactsListPaginated;
};

export const getCallSignDataSelector = (state: RootState) => {
  return state.logBook.callSignLookup || null;
};

export const getEditContactModalDataSelector = (state: RootState) => {
  return state.logBook.editContactModalData || null;
};

export const getConfirmChangesModalDataSelector = (state: RootState) => {
  return state.logBook.confirmChangesModalData || null;
};

export const getAllUserContactsDataSelector = (state: RootState) => {
  return state.logBook.allUserContactsData || null;
};

export const getAllPaginatedUserContactsDataSelector = (state: RootState) => {
  return state.logBook.allProfilePaginatedContacts || null;
};

export const getWorkedUserCountriesSelector = (state: RootState) => {
  return state.logBook.workedUserCountries || [];
};

export const getWorkedUserGridSquareSelector = (state: RootState) => {
  return state.logBook.workedUserGridSquare || [];
};

export const getAllPaginatedLogBookContactsDataSelector = (
  state: RootState
) => {
  return state.logBook.allLogBookPaginatedContacts || null;
};

export const getContactDetailSelector = (state: RootState) => {
  return state.logBook.contactDetail || null;
};

export const getSearchResultsSelector = (state: RootState) => {
  return state.logBook.searchResults || null;
};

export const getDailyLeadersSelector = (state: RootState) => {
  return state.logBook.dailyLeaders || [];
};

export const getAddSpotModalSelector = (state: RootState) => {
  return state.logBook.addSpotModal || null;
};

export const getAddRadioModalSelector = (state: RootState) => {
  return state.logBook.addRadioModal;
};

export const getAddAntennaModalSelector = (state: RootState) => {
  return state.logBook.addAntennaModal;
};

export const getSavedLocationsSelector = (state: RootState) => {
  return state.logBook.savedLocations || [];
};

export const getAntennasSelector = (state: RootState) => {
  return state.logBook.antennas || [];
};

export const getRadiosSelector = (state: RootState) => {
  return state.logBook.radios || [];
};
export const getAddContactPopupSummarySelector = (state: RootState) => {
  return state.logBook.addContactPopupSummary || null;
};

export const getAddStationModalSelector = (state: RootState) => {
  return state.logBook.addStationModal || null;
};

export const getStationsSelector = (state: RootState) => {
  return state.logBook.stations || [];
};

export const getMergeLogbookSelector = (state: RootState) => {
  return state.logBook.mergeLogbook || null;
};

export const getQuickSettingsCurrentTabSelector = (state: RootState) => {
  return state.logBook.quickSettingsCurrentTab;
}