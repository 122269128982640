import React, { useEffect, useState } from "react";
import { MarkerF, InfoWindowF } from "@react-google-maps/api";
import MemberMapInfo from "components/MemberMapInfo";

import customMarkerIcon from "../../assets/Icons/memberMarker.svg";
import { globalNavigate } from "routes/GlobalRoutes";
import { MY_PROFILE_ROUTE } from "constants/Links";
import CustomValidation from "helpers/Validation";
import { Activities, ActivitiesMapData } from "types/Models";
import {
  getActivityIcon,
  getActivityLogo,
  getAddActivityIcon,
  getCountryName,
} from "helpers/Utils";
import Icons from "assets/Icons";
import CountryDxccFlag from "components/CountryDxccFlag";
import { UserAvatar } from "components/UserAvatar";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import PotaService from "services/pota.service";
import { auth } from "firebase-config";
import moment from "moment";
import ActivityIndicator from "components/Loading/ActivityIndicator";

// define the props interface
interface ActivityInfoProps {
  activity: ActivitiesMapData;
  index: number;
  setInfoVisibile: (value: boolean) => void;
  onCreateLogbook: (activity: ActivitiesMapData) => void;
  setInfoWindowOpen: (value: boolean) => void;
}

const ActivityInfo = ({
  activity,
  index,
  setInfoVisibile,
  onCreateLogbook,
  setInfoWindowOpen,
}: ActivityInfoProps) => {
  const [activityDetails, setActivityDetails] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getActivityDetails();
  }, []);

  const getActivityDetails = async () => {
    try {
      setLoading(true);

      let finalData: any = {};
      const activityDetail = await PotaService.fetchActivityDetail(
        activity?.reference
      );
      let userActivityDetail = null;
      if (auth?.currentUser?.uid) {
        userActivityDetail = await PotaService.fetchMyActivityDetail(
          auth?.currentUser?.uid,
          activity?.reference
        );
      }

      // if (activityDetail && activityDetail.length) {
      const activityDetailData = activityDetail[0] || {};
      finalData.lastActivationCall = activityDetailData?.myCallSign || "";
      finalData.activatorProfilePicture =
        activityDetailData?.myProfilePic || "";
      finalData.activatorCountry = activityDetailData?.myProfilePic || "";
      finalData.activatorName = activityDetailData?.myName || "";
      if (userActivityDetail) {
        finalData.myActivationCount = userActivityDetail?.activationsCount;
        finalData.huntCount = userActivityDetail?.huntsCount;
        finalData.wrlActivationCount = userActivityDetail?.wrlActivationsCount;
      }

      const contactSeconds = activityDetailData?.contactTimeStamp?.seconds
        ? activityDetailData?.contactTimeStamp?.seconds
        : activityDetailData?.contactTimeStamp?._seconds;
      finalData.lastWrlActivationDate = moment(
        new Date(contactSeconds * 1000)
      ).fromNow();
      //check if contactSeconds is within 30 minutes of current time
      const currentTime = new Date().getTime() / 1000;
      const diff = currentTime - contactSeconds;
      if (diff < 1800) {
        finalData.isLive = true;
      } else {
        finalData.isLive = false;
      }
      setLoading(false);
      console.log("finalData===", finalData);
      setActivityDetails(finalData);
      // }
      setLoading(false);
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }
  };

  const getLastActiviation = () => {
    if (activityDetails && activityDetails?.lastActivationCall) {
      return `${
        activityDetails?.lastWrlActivationDate
          ? activityDetails.lastWrlActivationDate
          : moment(activityDetails?.lastActivationDate, "DD/MM/YYYY").fromNow()
      } by ${activityDetails?.lastActivationCall}`;
    } else {
      if (activity?.lastActivationDate) {
        return `${moment(
          activity?.lastActivationDate,
          "DD/MM/YYYY"
        ).fromNow()} by ${activity?.lastActivationCall}`;
      } else {
        return "";
      }
    }
  };

  return (
    <div className="w-[300px] h-[350px] bg-[#fff] border-[2px] border-[#12CAA7] rounded-md px-2 text-[#010A41] font-[Play]">
      <div className="w-full flex md:justify-between">
        <div className="mt-2">
          <img
            src={getAddActivityIcon(activity.type || Activities.POTA)}
            alt=""
            className="w-[60px]"
          />
        </div>
        <img
          src={Icons.CloseCircle}
          alt=""
          className="w-[30px] cursor-pointer md:ml-0 ml-[100px]"
          onClick={() => {
            setInfoVisibile(false);
            setInfoWindowOpen(false);
          }}
        />
      </div>
      <div className="flex w-full justify-between">
        {activityDetails?.isLive ? (
          <img src={Icons.LiveIcon} alt="" className="w-[60px]" />
        ) : null}
      </div>
      <h2 className="font-bold mt-2">{activity?.reference}</h2>
      <h2 className="font-bold">{activity?.name}</h2>
      <div className="flex items-center">
        <CountryDxccFlag
          countryCode={getCountryName(activity?.country)}
          flagCode={""}
        />
        <p className="ml-2">
          {getCountryName(activity?.country)} - {activity?.state}
        </p>
      </div>
      <hr className="mt-2 border-[1px] border-[#E5E5E5]" />
      {loading ? (
        <div className="mt-5">
          <ActivityIndicator size={20} />
        </div>
      ) : (
        <div className="mt-2">
          <div className="flex justify-between items-center">
            <div>
              <p className="font-bold">Last Activated on:</p>
              <p className="">{getLastActiviation()}</p>
            </div>
            {activityDetails?.activatorProfilePicture ||
            activityDetails?.activatorCountry ? (
              <div className="relative flex items-center mr-3">
                {activityDetails?.activatorProfilePicture ? (
                  <img
                    className="w-[40px] h-[40px] rounded-full bg-[#f2f2f2]"
                    src={activityDetails?.activatorProfilePicture}
                    alt=""
                  />
                ) : (
                  <UserAvatar className="h-6 w-6" />
                )}
                <div className="absolute bottom-[-2px] right-[-5px]">
                  <CountryDxccFlag
                    countryCode={getCountryName(
                      activityDetails?.activatorCountry
                    )}
                    flagCode={""}
                    imageClassName="w-[15px] h-[15px]"
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="mt-2">
            <p className="font-bold">Number of times Activated:</p>
            <p className="">{activityDetails?.wrlActivationCount || 0}</p>
          </div>
          <div className="mt-2">
            <p className="font-bold">Your Stats:</p>
            <p className="">
              Activations: {activityDetails?.myActivationCount || 0} | Hunts:{" "}
              {activityDetails?.huntCount || 0}
            </p>
          </div>
          <div className="mt-2">
            <p className="font-bold">Latitude / Longitude:</p>
            <p className="">
              {activity?.latitude}, {activity?.longitude} (
              {activity?.grid6 || activity?.grid4})
            </p>
          </div>
        </div>
      )}
      <div className="flex justify-between w-full mt-8 flex-col md:flex-row">
        <div className="w-[50%] md-[45%] xl-[45%] mb-3">
          <a
            target="_blank"
            href={`https://www.google.com/maps/search/?api=1&query=${activity?.latitude},${activity?.longitude}`}
            rel="noreferrer"
          >
            <BorderButtonSecondary
              text={`OPEN IN MAPS`}
              onClick={() => {
                // open in google maps in new tab with lat long
              }}
              className="sm:mb-0 cursor-pointer"
              height="h-6"
              childClassName="text-xs bg-white text-[#010A41] font-bold"
            />
          </a>
        </div>
        <div className="w-[50%] md-[50%] xl-[50%]">
          <BorderButtonSecondary
            text={`+ CREATE LOGBOOK`}
            onClick={() => {
              onCreateLogbook(activity);
            }}
            className="sm:mb-0 cursor-pointer"
            height="h-6"
            childClassName="text-xs bg-white text-[#010A41] font-bold"
          />
        </div>
      </div>
    </div>
  );
};

export default ActivityInfo;
