import Icons from "assets/Icons";
import CountryDxccFlag from "components/CountryDxccFlag";
import { UserAvatar } from "components/UserAvatar";
import { Activities } from "types/Models";

interface LogBookSidebarProps {
  userProfile: any;
  qsoStats: any;
  bandStats: {
    topBands: { [key: string]: number };
    topBand: string;
    count: number;
  };
  modeStats: {
    topModes: { [key: string]: number };
    topMode: string;
    count: number;
  };
  dxccEntities: Array<{
    country: string;
    flagCode: string;
  }>;
  equipment: any;
}
const LogBookSidebar: React.FC<LogBookSidebarProps> = ({
  userProfile,
  qsoStats,
  bandStats,
  modeStats,
  dxccEntities,
  equipment,
}) => {
  return (
    <div className="w-[250px] h-full bg-black/20 px-4 pt-4 pb-1 flex flex-col gap-2">
      {/* User Profile */}
      <div className="flex items-start gap-4">
        <div className="relative flex-shrink-0">
          {userProfile.avatar ? (
            <img
              className="w-[65px] h-[65px] rounded-full"
              src={userProfile.avatar}
              alt="Profile"
            />
          ) : (
            <UserAvatar className="w-[65px] h-[65px]" />
          )}
          <div className="absolute bottom-0 right-0">
            <div className="w-[25px] h-[18px] rounded-sm">
              <CountryDxccFlag
                countryCode={userProfile.country}
                flagCode={userProfile.flagCode}
              />
            </div>
          </div>
        </div>
        <div className="min-w-0 flex-1">
          <h1 
            className={`font-bold font-['Barlow'] bg-gradient-to-b from-white via-white to-[#4AA0D3] bg-clip-text text-transparent break-words ${
              userProfile.callSign.length <= 7 ? 'text-3xl' : 'text-2xl'
            }`}
          >
           { qsoStats.defaultCallSign ||userProfile.callSign}
          </h1>
          <p className="text-lg font-bold font-['Barlow'] bg-gradient-to-b from-white via-white to-[#4AA0D3] bg-clip-text text-transparent break-words">
            {userProfile.name} -{" "}
            {userProfile.countryCode || userProfile.country}
          </p>
        </div>
      </div>

      {/* Logbook Title */}
      <div className="">
        <h2 className="text-md font-bold font-['Barlow'] text-white">
          {qsoStats.logBookName}
        </h2>
        <p className="text-md font-normal font-['Barlow'] text-white/80">
          {qsoStats.timestamp}
        </p>
      </div>

      {/* Activity Section */}
      {qsoStats.activity &&
      (qsoStats.activity.activityType ||
        qsoStats.activity.name ||
        qsoStats.activity?.reference ||
        qsoStats.activity.contestName ||
        qsoStats.activity.contestImage) ? (
        <div className="relative -mx-4">
          <div className="w-full h-[65px] bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] p-[1px] inline-flex">
            <div className="w-full dark:bg-[#1c265a] p-3 flex items-center gap-3">
              <div className="w-8 h-8 bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] p-[1px] rounded-lg flex items-center justify-center flex-shrink-0">
                <div className="w-full h-full bg-[#1c265a] rounded-lg flex items-center justify-center">
                  <img
                    src={
                      qsoStats.activity.contestImage ||
                      getActivityIcon(qsoStats.activity.activityType)
                    }
                    alt="Contest or Activity"
                    className="w-[85%] h-[85%] m-auto"
                  />
                </div>
              </div>
              <div className="flex flex-col min-w-0 gap-2">
                <p className="text-white text-md font-bold font-['Barlow'] leading-3 truncate">
                  {qsoStats.activity.contestName
                    ? qsoStats.activity.contestName
                    : `${qsoStats.activity.activityType}: ${qsoStats.activity?.reference}`}
                </p>
                {!qsoStats.activity.contestName && (
                  <p className="text-sm font-normal font-['Barlow'] leading-3 text-white/90 truncate">
                    {qsoStats.activity.name}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* QSO Stats */}
      <div className="w-full bg-white/10 backdrop-blur-[81.55px] p-3 rounded-tr-lg rounded-br-lg">
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-3">
            <img src={Icons.QsoPerMinIcon} className="w-6 h-6" alt="QSO" />
            <div className="font-['Barlow'] leading-3">
              <span className="text-white text-md font-bold">
                {qsoStats.total} QSO
              </span>
              <span className="text-white text-sm font-normal">
                {" "}
                in{" "}
                {qsoStats.duration < 24
                  ? `${qsoStats.duration.toFixed(2)} hours`
                  : `${(qsoStats.duration / 24).toFixed(2)} days`}
              </span>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <img src={Icons.SpeedometerIcon} className="w-6 h-6" alt="QSO" />
            <div className="font-['Barlow'] leading-3">
              <span className="text-white text-md font-bold">
                {qsoStats.rate.toFixed(2)} QSO/hr
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* Band Stats */}
      <div className="w-full bg-white/10 backdrop-blur-[81.55px] p-3 rounded-tr-lg rounded-br-lg">
        <div className="flex flex-col gap-2">
          {/* Title with Top Band */}
          <div className="flex items-center gap-1">
            <span className="text-white text-md font-bold font-['Barlow'] leading-3">
              {bandStats?.count || 0} Bands |
            </span>
            <span className="text-[#17f9da] text-sm font-bold font-['Barlow'] leading-3">
              Top Band {bandStats?.topBand}
            </span>
          </div>

          {/* Band Bars */}
          <div className="flex flex-col gap-1 mt-2">
            {Object.entries(bandStats?.topBands || {}).map(([band, count]) => {
              const maxCount = Math.max(
                ...Object.values(bandStats?.topBands || {})
              );
              return (
                <div key={band} className="flex items-center gap-[3px]">
                  <span className="w-10 text-white text-sm font-normal font-['Barlow'] leading-3">
                    {band}
                  </span>
                  <div className="flex-1 h-[7px] flex items-center">
                    <div
                      className="h-full bg-gradient-to-l from-[#4dffde] to-[#4da1ff]"
                      style={{ width: `${(count / maxCount) * 100}%` }}
                    />
                    <span className="ml-1 text-white text-sm font-normal font-['Barlow'] leading-3">
                      {count}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Mode Stats */}
      <div className="w-full bg-white/10 backdrop-blur-[81.55px] p-3 rounded-tr-lg rounded-br-lg">
        <div className="flex flex-col gap-2">
          {/* Title with Top Mode */}
          <div className="flex items-center gap-1">
            <span className="text-white text-md font-bold font-['Barlow'] leading-3">
              {modeStats?.count || 0} Modes |
            </span>
            <span className="text-[#17f9da] text-sm font-bold font-['Barlow'] leading-3">
              Top Mode {modeStats?.topMode.toUpperCase()}
            </span>
          </div>

          {/* Mode Bars */}
          <div className="flex flex-col gap-1 mt-2">
            {Object.entries(modeStats?.topModes || {}).map(([mode, count]) => {
              const maxCount = Math.max(
                ...Object.values(modeStats?.topModes || {})
              );
              return (
                <div key={mode} className="flex items-center gap-[3px]">
                  <span className="w-10 text-white text-sm font-normal font-['Barlow'] leading-3">
                    {mode.toUpperCase()}
                  </span>
                  <div className="flex-1 h-[7px] flex items-center">
                    <div
                      className="h-full bg-gradient-to-l from-[#4dffde] to-[#4da1ff]"
                      style={{ width: `${(count / maxCount) * 100}%` }}
                    />
                    <span className="ml-1 text-white text-sm font-normal font-['Barlow'] leading-3">
                      {count}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* DX Countries */}
      <div className="w-full bg-white/10 backdrop-blur-[81.55px] p-3 rounded-tr-lg rounded-br-lg">
        <h3 className="text-white text-md font-bold font-['Barlow'] leading-3 mb-2">
          {dxccEntities ? dxccEntities.length : 0} DX
        </h3>
        <div className="grid grid-cols-[repeat(auto-fill,minmax(24px,1fr))] gap-1 h-[52px] overflow-hidden">
          {dxccEntities
            ? Array.from(
                new Set(dxccEntities.map((entity) => JSON.stringify(entity)))
              )
                .map((str) => JSON.parse(str))
                .map((entity) => (
                  <CountryDxccFlag
                    key={entity.flagCode}
                    countryCode={entity.country}
                    flagCode={entity.flagCode}
                    imageClassName="rounded-full w-6 h-6"
                  />
                ))
            : null}
        </div>
      </div>
      {/* Equipment Info Section - Fixed width matching sidebar */}
      {(equipment && (equipment.radio || equipment.antenna || equipment.power)) && (
      <div className="w-[220px] ml-4 self-end bg-white/10 backdrop-blur-[81.55px] p-3 rounded-tr-lg rounded-br-lg">
          {equipment && (equipment.radio || equipment.antenna || equipment.power) && (
            <div className="flex flex-col justify-end gap-2">
              {equipment.radio && (
              <div className="flex items-center gap-2">
                <img src={Icons.RadioIcon} alt="Radio" className="w-4 h-4" />
                <span className="text-[#1F1F3F] dark:text-white text-sm font-normal font-['Barlow']">
                  {equipment.radio.model}
                </span>
              </div>
            )}
            {equipment.antenna && (
              <div className="flex items-center gap-2">
                <img src={Icons.AntennaIcon} alt="Antenna" className="w-4 h-4" />
                <span className="text-[#1F1F3F] dark:text-white text-sm font-normal font-['Barlow']">
                  {equipment.antenna.model}
                </span>
              </div>
            )}
            {equipment.power && (
              <div className="flex items-center gap-2">
                <img src={Icons.Power} alt="Power" className="w-4 h-4" />
                <span className="text-[#1F1F3F] dark:text-white text-sm font-normal font-['Barlow']">
                  {equipment.power}
                </span>
              </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const getActivityIcon = (type: string) => {
  if (type === Activities.SOTA) {
    return Icons.ActivitySOTA;
  } else if (type === Activities.WWFF) {
    return Icons.ActivityWWFF;
  } else if (type === Activities.IOTA) {
    return Icons.ActivityIOTA;
  } else {
    return Icons.ActivityPOTA;
  }
};

export default LogBookSidebar;
