import { DropDownProps, SubscriptionTypeArray } from "types/Component";

function DropDown({
  placeHolder,
  list,
  width,
  className,
  onChange,
  value,
}: DropDownProps) {
  return (
    <select
      className={`dark:bg-[#23234C] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none text-sm font-[Barlow] px-2 ${
        width ? width : "w-full"
      } ${className ? className : ""}`}
      onChange={onChange}
      value={value}
    >
      {placeHolder && (<option value="">{placeHolder}</option>)}
      {list.map((item: SubscriptionTypeArray, index) => {
        return (
          <option value={item?.subscriptionType} key={index}>
            {item?.subscriptionType}
          </option>
        );
      })}
    </select>
  );
}

export default DropDown;
