import React, { useEffect, useMemo, useRef } from "react";
import * as echarts from "echarts/core";
import { GridComponent, GridComponentOption } from "echarts/components";
import { BarChart, BarSeriesOption } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import useTheme from "hooks/useTheme.hook";

echarts.use([GridComponent, BarChart, CanvasRenderer]);

type EChartsOption = echarts.ComposeOption<
  GridComponentOption | BarSeriesOption
>;

// Helper function to determine max value for Y-axis
function getMaxYAxisValue(values: number[]): number {
  const maxValue = Math.max(...values);
  const increaseAmount = maxValue * 0.1; // 10% of the current max value
  const adjustedMax = maxValue + increaseAmount;

  // Ensure that the max value is at least 2 when the data is small (e.g., max is 1)
  const minBuffer = 2;
  const finalMax = adjustedMax < minBuffer ? minBuffer : adjustedMax;

  // Find the next multiple of the max value
  const nextMultiple = Math.ceil(finalMax / maxValue) * maxValue;

  return nextMultiple;
}

interface BarChartProps {
  labels: string[]; // Labels for the x-axis
  data: number[]; // Data for the bars
}

const BarChartComponent: React.FC<BarChartProps> = ({ labels, data }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<echarts.ECharts | null>(null);
  const { theme } = useTheme();
  const isDark = theme === "dark";

  // Chart options using useMemo for performance optimization
  const option: EChartsOption = useMemo(() => {
    const maxYAxisValue = getMaxYAxisValue(data);

    return {
      xAxis: {
        type: "category",
        data: labels,
        axisLabel: {
          color: isDark ?  "#FFFFFF": "#2b2e48", // Set x-axis label color to white
        },
        splitLine: {
          show: true, // Show horizontal grid lines
          lineStyle: {
            color: "rgba(255, 255, 255, 0.2)", // Light white grid lines
          },
        },
      },
      yAxis: {
        type: "value",
        max: maxYAxisValue, // Dynamically set max value
        axisLabel: {
          color: isDark ?  "#FFFFFF": "#2b2e48", // Set y-axis label color to white
        },
        splitLine: {
          show: true, // Show horizontal grid lines
          lineStyle: {
            color: isDark ? "rgba(255, 255, 255, 0.2)" : "rgba(0,0,0, 0.2)", // Light white grid lines
          },
        },
      },
      grid: {
        show: true,
        borderColor: isDark ?  "#FFFFFF": "#2b2e48", // White border around the grid
        borderWidth: 1,
      },
      series: [
        {
          data,
          type: "bar",
          itemStyle: {
            color: "#00C1FF", // Bar color
          },
          label: {
            show: true, // Show value above each bar
            position: "top",
            formatter: "{c}", // Display the value
            color: isDark ?  "#FFFFFF": "#2b2e48", // Set value label color to white
          },
        },
      ],
    };
  }, [labels, data]);

  useEffect(() => {
    if (chartRef.current) {
      // Initialize the chart
      if (!chartInstance.current) {
        chartInstance.current = echarts.init(chartRef.current);
      }
      chartInstance.current.setOption(option);

      // Handle resizing
      const handleResize = () => {
        chartInstance.current?.resize();
      };
      window.addEventListener("resize", handleResize);

      // Cleanup function
      return () => {
        window.removeEventListener("resize", handleResize);
        chartInstance.current?.dispose();
        chartInstance.current = null;
      };
    }
  }, [option]);

  return <div ref={chartRef} style={{ width: "100%", height: "350px" }} />;
};

export default BarChartComponent;
