import Icons from "assets/Icons";
import { getCurrentDateString, getCurrentUTCtime } from "helpers/Date/Time";
import { LogBookQuickSettingItem } from "pages/Dashboard/LogBook/LogBookQuickSettings";
import React, { useEffect, useState } from "react";

const ClockDisplay: React.FC = () => {
  const [timeLabel, setTimeLabel] = useState(getTimeLabel());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLabel(getTimeLabel());
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  function getTimeLabel() {
    return `${getCurrentDateString()} | ${getCurrentUTCtime()} z`;
  }

  return (
    <div>
      <LogBookQuickSettingItem
        onItemClick={() => {}}
        data={{
          id: 6,
          icon: Icons.ClockIcon,
          label: timeLabel,
        }}
      />
    </div>
  );
};

export default ClockDisplay;
