import React, { useEffect, useState } from "react";
import InputWithButton from "components/Inputs/InputWithButton";
import TitledInput from "components/Inputs/TitledInput";
import TitledTimeInput from "components/Inputs/TitledTimeInput";
import TitledCountryPicker from "components/Inputs/TitledCountryPicker";
import DropDownTitled from "components/DropDown/DropDownTitled";
import { Modes } from "constants/ArrayVariables";
import TitledRegionPicker from "components/Inputs/TitledRegionPicker";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import TitledInputGradient from "components/Inputs/TitledInputGradient";
import { useSelector } from "react-redux";
import { getContestDetailsSelector } from "store/Reducers/contest.reducer";
import { useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { LocationMappings } from "constants/LocationMappings";
import {
  formatBandValue,
  getDxDataFromCountry,
  setTabIndexForTimeInputs,
} from "helpers/Utils";
import SearchCountryPicker from "components/Inputs/SearchCountryPicker";
import { SelectableButtonsGroup } from "components/SelectableButtonsGroup";
import Icons from "assets/Icons";
import { TheirParkField } from "pages/Dashboard/LogBook/EditContact/TheirParksField";
import LogContactButton from "components/Button/AnimatedPrimaryButton";
import AnimatedPrimaryButton from "components/Button/AnimatedPrimaryButton";
import { useDuplicateCheck } from "hooks/useDuplicateCheck";

const N4MLLog = ({
  setValues,
  values,
  errors,
  handleChange,
  touched,
  initialData,
  setTouched,
  onLogPress,
  display,
  callSignBlurPopupHandler,
  userMode,
  setUserMode,
  setUserBand,
  selectedLogBook,
  isSavingOnLoading,
  date,
  setDate,
  setTime,
  currentTime,
  onFrequencyBlur,
  getUserFromHamApiData,
  isCallSignDataLoading,
  callSignData,
  handleClearAddContactForm,
  setStopRealTimeChange,
  userTimeInput,
  setUserTimeInput,
  checkLocationPermission,
  callSignRef,
  selectedSecondaryField,
  setSelectedSecondaryField,
  endSuccessAnimation,
  contactSuccess,
  spottingCopied,
  debounceChangeFrequencyCATControl,
  freqFromRadio,
  currentDate,
  setDuplicateInfo,
}: any) => {
  const contestDetail = useSelector(getContestDetailsSelector);
  let [searchParams] = useSearchParams();
  const { duplicateContactInfo } = useDuplicateCheck(values);

  useEffect(() => {
    if (freqFromRadio) {
      if (freqFromRadio !== values.frequency) {
        setValues((prev: any) => {
          return {
            ...prev,
            frequency: freqFromRadio,
          };
        });
      }
    }
  }, [freqFromRadio]);

  const debouncedCallsignInformation = useDebouncedCallback((searchString) => {
    if (searchString) {
      getUserFromHamApiData(searchString, setValues);
    } else {
      handleClearAddContactForm(initialData, setTouched, setValues);
    }

    callSignBlurPopupHandler(searchString);
  }, 800);

  const theirCallSignChangeHandler = (e: any) => {
    const theirCallSign = e.target.value.toUpperCase().trim();
    setValues((prev: any) => {
      return {
        ...prev,
        theirCallsign: theirCallSign,
      };
    });
    debouncedCallsignInformation(theirCallSign);
  };

  useEffect(() => {
    setTabIndexForTimeInputs("6", "7", "8");
  }, []);

  useEffect(() => {
    if (callSignData === "NOT_FOUND") {
      setValues((prev: any) => {
        let data = {
          ...prev,
          country: "",
          grid: "",
          theirName: "",
          qth: "",
          state: "",
        };
        return data;
      });
    }
  }, [callSignData]);

  const renderSecondaryFields = (values: any, setValues: any) => {
    if (selectedSecondaryField === 2) {
      return (
        <div className="flex items-start w-full gap-3">
          <TheirParkField {...{ values, setValues }} />
          <button
            type="button"
            className="flex items-center justify-center mb-[20px] p-2 bg-blue-600  h-[40px] w-[40px]"
            onClick={() => setSelectedSecondaryField(0)}
          >
            <img src={Icons.SendIcon} alt="" />
          </button>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    if (duplicateContactInfo) {
      setDuplicateInfo(duplicateContactInfo);
    } else {
      setDuplicateInfo(null);
    }
  }, [duplicateContactInfo]);

  return (
    <div className="w-full">
      <div className="flex flex-wrap gap-x-3">
        <TitledInput
          // setText={handleChange}
          setText={theirCallSignChangeHandler}
          name="theirCallsign"
          title="Their Callsign"
          placeHolder="Their Callsign"
          tabindex={1}
          // onBlur={() => {
          //   if (values.theirCallsign) {
          //     getUserFromHamApiData(values.theirCallsign, setValues);
          //   }
          //   callSignBlurPopupHandler(values.theirCallsign);
          // }}
          status={
            duplicateContactInfo && callSignData !== "NOT_FOUND" && callSignData
              ? `DUPE | ${formatBandValue(duplicateContactInfo.band)}`
              : callSignData !== "NOT_FOUND" && callSignData
              ? "Callsign Match"
              : null
          }
          statusBg={
            duplicateContactInfo && callSignData !== "NOT_FOUND" && callSignData
              ? "bg-gradient-to-r from-[#9B8647] to-[#C7B36D]"
              : callSignData !== "NOT_FOUND" && callSignData
              ? "bg-gradient-to-r from-[#8A24CA] to-[#651FFF]"
              : "bg-gradient-to-r from-[#FD9D82] to-[#FF6F46]"
          }
          isLoading={isCallSignDataLoading}
          width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] lg:w-[25%] xl:w-[18%]"
          value={values.theirCallsign}
          className={"mb-1.5"}
          error={
            errors?.theirCallsign && touched?.theirCallsign
              ? errors?.theirCallsign
              : undefined
          }
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
          props={{ innerRef: callSignRef }}
          flashField={spottingCopied}
        />
        <TitledInput
          setText={handleChange}
          name="rstSent"
          title="RST Sent"
          info='Signal report that you gave to the other operator. "59" is a good signal.'
          placeHolder="RST Sent"
          value={values.rstSent}
          tabindex={2}
          className={"mb-1"}
          width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] lg:w-[25%] xl:w-[13%]"
          error={
            errors?.rstSent && touched?.rstSent ? errors?.rstSent : undefined
          }
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
          flashField={spottingCopied}
        />
        <TitledInput
          setText={handleChange}
          name="rstRCVD"
          title="RST RCVD"
          info='Signal report that you received from the other operator. "59" would be a good signal.'
          placeHolder="RST RCVD"
          value={values.rstRCVD}
          tabindex={3}
          className={"mb-1"}
          width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] lg:w-[25%] xl:w-[13%]"
          error={
            errors?.rstRCVD && touched?.rstRCVD ? errors?.rstRCVD : undefined
          }
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
          flashField={spottingCopied}
        />
        <InputWithButton
          setText={(e) => {
            handleChange(e);
            // debounceChangeFrequencyCATControl(e.target.value);
          }}
          name="frequency"
          title="Frequency"
          info="Frequency on which you made the contact, you can get this from reading your radio."
          placeHolder="Frequency"
          onFieldBlur={() => {
            // debounceChangeFrequencyCATControl(values.frequency);
            onFrequencyBlur(values.frequency, setValues);
          }}
          tabindex={4}
          width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] lg:w-[25%] xl:w-[16%]"
          value={values.frequency}
          btnTxt="MHz"
          className={"mb-3 inline-block"}
          fitBtn
          error={
            errors?.frequency && touched?.frequency
              ? errors?.frequency
              : undefined
          }
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
          flashField={spottingCopied}
        />

        <DropDownTitled
          onChange={(e) => {
            handleChange(e);
            setUserMode(e.target.value);
          }}
          name="userMode"
          info="The mode of operation you are using on your radio."
          value={userMode ? userMode : ""}
          title="Mode"
          placeHolder="Mode"
          tabindex={5}
          className={"mb-1"}
          width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] lg:w-[25%] xl:w-[16%]"
          lists={Modes}
          error={
            errors?.userMode && touched?.userMode ? errors?.userMode : undefined
          }
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
        />


        <TitledTimeInput
          setText={(e) => {
            setStopRealTimeChange(true);
            setUserTimeInput(e);
            setTime(e);
            setValues((prev: any) => {
              return { ...prev, time: e };
            });
          }}
          name="time"
          title="Time (UTC)"
          placeHolder="00:00 AM"
          // tabindex={10}
          className={"mb-3"}
          width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] lg:w-[25%] xl:w-[15%]"
          type="time"
          value={userTimeInput ? values.time : currentTime}
          error={errors?.time && touched?.time ? errors?.time : undefined}
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
        />
        <TitledInput
          setText={(e) => {
            setValues((prev: any) => {
              return { ...prev, date: e.target.value };
            });
            setDate(e.target.value);
          }}
          name="date"
          title="Date"
          placeHolder="dd/mm/yyyy"
          tabindex={9}
          width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] lg:w-[25%] xl:w-[18%]"
          type="date"
          value={date ? date : currentDate}
          className={"mb-1"}
          error={errors?.date && touched?.date ? errors?.date : undefined}
          props={
            {
              // max: new Date().toJSON().slice(0, 10),
            }
          }
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
        />
        <SearchCountryPicker
          name="country"
          title="Their Country"
          info="Country of the other operator."
          tabindex={10}
          value={values?.country}
          countryCode={values?.countryCode}
          placeHolder="Country"
          dxccNumber={values?.dxccNumber || ""}
          width={
            values?.country && values?.country.length
              ? "w-[48.5%] sm:w-[23.5%] md:w-[18.7%] lg:w-[56.5%] xl:w-[27%]"
              : "w-[48.5%] sm:w-[23.5%] md:w-[18.7%] lg:w-[56.5%] xl:w-[27.5%]"
          }
          setText={(val) => {
            const dxccData = getDxDataFromCountry(val);
            setValues((prev: any) => {
              return {
                ...prev,
                country: val,
                grid: val !== values?.country ? "" : values?.grid,
                dxccNumber: dxccData?.dxccNumber || "",
                continent: dxccData?.continent || "",
                flagCode: dxccData?.flagCode || "",
                countryCode: dxccData?.countryCode || "",
                state: val !== values?.country ? "" : values?.state,
              };
            });
          }}
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
        />

        {values?.country && values?.country.length ? (
          <TitledRegionPicker
            setText={(val) => {
              setValues((prev: any) => {
                return {
                  ...prev,
                  state: val,
                  grid: val !== values?.state ? "" : values.grid,
                };
              });
            }}
            name="state"
            title="Their State"
            info="State of the other operator."
            placeHolder="State"
            tabindex={11}
            width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] lg:w-[25%] xl:w-[21%]"
            className={
              "mb-1 dark:!bg-[#23234C] !bg-[#f1f3f4] dark:!text-white !text-[#2b2e48]"
            }
            value={values?.state}
            error={errors?.state && touched?.state ? errors?.state : undefined}
            country={LocationMappings[`${values?.country}`] || values?.country}
            containerClass={
              display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
            }
          />
        ) : null}
        <TitledInput
          setText={handleChange}
          name="theirName"
          title="Their Name"
          placeHolder="Their Name"
          width="w-[48.5%] sm:w-[23.5%] md:w-[18.7%] lg:w-[25%] xl:w-[20%]"
          value={values.theirName}
          tabindex={12}
          className={"mb-3"}
          error={
            errors?.theirName && touched?.theirName
              ? errors?.theirName
              : undefined
          }
          containerClass={
            display ? "pointer-events-none mr-1 md:mr-4" : "mr-1 md:mr-4"
          }
        />
        <TitledInput
          setText={handleChange}
          name="notes"
          title="Notes"
          placeHolder="Notes"
          value={values.notes}
          width={"w-full sm:w-[49%] md:w-[62.5%] lg:w-[90%] xl:w-[70%]"}
          className={"mb-3"}
          tabindex={17}
          error={errors?.notes && touched?.notes ? errors?.notes : undefined}
          containerClass={display ? "pointer-events-none" : ""}
        />
      </div>
      {searchParams.get("contest_id") || contestDetail ? (
        <div className="flex flex-wrap gap-6 w-full items-center">
          {contestDetail?.exchangeOne ||
          contestDetail?.exchangeTwo ||
          contestDetail?.exchangeThree ||
          contestDetail?.exchangeFour ? (
            <p className=" dark:text-white text-[#2b2e48] font-[Play] text-sm font-bold">
              CONTEST EXCHANGE
            </p>
          ) : null}

          {contestDetail?.exchangeOne ? (
            <TitledInputGradient
              setText={handleChange}
              name="exchangeOne"
              title={contestDetail?.exchangeOne}
              placeHolder="Enter Answer"
              value={values.exchangeOne}
              width={"w-full sm:w-[47.5%] md:w-[18%] lg:w-[25%] xl:w-[18%]"}
              className={"placeholder-[#000]"}
              tabindex={13}
              error={undefined}
            />
          ) : null}
          {contestDetail?.exchangeTwo ? (
            <TitledInputGradient
              setText={handleChange}
              name="exchangeTwo"
              title={contestDetail?.exchangeTwo}
              placeHolder="Enter Answer"
              value={values.exchangeTwo}
              width={"w-full sm:w-[47.5%] md:w-[18%] lg:w-[25%] xl:w-[18%]"}
              className={"placeholder-[#000]"}
              tabindex={14}
              error={undefined}
            />
          ) : null}
          {contestDetail?.exchangeThree ? (
            <TitledInputGradient
              setText={handleChange}
              name="exchangeThree"
              title={contestDetail?.exchangeThree}
              placeHolder="Enter Answer"
              value={values.exchangeThree}
              width={"w-full sm:w-[47.5%] md:w-[18%] lg:w-[25%] xl:w-[18%]"}
              className={"placeholder-[#000]"}
              tabindex={15}
              error={undefined}
            />
          ) : null}
          {contestDetail?.exchangeFour ? (
            <TitledInputGradient
              setText={handleChange}
              name="exchangeFour"
              title={contestDetail?.exchangeFour}
              placeHolder="Enter Answer"
              value={values.exchangeFour}
              width={"w-full sm:w-[47.5%] md:w-[18%] lg:w-[25%] xl:w-[18%]"}
              className={"placeholder-[#000]"}
              tabindex={16}
              error={undefined}
            />
          ) : null}
        </div>
      ) : null}

      <div className="flex">
        <div className="w-full sm:w-[100%] md:w-full xl:w-[70%] flex justify-between mb-8 ml-3">
          <div className="flex items-end w-[47.5%]" tabIndex={18}>
            <AnimatedPrimaryButton
              isLoading={isSavingOnLoading}
              text={"Log Contact"}
              onClick={() => {
                // setUserBand("");
                // setUserMode("");
                // setDate(getCurrentDate());
                onLogPress && onLogPress();
              }}
              childClassName="text-sm dark:text-white text-[#2b2e48] w-[calc(100%-3px)] h-[calc(100%-3px)]"
              className={`${
                display ? "pointer-events-none" : ""
              } rounded-full bg-gradient-to-r from-[#37A4BA] via-[#496780] to-[#CF0971] w-full`}
              successState={contactSuccess}
              endAnimation={endSuccessAnimation}
            />
          </div>

          <div className="flex items-end w-[47.5%]" tabIndex={19}>
            <BorderButtonSecondary
              isLoading={false}
              text={"Clear"}
              onClick={() =>
                handleClearAddContactForm(
                  initialData,
                  setTouched,
                  setValues,
                  true
                )
              }
              childClassName="text-sm"
              className={display ? "pointer-events-none" : ""}
            />
          </div>
        </div>
      </div>

      <SelectableButtonsGroup
        initialSelected={selectedSecondaryField}
        toggle
        actions={[
          {
            id: 2,
            icon: Icons.parkWhite,
            label: "Their Park Number",
            tabIndex: 20,
          },
        ]}
        onButtonChange={(action) => {
          if (action && (action.id as number) > 0) {
            setSelectedSecondaryField(action.id as number);
          } else {
            setSelectedSecondaryField(-1);
          }
        }}
      />
      {selectedSecondaryField > 0 ? (
        <div className="flex w-full bg-indigo-500 bg-opacity-10  mt-4 items-center p-4">
          {renderSecondaryFields(values, setValues)}
        </div>
      ) : null}
    </div>
  );
};

export default N4MLLog;
