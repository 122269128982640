import React, { FC, useState } from "react";
import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import { WFDClassOptions, WFDSectionOptions, WFDTransmittersOptions } from "constants/Config";
import useLogbookSetting from "hooks/logbookSetting.hook";

type ModalProps = {
  onClose: () => void;
};

export const ContestModal: FC<ModalProps> = ({ onClose }) => {
  const { selectedLogbook, saveLogbookSettings } = useLogbookSetting();

  const [contestTransmitters, setContestTransmitters] = useState<string>(selectedLogbook?.contestTransmitters || "");
  const [contestClass, setContestClass] = useState<string>(selectedLogbook?.contestClass || "");
  const [contestSection, setContestSection] = useState<string>(selectedLogbook?.contestSection || "");


  const onSaveClickHandler = () => {
    saveLogbookSettings({ contestTransmitters, contestClass, contestSection });
    onClose();
  };

  const renderWFDSettings = () => {
    return (
      <div className="flex flex-wrap">
        <LogbookSettingDropdown
          list={WFDTransmittersOptions}
          title="Number of Transmitters:"
          placeholder="select option"
          value={contestTransmitters || ""}
          setValue={(value) => setContestTransmitters(value)}
          className="sm:w-[36%] md:w-[26%]"
        />
        <LogbookSettingDropdown
          list={WFDClassOptions}
          title="Class:"
          placeholder="select option"
          value={contestClass || ""}
          setValue={(value) => setContestClass(value)}
          className="sm:w-[36%] md:w-[26%]"
        />
        <LogbookSettingDropdown
          list={WFDSectionOptions}
          title="Section:"
          placeholder="select option"
          value={contestSection || ""}
          setValue={(value) => setContestSection(value)}
          className="sm:w-[36%] md:w-[26%]"
        />
      </div>
    );
  };

  return (
    <section className="absolute w-full h-screen top-0 left-0 z-[1000] flex justify-center glassmorphism py-[5%] px-[2%] md:px-[8%] lg:px-[12%] xl:px-[20%] overflow-scroll">
      <div className=" w-full">
        <div className="w-full">
          <div className="flex flex-col w-full dark:bg-[#151541] bg-white dark:text-white text-[#2b2e48] font-[Play] relative mt-20 border border-[#451836] px-[6%] py-[10%] gap-8">
            <div className="flex w-full flex-wrap relative">
              <div className="flex flex-col dark:text-white text-[#2b2e48] gap-4 w-full">
                <div className="font-bold font-Play text-xl">
                  Contest Settings
                </div>
                <div className="flex flex-wrap w-full">
                  {renderWFDSettings()}
                </div>
              </div>
            </div>

            <div className="flex w-full justify-center">
              <BorderButtonSecondary
                // isLoading={isLoading}
                text={"Save"}
                onClick={onSaveClickHandler}
                childClassName="text-sm"
                className="w-[48.5%] sm:w-[23.5%] md:w-[30.7%] xl:w-[30%] mt-3"
              />
            </div>
            <div
              className="absolute -top-2 -right-3 md:-right-3 cursor-pointer"
              onClick={onClose}
            >
              <img src={Icons.GlowingClose} className="h-8 w-8 invert dark:filter-none dark:invert-0" alt="close" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
