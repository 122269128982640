import React, { useEffect, useState } from "react";
import Header from "components/Header";
import Icons from "assets/Icons";
import { globalNavigate } from "routes/GlobalRoutes";
import DefaultSettings from "./DefaultSettings";
import { useDispatch } from "react-redux";
import {
  fetchAntennas,
  fetchRadios,
  fetchStations,
} from "store/Actions/logBook.actions";
// import useMap from "hooks/map.hook";

function DefaultLogbooksSettings() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRadios());
    dispatch(fetchAntennas());
    dispatch(fetchStations());
  }, [dispatch]);

  const onBackClickHandler = () => {
    globalNavigate("/log-contacts");
  };

  return (
    <main className={`relative w-full flex-grow overflow-x-auto h-full`}>
      <Header title="Add a Contact" />
      <div
        className={`w-full flex flex-col items-center pt-6 pb-7 sm:pb-10 overflow-x-hidden dashboardWrapper px-3 sm:px-7`}
      >
        <div className="flex flex-row w-full dark:text-white text-[#2b2e48] py-5 justify-between">
          <div
            className="flex flex-row gap-1 items-center cursor-pointer"
            onClick={onBackClickHandler}
          >
            <img src={Icons.BackIcon} alt="back button" className="invert dark:filter-none dark:invert-0" /> Back
          </div>
          <div className="flex items-center text-2xl font-Play gap-2">
            Default Settings for New Logbooks
          </div>
          <div></div>
        </div>

        <DefaultSettings />
      </div>
    </main>
  );
}

export default DefaultLogbooksSettings;
