import { LeaderBoardTabProps } from "types/Component";

function LeaderBoardTab({
  tabs,
  selectedTab,
  setSelectedTab,
  fetchUsersDataByMode,
}: LeaderBoardTabProps) {
  return (
    <div className="flex items-center overflow-y-hidden pb-[7px] px-2">
      {tabs.map((item, index) => {
        return (
          <div
            className="flex flex-col items-center justify-center w-fit mx-3 cursor-pointer"
            key={index}
            onClick={() => {
              fetchUsersDataByMode(item);
              setSelectedTab(item);
            }}
          >
            <p className="dark:text-white text-[#2b2e48] px-[5px] pb-1 text-sm font-[Play] font-bold">
              {item}
            </p>
            {selectedTab === item ? (
              <div className="bg-[#17F9DA] h-0.5 w-full"></div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

export default LeaderBoardTab;
