import EntriesTableComp from "components/Entries/EntriesTable";
import useLogBook from "hooks/logBook.hook";
import { useState } from "react";
import { IoCaretBack, IoCaretForward } from "react-icons/io5";
import { EntriesProps } from "types/Component";

import AlgoliaSearch from "components/AlgoliaSearch";

import { useSelector } from "react-redux";
import { getSearchResultsSelector } from "store/Reducers/logBook.reducer";

function Entries({
  lists,
  editable,
  pagination,
  paginateContact,
  missingFields,
  paginatedData,
  logbookId,
  isLoading,
  guestUser,
  userProfile,
  searchable,
  showSort,
  showExchange,
  exchangeOne,
  exchangeTwo,
  exchangeThree,
  exchangeFour,
  isCompact = false,
  isWFDLogBook = false,
}: EntriesProps) {
  const { isSavingOnLoading } = useLogBook();
  const searchResults = useSelector(getSearchResultsSelector);
  const [inputValue, setInputValue] = useState("");
  const [dateOrder, setDateOrder] = useState("");

  return (
    <section className="dark:bg-[#151541] bg-white mt-2.5 w-full pt-5 px-2.5 py-10 relative">
      {searchable && !guestUser ? (
        <AlgoliaSearch
          userProfile={userProfile}
          logbookId={logbookId}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
      ) : null}
      <EntriesTableComp
        lists={inputValue ? (searchResults ? searchResults : lists) : lists}
        isSavingOnLoading={isSavingOnLoading}
        isLoading={isLoading!!}
        editable={editable}
        missingFields={missingFields}
        guestUser={guestUser}
        showSort={showSort}
        setDateOrder={() => {
          const order = dateOrder === "asc" ? "desc" : "asc";
          setDateOrder(order);
          if (paginatedData && paginatedData.count && paginateContact) {
            const paginatedDataCustom: any = {
              dateOrder: order,
              page: 1,
              move: -2,
              logbookId: logbookId,
            };
            // if (paginatedData.page === 1) {
            //   paginatedDataCustom.move = -2;
            // } else {
            //   paginatedDataCustom.move = -2;
            //   paginatedDataCustom.firstDoc = paginatedData?.contacts[0];
            // }
            paginateContact(paginatedDataCustom, userProfile?.uid);
          }
        }}
        showExchange={showExchange}
        exchangeOne={exchangeOne}
        exchangeTwo={exchangeTwo}
        exchangeThree={exchangeThree}
        exchangeFour={exchangeFour}
        isCompact={isCompact}
        isWFDLogBook={isWFDLogBook}
      />
      {!isLoading &&
      pagination &&
      paginatedData &&
      paginatedData?.count &&
      !searchResults ? (
        <div className="flex justify-between items-center w-full mt-8">
          {paginatedData?.page * paginatedData?.contacts?.length ? (
            <p className="font-[Barlow] text-xs dark:text-white text-[#2b2e48]">
              Showing{" "}
              {((paginatedData.page || 1) - 1) * paginatedData.contacts.length +
                1}
              {" - "}
              {paginatedData?.count &&
              paginatedData?.page * paginatedData?.contacts?.length >=
                paginatedData?.count
                ? paginatedData?.count
                : paginatedData?.page * paginatedData?.contacts?.length}{" "}
              of {paginatedData?.count}
            </p>
          ) : null}
          <div className="flex justify-between items-center">
            {paginatedData?.page > 1 ? (
              <div
                className="bg-[#0243DD] h-7 w-7 flex justify-center items-center mr-3"
                onClick={() => {
                  if (paginateContact) {
                    paginateContact(
                      {
                        move: -1,
                        page: paginatedData?.page - 1,
                        firstDoc: paginatedData?.contacts[0],
                        lastDoc:
                          paginatedData?.contacts[
                            paginatedData?.contacts?.length - 1
                          ],
                        logbookId: logbookId,
                        dateOrder: dateOrder,
                      },
                      userProfile?.uid
                    );
                  }
                }}
              >
                <IoCaretBack color="#fff" />
              </div>
            ) : null}
            {paginatedData?.count &&
            paginatedData?.contacts &&
            paginatedData?.page * 50 < paginatedData?.count ? (
              <div
                className="bg-[#0243DD] h-7 w-7 flex justify-center items-center"
                onClick={() => {
                  if (paginateContact) {
                    paginateContact(
                      {
                        move: 1,
                        page: paginatedData?.page + 1,
                        firstDoc: paginatedData?.contacts[0],
                        lastDoc:
                          paginatedData?.contacts[
                            paginatedData?.contacts?.length - 1
                          ],
                        logbookId: logbookId,
                        dateOrder: dateOrder,
                      },
                      userProfile?.uid
                    );
                  }
                }}
              >
                <IoCaretForward color="#fff" />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </section>
  );
}

export default Entries;
