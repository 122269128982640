import LogbookSettingDropdown from "components/DropDown/DropDown/LogbookSettingDropdown";
import {
  BandOption,
  IsAssistedOption,
  WFDClassOptions,
  WFDSectionOptions,
  WFDTransmittersOptions,
} from "constants/Config";
import useContest from "hooks/contest.hook";
import { useLogBookSettingsForm } from "hooks/useLogBookSettingsForm.hook";
import { FC } from "react";

export const ContestSettings: FC = () => {
  const { values, setFormValue } = useLogBookSettingsForm();
  const { contestDetail } = useContest();

  const isWFDLogBook = contestDetail?.isWFDContest;

  const renderWFDSettings = () => {
    return (
      <div className="flex flex-wrap">
        <LogbookSettingDropdown
          list={WFDTransmittersOptions}
          title="Number of Transmitters:"
          placeholder="select option"
          value={values?.contestTransmitters || ""}
          setValue={(value) => setFormValue("contestTransmitters", value)}
          className="sm:w-[36%] md:w-[26%]"
        />
        <LogbookSettingDropdown
          list={WFDClassOptions}
          title="Class:"
          placeholder="select option"
          value={values?.contestClass || ""}
          setValue={(value) => setFormValue("contestClass", value)}
          className="sm:w-[36%] md:w-[26%]"
        />
        <LogbookSettingDropdown
          list={WFDSectionOptions}
          title="Section:"
          placeholder="select option"
          value={values?.contestSection || ""}
          setValue={(value) => setFormValue("contestSection", value)}
          className="sm:w-[36%] md:w-[26%]"
        />
      </div>
    );
  };

  const renderCommonSettings = () => {
    return (
      <div className="flex flex-wrap">
        <LogbookSettingDropdown
          title="Power:"
          value={values?.contestPower || ""}
          input
          type="number"
          setValue={(value) => setFormValue("contestPower", value)}
          className="sm:w-[36%] md:w-[26%]"
        />
        <LogbookSettingDropdown
          title="# of Transmitters:"
          input
          type="number"
          placeholder="Enter number"
          value={values?.numberOfTransmitters || ""}
          setValue={(value) => setFormValue("numberOfTransmitters", value)}
          className="sm:w-[36%] md:w-[26%]"
        />
        <LogbookSettingDropdown
          list={BandOption}
          title="Band:"
          placeholder="Enter band"
          value={values?.contestBand || ""}
          setValue={(value) => setFormValue("contestBand", value)}
          className="sm:w-[36%] md:w-[26%]"
        />
        <LogbookSettingDropdown
          list={IsAssistedOption}
          title="Assisted / Non- Assisted:"
          value={values?.assisted || ""}
          setValue={(value) => setFormValue("assisted", value)}
          className="sm:w-[36%] md:w-[26%]"
        />
        <LogbookSettingDropdown
          title="Operator:"
          input
          placeholder="Enter operator"
          value={values?.operator || ""}
          setValue={(value) => setFormValue("operator", value)}
          className="sm:w-[36%] md:w-[26%]"
        />
      </div>
    );
  };

  const renderContestSettings = () => {
    if (isWFDLogBook) {
      return renderWFDSettings();
    }

    return renderCommonSettings();
  };

  return (
    <div className="flex flex-col dark:text-white text-[#2b2e48] gap-4">
      <div className="font-bold font-Play text-xl">Contest Settings</div>

      {renderContestSettings()}
    </div>
  );
};
