import { GoogleMap, MarkerF } from "@react-google-maps/api";
import Icons from "assets/Icons";
import BorderButtonSecondary from "components/Button/BorderButtonSecondary";
import CountrySelector from "components/Inputs/CountrySelector";
import TitledDefaultRegionPicker from "components/Inputs/TitledDefaultRegionPicker";
import TitledInputDefault from "components/Inputs/TitledInputDefault";
import { mapStylesDark, mapStylesLight } from "constants/MapStyles";
import { getStateLongName } from "helpers/Utils";
import useTheme from "hooks/useTheme.hook";
import { FC, memo, useEffect, useMemo } from "react";
import { SavedLocation } from "types/Models";

export const MapLocation: FC<{
  locationForm: SavedLocation;
  setLocationForm: React.Dispatch<React.SetStateAction<SavedLocation>>;
  callback: (arg: any) => void;
  addressLoading?: boolean;
  getUserCurrentLocation: () => void;
  mode?: string;
  currentUserLocationFetched: boolean;
  setCurrentUserLocationFetched: (arg: boolean) => void;
}> = memo(
  ({
    locationForm,
    setLocationForm,
    callback,
    addressLoading,
    getUserCurrentLocation,
    mode,
    currentUserLocationFetched,
    setCurrentUserLocationFetched,
  }) => {
    const center = useMemo(
      () => ({
        lat: (locationForm.lat as number) || 37.0902,
        lng: (locationForm.lng as number) || 95.7129,
      }),
      [locationForm]
    );
    const { theme } = useTheme();

    useEffect(() => {
      if (
        !currentUserLocationFetched &&
        !locationForm.lat &&
        !locationForm.lng &&
        setCurrentUserLocationFetched
      ) {
        getUserCurrentLocation();
        setCurrentUserLocationFetched(true);
      }
    }, [locationForm.lat, locationForm.lng, currentUserLocationFetched]);

    const fieldPrefixColor =
      mode === "light"
        ? "bg-[#ffffff] text-slate-900"
        : "bg-blue-600 text-white";

    return (
      <div className="flex flex-col lg:flex-row gap-4">
        <div className="flex justify-center lg:flex-1 lg:items-center gap-4 relative h-[300px] mb-[40px]">
          <GoogleMap
            mapContainerClassName="map-container"
            center={center}
            zoom={2}
            options={{
              styles: theme === "dark" ? mapStylesDark : mapStylesLight,
              streetViewControl: false,
              rotateControl: false,
              mapTypeControl: true,
              fullscreenControl: true,
              scrollwheel: true,
            }}
            onClick={(e) => {
              if (e.latLng?.lat() && e.latLng?.lng()) {
                callback({
                  lat: e.latLng?.lat(),
                  lng: e.latLng?.lng(),
                });
              }
            }}
          >
            {locationForm.lat && locationForm.lng && (
              <MarkerF
                position={{
                  lat: locationForm.lat as number,
                  lng: locationForm.lng as number,
                }}
                draggable
                onDragEnd={(e) => {
                  if (e.latLng?.lat() && e.latLng?.lng()) {
                    callback({
                      lat: e.latLng?.lat(),
                      lng: e.latLng?.lng(),
                    });
                  }
                }}
              />
            )}
          </GoogleMap>

          <div className=" absolute -bottom-[40px] flex justify-center w-[50%]">
            <BorderButtonSecondary
              isLoading={addressLoading}
              text={"Find My Location"}
              onClick={getUserCurrentLocation}
              childClassName="text-sm"
            />
          </div>
        </div>
        <div className="flex flex-1 flex-col">
          <div className="w-[100%] relative mb-4 border border-[#3EB7D4] mt-2 flex pl-3 items-center pt-1 bg-[#10103D]">
            <img src={Icons.Info} className="w-[20px] h-[20px] mr-3" alt="" />
            <div className="flex py-1">
              <p className="text-white font-[Barlow] font-semibold text-xs">
                Hint:{" "}
              </p>
              <p className="text-white font-[Barlow] text-xs">
                Select your location on the map, and the other details will be
                pulled automatically
              </p>
            </div>
          </div>

          <div className="flex flex-row flex-wrap">
            <CountrySelector
              setText={(val) => {
                setLocationForm((prev) => ({
                  ...prev,
                  country: val,
                }));
              }}
              name="country"
              title="Country"
              placeHolder="Country"
              value={locationForm.country}
              className={"h-[36px] dark:!text-white"}
              tabindex={8}
              width={"w-full sm:w-[49%] md:w-[20.5%] xl:w-[30%]"}
              error={undefined}
              containerClass="mr-0 md:mr-3 sm:mr-3 mb-3"
            />
            {locationForm?.country ? (
              <TitledDefaultRegionPicker
                setText={(val) => {
                  setLocationForm((prev) => ({
                    ...prev,
                    state: val,
                  }));
                }}
                name="state"
                title="State"
                placeHolder="State"
                tabindex={9}
                width="w-full sm:w-[23.5%] md:w-[18.7%] xl:w-[30%]"
                className={"mt-3 h-[36px] dark:!bg-[#23234C] dark:!text-white"}
                value={getStateLongName(locationForm.state || "")}
                error={undefined}
                country={locationForm.country}
                containerClass="mr-0 md:mr-3 sm:mr-3 mb-3"
              />
            ) : null}

            <TitledInputDefault
              setText={(e) => {
                setLocationForm((prev) => ({
                  ...prev,
                  city: e.target.value,
                }));
              }}
              name="city"
              title="City"
              placeHolder="Enter City"
              value={locationForm.city}
              width={"w-full sm:w-[49%] md:w-[20.5%] xl:w-[30%]"}
              className={"h-[36px] mt-[12px] mr-0 md:mr-3 sm:mr-3"}
              tabindex={20}
              error={null}
              containerClass="mr-0 md:mr-5"
            />
          </div>

          <div className="flex items-center justify-between flex-wrap">
            <div className="flex flex-wrap font-[Play] text-sm gap-3">
              <div className="flex items-center mb-2">
                <div className={`${fieldPrefixColor} px-2 h-[36px] pt-1.5`}>
                  Lat.
                </div>
                <input
                  className="dark:bg-[#23234C] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none px-2 h-[36px] md:w-[100px]"
                  value={locationForm.lat}
                  disabled
                  onChange={(e) => {
                    setLocationForm((prev) => ({
                      ...prev,
                      lat: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="flex items-center mb-2">
                <div className={`${fieldPrefixColor} px-2 h-[36px] pt-1.5`}>
                  Lon.
                </div>
                <input
                  className="dark:bg-[#23234C] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none px-2 h-[36px] md:w-[100px]"
                  value={locationForm.lng}
                  disabled
                  onChange={(e) => {
                    setLocationForm((prev) => ({
                      ...prev,
                      lng: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="flex items-center mb-2">
                <div className={`${fieldPrefixColor} px-2 h-[36px]  pt-1.5`}>
                  Grid
                </div>
                <input
                  className="dark:bg-[#23234C] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none px-2 h-[36px] md:w-[100px]"
                  value={locationForm.gridsquare}
                  disabled
                  onChange={(e) => {
                    setLocationForm((prev) => ({
                      ...prev,
                      gridsquare: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="flex items-center mb-2">
                <div className={`${fieldPrefixColor} px-2 h-[36px]  pt-1.5`}>
                  ITU
                </div>
                <input
                  className="dark:bg-[#23234C] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none px-2 h-[36px] md:w-[100px]"
                  value={locationForm.ituZone}
                  disabled
                  onChange={(e) => {
                    setLocationForm((prev) => ({
                      ...prev,
                      ituZone: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="flex items-center mb-2">
                <div className={`${fieldPrefixColor} px-2 h-[36px]  pt-1.5`}>
                  CQ
                </div>
                <input
                  className="dark:bg-[#23234C] bg-[#f1f3f4] dark:text-white text-[#2b2e48] border border-[#dcdcdd] dark:border-none px-2 h-[36px] md:w-[100px]"
                  value={locationForm.cqZone}
                  disabled
                  onChange={(e) => {
                    setLocationForm((prev) => ({
                      ...prev,
                      cqZone: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
