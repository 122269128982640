import { calculateLogBookAnalytics } from "firebase-config";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getAllPaginatedLogBookContactsDataSelector } from "store/Reducers/logBook.reducer";

interface ActivityStats {
  bands: {
    [key: string]: number;
  };
  modes: {
    [key: string]: number;
  };
  activityStats: {
    totalQsos: number;
    timespan: number;
    qsoRate: number;
    timestamp: string;
    bandStats: any;
    modeStats: any;
    dxCountries: any;
    bands: string[];
    modes: string[];
  };
}

export const useLogBookStats = () => {
  const allPaginatedLogbookContacts = useSelector(
    getAllPaginatedLogBookContactsDataSelector
  );

  const [logBookAnalytics, setLogBookAnalytics] = useState<ActivityStats | null>(null);
  const [loading, setLoading] = useState(false);
  
  const getLogBookStats = () => {
    const qsoArray =
      allPaginatedLogbookContacts?.contacts &&
      Array.isArray(allPaginatedLogbookContacts?.contacts)
        ? [...allPaginatedLogbookContacts?.contacts]
        : [];

    if (!qsoArray.length) return null;

    const stats = allPaginatedLogbookContacts?.contacts?.map(
      (contact) => contact.state
    );
    const countries = allPaginatedLogbookContacts?.contacts?.map(
      (contact) => contact.country
    );

    let maxDistance = allPaginatedLogbookContacts?.contacts?.[0];

    allPaginatedLogbookContacts?.contacts?.forEach((contact) => {
      if (
        contact?.distance &&
        maxDistance?.distance &&
        contact?.distance > maxDistance?.distance
      ) {
        maxDistance = contact;
      }
    });

    // Sort the QSO array by date and time
    qsoArray.sort((a, b) => {
      const dateTimeA: any = moment(
        `${a.date} ${a.time}`,
        "YYYY-MM-DD HH:mm:ss"
      );
      const dateTimeB: any = moment(
        `${b.date} ${b.time}`,
        "YYYY-MM-DD HH:mm:ss"
      );
      return dateTimeA - dateTimeB;
    });

    // Calculate time differences between consecutive QSOs
    const timeDifferences = [];
    for (let i = 1; i < qsoArray.length; i++) {
      const dateTime1 = moment(
        `${qsoArray[i - 1].date} ${qsoArray[i - 1].time}`,
        "YYYY-MM-DD HH:mm:ss"
      );
      const dateTime2 = moment(
        `${qsoArray[i].date} ${qsoArray[i].time}`,
        "YYYY-MM-DD HH:mm:ss"
      );
      const diff = dateTime2.diff(dateTime1, "seconds"); // Time difference in seconds
      timeDifferences.push(diff);
    }

    // Convert time differences to minutes
    const timeDifferencesInMinutes = timeDifferences.map((diff) => diff / 60); // Convert seconds to minutes

    // Calculate the average number of QSOs per minute
    const totalMinutes = timeDifferencesInMinutes.reduce(
      (acc, val) => acc + val,
      0
    );
    const averageQSOsPerMinute = totalMinutes
      ? qsoArray.length / totalMinutes
      : qsoArray.length || 0;

    // Get the date and time of the last QSO
    const lastQSODate = qsoArray[qsoArray.length - 1].date;
    const lastQSOTime = qsoArray[qsoArray.length - 1].time;

    // Calculate the difference between the last QSO's date and time and the current date and time
    const lastQSODateTime = moment.utc(
      `${lastQSODate} ${lastQSOTime}`,
      "YYYY-MM-DD HH:mm:ss"
    );
    const currentDateTime = moment.utc();
    const timeDifference = moment.duration(
      currentDateTime.diff(lastQSODateTime)
    );

    // Get the current UTC date
    const currentUTCDate = moment.utc().format("YYYY-MM-DD");

    // Count the number of contacts made today in UTC time
    const contactsMadeToday = qsoArray.filter(
      (contact) => contact.date === currentUTCDate
    ).length;

    return {
      stats: Array.from(new Set(stats)).length,
      countries: Array.from(new Set(countries)).length,
      maxDistance,
      averageQSOsPerMinute,
      lastQSODateTime: timeDifference.humanize(),
      totalQsos: contactsMadeToday,
    };
  };

  const getLogBookAnalytics = async(logBookId: string, userId: string, includeActivity: boolean = false) => {
    try {
      setLoading(true);
      const requestPayload = {
        logBookId,
        userId,
        includeActivity,
      };
    
      const analytics: any = await calculateLogBookAnalytics(requestPayload);

      setLogBookAnalytics(analytics?.data);
      setLoading(false);
    } catch (error) {
      console.log('Error getting logbook analytics', error);
      setLoading(false);
      return null;
    }

  }

  return {
    logBookAnalytics,
    loading,
    getLogBookStats,
    getLogBookAnalytics,
  };
};
